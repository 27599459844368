
.banner-item{
  font-weight: 400;
  &-inner{
    display: flex;
    flex-direction: row;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    &:before{
      content:"";
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.3s ease-out;
    }
    &:after{
      //content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-out;
    }
    &-content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding:3rem;
      width: 100%;
      position: absolute;
      height: 100%;
      z-index: 33;
    }
  }
  &-helper{
    width: 100%;
  }
  &:focus,
  &:hover{
    .banner-item-inner:before{
      opacity: 0.8;
      //transform: scale(1.25);
    }
    .banner-item-inner:after{
      opacity: 0.75;
    }
  }
  .h2{
    font-size: 21px;
    @include media(sm){
      font-size: 25px;
    }
    @include media(md){
      font-size: 28px;
    }
    @include pux-scale-from("font-size", xl, 30px);
  }
}

.banner-item.style-1,
.banner-item.style-2,
.banner-item.style-3,
.banner-item.style-4{
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden;
  .banner-item-bgr{
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-out;
  }
  .banner-item-inner{
    min-height: 219px;
    @include media(md){
      min-height: 30.4rem;
    }
    @include media(lg){
      min-height: 25.4rem;
    }
  }
  &:hover,
  &:focus{
    .banner-item-bgr{
      //@include transform(scale(1.05));
    }
  }
}

.banner-item.style-1{
  .banner-item-inner{
    &-content{
      &-title{
        padding: 1rem 3rem;
        margin: 0;
      }
      &-btn{
        padding: 1rem 3rem;
        letter-spacing: 0.28px;
        font-weight: 700;
        transition: all 0.3s ease-out;
        &.btn-banner{
          text-transform: none;
          border-radius: 50px;
          margin:1rem 0 0 0;
        }
      }
    }
  }
}

.banner-item.style-2{
  &:hover,
  &:focus{
    .banner-item-inner-content-row-btn{
      @include transform(translateX(0.5rem));
    }
  }
  .banner-item-inner{
    &-content{
      align-items: flex-start;
      justify-content: flex-end;
      &-title{
        padding: 1rem 3rem;
        margin: 0;
        display: inline-block;
      }
      &-row{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem 0 0 0;
        width: 100%;
        text-align: left;
        &-perex{
          padding:2rem 2rem 0 3rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        &-btn{
          padding: 0 0 0 2rem;
          transition: all 0.3s ease-out;
          &:after{
            content: "\e99f";
            display: block;
            font-size: 30px;
          }
        }
      }
    }
    &:before{
      opacity: 1;
    }
  }
}

.banner-item.style-3{
  &:hover,
  &:focus{
    .banner-item-inner-content-btn{
      @include transform(translateY(0));
    }
  }
  .banner-item-inner{
    &-content{
      flex-direction: column;
      justify-content: space-between;
      padding: 0 3rem;
      &-title{
        padding: 1rem 3rem;
        margin: 0;
      }
      &-btn{
        padding: 1.5rem 3rem 2.5rem;
        letter-spacing: 0.28px;
        font-weight: 700;
        @include transform(translateY(1rem));
        transition: all 0.3s ease-out;
      }
    }
  }
}

.banner-item.style-4{
  .banner-item-inner{
    &-content{
      &-title{
        padding: 1rem 3rem;
        margin: 0;
        @include transform(translateY(-4rem));
      }
      &-btn{
        padding: 1.5rem 3rem;
        letter-spacing: 0.28px;
        font-weight: 700;
        transition: all 0.3s ease-out;
        position: absolute;
        bottom: 0;
        left: 50%;
        @include transform(translate(-50%,0));
      }
    }
  }
}

.banner-box.dynamic-box{
  width: 100%;
  @include media(sm){
    width: 100%;
  }
  @include media(md){
    width: 50%;
  }
  @include media(lg){
    width: 33.33%;
  }
  &.box-single{
    max-width: 78rem;
  }
}

.banner-box.box-single{
  .banner-item-inner{
    @include media(md){
      min-height: 30.4rem;
    }
  }
  .h2{
    font-size: 23px;
    @include media(sm){
      font-size: 28px;
    }
    @include media(md){
      font-size: 33px;
    }
    @include pux-scale-from("font-size", xl, 36px);
  }
}

.banner-box.box-half{
  width: 100%;
  .banner-item-inner{
    @include media(md){
      min-height: 30.4rem;
    }
    @include media(lg){
      min-height: 39.1rem;
    }
  }
  @include media(md){
    width: 50%;
  }
  .h2{
    font-size: 23px;
    @include media(sm){
      font-size: 28px;
    }
    @include media(md){
      font-size: 33px;
    }
    @include pux-scale-from("font-size", xl, 36px);
  }
}
