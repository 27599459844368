
.wizard-container{
  max-width: 1500px;
  margin:  0 auto;
  border-radius: 1rem;
  min-height: 820px;
  @media (min-width: 500px) {
    min-height: auto;
  }
  .ErrorLabel{
    padding: 2rem 2rem 0 2rem;
  }
  .personal-btn{
    //display: none;
  }
  .full-btn{
    display: none;
  }
  .personal-wizard{
    .full-btn{
      display: inline-flex;
    }
    .personal-btn{
      display: none;
    }
  }

  .hide-input input{
    display: none;
  }
  .date-container{
    display: flex;
    gap: 15px;
    flex-direction: column;
    @include media(sm){
      flex-direction: row;
    }
    .data-item{
      width: 100%;
    }
  }
  select.form-control:not([size]):not([multiple]){
    height: 100%;
  }
}

.wizard-bottom{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin:6rem 0 0 0;
  padding: 6rem 0 8rem 0;
  gap: 2rem;
  border-top-width: 1px;
  border-top-style: solid;
  &.jc-end{
    justify-content: flex-end;
  }
  &.jc-between{
    justify-content: space-between;
  }
  .btn.disabled{
    cursor: not-allowed;
  }
}


.wizard-step{
  display: none;
  width: 100%;
  position: relative;
  padding: 80px 20px;
  @include media(xl){
    max-width: 940px;
    margin: 0 auto;
    padding: 0 20px;
  }
  .file-placeholder{
    display: none !important;
    &.active{
      display: flex !important;
    }
  }
  .file-delete{
    cursor: pointer;
    display: none !important;
    pointer-events: all;
    &:before{
      content: "\e9a1";
    }
    &.active{
      display: flex !important;
    }
  }
  &.active{
    display: block;
  }
  .wizard-progress-layout{
    display: flex;
    gap: 5px;
    .wizard-progress-left{
      width: 5px;
    }
    .wizard-progress-right{
      width: 100%;
    }
  }
  .witard-progress{
    height: 100%;
    background-color: #c1c1c1;
    width: 5px;
    position: sticky;
    top: 70px;
    transform: translateX(-10px);
    transition: 250ms;
    @include media(md){
      transform: translateX(-30px);
    }
    @include media(xl){
      transform: translateX(-50px);
    }
    .witard-progress-inner{
      width: 5px;
      background-color: green;
    }
  }

  .form-item{
    padding: 0;
    width: 100%;
    position: relative;
    @include media(md){
      width: calc(50% - 2rem);
    }
    &.w100{
      width: 100%;
    }
    &.w50{
      @include media(md){
        width: 50%;
      }
    }
    &.w50-md{
      @include media(sm){
        width: 100%;
      }
      @include media(md){
        width: 50%;
      }
    }
    &.border-left{
      &:before{
        @include media(md){
          content: "";
          width: 1px;
          height: 100%;
          position: absolute;
          top: 0;
          left: -10px;
        }
      }
    }
  }
  .form-section{
    border-width: 1px;
    border-style: solid;
    padding: 2rem;
    border-radius: 4px; 
    & + .form-section{
      margin-top: 3rem;
    }
    & + .form-item{
      margin-top: 3rem;
    }
  }
  .form-row{
    gap: 3rem 4rem;
  }
  .grouped{
    .editing-form-control-nested-control + .editing-form-control-nested-control,
    .editing-form-control-nested-control + .gruped-padding{
      //display: none;
      margin: 10px 0 0 0;
    }
    &.open{
      .editing-form-control-nested-control + .editing-form-control-nested-control,
      .editing-form-control-nested-control + .gruped-padding{
        display: block;
      }
    }
  }
  .label-title{
    margin: 0 0 10px 0;
    display: block;
    font-weight: 600;
  }
  .form-item-row{
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
    .EditingFormLabel{
      margin: 0;
    }
    &.grouped{
      .editing-form-control-nested-control + .editing-form-control-nested-control{
        width: 100%;
      }
    }
    .checkbox-list-horizontal{
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
  }
  .radio-list-horizontal{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .radio-list-vertical{
    display: flex;
    gap: 10px;
    flex-direction: column;
    br{
      display: none;
    }
  }
  .wizard-section-title{
    font-weight: 700;
    margin: 3rem 0 1rem 0;
    @include pux-scale-with-min("font-size", 20px, 18px);
    @include pux-scale-with-min("line-height", 20px, 18px);
  }
  .EditingFormLabel{
    font-weight: 600;
    font-size: 16px;
    @include pux-scale-from("font-size", lg, 16px);
    line-height: normal;
    margin:0 0 1.5rem 0;
    display: inline-block;
    span{
      font-weight: 400;
    }
  }
  .wizard-step-subtitle{
    @include pux-scale-with-min("font-size", 19px, 17px);
    @include pux-scale-with-min("line-height", 19px, 16px);
    font-weight: 700;
    padding-bottom: 3rem;
  }
  .wizard-line{
    width: 100%;
    height: 1px;
    margin: 2rem 0;
  }
  .wizard-step-title{
    margin: 0 0 6rem 0;
  }
  .selectric,
  .form-control, select, select.form-control, input[type="text"]{
    border-radius: 3px;
    @include pux-scale-with-min("height", 56px, 46px);
    padding: 0 2rem;
  }
  .selectric{
    border-width: 1px;
    border-style: solid;
    padding: 0;
  }
  .selectric-wrapper:focus, .selectric-wrapper:hover{
    box-shadow: unset;
  }
  .textarea, textarea.form-control{
    height: 112px;
    padding: 1.5rem 1.8rem;
  }
  .radio input[type="radio"] + label{
    font-size: 15px;
  }
  .radio input[type="radio"]{
    width: 18px;
    height: 18px;
  }
  .wizard-step-desc{
    margin-top: 10px;
    display: none
  }
  .line{
    width: 100%;
    height: 1px;
    margin: 6rem 0;
  }
  [class*="open-"]{
    //display: none;
  }
  .open-ano,
  .open-zazadano,
  .open-ne{
    margin-top: 1rem;
  }
  .wizard-bottom .EditingFormLabel,
  .personal-detail-text{
    width: 100%;
    text-align: center;
  }
  .form-item.open-ne{
    //padding-left: 4rem;
    .EditingFormLabel{
      display: none;
    }
  }
  .gruped-padding{
    padding-left: 4rem;
  }
}

.wizard-top{
  padding: 8rem 0;
  border-right-width: 1px;
  border-right-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  min-height: 820px;
  height: 100%;
  left: -130px;
  transition: 250ms;
  @media (min-width: 500px) {
    position: static;
    min-height: unset;
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;
  }
  @include media(xl){
    border:none;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
}

.wizard-block{
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 3rem;
}

.wizard-menu{
  display: flex;
  gap: 0 2rem;
  flex-direction: column;
  width: 127px;
  @include media(xl){
    flex-direction: row;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    align-items: center;
    justify-content: center;
    width: auto;
  }
  &-link{
    padding: 1rem 1.5rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    font-size: 16px;
    min-width: 127px;
    @include pux-scale-from("font-size", lg, 16px);
    @include media(xl){
      display: inline-flex;
      padding: 0 0.5rem  1.5rem 0.5rem;
      min-width: 96px;
    }
    @media (min-width: 1500px) {
      min-width: 127px;
      padding: 0 1.5rem  2.5rem 1.5rem;
    }
    &:after{
      content:"";
      width: 3px;
      height: 100%;
      position: absolute;
      right:-1px;
      bottom: 0;
      z-index: 22;
      opacity: 0;
      @media (min-width: 500px) {
        width: 1px;
      }
      @include media(xl){
        content:"";
        width: 100%;
        height: 1px;
        bottom: -1px;
        left:0;
        opacity: 0;
      }
    }
    span{
      display: block;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      border-radius: 50%;
      transition: 250ms;
    }
    &:hover{
      text-decoration: none;
    }
    &:focus,
    &.active{
      text-decoration: none;
      &:after{
        opacity: 1;
      }
    }
    &.disabled{
      cursor: default;
    }
    &.invalid{
      align-items: center;
      position: relative;
      &:before{
        content: "\0021";
        font-family: "proxima-nova", sans-serif !important;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background-color: #d40511;
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        top: 7px;
        left: 20px;
        position: absolute;
        @include media(xl){
          top: -25px;
          left: unset;
        }
      }
    }
  }
}

.scrolled.up{
  .wizard-step .witard-progress{
    top: 140px;
  }
}

.scrolled.down{
  .wizard-step .witard-progress{
    top: 100px;
  }
}

.wizard-container-inner{
  display: flex;
  position: relative;
  overflow: hidden;
  @media (min-width: 500px) {
    overflow: unset;
  }
  .wizard-close{
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    z-index: 11;
    &:before{
      content: "\e9ce";
    }
    @media (min-width: 500px) {
      display: none;
    }
  }
  &:before{
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.4);
    z-index: 11;
    position: absolute;
    border-radius: 1rem;
    transition: 250ms;
    @media (min-width: 500px) {
      display: none;
    }
  }
  min-height: 820px;
  @media (min-width: 500px) {
    min-height: auto;
  }
  @include media(md){
    gap: 0 3rem;
  }
  @include media(xl){
    display: block;
  }
  &.open-menu{
    &:before{
      width: 100%;
    }
    .wizard-close:before{
      content:"\e9a1";
    }
    .wizard-top{
      left: 0;
    }
  }
}

.wizard-highlight{
  padding: 1rem;
  border: 1px solid red;
  display: flex;
  align-items: center;
    &:before{
      content: "\0021";
      font-family: "proxima-nova", sans-serif !important;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      background-color: #d40511;
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: flex-end;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      margin-right: 1rem;
      min-width: 20px;
    }
}

.wizard-download{
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  max-width: 1024px;
}

.wizard-download-item{
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 3.3rem 3.5rem;
  font-weight: 400;
  width: 100%;
  @include media(md){
    width: calc(50% - 1.5rem);
  }
  .icon-pdf{
    @include pux-scale-with-min("font-size", 25px, 40px);
  }
  .wizard-download-item-ico{
    margin-right: 1.5rem;
  }
  .wizard-download-item-size{
    margin-left: 5px;
    margin-right: 1.5rem;
    white-space: nowrap;
    //display: none;
    @include media(xl){
      display: block;
    }
  }
  &:after{
    @include media(lg){
      content: "\e92b";
      @include pux-scale-with-min("width", 25px, 35px);
      @include pux-scale-with-min("height", 25px, 35px);
      @include pux-scale-with-min("min-width", 25px, 35px);
      border-radius: 50%;
      //border-width: 1px;
      //border-style: solid;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.2rem;
      @include pux-scale-with-min("font-size", 16px, 22px);
      margin-left: auto;
    }
  }
}

.wizard-file{
  .file-not-valid{
    display: none;
  }
  .extension-not-valid{
    display: none;
  }
  &.not-valid{
    .file-not-valid{
      display: block;
    }
  }
  &.not-extension{
    .extension-not-valid{
      display: block;
    }
  }
  &.input-full{
    .file-label{
      pointer-events: none;
    }
    .file-button{
      opacity: 0.4;
    }
  }
}

.dtp-picker-years{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 6px;
  .year-picker-item{
    padding-top: 0;
  }
}

.dtp-actual-num{
  display: none;
}

.dtp div.dtp-actual-year,
.dtp div.dtp-actual-month{
  font-size: 25px !important;
}

.dtp table.dtp-picker-days tr > td{
  font-size: 13px !important;
  @include media(sm){
    font-size: 15px !important;
  }
}

.dtp{
  font-size: 20px;
}

.dtp-btn-ok{
  padding: 5px 15px !important;
  border-radius: 20px !important;
}

.dtp > .dtp-content{
  max-width: 310px !important;
  @include media(sm){
    max-width: 345px !important;
  }
}

.form-warrnig{
  font-size: 14px;
  color: #856404;
  display: block;
  margin: 0.5rem 0 0 0;
  &:before{
    content: "\0021";
    font-family: "proxima-nova", sans-serif !important;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #856404;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px;
  }
}

@media screen and (orientation: landscape) and (max-width: 991px) {
  .dtp-content{
    top: 0 !important;
    max-height: 100dvh !important;
    overflow: auto;
  }
}