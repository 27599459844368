@charset "UTF-8";
@font-face {
  font-family: 'icomoon';
  src: url("fonts/icomoon.eot?4dcuce");
  src: url("fonts/icomoon.eot?4dcuce#iefix") format("embedded-opentype"), url("fonts/icomoon.woff2?4dcuce") format("woff2"), url("fonts/icomoon.ttf?4dcuce") format("truetype"), url("fonts/icomoon.woff?4dcuce") format("woff"), url("fonts/icomoon.svg?4dcuce#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-menu:before {
  content: "\e9ce";
  color: #004953; }

.icon-Vector-1:before {
  content: "\e9cd"; }

.icon-Group-6:before {
  content: "\e9cc"; }

.icon-info:before {
  content: "\e9c8"; }

.icon-mail:before {
  content: "\e9c9"; }

.icon-phone:before {
  content: "\e9ca"; }

.icon-pin:before {
  content: "\e9cb"; }

.icon-arrow-go2top:before {
  content: "\e9c7";
  color: #fff; }

.icon-icon-0:before {
  content: "\e9ab"; }

.icon-icon-1:before {
  content: "\e9ac"; }

.icon-icon-3:before {
  content: "\e9ad"; }

.icon-icon-4:before {
  content: "\e9ae"; }

.icon-icon-101:before {
  content: "\e91d"; }

.icon-icon-11:before {
  content: "\e91f"; }

.icon-icon-12:before {
  content: "\e92f"; }

.icon-icon-13:before {
  content: "\e934"; }

.icon-icon-14:before {
  content: "\e935"; }

.icon-icon-15:before {
  content: "\e936"; }

.icon-icon-2:before {
  content: "\e938"; }

.icon-icon-5:before {
  content: "\e939"; }

.icon-icon-6:before {
  content: "\e9a7"; }

.icon-icon-7:before {
  content: "\e9a8"; }

.icon-icon-8:before {
  content: "\e9a9"; }

.icon-icon-91:before {
  content: "\e9aa"; }

.icon-card-0:before {
  content: "\e904"; }

.icon-card-1:before {
  content: "\e905"; }

.icon-card-10:before {
  content: "\e906"; }

.icon-card-11:before {
  content: "\e907"; }

.icon-card-12:before {
  content: "\e908"; }

.icon-card-13:before {
  content: "\e909"; }

.icon-card-14:before {
  content: "\e90a"; }

.icon-card-2:before {
  content: "\e90b"; }

.icon-card-3:before {
  content: "\e90c"; }

.icon-card-4:before {
  content: "\e90d"; }

.icon-card-5:before {
  content: "\e90e"; }

.icon-card-6:before {
  content: "\e90f"; }

.icon-card-7:before {
  content: "\e91a"; }

.icon-card-8:before {
  content: "\e91b"; }

.icon-card-9:before {
  content: "\e91e"; }

.icon-separator-25:before {
  content: "\e9bf"; }

.icon-separator-17:before {
  content: "\e9af"; }

.icon-separator-8:before {
  content: "\e9b0"; }

.icon-separator-18:before {
  content: "\e9b1"; }

.icon-separator-20:before {
  content: "\e9b2"; }

.icon-separator-21:before {
  content: "\e9b3"; }

.icon-separator-22:before {
  content: "\e9b4"; }

.icon-separator-23:before {
  content: "\e9b5"; }

.icon-separator-24:before {
  content: "\e9b6"; }

.icon-separator-0:before {
  content: "\e921"; }

.icon-separator-1:before {
  content: "\e922"; }

.icon-separator-10:before {
  content: "\e923"; }

.icon-separator-11:before {
  content: "\e924"; }

.icon-separator-12:before {
  content: "\e925"; }

.icon-separator-13:before {
  content: "\e926"; }

.icon-separator-14:before {
  content: "\e927"; }

.icon-separator-15:before {
  content: "\e92b"; }

.icon-separator-16:before {
  content: "\e92d"; }

.icon-separator-19:before {
  content: "\e92e"; }

.icon-separator-2:before {
  content: "\e931"; }

.icon-separator-3:before {
  content: "\e932"; }

.icon-separator-4:before {
  content: "\e933"; }

.icon-separator-5:before {
  content: "\e937"; }

.icon-separator-6:before {
  content: "\e93a"; }

.icon-separator-7:before {
  content: "\e93b"; }

.icon-separator-9:before {
  content: "\e93c"; }

.icon-tooltip .path1:before {
  content: "\e9c4";
  color: black; }

.icon-tooltip .path2:before {
  content: "\e9c5";
  margin-left: -1em;
  color: white; }

.icon-tooltip .path3:before {
  content: "\e9c6";
  margin-left: -1em;
  color: white; }

.icon-skype .path1:before {
  content: "\e9c0";
  color: #00aff0; }

.icon-skype .path2:before {
  content: "\e9c1";
  margin-left: -1em;
  color: white; }

.icon-whatsapp .path1:before {
  content: "\e9c2";
  color: #25d366; }

.icon-whatsapp .path2:before {
  content: "\e9c3";
  margin-left: -1em;
  color: white; }

.icon-ico-phone:before {
  content: "\e9be"; }

.icon-sipka_slideshow:before {
  content: "\e919"; }

.icon-sipka_slideshow1:before {
  content: "\e920"; }

.icon-home:before {
  content: "\e918"; }

.icon-krizek_vyhledavani:before {
  content: "\e917"; }

.icon-icon-10:before {
  content: "\e91c"; }

.icon-icon-21:before {
  content: "\e930"; }

.icon-icon-9:before {
  content: "\e93d"; }

.icon-system-1:before {
  content: "\e93e"; }

.icon-system-10:before {
  content: "\e93f"; }

.icon-system-11:before {
  content: "\e940"; }

.icon-system-12:before {
  content: "\e944"; }

.icon-system-13:before {
  content: "\e999"; }

.icon-system-14:before {
  content: "\e99a"; }

.icon-system-15:before {
  content: "\e99b"; }

.icon-system-16:before {
  content: "\e99c"; }

.icon-system-17:before {
  content: "\e99d"; }

.icon-system-2:before {
  content: "\e99e"; }

.icon-system-3:before {
  content: "\e99f"; }

.icon-system-4:before {
  content: "\e9a0"; }

.icon-system-5:before {
  content: "\e9a1"; }

.icon-system-6:before {
  content: "\e9a2"; }

.icon-system-7:before {
  content: "\e9a3"; }

.icon-system-8:before {
  content: "\e9a4"; }

.icon-system-9:before {
  content: "\e9a5"; }

.icon-check:before {
  content: "\e9a6"; }

.icon-mp4 .path1:before {
  content: "\e945";
  color: #b3b3b3; }

.icon-mp4 .path2:before {
  content: "\e946";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-mp4 .path3:before {
  content: "\e947";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-mp4 .path4:before {
  content: "\e948";
  margin-left: -0.896484375em;
  color: #1b1464; }

.icon-mp4 .path5:before {
  content: "\e949";
  margin-left: -0.896484375em;
  color: white; }

.icon-mp4 .path6:before {
  content: "\e94a";
  margin-left: -0.896484375em;
  color: white; }

.icon-mp4 .path7:before {
  content: "\e94b";
  margin-left: -0.896484375em;
  color: white; }

.icon-png .path1:before {
  content: "\e94c";
  color: #b3b3b3; }

.icon-png .path2:before {
  content: "\e94d";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-png .path3:before {
  content: "\e94e";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-png .path4:before {
  content: "\e94f";
  margin-left: -0.896484375em;
  color: #8cc63f; }

.icon-png .path5:before {
  content: "\e950";
  margin-left: -0.896484375em;
  color: white; }

.icon-png .path6:before {
  content: "\e951";
  margin-left: -0.896484375em;
  color: white; }

.icon-png .path7:before {
  content: "\e952";
  margin-left: -0.896484375em;
  color: white; }

.icon-jpg .path1:before {
  content: "\e953";
  color: #b3b3b3; }

.icon-jpg .path2:before {
  content: "\e954";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-jpg .path3:before {
  content: "\e955";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-jpg .path4:before {
  content: "\e956";
  margin-left: -0.896484375em;
  color: #00a99d; }

.icon-jpg .path5:before {
  content: "\e957";
  margin-left: -0.896484375em;
  color: white; }

.icon-jpg .path6:before {
  content: "\e958";
  margin-left: -0.896484375em;
  color: white; }

.icon-jpg .path7:before {
  content: "\e959";
  margin-left: -0.896484375em;
  color: white; }

.icon-pdf .path1:before {
  content: "\e95a";
  color: #b3b3b3; }

.icon-pdf .path2:before {
  content: "\e95b";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-pdf .path3:before {
  content: "\e95c";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-pdf .path4:before {
  content: "\e95d";
  margin-left: -0.896484375em;
  color: #e30613; }

.icon-pdf .path5:before {
  content: "\e95e";
  margin-left: -0.896484375em;
  color: white; }

.icon-pdf .path6:before {
  content: "\e95f";
  margin-left: -0.896484375em;
  color: white; }

.icon-pdf .path7:before {
  content: "\e960";
  margin-left: -0.896484375em;
  color: white; }

.icon-mov .path1:before {
  content: "\e961";
  color: #b3b3b3; }

.icon-mov .path2:before {
  content: "\e962";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-mov .path3:before {
  content: "\e963";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-mov .path4:before {
  content: "\e964";
  margin-left: -0.896484375em;
  color: #666666; }

.icon-mov .path5:before {
  content: "\e965";
  margin-left: -0.896484375em;
  color: white; }

.icon-mov .path6:before {
  content: "\e966";
  margin-left: -0.896484375em;
  color: white; }

.icon-mov .path7:before {
  content: "\e967";
  margin-left: -0.896484375em;
  color: white; }

.icon-avi .path1:before {
  content: "\e968";
  color: #b3b3b3; }

.icon-avi .path2:before {
  content: "\e969";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-avi .path3:before {
  content: "\e96a";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-avi .path4:before {
  content: "\e96b";
  margin-left: -0.896484375em;
  color: #662d91; }

.icon-avi .path5:before {
  content: "\e96c";
  margin-left: -0.896484375em;
  color: white; }

.icon-avi .path6:before {
  content: "\e96d";
  margin-left: -0.896484375em;
  color: white; }

.icon-avi .path7:before {
  content: "\e96e";
  margin-left: -0.896484375em;
  color: white; }

.icon-mp3 .path1:before {
  content: "\e96f";
  color: #b3b3b3; }

.icon-mp3 .path2:before {
  content: "\e970";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-mp3 .path3:before {
  content: "\e971";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-mp3 .path4:before {
  content: "\e972";
  margin-left: -0.896484375em;
  color: #29abe2; }

.icon-mp3 .path5:before {
  content: "\e973";
  margin-left: -0.896484375em;
  color: white; }

.icon-mp3 .path6:before {
  content: "\e974";
  margin-left: -0.896484375em;
  color: white; }

.icon-mp3 .path7:before {
  content: "\e975";
  margin-left: -0.896484375em;
  color: white; }

.icon-xls .path1:before {
  content: "\e976";
  color: #b3b3b3; }

.icon-xls .path2:before {
  content: "\e977";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-xls .path3:before {
  content: "\e978";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-xls .path4:before {
  content: "\e979";
  margin-left: -0.896484375em;
  color: #009245; }

.icon-xls .path5:before {
  content: "\e97a";
  margin-left: -0.896484375em;
  color: white; }

.icon-xls .path6:before {
  content: "\e97b";
  margin-left: -0.896484375em;
  color: white; }

.icon-xls .path7:before {
  content: "\e97c";
  margin-left: -0.896484375em;
  color: white; }

.icon-doc .path1:before {
  content: "\e97d";
  color: #b3b3b3; }

.icon-doc .path2:before {
  content: "\e97e";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-doc .path3:before {
  content: "\e97f";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-doc .path4:before {
  content: "\e980";
  margin-left: -0.896484375em;
  color: #0071bc; }

.icon-doc .path5:before {
  content: "\e981";
  margin-left: -0.896484375em;
  color: white; }

.icon-doc .path6:before {
  content: "\e982";
  margin-left: -0.896484375em;
  color: white; }

.icon-doc .path7:before {
  content: "\e983";
  margin-left: -0.896484375em;
  color: white; }

.icon-docx .path1:before {
  content: "\e9b7";
  color: #b3b3b3; }

.icon-docx .path2:before {
  content: "\e9b8";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-docx .path3:before {
  content: "\e9b9";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-docx .path4:before {
  content: "\e9ba";
  margin-left: -0.896484375em;
  color: #0071bc; }

.icon-docx .path5:before {
  content: "\e9bb";
  margin-left: -0.896484375em;
  color: white; }

.icon-docx .path6:before {
  content: "\e9bc";
  margin-left: -0.896484375em;
  color: white; }

.icon-docx .path7:before {
  content: "\e9bd";
  margin-left: -0.896484375em;
  color: white; }

.icon-ppt .path1:before {
  content: "\e984";
  color: #b3b3b3; }

.icon-ppt .path2:before {
  content: "\e985";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-ppt .path3:before {
  content: "\e986";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-ppt .path4:before {
  content: "\e987";
  margin-left: -0.896484375em;
  color: #e34013; }

.icon-ppt .path5:before {
  content: "\e988";
  margin-left: -0.896484375em;
  color: white; }

.icon-ppt .path6:before {
  content: "\e989";
  margin-left: -0.896484375em;
  color: white; }

.icon-ppt .path7:before {
  content: "\e98a";
  margin-left: -0.896484375em;
  color: white; }

.icon-rar .path1:before {
  content: "\e98b";
  color: #b3b3b3; }

.icon-rar .path2:before {
  content: "\e98c";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-rar .path3:before {
  content: "\e98d";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-rar .path4:before {
  content: "\e98e";
  margin-left: -0.896484375em;
  color: #662d91; }

.icon-rar .path5:before {
  content: "\e98f";
  margin-left: -0.896484375em;
  color: white; }

.icon-rar .path6:before {
  content: "\e990";
  margin-left: -0.896484375em;
  color: white; }

.icon-rar .path7:before {
  content: "\e991";
  margin-left: -0.896484375em;
  color: white; }

.icon-zip .path1:before {
  content: "\e992";
  color: #b3b3b3; }

.icon-zip .path2:before {
  content: "\e993";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-zip .path3:before {
  content: "\e994";
  margin-left: -0.896484375em;
  color: #b3b3b3; }

.icon-zip .path4:before {
  content: "\e995";
  margin-left: -0.896484375em;
  color: #fbb03b; }

.icon-zip .path5:before {
  content: "\e996";
  margin-left: -0.896484375em;
  color: white; }

.icon-zip .path6:before {
  content: "\e997";
  margin-left: -0.896484375em;
  color: white; }

.icon-zip .path7:before {
  content: "\e998";
  margin-left: -0.896484375em;
  color: white; }

.icon-woman:before {
  content: "\e942"; }

.icon-man:before {
  content: "\e943"; }

.icon-Datov-zdroj-1:before {
  content: "\e941"; }

.icon-pristupnost_kontrast_switch:before {
  content: "\e903"; }

.icon-360:before {
  content: "\e900"; }

.icon-arrow-big:before {
  content: "\e901"; }

.icon-arrow-small:before {
  content: "\e902"; }

.icon-instagram:before {
  content: "\e910"; }

.icon-facebook:before {
  content: "\e911"; }

.icon-linkedin:before {
  content: "\e912"; }

.icon-pinterest:before {
  content: "\e913"; }

.icon-twitter:before {
  content: "\e914"; }

.icon-vimeo:before {
  content: "\e915"; }

.icon-youtube:before {
  content: "\e916"; }

.icon-external-link:before {
  content: "\e92c"; }

.icon-right-quote-sign:before {
  content: "\e928"; }

.icon-timeline-hodiny-zlute:before {
  content: "\e929"; }

.icon-timeline-hodiny-bile:before {
  content: "\e92a"; }

.pux-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1580px; }
  .pux-container:after {
    display: block;
    content: "";
    clear: both; }
  @media (min-width: 460px) {
    .pux-container {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (min-width: 1024px) {
    .pux-container {
      padding-left: 35px;
      padding-right: 35px; } }
  @media (min-width: 1200px) {
    .pux-container {
      padding-left: 40px;
      padding-right: 40px; } }
  .pux-container .pux-container {
    padding-left: 0;
    padding-right: 0; }

.pux-container .row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px; }
  @media (min-width: 460px) {
    .pux-container .row {
      margin-left: -25px;
      margin-right: -25px; } }
  @media (min-width: 1024px) {
    .pux-container .row {
      margin-left: -35px;
      margin-right: -35px; } }
  @media (min-width: 1200px) {
    .pux-container .row {
      margin-left: -40px;
      margin-right: -40px; } }

.pux-container [class^="col-"] {
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 460px) {
    .pux-container [class^="col-"] {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (min-width: 1024px) {
    .pux-container [class^="col-"] {
      padding-left: 35px;
      padding-right: 35px; } }
  @media (min-width: 1200px) {
    .pux-container [class^="col-"] {
      padding-left: 40px;
      padding-right: 40px; } }

.pux-fluid-container {
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 768px) {
    .pux-fluid-container {
      padding-left: 35px;
      padding-right: 35px; } }
  @media (min-width: 1200px) {
    .pux-fluid-container {
      padding-left: 12rem;
      padding-right: 12rem; } }

.small-container {
  max-width: 860px;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px; }
  @media (min-width: 460px) {
    .small-container {
      padding-left: 25px;
      padding-right: 25px; } }
  @media (min-width: 1024px) {
    .small-container {
      padding-left: 35px;
      padding-right: 35px; } }
  @media (min-width: 1200px) {
    .small-container {
      padding-left: 40px;
      padding-right: 40px; } }

.pux-container .small-container {
  max-width: 780px;
  padding-left: 0;
  padding-right: 0; }

.EditMode header,
.DesignMode header {
  position: static; }

.EditMode .header-spacer,
.DesignMode .header-spacer {
  display: none; }

@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px); }
  100% {
    opacity: 1;
    filter: blur(0); } }

[data-lazy-type].loaded {
  animation: animateLoaded__ 0.1s ease-out forwards; }

.back-to-top {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 56px;
  width: 56px;
  bottom: 90px;
  right: 2.5rem;
  transform: translateY(15px);
  text-align: center;
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 200ms;
  z-index: 1049; }
  @media (min-width: 460px) {
    .back-to-top {
      bottom: 90px;
      right: 2.5rem; } }
  .back-to-top span {
    display: none; }
  .back-to-top:hover, .back-to-top:focus {
    text-decoration: none; }
    .back-to-top:hover::after, .back-to-top:focus::after {
      transform: translateY(-7px); }
  .back-to-top::after {
    content: '\e9c7';
    transition: all ease-in-out 200ms; }
  .back-to-top-show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0); }

.header-spacer {
  height: 60px; }
  @media (min-width: 1024px) {
    .header-spacer.spacer-big {
      height: 110px; } }

header {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 999;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: 250ms; }
  header .header {
    display: flex;
    justify-content: space-between;
    height: 60px; }
    @media (min-width: 1024px) {
      header .header {
        height: 70px; } }
    header .header-logo {
      display: flex;
      align-items: center;
      transition: 250ms;
      align-self: flex-end;
      height: 100%; }
      @media (min-width: 1024px) {
        header .header-logo {
          display: none; } }
      @media (min-width: 1200px) {
        header .header-logo {
          display: flex;
          align-items: center; } }
      header .header-logo a {
        display: flex; }
        header .header-logo a span {
          display: none; }
        header .header-logo a img {
          max-width: none;
          transition: 250ms;
          height: 45px; }
          @media (min-width: 300px) and (max-width: 1199px) {
            header .header-logo a img {
              height: 45px !important; } }
      header .header-logo .header-logo-helper {
        display: flex; }
        header .header-logo .header-logo-helper a {
          display: block; }
      @media (min-width: 460px) and (max-width: 1023px) {
        header .header-logo.header-logos a img {
          height: 45px !important; } }
      @media (min-width: 320px) and (max-width: 767px) {
        header .header-logo.header-logos a img {
          height: 45px !important; } }
      @media (min-width: 300px) and (max-width: 459px) {
        header .header-logo.header-logos a img {
          height: 45px !important; } }
    header .header .header-delimiter {
      margin: 0 20px;
      width: 1px;
      background: #000; }
      @media (min-width: 300px) and (max-width: 1023px) {
        header .header .header-delimiter {
          margin: 0 10px; } }
    header .header-menu {
      width: 100%; }
      @media (min-width: 768px) {
        header .header-menu {
          display: none;
          position: absolute;
          top: 100%;
          z-index: 77;
          left: 0;
          overflow: auto;
          max-height: -webkit-calc(100vh - 60px);
          max-height: -moz-calc(100vh - 60px);
          max-height: calc(100vh - 60px); } }
      @media (min-width: 1024px) {
        header .header-menu {
          display: flex;
          position: static;
          width: auto;
          overflow: visible; } }
      header .header-menu ul {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 100%; }
        @media (min-width: 1024px) {
          header .header-menu ul {
            display: flex;
            width: auto; } }
        header .header-menu ul li {
          display: block;
          position: relative;
          margin: 0 0 10px 0; }
          @media (min-width: 1024px) {
            header .header-menu ul li {
              margin: 0 2rem 0 0;
              display: flex; } }
          header .header-menu ul li span,
          header .header-menu ul li a {
            display: flex;
            align-items: center;
            padding: 5px 20px;
            font-weight: 700;
            font-size: 18px;
            min-height: 40px; }
            @media (min-width: 1024px) {
              header .header-menu ul li span,
              header .header-menu ul li a {
                padding: 0 0.5rem;
                height: auto; } }
            header .header-menu ul li span:focus, header .header-menu ul li span:hover,
            header .header-menu ul li a:focus,
            header .header-menu ul li a:hover {
              text-decoration: none; }
          header .header-menu ul li ul {
            display: none;
            width: 100%;
            opacity: 1;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            filter: alpha(opacity=100);
            visibility: visible;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
            @media (min-width: 1024px) {
              header .header-menu ul li ul {
                position: absolute;
                width: 270px;
                visibility: hidden;
                left: 0;
                opacity: 0;
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
                filter: alpha(opacity=0);
                display: block;
                top: 100%;
                max-height: 490px;
                overflow-y: auto;
                box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1); } }
            header .header-menu ul li ul::-webkit-scrollbar {
              width: 10px; }
            header .header-menu ul li ul.visible {
              max-height: 100vh;
              overflow-y: visible; }
            header .header-menu ul li ul li {
              margin: 0;
              border-bottom-width: 1px;
              border-bottom-style: solid;
              line-height: 1;
              position: relative; }
              header .header-menu ul li ul li span,
              header .header-menu ul li ul li a {
                font-weight: 400;
                padding: 5px 30px; }
                @media (min-width: 1024px) {
                  header .header-menu ul li ul li span,
                  header .header-menu ul li ul li a {
                    padding: 1.5rem 3.5rem;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    min-height: 6rem;
                    width: 100%;
                    font-weight: 700; } }
              header .header-menu ul li ul li a, header .header-menu ul li ul li span {
                order: 1; }
              header .header-menu ul li ul li span.menu-openner {
                order: 2;
                margin: 0;
                padding: 0 10px; }
                @media (min-width: 1024px) {
                  header .header-menu ul li ul li span.menu-openner:before {
                    transform: rotate(90deg); } }
              header .header-menu ul li ul li:last-child {
                border: none; }
              header .header-menu ul li ul li ul {
                border-top-width: 1px;
                border-top-style: solid; }
                @media (min-width: 1024px) {
                  header .header-menu ul li ul li ul {
                    position: absolute;
                    left: 100%;
                    top: 0;
                    box-shadow: none;
                    width: 270px;
                    border-top: none;
                    display: none; } }
                header .header-menu ul li ul li ul li span,
                header .header-menu ul li ul li ul li a {
                  padding: 5px 40px; }
                  @media (min-width: 1024px) {
                    header .header-menu ul li ul li ul li span,
                    header .header-menu ul li ul li ul li a {
                      padding: 1.5rem 3.5rem; } }
              @media (min-width: 1024px) {
                header .header-menu ul li ul li:hover span.menu-openner:before {
                  transform: rotate(90deg); }
                header .header-menu ul li ul li:hover ul {
                  display: block !important;
                  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); } }
              header .header-menu ul li ul li.open > .menu-openner:before {
                -ms-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg); }
                @media (min-width: 1024px) {
                  header .header-menu ul li ul li.open > .menu-openner:before {
                    transform: rotate(90deg); } }
              header .header-menu ul li ul li.open > ul {
                display: block; }
                @media (min-width: 1024px) {
                  header .header-menu ul li ul li.open > ul {
                    display: none; } }
          @media (min-width: 1024px) {
            header .header-menu ul li.hovered > span,
            header .header-menu ul li.hovered > a, header .header-menu ul li:hover > span,
            header .header-menu ul li:hover > a, header .header-menu ul li.focused > span,
            header .header-menu ul li.focused > a {
              outline: 0; } }
          @media (min-width: 1024px) {
            header .header-menu ul li.hovered > .menu-openner:before, header .header-menu ul li:hover > .menu-openner:before, header .header-menu ul li.focused > .menu-openner:before {
              -ms-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              transform: rotate(0deg); } }
          @media (min-width: 1024px) {
            header .header-menu ul li.hovered > ul, header .header-menu ul li:hover > ul, header .header-menu ul li.focused > ul {
              visibility: visible;
              opacity: 1;
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
              filter: alpha(opacity=100); } }
          header .header-menu ul li.open > .menu-openner:before {
            -ms-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
          header .header-menu ul li.open > ul {
            display: block; }
          @media (min-width: 1024px) {
            header .header-menu ul li:last-child {
              margin: 0; } }
      header .header-menu .menu-openner {
        position: absolute;
        top: 0;
        right: 25px;
        width: 40px;
        height: 40px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center; }
        @media (min-width: 1024px) {
          header .header-menu .menu-openner {
            position: static;
            width: auto;
            height: auto;
            margin: 0 5px 0 0; } }
        header .header-menu .menu-openner:before {
          content: "\e9a0";
          transform: rotate(180deg);
          font-size: 14px;
          transition: 200ms; }
    header .header-hamburger {
      display: flex;
      align-items: center; }
      @media (min-width: 1024px) {
        header .header-hamburger {
          display: none; } }
      header .header-hamburger .open-menu {
        width: 25px;
        height: 25px;
        position: relative;
        display: flex;
        align-items: center; }
        header .header-hamburger .open-menu span {
          height: 3px;
          width: 25px;
          -moz-transition: all ease-in-out 200ms;
          -o-transition: all ease-in-out 200ms;
          -webkit-transition: all ease-in-out 200ms;
          transition: all ease-in-out 200ms;
          position: relative; }
          header .header-hamburger .open-menu span:before, header .header-hamburger .open-menu span:after {
            content: "";
            width: 25px;
            left: 0;
            height: 3px;
            position: absolute;
            -moz-transition: all ease-in-out 200ms;
            -o-transition: all ease-in-out 200ms;
            -webkit-transition: all ease-in-out 200ms;
            transition: all ease-in-out 200ms; }
          header .header-hamburger .open-menu span:before {
            top: -8px; }
          header .header-hamburger .open-menu span:after {
            bottom: -8px; }
      header .header-hamburger .open-search {
        margin: 0 3rem 0 1rem; }
    header .header.open .header-hamburger .open-menu span {
      background: transparent; }
      header .header.open .header-hamburger .open-menu span:before {
        top: 0;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      header .header.open .header-hamburger .open-menu span:after {
        top: 0;
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    header .header.open .header-menu {
      display: flex; }

.header-email,
.header-phone {
  display: inline-flex;
  align-items: center;
  font-size: 18px; }
  @media (min-width: 1024px) {
    .header-email,
    .header-phone {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .header-email,
    .header-phone {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    .header-email,
    .header-phone {
      font-size: 1.8rem; } }
  .header-email:before,
  .header-phone:before {
    margin: 0 10px 0 0; }

.header-email:before {
  content: "\e99a"; }

.header-phone {
  font-weight: 700; }
  .header-phone:before {
    font-weight: 400;
    content: "\e99c"; }

.header-menu a:before {
  font-size: 3.6rem;
  margin: 0 10px 0 0; }
  @media (min-width: 320px) {
    .header-menu a:before {
      font-size: 3.27273rem; } }
  @media (min-width: 460px) {
    .header-menu a:before {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .header-menu a:before {
      font-size: 2.4rem; } }
  @media (min-width: 1024px) {
    .header-menu a:before {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .header-menu a:before {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    .header-menu a:before {
      font-size: 1.8rem; } }

@media (min-width: 1024px) {
  .header-menu .menu-home a:before {
    content: "\e918"; } }

@media (min-width: 1024px) {
  .header-menu .menu-360 a:before {
    font-size: 35px;
    content: "\e93e"; } }

.header-menu .menu-360 a:after {
  content: "\e93e";
  margin: 0 0 0 10px;
  font-size: 35px; }
  @media (min-width: 1024px) {
    .header-menu .menu-360 a:after {
      display: none; } }

header .header-right {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  width: 100%; }
  @media (min-width: 1024px) {
    header .header-right {
      flex-wrap: wrap; } }
  header .header-right-menu {
    display: none;
    flex-wrap: wrap;
    position: absolute;
    top: 100%;
    z-index: 77;
    left: 0;
    width: 100%;
    overflow: auto;
    max-height: -webkit-calc(100vh - 60px);
    max-height: -moz-calc(100vh - 60px);
    max-height: calc(100vh - 60px); }
    @media (min-width: 768px) {
      header .header-right-menu {
        display: flex;
        position: static;
        overflow: visible; } }
    @media (min-width: 1024px) {
      header .header-right-menu {
        display: block; } }
    @media (min-width: 1200px) {
      header .header-right-menu {
        width: auto;
        display: flex; } }

header .header-top {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2rem 2rem 0 2rem; }
  @media (min-width: 768px) {
    header .header-top {
      padding: 0; } }
  @media (min-width: 1024px) {
    header .header-top {
      display: block;
      padding: 14px 0 0 0; } }
  @media (min-width: 1200px) {
    header .header-top {
      padding: 20px 0 0 0; } }
  header .header-top-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%; }
    @media (min-width: 1200px) {
      header .header-top-inner {
        margin: 0 -12px;
        width: auto; } }
  header .header-top-item {
    padding: 0 12px; }
    header .header-top-item:last-child {
      display: none; }
      @media (min-width: 1024px) {
        header .header-top-item:last-child {
          display: block; } }
  header .header-top-logo {
    display: flex;
    align-items: center; }
    header .header-top-logo a {
      display: flex; }
      header .header-top-logo a span {
        display: none; }
      header .header-top-logo a img {
        max-width: none;
        height: 36px !important; }

header .header-email {
  font-weight: 700; }

header .header-phone {
  font-weight: 700; }

header .open-search:before {
  content: "\e940"; }

header.header-big .header {
  height: 60px; }
  @media (min-width: 1024px) {
    header.header-big .header {
      height: 110px; } }

header.header-big .header-menu {
  align-self: flex-end; }
  @media (min-width: 1024px) {
    header.header-big .header-menu {
      height: 60px;
      justify-content: flex-end;
      width: 100%; } }

.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  flex-direction: row;
  display: none; }
  .search-overlay-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; }
  .search-overlay-content {
    max-width: 740px;
    width: 100%;
    padding: 0 20px; }
  .search-overlay ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    .search-overlay ul li {
      margin: 0 0 4px 0; }
  .search-overlay .search-close {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: all 0.3s ease-out;
    margin-left: auto;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 7rem; }
    @media (min-width: 768px) {
      .search-overlay .search-close {
        width: 110px;
        height: 110px;
        margin-top: 5rem;
        margin-right: 7rem; } }
    .search-overlay .search-close .esc {
      display: none; }
      @media (min-width: 768px) {
        .search-overlay .search-close .esc {
          display: block;
          width: 100%;
          text-align: center;
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 5px;
          left: 0; } }
    .search-overlay .search-close-inner:before {
      content: "\e917";
      display: block;
      font-size: 40px; }
      @media (min-width: 768px) {
        .search-overlay .search-close-inner:before {
          font-size: 50px; } }
  .search-overlay.open {
    display: block; }

.header-search-helper {
  padding: 4rem 3rem 0 3rem; }
  .header-search-helper-title {
    letter-spacing: 0.24px;
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 2rem 0; }
    @media (min-width: 1024px) {
      .header-search-helper-title {
        font-size: 3rem; } }
    @media (min-width: 1200px) {
      .header-search-helper-title {
        font-size: 2.66667rem; } }
    @media (min-width: 1420px) {
      .header-search-helper-title {
        font-size: 2.4rem; } }
  .header-search-helper a {
    text-decoration: underline;
    font-weight: 400;
    transition: all 0.3s ease-out;
    font-size: 20px; }
    @media (min-width: 1024px) {
      .header-search-helper a {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .header-search-helper a {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      .header-search-helper a {
        font-size: 2rem; } }
    .header-search-helper a:focus, .header-search-helper a:hover {
      font-weight: 700;
      text-decoration: underline; }

.header-search-box-inner {
  display: flex; }
  .header-search-box-inner-input {
    width: 100%; }

input[type="text"].search-panel-input {
  height: 60px;
  font-size: 24px;
  letter-spacing: 0.24px;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 1024px) {
    input[type="text"].search-panel-input {
      font-size: 3rem; } }
  @media (min-width: 1200px) {
    input[type="text"].search-panel-input {
      font-size: 2.66667rem; } }
  @media (min-width: 1420px) {
    input[type="text"].search-panel-input {
      font-size: 2.4rem; } }
  @media (min-width: 300px) and (max-width: 767px) {
    input[type="text"].search-panel-input {
      height: 50px;
      font-size: 18px; } }
  input[type="text"].search-panel-input:focus {
    border: none; }

.search-panel-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 160px;
  font-weight: 700;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: all 0.3s ease-out; }
  @media (min-width: 300px) and (max-width: 767px) {
    .search-panel-btn {
      font-size: 0;
      height: 50px;
      width: 50px; } }
  .search-panel-btn:before {
    content: "\e940";
    margin: 0 10px 0 0; }
    @media (min-width: 300px) and (max-width: 767px) {
      .search-panel-btn:before {
        font-size: 21px;
        margin: 0 auto; } }

@media (min-width: 1024px) {
  header.header-big .header-logo {
    height: 110px; } }

@media (min-width: 1024px) {
  header.header-big .header-top {
    display: flex;
    justify-content: space-between; } }

@media (min-width: 1200px) {
  header.header-big .header-top {
    display: block; } }

header.header-big .header-top-logo {
  display: none; }
  @media (min-width: 1024px) {
    header.header-big .header-top-logo {
      display: flex;
      align-items: center; } }
  @media (min-width: 1200px) {
    header.header-big .header-top-logo {
      display: none; } }

header.header-big .header.open .header-right-menu {
  display: flex; }

header.header-big .header-top {
  order: 2; }
  @media (min-width: 768px) {
    header.header-big .header-top {
      order: 1; } }
  header.header-big .header-top-inner {
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      header.header-big .header-top-inner {
        flex-wrap: nowrap; } }

header.header-big .header-top-item {
  width: 100%;
  text-align: center;
  padding: 0 12px 2rem 12px; }
  @media (min-width: 768px) {
    header.header-big .header-top-item {
      width: auto;
      text-align: left;
      padding: 0 12px; } }

header.header-big .header-menu {
  order: 1; }
  @media (min-width: 768px) {
    header.header-big .header-menu {
      order: 2; } }

.option-box {
  display: none;
  margin-top: 1px;
  position: fixed;
  right: 0;
  z-index: 888; }
  @media (min-width: 768px) {
    .option-box {
      display: block; } }
  .option-box-item {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    .option-box-item:before {
      font-size: 23px; }
    .option-box-item span {
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      white-space: nowrap;
      width: 0;
      overflow: hidden;
      padding: 0;
      transition: 200ms; }
  .option-box-size {
    margin: 0 0 1px 0; }
  .option-box .size-plus:before {
    content: "\e9a4"; }
  .option-box .size-plus:hover span {
    padding: 0 15px;
    width: auto;
    min-width: 110px; }
  .option-box .size-minus:before {
    content: "\e944"; }
  .option-box .size-minus:hover span {
    padding: 0 15px;
    width: auto;
    min-width: 130px; }
  .option-box .contrast-item:before {
    content: "\e99d"; }
  .option-box .contrast-item .contrast-text-hight {
    display: none; }
  .option-box .contrast-item:hover .contrast-text-normal {
    padding: 0 15px;
    width: auto;
    min-width: 140px; }

body#normal .option-box .size-minus {
  display: none; }

body#big .option-box .size-plus {
  display: none; }

body.contrast .contrast-item .contrast-text-normal {
  display: none; }

body.contrast .contrast-item .contrast-text-hight {
  display: inline-flex; }

body.contrast .contrast-item:hover .contrast-text-hight {
  padding: 0 15px;
  width: auto;
  min-width: 140px; }

@media (min-width: 1024px) {
  .scrolled.down header.header-big {
    top: -50px; } }

.scrolled.down header.header-big .header-logo {
  height: 60px; }

.scrolled.down header .header-logo a img {
  height: 45px !important; }

.header-facebook {
  color: #4267B2;
  border: 1px solid #4267B2;
  background: #fff;
  border-radius: 40px;
  font-size: 0;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center; }
  @media (min-width: 1024px) {
    .header-facebook {
      font-size: 14px; } }
  .header-facebook:before {
    content: "\e911";
    font-size: 18px; }
    @media (min-width: 1024px) {
      .header-facebook:before {
        margin: 0 10px 0 0; } }
  .header-facebook:focus, .header-facebook:hover {
    background: #4267B2;
    color: #fff; }

.facebook-placeholder {
  display: block; }
  .facebook-placeholder .header-facebook {
    margin: 0 10px 0 0; }
  @media (max-width: 375px) {
    .facebook-placeholder {
      display: none; } }
  @media (min-width: 768px) {
    .facebook-placeholder {
      display: none; } }

.header-info {
  margin-left: 5px; }
  .header-info:before {
    content: "\e9c8"; }

footer {
  font-size: 17px; }
  @media (min-width: 1024px) {
    footer {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    footer {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    footer {
      font-size: 1.8rem; } }
  footer *:before {
    font-size: 4rem; }
    @media (min-width: 320px) {
      footer *:before {
        font-size: 3.63636rem; } }
    @media (min-width: 460px) {
      footer *:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      footer *:before {
        font-size: 2.66667rem; } }
    @media (min-width: 1024px) {
      footer *:before {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      footer *:before {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      footer *:before {
        font-size: 2rem; } }
  footer .footer-top {
    display: flex;
    flex-wrap: wrap;
    padding: 10rem 0 7rem 0;
    margin: 0 -20px; }
    @media (min-width: 768px) {
      footer .footer-top {
        padding: 13rem 0 10rem 0; } }
    @media (min-width: 1024px) {
      footer .footer-top {
        margin: 0 -2rem; } }
    footer .footer-top-item {
      width: 100%;
      padding: 0 20px 3rem 20px; }
      @media (min-width: 768px) {
        footer .footer-top-item {
          width: 33.33%; } }
      @media (min-width: 1024px) {
        footer .footer-top-item {
          padding: 0 2rem 3rem 2rem; } }
    @media (min-width: 768px) {
      footer .footer-top.two-logos .footer-top-item {
        width: 50%; } }
    @media (min-width: 1024px) {
      footer .footer-top.two-logos .footer-top-item {
        width: 33.33%; } }
    @media (min-width: 768px) {
      footer .footer-top.two-logos .footer-top-item:last-child {
        width: 100%; } }
    @media (min-width: 1024px) {
      footer .footer-top.two-logos .footer-top-item:last-child {
        width: 33.33%; } }
    @media (min-width: 1024px) {
      footer .footer-top.two-address .footer-top-item {
        width: 25%; } }
    @media (min-width: 768px) {
      footer .footer-top.two-address.two-logos:last-child .footer-top-item {
        width: 50%; } }
    @media (min-width: 1024px) {
      footer .footer-top.two-address.two-logos:last-child .footer-top-item {
        width: 25%; } }
    footer .footer-top.two-address.two-logos:last-child .footer-logos.cols-2 .footer-logo-item {
      width: 100%; }
  footer .footer-address {
    display: flex;
    align-items: baseline;
    padding: 0 0 3.5rem 0; }
    footer .footer-address:before {
      display: inline-block;
      content: "\e918";
      margin: 0 8px 0 0; }
  footer .footer-contact {
    display: flex;
    flex-wrap: wrap; }
    footer .footer-contact-item:first-child {
      padding: 0 4rem 0 0; }
    footer .footer-contact-item:last-child {
      flex-grow: 1; }
  footer .footer-map {
    display: none; }
    @media (min-width: 1200px) {
      footer .footer-map {
        display: block; } }
    footer .footer-map a {
      display: block; }
  footer .footer-map-link {
    display: flex;
    align-items: baseline;
    font-weight: 700;
    margin: 0 0 3.5rem 0; }
    footer .footer-map-link:before {
      display: inline-block;
      content: "\e9a3";
      margin: 0 8px 0 0;
      font-weight: 400; }
  footer .insert-phone {
    display: flex;
    align-items: baseline; }
    footer .insert-phone:before {
      display: inline-block;
      content: "\e99c";
      margin: 0 8px 0 0; }
  footer .insert-mail {
    display: flex;
    align-items: baseline; }
    footer .insert-mail:before {
      display: inline-block;
      content: "\e99a";
      margin: 0 8px 0 0; }
  footer .footer-links {
    padding: 0 0 3rem 27px; }
    footer .footer-links-title {
      font-weight: 700;
      margin: 0 0 3rem -15px; }
  footer .footer-social ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  footer .footer-logo {
    display: block;
    width: 100%; }
  footer .footer-logo-link {
    display: block;
    text-transform: uppercase;
    max-width: 280px;
    margin-top: 2rem;
    font-size: 3.8rem;
    line-height: 4.6rem; }
    @media (min-width: 320px) {
      footer .footer-logo-link {
        font-size: 3.45455rem; } }
    @media (min-width: 460px) {
      footer .footer-logo-link {
        font-size: 3.16667rem; } }
    @media (min-width: 768px) {
      footer .footer-logo-link {
        font-size: 2.53333rem; } }
    @media (min-width: 1024px) {
      footer .footer-logo-link {
        font-size: 2.375rem; } }
    @media (min-width: 1200px) {
      footer .footer-logo-link {
        font-size: 2.11111rem; } }
    @media (min-width: 1420px) {
      footer .footer-logo-link {
        font-size: 1.9rem; } }
    @media (min-width: 320px) {
      footer .footer-logo-link {
        line-height: 4.18182rem; } }
    @media (min-width: 460px) {
      footer .footer-logo-link {
        line-height: 3.83333rem; } }
    @media (min-width: 768px) {
      footer .footer-logo-link {
        line-height: 3.06667rem; } }
    @media (min-width: 1024px) {
      footer .footer-logo-link {
        line-height: 2.875rem; } }
    @media (min-width: 1200px) {
      footer .footer-logo-link {
        line-height: 2.55556rem; } }
    @media (min-width: 1420px) {
      footer .footer-logo-link {
        line-height: 2.3rem; } }
    footer .footer-logo-link.link-formated {
      text-transform: none; }
  footer .social-ico {
    display: flex;
    align-items: baseline; }
    footer .social-ico:before {
      display: inline-block;
      margin: 0 8px 0 0; }
    footer .social-ico.facebook:before {
      content: "\e9a2"; }
  footer .footer-bottom {
    padding: 3.5rem 0 3.5rem 0; }
    @media (min-width: 300px) {
      footer .footer-bottom {
        font-size: 3rem; } }
    @media (min-width: 320px) {
      footer .footer-bottom {
        font-size: 2.72727rem; } }
    @media (min-width: 460px) {
      footer .footer-bottom {
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      footer .footer-bottom {
        font-size: 2rem; } }
    @media (min-width: 1024px) {
      footer .footer-bottom {
        font-size: 1.875rem; } }
    @media (min-width: 1200px) {
      footer .footer-bottom {
        font-size: 1.8rem; } }
    footer .footer-bottom.row {
      display: block;
      text-align: center; }
      @media (min-width: 1024px) {
        footer .footer-bottom.row {
          display: flex;
          text-align: left; } }
    footer .footer-bottom div[class^=col] {
      display: block;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center; }
      @media (min-width: 768px) {
        footer .footer-bottom div[class^=col] {
          display: flex; } }
      @media (min-width: 1024px) {
        footer .footer-bottom div[class^=col] {
          justify-content: flex-start;
          margin: 0; } }
    footer .footer-bottom div[class^=col]:last-child {
      justify-content: center;
      margin: 4rem 0 0 0; }
      @media (min-width: 1024px) {
        footer .footer-bottom div[class^=col]:last-child {
          justify-content: flex-end;
          flex-wrap: nowrap;
          white-space: nowrap;
          margin: 0; } }
  footer .icon-footer-copyright-first {
    margin: 2rem auto 2rem;
    height: 44px;
    width: 122px;
    background-image: url("../img/domovy-logo.svg");
    display: block; }
    @media (min-width: 768px) {
      footer .icon-footer-copyright-first {
        margin: 0 4rem 0 2rem; } }
    @media (min-width: 1200px) {
      footer .icon-footer-copyright-first {
        margin: 0 6rem 0 4rem; } }
  footer .icon-footer-copyright-second {
    height: 36px;
    width: 196px;
    background-image: url("../img/virtual-logo.svg");
    display: block;
    margin: 0 auto; }
    @media (min-width: 768px) {
      footer .icon-footer-copyright-second {
        margin: 0; } }
  footer .footer-logo-item {
    width: 100%;
    padding: 0 1.5rem 3rem 1.5rem;
    font-size: 16px;
    line-height: 20px; }
    @media (min-width: 1024px) {
      footer .footer-logo-item {
        font-size: 2.125rem; } }
    @media (min-width: 1200px) {
      footer .footer-logo-item {
        font-size: 1.88889rem; } }
    @media (min-width: 1420px) {
      footer .footer-logo-item {
        font-size: 1.7rem; } }
    @media (min-width: 1024px) {
      footer .footer-logo-item {
        line-height: 2.625rem; } }
    @media (min-width: 1200px) {
      footer .footer-logo-item {
        line-height: 2.33333rem; } }
    @media (min-width: 1420px) {
      footer .footer-logo-item {
        line-height: 2.1rem; } }
    footer .footer-logo-item small {
      font-size: 16px;
      line-height: 20px; }
      @media (min-width: 1024px) {
        footer .footer-logo-item small {
          font-size: 2.125rem; } }
      @media (min-width: 1200px) {
        footer .footer-logo-item small {
          font-size: 1.88889rem; } }
      @media (min-width: 1420px) {
        footer .footer-logo-item small {
          font-size: 1.7rem; } }
      @media (min-width: 1024px) {
        footer .footer-logo-item small {
          line-height: 2.625rem; } }
      @media (min-width: 1200px) {
        footer .footer-logo-item small {
          line-height: 2.33333rem; } }
      @media (min-width: 1420px) {
        footer .footer-logo-item small {
          line-height: 2.1rem; } }
    footer .footer-logo-item:first-child:last-child {
      padding: 0 1.5rem; }
  footer .footer-logos {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem; }
    footer .footer-logos.cols-2 .footer-logo-item {
      width: 100%; }
      @media (min-width: 460px) {
        footer .footer-logos.cols-2 .footer-logo-item {
          width: 50%; } }

#chatbase-bubble-window {
  bottom: 75px !important;
  height: 84dvh !important; }
  @media (min-width: 640px) {
    #chatbase-bubble-window {
      bottom: 75px !important;
      height: 82dvh !important; } }

.footer-info {
  font-size: 14px;
  padding-left: 28px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  display: inline-block;
  margin-top: 10px; }

#chatbase-bubble-button {
  width: 75px !important;
  height: 75px !important;
  border-radius: 50% !important;
  right: 1.5rem !important; }

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
  font-family: 'Core-icons' !important; }

*:before,
*:after {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.helper-4k {
  max-width: 200rem;
  margin: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.bold {
  font-weight: bold; }

.underline {
  text-decoration: underline; }

.line-through {
  text-decoration: line-through; }

.uppercase {
  text-transform: uppercase; }

.select-box {
  display: flex;
  justify-content: center;
  margin: 2rem auto 3rem; }
  .select-box .filter-item {
    padding: 0 1rem 2rem 1rem;
    min-width: 50%; }
    @media (min-width: 460px) {
      .select-box .filter-item {
        width: 240px;
        min-width: auto; } }

.articles-filter {
  flex-wrap: wrap; }
  .articles-filter .filter-item {
    padding: 0 1rem 2rem 1rem;
    width: 50%; }
    @media (min-width: 300px) and (max-width: 767px) {
      .articles-filter .filter-item:first-child {
        order: 3;
        width: 100%; } }
    @media (min-width: 768px) {
      .articles-filter .filter-item {
        width: 33.3333%; } }
    @media (min-width: 1024px) {
      .articles-filter .filter-item {
        width: 25rem; } }

.insert-image a {
  display: inline-block; }
  .insert-image a:before {
    display: none !important; }

html {
  font-size: 5px; }
  @media (min-width: 320px) {
    html {
      font-size: 5.5px; } }
  @media (min-width: 460px) {
    html {
      font-size: 6px; } }
  @media (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media (min-width: 1024px) {
    html {
      font-size: 8px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 9px; } }
  @media (min-width: 1420px) {
    html {
      font-size: 10px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (-o-min-device-pixel-ratio: 5 / 4), only screen and (min-resolution: 120dpi), only screen and (min-resolution: 1.25dppx) {
  html {
    font-size: 5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 320px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 320px), only screen and (min-resolution: 120dpi) and (min-width: 320px), only screen and (min-resolution: 1.25dppx) and (min-width: 320px) {
    html {
      font-size: 5.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 460px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 460px), only screen and (min-resolution: 120dpi) and (min-width: 460px), only screen and (min-resolution: 1.25dppx) and (min-width: 460px) {
    html {
      font-size: 6px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 768px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 768px), only screen and (min-resolution: 120dpi) and (min-width: 768px), only screen and (min-resolution: 1.25dppx) and (min-width: 768px) {
    html {
      font-size: 7.5px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1024px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1024px), only screen and (min-resolution: 120dpi) and (min-width: 1024px), only screen and (min-resolution: 1.25dppx) and (min-width: 1024px) {
    html {
      font-size: 8px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1200px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1200px), only screen and (min-resolution: 120dpi) and (min-width: 1200px), only screen and (min-resolution: 1.25dppx) and (min-width: 1200px) {
    html {
      font-size: 7.2px; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1.25) and (min-width: 1420px), only screen and (-o-min-device-pixel-ratio: 5 / 4) and (min-width: 1420px), only screen and (min-resolution: 120dpi) and (min-width: 1420px), only screen and (min-resolution: 1.25dppx) and (min-width: 1420px) {
    html {
      font-size: 8px; } }

form,
html,
body {
  font-family: "proxima-nova", sans-serif;
  height: 100%; }

body {
  font-size: 18px; }
  @media (min-width: 1024px) {
    body {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    body {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    body {
      font-size: 1.8rem; } }
  body#big {
    font-size: 20px; }
    @media (min-width: 1024px) {
      body#big {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      body#big {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      body#big {
        font-size: 2rem; } }

body#big .slider-grey-title {
  font-size: 27px; }
  @media (min-width: 1024px) {
    body#big .slider-grey-title {
      font-size: 3.75rem; } }
  @media (min-width: 1200px) {
    body#big .slider-grey-title {
      font-size: 3.33333rem; } }
  @media (min-width: 1420px) {
    body#big .slider-grey-title {
      font-size: 3rem; } }

body#big .btn {
  font-size: 20px; }
  @media (min-width: 1024px) {
    body#big .btn {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) {
    body#big .btn {
      font-size: 2.22222rem; } }
  @media (min-width: 1420px) {
    body#big .btn {
      font-size: 2rem; } }

body#big .cta-block-big {
  font-size: 18px; }
  @media (min-width: 1024px) {
    body#big .cta-block-big {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    body#big .cta-block-big {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    body#big .cta-block-big {
      font-size: 1.8rem; } }

body#big .timeline-item-content-perex.collapsed {
  max-height: 122px; }
  body#big .timeline-item-content-perex.collapsed.open {
    max-height: 2000px; }

body#big .header .header-menu ul li ul {
  margin-top: -1px; }

body#big .breadcrumbs .home:before,
body#big .breadcrumbs a,
body#big .breadcrumbs span,
body#big .header-intro-subtitle {
  font-size: 22px; }
  @media (min-width: 1200px) {
    body#big .breadcrumbs .home:before,
    body#big .breadcrumbs a,
    body#big .breadcrumbs span,
    body#big .header-intro-subtitle {
      font-size: 2.44444rem; } }
  @media (min-width: 1420px) {
    body#big .breadcrumbs .home:before,
    body#big .breadcrumbs a,
    body#big .breadcrumbs span,
    body#big .header-intro-subtitle {
      font-size: 2.2rem; } }

body#big h3,
body#big .h3 {
  font-size: 22px; }
  @media (min-width: 460px) {
    body#big h3,
    body#big .h3 {
      font-size: 23px; } }
  @media (min-width: 768px) {
    body#big h3,
    body#big .h3 {
      font-size: 25px; } }
  @media (min-width: 1200px) {
    body#big h3,
    body#big .h3 {
      font-size: 2.88889rem; } }
  @media (min-width: 1420px) {
    body#big h3,
    body#big .h3 {
      font-size: 2.6rem; } }

body#big footer {
  font-size: 18px; }
  @media (min-width: 1024px) {
    body#big footer {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    body#big footer {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    body#big footer {
      font-size: 1.8rem; } }

body#big .important-item-item {
  font-size: 22px; }
  @media (min-width: 460px) {
    body#big .important-item-item {
      font-size: 24px; } }
  @media (min-width: 1024px) {
    body#big .important-item-item {
      font-size: 3.5rem; } }
  @media (min-width: 1200px) {
    body#big .important-item-item {
      font-size: 3.11111rem; } }
  @media (min-width: 1420px) {
    body#big .important-item-item {
      font-size: 2.8rem; } }

body#big .download-item-content-date {
  font-size: 18px; }
  @media (min-width: 1024px) {
    body#big .download-item-content-date {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    body#big .download-item-content-date {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    body#big .download-item-content-date {
      font-size: 1.8rem; } }

body#big .download-item-content-name {
  font-size: 22px; }
  @media (min-width: 1024px) {
    body#big .download-item-content-name {
      font-size: 2.75rem; } }
  @media (min-width: 1200px) {
    body#big .download-item-content-name {
      font-size: 2.44444rem; } }
  @media (min-width: 1420px) {
    body#big .download-item-content-name {
      font-size: 2.2rem; } }

body#big .card-box-big .card-item-big h3 {
  font-size: 24px; }
  @media (min-width: 460px) {
    body#big .card-box-big .card-item-big h3 {
      font-size: 26px; } }
  @media (min-width: 1024px) {
    body#big .card-box-big .card-item-big h3 {
      font-size: 28px; } }
  @media (min-width: 1200px) {
    body#big .card-box-big .card-item-big h3 {
      font-size: 3.33333rem; } }
  @media (min-width: 1420px) {
    body#big .card-box-big .card-item-big h3 {
      font-size: 3rem; } }

body#big .card-box-mini .card-item-mini h3 {
  font-size: 22px; }
  @media (min-width: 460px) {
    body#big .card-box-mini .card-item-mini h3 {
      font-size: 22px; } }
  @media (min-width: 1200px) {
    body#big .card-box-mini .card-item-mini h3 {
      font-size: 2.88889rem; } }
  @media (min-width: 1420px) {
    body#big .card-box-mini .card-item-mini h3 {
      font-size: 2.6rem; } }

@media (min-width: 1024px) {
  body#big .event-big-single .event-item-content h3 {
    font-size: 4.25rem; } }

@media (min-width: 1200px) {
  body#big .event-big-single .event-item-content h3 {
    font-size: 3.77778rem; } }

@media (min-width: 1420px) {
  body#big .event-big-single .event-item-content h3 {
    font-size: 3.4rem; } }

body#big .event-big-single .event-item-label,
body#big .event-big-single .event-item-from {
  font-size: 18px; }
  @media (min-width: 1024px) {
    body#big .event-big-single .event-item-label,
    body#big .event-big-single .event-item-from {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    body#big .event-big-single .event-item-label,
    body#big .event-big-single .event-item-from {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    body#big .event-big-single .event-item-label,
    body#big .event-big-single .event-item-from {
      font-size: 1.8rem; } }

@media (min-width: 300px) {
  body#big .collapsible-item-heading h2.h3,
  body#big .collapsible-item-heading .collapsible-item-index {
    font-size: 4.8rem; } }

@media (min-width: 320px) {
  body#big .collapsible-item-heading h2.h3,
  body#big .collapsible-item-heading .collapsible-item-index {
    font-size: 4.36364rem; } }

@media (min-width: 460px) {
  body#big .collapsible-item-heading h2.h3,
  body#big .collapsible-item-heading .collapsible-item-index {
    font-size: 4rem; } }

@media (min-width: 768px) {
  body#big .collapsible-item-heading h2.h3,
  body#big .collapsible-item-heading .collapsible-item-index {
    font-size: 3.2rem; } }

@media (min-width: 1024px) {
  body#big .collapsible-item-heading h2.h3,
  body#big .collapsible-item-heading .collapsible-item-index {
    font-size: 3rem; } }

@media (min-width: 1200px) {
  body#big .collapsible-item-heading h2.h3,
  body#big .collapsible-item-heading .collapsible-item-index {
    font-size: 2.66667rem; } }

@media (min-width: 1420px) {
  body#big .collapsible-item-heading h2.h3,
  body#big .collapsible-item-heading .collapsible-item-index {
    font-size: 2.4rem; } }

@media (min-width: 1024px) {
  body#big .events-big .event-big-parent .event-item-content h3,
  body#big .events-big .event-big .event-item-content h3 {
    font-size: 4.25rem; } }

@media (min-width: 1200px) {
  body#big .events-big .event-big-parent .event-item-content h3,
  body#big .events-big .event-big .event-item-content h3 {
    font-size: 3.77778rem; } }

@media (min-width: 1420px) {
  body#big .events-big .event-big-parent .event-item-content h3,
  body#big .events-big .event-big .event-item-content h3 {
    font-size: 3.4rem; } }

@media (min-width: 1024px) {
  body#big .events-big .event-big-parent .event-item-content h3,
  body#big .events-big .event-big .event-item-content h3 {
    line-height: 5rem; } }

@media (min-width: 1200px) {
  body#big .events-big .event-big-parent .event-item-content h3,
  body#big .events-big .event-big .event-item-content h3 {
    line-height: 4.44444rem; } }

@media (min-width: 1420px) {
  body#big .events-big .event-big-parent .event-item-content h3,
  body#big .events-big .event-big .event-item-content h3 {
    line-height: 4rem; } }

body#big .EditingFormLabel {
  font-size: 18px; }
  @media (min-width: 1024px) {
    body#big .EditingFormLabel {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    body#big .EditingFormLabel {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    body#big .EditingFormLabel {
      font-size: 1.8rem; } }

body#big .radio input[type="radio"] + label {
  font-size: 17px; }
  @media (min-width: 1024px) {
    body#big .radio input[type="radio"] + label {
      font-size: 2.125rem; } }
  @media (min-width: 1200px) {
    body#big .radio input[type="radio"] + label {
      font-size: 1.88889rem; } }
  @media (min-width: 1420px) {
    body#big .radio input[type="radio"] + label {
      font-size: 1.7rem; } }

@media (min-width: 1024px) {
  body#big .wizard-step-subtitle {
    font-size: 2.5rem; } }

@media (min-width: 1200px) {
  body#big .wizard-step-subtitle {
    font-size: 2.22222rem; } }

@media (min-width: 1420px) {
  body#big .wizard-step-subtitle {
    font-size: 2rem; } }

footer img,
main img {
  max-width: 100%;
  height: auto !important; }

@media (min-width: 300px) {
  .pt-5 {
    padding-top: 1rem !important; } }

@media (min-width: 320px) {
  .pt-5 {
    padding-top: 0.90909rem !important; } }

@media (min-width: 460px) {
  .pt-5 {
    padding-top: 0.83333rem !important; } }

@media (min-width: 768px) {
  .pt-5 {
    padding-top: 0.66667rem !important; } }

@media (min-width: 1024px) {
  .pt-5 {
    padding-top: 0.625rem !important; } }

@media (min-width: 1200px) {
  .pt-5 {
    padding-top: 0.55556rem !important; } }

@media (min-width: 1420px) {
  .pt-5 {
    padding-top: 0.5rem !important; } }

@media (min-width: 300px) {
  .pb-5 {
    padding-bottom: 1rem !important; } }

@media (min-width: 320px) {
  .pb-5 {
    padding-bottom: 0.90909rem !important; } }

@media (min-width: 460px) {
  .pb-5 {
    padding-bottom: 0.83333rem !important; } }

@media (min-width: 768px) {
  .pb-5 {
    padding-bottom: 0.66667rem !important; } }

@media (min-width: 1024px) {
  .pb-5 {
    padding-bottom: 0.625rem !important; } }

@media (min-width: 1200px) {
  .pb-5 {
    padding-bottom: 0.55556rem !important; } }

@media (min-width: 1420px) {
  .pb-5 {
    padding-bottom: 0.5rem !important; } }

@media (min-width: 300px) {
  .mt-5 {
    margin-top: 1rem !important; } }

@media (min-width: 320px) {
  .mt-5 {
    margin-top: 0.90909rem !important; } }

@media (min-width: 460px) {
  .mt-5 {
    margin-top: 0.83333rem !important; } }

@media (min-width: 768px) {
  .mt-5 {
    margin-top: 0.66667rem !important; } }

@media (min-width: 1024px) {
  .mt-5 {
    margin-top: 0.625rem !important; } }

@media (min-width: 1200px) {
  .mt-5 {
    margin-top: 0.55556rem !important; } }

@media (min-width: 1420px) {
  .mt-5 {
    margin-top: 0.5rem !important; } }

@media (min-width: 300px) {
  .mb-5 {
    margin-bottom: 1rem !important; } }

@media (min-width: 320px) {
  .mb-5 {
    margin-bottom: 0.90909rem !important; } }

@media (min-width: 460px) {
  .mb-5 {
    margin-bottom: 0.83333rem !important; } }

@media (min-width: 768px) {
  .mb-5 {
    margin-bottom: 0.66667rem !important; } }

@media (min-width: 1024px) {
  .mb-5 {
    margin-bottom: 0.625rem !important; } }

@media (min-width: 1200px) {
  .mb-5 {
    margin-bottom: 0.55556rem !important; } }

@media (min-width: 1420px) {
  .mb-5 {
    margin-bottom: 0.5rem !important; } }

@media (min-width: 300px) {
  .pt-10 {
    padding-top: 2rem !important; } }

@media (min-width: 320px) {
  .pt-10 {
    padding-top: 1.81818rem !important; } }

@media (min-width: 460px) {
  .pt-10 {
    padding-top: 1.66667rem !important; } }

@media (min-width: 768px) {
  .pt-10 {
    padding-top: 1.33333rem !important; } }

@media (min-width: 1024px) {
  .pt-10 {
    padding-top: 1.25rem !important; } }

@media (min-width: 1200px) {
  .pt-10 {
    padding-top: 1.11111rem !important; } }

@media (min-width: 1420px) {
  .pt-10 {
    padding-top: 1rem !important; } }

@media (min-width: 300px) {
  .pb-10 {
    padding-bottom: 2rem !important; } }

@media (min-width: 320px) {
  .pb-10 {
    padding-bottom: 1.81818rem !important; } }

@media (min-width: 460px) {
  .pb-10 {
    padding-bottom: 1.66667rem !important; } }

@media (min-width: 768px) {
  .pb-10 {
    padding-bottom: 1.33333rem !important; } }

@media (min-width: 1024px) {
  .pb-10 {
    padding-bottom: 1.25rem !important; } }

@media (min-width: 1200px) {
  .pb-10 {
    padding-bottom: 1.11111rem !important; } }

@media (min-width: 1420px) {
  .pb-10 {
    padding-bottom: 1rem !important; } }

@media (min-width: 300px) {
  .mt-10 {
    margin-top: 2rem !important; } }

@media (min-width: 320px) {
  .mt-10 {
    margin-top: 1.81818rem !important; } }

@media (min-width: 460px) {
  .mt-10 {
    margin-top: 1.66667rem !important; } }

@media (min-width: 768px) {
  .mt-10 {
    margin-top: 1.33333rem !important; } }

@media (min-width: 1024px) {
  .mt-10 {
    margin-top: 1.25rem !important; } }

@media (min-width: 1200px) {
  .mt-10 {
    margin-top: 1.11111rem !important; } }

@media (min-width: 1420px) {
  .mt-10 {
    margin-top: 1rem !important; } }

@media (min-width: 300px) {
  .mb-10 {
    margin-bottom: 2rem !important; } }

@media (min-width: 320px) {
  .mb-10 {
    margin-bottom: 1.81818rem !important; } }

@media (min-width: 460px) {
  .mb-10 {
    margin-bottom: 1.66667rem !important; } }

@media (min-width: 768px) {
  .mb-10 {
    margin-bottom: 1.33333rem !important; } }

@media (min-width: 1024px) {
  .mb-10 {
    margin-bottom: 1.25rem !important; } }

@media (min-width: 1200px) {
  .mb-10 {
    margin-bottom: 1.11111rem !important; } }

@media (min-width: 1420px) {
  .mb-10 {
    margin-bottom: 1rem !important; } }

@media (min-width: 300px) {
  .pt-15 {
    padding-top: 3rem !important; } }

@media (min-width: 320px) {
  .pt-15 {
    padding-top: 2.72727rem !important; } }

@media (min-width: 460px) {
  .pt-15 {
    padding-top: 2.5rem !important; } }

@media (min-width: 768px) {
  .pt-15 {
    padding-top: 2rem !important; } }

@media (min-width: 1024px) {
  .pt-15 {
    padding-top: 1.875rem !important; } }

@media (min-width: 1200px) {
  .pt-15 {
    padding-top: 1.66667rem !important; } }

@media (min-width: 1420px) {
  .pt-15 {
    padding-top: 1.5rem !important; } }

@media (min-width: 300px) {
  .pb-15 {
    padding-bottom: 3rem !important; } }

@media (min-width: 320px) {
  .pb-15 {
    padding-bottom: 2.72727rem !important; } }

@media (min-width: 460px) {
  .pb-15 {
    padding-bottom: 2.5rem !important; } }

@media (min-width: 768px) {
  .pb-15 {
    padding-bottom: 2rem !important; } }

@media (min-width: 1024px) {
  .pb-15 {
    padding-bottom: 1.875rem !important; } }

@media (min-width: 1200px) {
  .pb-15 {
    padding-bottom: 1.66667rem !important; } }

@media (min-width: 1420px) {
  .pb-15 {
    padding-bottom: 1.5rem !important; } }

@media (min-width: 300px) {
  .mt-15 {
    margin-top: 3rem !important; } }

@media (min-width: 320px) {
  .mt-15 {
    margin-top: 2.72727rem !important; } }

@media (min-width: 460px) {
  .mt-15 {
    margin-top: 2.5rem !important; } }

@media (min-width: 768px) {
  .mt-15 {
    margin-top: 2rem !important; } }

@media (min-width: 1024px) {
  .mt-15 {
    margin-top: 1.875rem !important; } }

@media (min-width: 1200px) {
  .mt-15 {
    margin-top: 1.66667rem !important; } }

@media (min-width: 1420px) {
  .mt-15 {
    margin-top: 1.5rem !important; } }

@media (min-width: 300px) {
  .mb-15 {
    margin-bottom: 3rem !important; } }

@media (min-width: 320px) {
  .mb-15 {
    margin-bottom: 2.72727rem !important; } }

@media (min-width: 460px) {
  .mb-15 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 768px) {
  .mb-15 {
    margin-bottom: 2rem !important; } }

@media (min-width: 1024px) {
  .mb-15 {
    margin-bottom: 1.875rem !important; } }

@media (min-width: 1200px) {
  .mb-15 {
    margin-bottom: 1.66667rem !important; } }

@media (min-width: 1420px) {
  .mb-15 {
    margin-bottom: 1.5rem !important; } }

@media (min-width: 300px) {
  .pt-20 {
    padding-top: 4rem !important; } }

@media (min-width: 320px) {
  .pt-20 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pt-20 {
    padding-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pt-20 {
    padding-top: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .pt-20 {
    padding-top: 2.5rem !important; } }

@media (min-width: 1200px) {
  .pt-20 {
    padding-top: 2.22222rem !important; } }

@media (min-width: 1420px) {
  .pt-20 {
    padding-top: 2rem !important; } }

@media (min-width: 300px) {
  .pb-20 {
    padding-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .pb-20 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pb-20 {
    padding-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pb-20 {
    padding-bottom: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .pb-20 {
    padding-bottom: 2.5rem !important; } }

@media (min-width: 1200px) {
  .pb-20 {
    padding-bottom: 2.22222rem !important; } }

@media (min-width: 1420px) {
  .pb-20 {
    padding-bottom: 2rem !important; } }

@media (min-width: 300px) {
  .mt-20 {
    margin-top: 4rem !important; } }

@media (min-width: 320px) {
  .mt-20 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mt-20 {
    margin-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mt-20 {
    margin-top: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .mt-20 {
    margin-top: 2.5rem !important; } }

@media (min-width: 1200px) {
  .mt-20 {
    margin-top: 2.22222rem !important; } }

@media (min-width: 1420px) {
  .mt-20 {
    margin-top: 2rem !important; } }

@media (min-width: 300px) {
  .mb-20 {
    margin-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .mb-20 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mb-20 {
    margin-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mb-20 {
    margin-bottom: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .mb-20 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 1200px) {
  .mb-20 {
    margin-bottom: 2.22222rem !important; } }

@media (min-width: 1420px) {
  .mb-20 {
    margin-bottom: 2rem !important; } }

@media (min-width: 300px) {
  .pt-25 {
    padding-top: 4rem !important; } }

@media (min-width: 320px) {
  .pt-25 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pt-25 {
    padding-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pt-25 {
    padding-top: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .pt-25 {
    padding-top: 2.5rem !important; } }

@media (min-width: 300px) {
  .pb-25 {
    padding-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .pb-25 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pb-25 {
    padding-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pb-25 {
    padding-bottom: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .pb-25 {
    padding-bottom: 2.5rem !important; } }

@media (min-width: 300px) {
  .mt-25 {
    margin-top: 4rem !important; } }

@media (min-width: 320px) {
  .mt-25 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mt-25 {
    margin-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mt-25 {
    margin-top: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .mt-25 {
    margin-top: 2.5rem !important; } }

@media (min-width: 300px) {
  .mb-25 {
    margin-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .mb-25 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mb-25 {
    margin-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mb-25 {
    margin-bottom: 2.66667rem !important; } }

@media (min-width: 1024px) {
  .mb-25 {
    margin-bottom: 2.5rem !important; } }

@media (min-width: 300px) {
  .pt-30 {
    padding-top: 4rem !important; } }

@media (min-width: 320px) {
  .pt-30 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pt-30 {
    padding-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pt-30 {
    padding-top: 3rem !important; } }

@media (min-width: 300px) {
  .pb-30 {
    padding-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .pb-30 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pb-30 {
    padding-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .pb-30 {
    padding-bottom: 3rem !important; } }

@media (min-width: 300px) {
  .mt-30 {
    margin-top: 4rem !important; } }

@media (min-width: 320px) {
  .mt-30 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mt-30 {
    margin-top: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mt-30 {
    margin-top: 3rem !important; } }

@media (min-width: 300px) {
  .mb-30 {
    margin-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .mb-30 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mb-30 {
    margin-bottom: 3.33333rem !important; } }

@media (min-width: 768px) {
  .mb-30 {
    margin-bottom: 3rem !important; } }

@media (min-width: 300px) {
  .pt-35 {
    padding-top: 4rem !important; } }

@media (min-width: 320px) {
  .pt-35 {
    padding-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pt-35 {
    padding-top: 3.5rem !important; } }

@media (min-width: 300px) {
  .pb-35 {
    padding-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .pb-35 {
    padding-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .pb-35 {
    padding-bottom: 3.5rem !important; } }

@media (min-width: 300px) {
  .mt-35 {
    margin-top: 4rem !important; } }

@media (min-width: 320px) {
  .mt-35 {
    margin-top: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mt-35 {
    margin-top: 3.5rem !important; } }

@media (min-width: 300px) {
  .mb-35 {
    margin-bottom: 4rem !important; } }

@media (min-width: 320px) {
  .mb-35 {
    margin-bottom: 3.63636rem !important; } }

@media (min-width: 460px) {
  .mb-35 {
    margin-bottom: 3.5rem !important; } }

@media (min-width: 300px) {
  .pt-40 {
    padding-top: 4rem !important; } }

@media (min-width: 300px) {
  .pb-40 {
    padding-bottom: 4rem !important; } }

@media (min-width: 300px) {
  .mt-40 {
    margin-top: 4rem !important; } }

@media (min-width: 300px) {
  .mb-40 {
    margin-bottom: 4rem !important; } }

@media (min-width: 300px) {
  .pt-45 {
    padding-top: 4.5rem !important; } }

@media (min-width: 300px) {
  .pb-45 {
    padding-bottom: 4.5rem !important; } }

@media (min-width: 300px) {
  .mt-45 {
    margin-top: 4.5rem !important; } }

@media (min-width: 300px) {
  .mb-45 {
    margin-bottom: 4.5rem !important; } }

@media (min-width: 300px) {
  .pt-50 {
    padding-top: 5rem !important; } }

@media (min-width: 300px) {
  .pb-50 {
    padding-bottom: 5rem !important; } }

@media (min-width: 300px) {
  .mt-50 {
    margin-top: 5rem !important; } }

@media (min-width: 300px) {
  .mb-50 {
    margin-bottom: 5rem !important; } }

@media (min-width: 300px) {
  .pt-55 {
    padding-top: 5.5rem !important; } }

@media (min-width: 300px) {
  .pb-55 {
    padding-bottom: 5.5rem !important; } }

@media (min-width: 300px) {
  .mt-55 {
    margin-top: 5.5rem !important; } }

@media (min-width: 300px) {
  .mb-55 {
    margin-bottom: 5.5rem !important; } }

@media (min-width: 300px) {
  .pt-60 {
    padding-top: 6rem !important; } }

@media (min-width: 300px) {
  .pb-60 {
    padding-bottom: 6rem !important; } }

@media (min-width: 300px) {
  .mt-60 {
    margin-top: 6rem !important; } }

@media (min-width: 300px) {
  .mb-60 {
    margin-bottom: 6rem !important; } }

@media (min-width: 300px) {
  .pt-65 {
    padding-top: 6.5rem !important; } }

@media (min-width: 300px) {
  .pb-65 {
    padding-bottom: 6.5rem !important; } }

@media (min-width: 300px) {
  .mt-65 {
    margin-top: 6.5rem !important; } }

@media (min-width: 300px) {
  .mb-65 {
    margin-bottom: 6.5rem !important; } }

@media (min-width: 300px) {
  .pt-70 {
    padding-top: 7rem !important; } }

@media (min-width: 300px) {
  .pb-70 {
    padding-bottom: 7rem !important; } }

@media (min-width: 300px) {
  .mt-70 {
    margin-top: 7rem !important; } }

@media (min-width: 300px) {
  .mb-70 {
    margin-bottom: 7rem !important; } }

@media (min-width: 300px) {
  .pt-75 {
    padding-top: 7.5rem !important; } }

@media (min-width: 300px) {
  .pb-75 {
    padding-bottom: 7.5rem !important; } }

@media (min-width: 300px) {
  .mt-75 {
    margin-top: 7.5rem !important; } }

@media (min-width: 300px) {
  .mb-75 {
    margin-bottom: 7.5rem !important; } }

@media (min-width: 300px) {
  .pt-80 {
    padding-top: 8rem !important; } }

@media (min-width: 300px) {
  .pb-80 {
    padding-bottom: 8rem !important; } }

@media (min-width: 300px) {
  .mt-80 {
    margin-top: 8rem !important; } }

@media (min-width: 300px) {
  .mb-80 {
    margin-bottom: 8rem !important; } }

@media (min-width: 300px) {
  .pt-85 {
    padding-top: 8.5rem !important; } }

@media (min-width: 300px) {
  .pb-85 {
    padding-bottom: 8.5rem !important; } }

@media (min-width: 300px) {
  .mt-85 {
    margin-top: 8.5rem !important; } }

@media (min-width: 300px) {
  .mb-85 {
    margin-bottom: 8.5rem !important; } }

@media (min-width: 300px) {
  .pt-90 {
    padding-top: 9rem !important; } }

@media (min-width: 300px) {
  .pb-90 {
    padding-bottom: 9rem !important; } }

@media (min-width: 300px) {
  .mt-90 {
    margin-top: 9rem !important; } }

@media (min-width: 300px) {
  .mb-90 {
    margin-bottom: 9rem !important; } }

@media (min-width: 300px) {
  .pt-95 {
    padding-top: 9.5rem !important; } }

@media (min-width: 300px) {
  .pb-95 {
    padding-bottom: 9.5rem !important; } }

@media (min-width: 300px) {
  .mt-95 {
    margin-top: 9.5rem !important; } }

@media (min-width: 300px) {
  .mb-95 {
    margin-bottom: 9.5rem !important; } }

@media (min-width: 300px) {
  .pt-100 {
    padding-top: 10rem !important; } }

@media (min-width: 300px) {
  .pb-100 {
    padding-bottom: 10rem !important; } }

@media (min-width: 300px) {
  .mt-100 {
    margin-top: 10rem !important; } }

@media (min-width: 300px) {
  .mb-100 {
    margin-bottom: 10rem !important; } }

.image-top-center {
  position: top center !important; }

.image-top-left {
  position: top left !important; }

.image-top-right {
  position: top right !important; }

.image-center-center {
  position: center center !important; }

.image-bottom-center {
  position: bottom center !important; }

.image-bottom-left {
  position: bottom left !important; }

.image-bottom-right {
  position: bottom right !important; }

main {
  min-height: 40rem; }

.small-container a {
  font-weight: 700; }

.color-box-one,
.color-box-two,
.color-box-three {
  padding: 20px;
  margin: 15px auto;
  max-width: 780px; }

.widget-container .widget-title.color-box-one, .widget-container .widget-title.color-box-two, .widget-container .widget-title.color-box-three {
  margin: 0 auto 5rem auto; }

iframe[src*="youtube"] {
  aspect-ratio: 16 / 9;
  width: 100%; }

.text-widget,
.event-detail {
  font-size: 2rem; }

#big .text-widget,
#big .event-detail {
  font-size: 2.2rem; }

.grecaptcha-badge {
  visibility: hidden; }

.label-form,
label {
  display: block;
  margin: 1rem 0 0.5rem 0; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
  width: 100%;
  height: 70px;
  padding: 0 3rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  font-size: 18px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  @media (min-width: 1024px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    .form-control,
    select,
    select.form-control,
    textarea,
    textarea.form-control,
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
      font-size: 1.8rem; } }
  .form-control:focus,
  select:focus,
  select.form-control:focus,
  textarea:focus,
  textarea.form-control:focus,
  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    outline: 0;
    border-width: 1px;
    border-style: solid; }

textarea.form-control,
textarea {
  height: 180px;
  padding: 22px 3rem; }
  textarea.form-control:focus,
  textarea:focus {
    outline: 0;
    border-width: 1px;
    border-style: solid; }

input[type="submit"] {
  cursor: pointer; }

.required label:before {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 320px) {
    .required label:before {
      font-size: 1.81818rem; } }
  @media (min-width: 460px) {
    .required label:before {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    .required label:before {
      font-size: 1.33333rem; } }
  @media (min-width: 1024px) {
    .required label:before {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    .required label:before {
      font-size: 1.11111rem; } }
  @media (min-width: 1420px) {
    .required label:before {
      font-size: 1rem; } }

label.required:after {
  content: "*";
  display: inline-block;
  vertical-align: 0.6rem;
  margin: 0 2px 0 0;
  font-size: 2rem; }
  @media (min-width: 320px) {
    label.required:after {
      font-size: 1.81818rem; } }
  @media (min-width: 460px) {
    label.required:after {
      font-size: 1.66667rem; } }
  @media (min-width: 768px) {
    label.required:after {
      font-size: 1.33333rem; } }
  @media (min-width: 1024px) {
    label.required:after {
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    label.required:after {
      font-size: 1.11111rem; } }
  @media (min-width: 1420px) {
    label.required:after {
      font-size: 1rem; } }

.file .uploader-upload {
  display: none; }

.file label {
  display: inline-block;
  padding: 1rem 2rem;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 2.8rem;
  border-radius: 0;
  max-width: 24rem;
  margin: 0;
  width: 100%;
  text-align: center;
  cursor: pointer;
  letter-spacing: 1px;
  outline: 0 !important; }
  @media (min-width: 320px) {
    .file label {
      font-size: 2.54545rem; } }
  @media (min-width: 460px) {
    .file label {
      font-size: 2.33333rem; } }
  @media (min-width: 768px) {
    .file label {
      font-size: 1.86667rem; } }
  @media (min-width: 1024px) {
    .file label {
      font-size: 1.75rem; } }
  @media (min-width: 1200px) {
    .file label {
      font-size: 1.55556rem; } }
  @media (min-width: 1420px) {
    .file label {
      font-size: 1.4rem; } }

.file .file-name {
  padding: 0.5rem; }

.form-validation-error select,
.form-validation-error .selectric,
.form-validation-error input[type="text"],
.form-validation-error input[type="password"],
.form-validation-error input[type="email"],
.form-validation-error input[type="number"],
.form-validation-error textarea,
.Error:not(.form-validation-valid) select,
.Error:not(.form-validation-valid) .selectric,
.Error:not(.form-validation-valid) input[type="text"],
.Error:not(.form-validation-valid) input[type="password"],
.Error:not(.form-validation-valid) input[type="email"],
.Error:not(.form-validation-valid) input[type="number"],
.Error:not(.form-validation-valid) textarea {
  border: 1px solid #d40511 !important;
  -webkit-animation-name: blink;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-iteration-count: 2;
  -webkit-animation-direction: normal;
  -moz-animation-name: blink;
  -moz-animation-duration: 1s;
  -moz-animation-timing-function: ease;
  -moz-animation-iteration-count: 2;
  -moz-animation-direction: normal;
  -o-animation-name: blink;
  -o-animation-duration: 1s;
  -o-animation-timing-function: ease;
  -o-animation-iteration-count: 2;
  -o-animation-direction: normal;
  animation-name: blink;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 2;
  animation-direction: normal; }

@-webkit-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-moz-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@-ms-keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

@keyframes blink {
  0% {
    background: #fff; }
  50% {
    background: #fec7ca; }
  100% {
    background: #fff; } }

.Error .form-validation-valid select,
.Error .form-validation-valid .selectric,
.Error .form-validation-valid input[type="text"],
.Error .form-validation-valid input[type="password"],
.Error .form-validation-valid input[type="email"],
.Error .form-validation-valid input[type="number"],
.Error .form-validation-valid textarea,
.form-validation-valid select,
.form-validation-valid .selectric,
.form-validation-valid input[type="text"],
.form-validation-valid input[type="password"],
.form-validation-valid input[type="email"],
.form-validation-valid input[type="number"],
.form-validation-valid textarea {
  background: #fff !important;
  animation: unset !important;
  border: 1px solid #757575 !important; }

select.form-validation-valid,
.selectric.form-validation-valid,
input[type="text"].form-validation-valid,
input[type="password"].form-validation-valid,
input[type="email"].form-validation-valid,
input[type="number"].form-validation-valid,
textarea.form-validation-valid {
  background: #fff !important;
  animation: unset !important;
  border: 1px solid #757575 !important; }

.Error:has(.form-validation-valid) + .EditingFormErrorLabel,
.form-validation-valid + .EditingFormErrorLabel {
  display: none; }

div.form-validation-valid .FormErrorLabel,
div.form-validation-valid .EditingFormErrorLabel {
  display: none; }

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel {
  font-size: 14px;
  color: #d40511;
  display: block;
  margin: 0.5rem 0 0 0; }

.hide-error .EditingFormErrorLabel {
  display: none; }

.form-validation-error + .EditingFormErrorLabel {
  display: none; }

.radio input[type="radio"] {
  display: none; }
  .radio input[type="radio"] + label {
    position: relative;
    display: flex;
    align-items: baseline;
    margin: 0; }
    .radio input[type="radio"] + label:before {
      content: "";
      border-radius: 50%;
      width: 20px;
      height: 20px;
      min-width: 20px;
      display: block;
      border-width: 5px;
      border-style: solid;
      margin: 0 10px 0 0;
      position: relative;
      top: 3px; }

.checkbox input[type="checkbox"] {
  display: none; }
  .checkbox input[type="checkbox"] + label {
    position: relative;
    margin: 0;
    display: inline-flex;
    align-items: baseline; }
    .checkbox input[type="checkbox"] + label:before {
      content: "\e9a6";
      width: 20px;
      min-width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 0;
      font-size: 14px; }

.row-form {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1rem; }

.row-form [class^="col-"] {
  padding-left: 1rem;
  padding-right: 1rem; }

.floating-label-text,
.floating-label {
  position: relative;
  width: 100%;
  padding: 0 0 10px 0;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .floating-label-text > .placeholder,
  .floating-label > .placeholder {
    opacity: 1;
    pointer-events: none;
    line-height: 70px;
    transform: translate3d(0, -100%, 0);
    padding: 0;
    display: inline-block;
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: 30px; }
    .floating-label-text > .placeholder.active,
    .floating-label > .placeholder.active {
      opacity: 0; }
  .floating-label-text > label,
  .floating-label > label {
    position: absolute;
    top: 22px;
    left: 3rem;
    display: inline-block;
    transition: all .3s ease;
    margin: 0;
    opacity: 1;
    z-index: 11;
    padding: 0; }
    .floating-label-text > label.is-visible,
    .floating-label > label.is-visible {
      transform: translate3d(0, -14px, 0);
      opacity: 1;
      font-size: 14px; }
  .floating-label-text > input,
  .floating-label > input {
    position: relative;
    z-index: 1;
    float: left;
    width: 100%;
    height: 70px;
    clear: both;
    padding: 0;
    background-color: transparent; }
  .floating-label-text.active,
  .floating-label.active {
    padding: 0 0 10px 0; }
    .floating-label-text.active input,
    .floating-label.active input {
      padding: 15px 3rem 0 3rem; }
    .floating-label-text.active textarea.form-control,
    .floating-label-text.active textarea,
    .floating-label.active textarea.form-control,
    .floating-label.active textarea {
      padding: 30px 3rem 15px 3rem; }
    .floating-label-text.active label,
    .floating-label.active label {
      transform: translate3d(0, -15px, 0);
      opacity: 1;
      font-size: 14px;
      left: 3rem; }

.form-container {
  max-width: 500px;
  margin: 0 auto; }
  .form-container .ErrorLabel {
    text-align: center;
    display: block;
    margin: 0 0 2rem 0; }
  .form-container input[type="submit"] {
    margin: 2rem auto 0 auto;
    display: flex; }

.InfoLabel {
  padding: 7px 10px;
  margin: 0;
  border: 1px solid transparent;
  font-weight: 700;
  margin-bottom: 20px;
  display: block;
  text-align: center;
  color: #155724;
  background-color: #d4edda;
  border-color: #d4edda; }
  .InfoLabel h2,
  .InfoLabel h3 {
    color: #155724; }

.job-bottom {
  display: flex;
  gap: 8rem;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .job-bottom {
      flex-wrap: nowrap; } }

.job-form {
  width: 100%; }
  .job-form .FormButton {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: inline-block; }

.job-person .person-item-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem; }

.job-person .person-item-content {
  padding: 0 !important; }

.job-person .person-item-description-text,
.job-person .person-item-description {
  display: none !important; }

.file-new input[type="file"],
.file-new .ExplanationText {
  display: none; }

.file-new .file-label {
  display: flex;
  gap: 2rem;
  flex-direction: column; }
  @media (min-width: 460px) {
    .file-new .file-label {
      flex-direction: row;
      align-items: center; } }

.file-new .file-placeholder {
  width: 100%;
  height: 70px;
  padding: 0 3rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  font-size: 18px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  cursor: pointer;
  display: flex;
  align-items: center; }
  @media (min-width: 1024px) {
    .file-new .file-placeholder {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .file-new .file-placeholder {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    .file-new .file-placeholder {
      font-size: 1.8rem; } }

.file-new .file-button {
  cursor: pointer; }

.wizard-file input[type="file"],
.wizard-file .ExplanationText {
  display: none; }

.wizard-file .file-label {
  display: inline-flex;
  gap: 2rem;
  flex-direction: column;
  margin: 0; }
  @media (min-width: 460px) {
    .wizard-file .file-label {
      flex-direction: row;
      align-items: center; } }

.wizard-file .file-placeholder {
  width: 100%;
  height: 70px;
  border: none;
  border-radius: 0;
  font-size: 13px;
  -moz-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  -webkit-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  pointer-events: none;
  display: flex;
  align-items: center; }
  @media (min-width: 1024px) {
    .wizard-file .file-placeholder {
      font-size: 1.625rem; } }
  @media (min-width: 1200px) {
    .wizard-file .file-placeholder {
      font-size: 1.44444rem; } }
  @media (min-width: 1420px) {
    .wizard-file .file-placeholder {
      font-size: 1.3rem; } }
  .wizard-file .file-placeholder.active {
    cursor: pointer; }

.wizard-file .file-button {
  cursor: pointer; }

@media (min-width: 300px) {
  .recaptcha-privacy {
    font-size: 2.4rem; } }

@media (min-width: 320px) {
  .recaptcha-privacy {
    font-size: 2.18182rem; } }

@media (min-width: 460px) {
  .recaptcha-privacy {
    font-size: 2rem; } }

@media (min-width: 768px) {
  .recaptcha-privacy {
    font-size: 1.6rem; } }

@media (min-width: 1024px) {
  .recaptcha-privacy {
    font-size: 1.5rem; } }

@media (min-width: 1200px) {
  .recaptcha-privacy {
    font-size: 1.4rem; } }

@media (min-width: 300px) {
  #big .recaptcha-privacy {
    font-size: 2.8rem; } }

@media (min-width: 320px) {
  #big .recaptcha-privacy {
    font-size: 2.54545rem; } }

@media (min-width: 460px) {
  #big .recaptcha-privacy {
    font-size: 2.33333rem; } }

@media (min-width: 768px) {
  #big .recaptcha-privacy {
    font-size: 1.86667rem; } }

@media (min-width: 1024px) {
  #big .recaptcha-privacy {
    font-size: 1.75rem; } }

@media (min-width: 1200px) {
  #big .recaptcha-privacy {
    font-size: 1.6rem; } }

.form-chat-banner {
  padding: 10px;
  margin: 1.5rem 0;
  cursor: pointer; }

.map-point {
  display: none; }

.brno-hr {
  margin: 3rem 0; }

.breadcrumbs {
  display: block;
  padding: 0 0 1rem 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0; }
  .breadcrumbs .home {
    font-size: 0 !important;
    transition: all 0.3s ease-out; }
    .breadcrumbs .home:after {
      content: "\e918";
      font-size: 18px;
      display: inline; }
      @media (min-width: 1024px) {
        .breadcrumbs .home:after {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        .breadcrumbs .home:after {
          font-size: 2rem; } }
      @media (min-width: 1420px) {
        .breadcrumbs .home:after {
          font-size: 1.8rem; } }
    .breadcrumbs .home:hover, .breadcrumbs .home:focus {
      text-decoration: none; }
      .breadcrumbs .home:hover:after, .breadcrumbs .home:focus:after {
        text-decoration: none; }
  .breadcrumbs a {
    transition: all 0.3s ease-out;
    font-weight: 400; }
    .breadcrumbs a:focus, .breadcrumbs a:hover {
      text-decoration: underline; }
    .breadcrumbs a[href="#"] {
      cursor: default;
      text-decoration: none !important; }
  .breadcrumbs a, .breadcrumbs span {
    display: inline;
    font-size: 16px; }
    @media (min-width: 768px) {
      .breadcrumbs a, .breadcrumbs span {
        font-size: 18px; } }
    @media (min-width: 1200px) {
      .breadcrumbs a, .breadcrumbs span {
        font-size: 2rem; } }
    @media (min-width: 1420px) {
      .breadcrumbs a, .breadcrumbs span {
        font-size: 1.8rem; } }
  .breadcrumbs .breadcrumbs-separator:after {
    display: inline-block;
    vertical-align: middle;
    content: "\e902";
    position: relative;
    -webkit-transform: scale(0.25) rotate(270deg);
    -moz-transform: scale(0.25) rotate(270deg);
    -ms-transform: scale(0.25) rotate(270deg);
    -o-transform: scale(0.25) rotate(270deg);
    transform: scale(0.25) rotate(270deg); }
  .breadcrumbs.breadcrumbs-old {
    opacity: 1; }
  .breadcrumbs.breadcrumbs-new {
    opacity: 1;
    display: flex;
    align-items: center;
    overflow: visible; }
    .breadcrumbs.breadcrumbs-new .CMSBreadCrumbsCurrentItem {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%; }
  .breadcrumbs .breadcrumbs-collapse-btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 2px 10px;
    border: 1px solid #c1d0e1;
    border-radius: 3px;
    background-color: transparent;
    cursor: pointer;
    line-height: 1; }
    .breadcrumbs .breadcrumbs-collapse-btn:before {
      position: absolute;
      z-index: 33;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #fff;
      content: "";
      opacity: 0;
      border: 1px solid #fff; }
  .breadcrumbs .breadcrumbs-collapse-list {
    position: absolute;
    z-index: 22;
    top: 100%;
    left: -46px;
    flex-direction: column;
    gap: 5px;
    display: none;
    width: 280px;
    padding: 15px 20px;
    border: 1px solid #c1d0e1;
    border-radius: .3px;
    margin-top: -1px;
    background-color: #fff;
    border-top-left-radius: 0; }
    @media (min-width: 460px) {
      .breadcrumbs .breadcrumbs-collapse-list {
        left: 0;
        width: auto; } }
  .breadcrumbs .breadcrumbs-collapse {
    position: relative;
    z-index: 22; }
    .breadcrumbs .breadcrumbs-collapse a {
      color: #000; }
      .breadcrumbs .breadcrumbs-collapse a:focus, .breadcrumbs .breadcrumbs-collapse a:hover {
        color: #000; }
    .breadcrumbs .breadcrumbs-collapse.open {
      filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.1)); }
      .breadcrumbs .breadcrumbs-collapse.open .breadcrumbs-collapse-list {
        display: flex; }
      .breadcrumbs .breadcrumbs-collapse.open .breadcrumbs-collapse-btn {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fff;
        color: #000; }
      .breadcrumbs .breadcrumbs-collapse.open .breadcrumbs-collapse-btn:before {
        opacity: 1; }

.search-item-top {
  display: block;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }
  @media (min-width: 300px) and (max-width: 767px) {
    .search-item-top {
      font-size: 16px; } }
  .search-item-top .home {
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    transition: all 0.3s ease-out; }
    .search-item-top .home:after {
      content: "\e918";
      font-size: 20px;
      display: block;
      position: relative;
      top: -3px; }
      @media (min-width: 1024px) {
        .search-item-top .home:after {
          font-size: 2.5rem; } }
      @media (min-width: 1200px) {
        .search-item-top .home:after {
          font-size: 2.22222rem; } }
      @media (min-width: 1420px) {
        .search-item-top .home:after {
          font-size: 2rem; } }
      @media (min-width: 300px) and (max-width: 767px) {
        .search-item-top .home:after {
          font-size: 16px; } }
    .search-item-top .home:hover, .search-item-top .home:focus {
      text-decoration: none; }
      .search-item-top .home:hover:after, .search-item-top .home:focus:after {
        text-decoration: none; }
  .search-item-top .search-separator:after {
    position: relative;
    top: 0px;
    display: inline-block;
    content: "\e902";
    vertical-align: middle;
    -webkit-transform: scale(0.25) rotate(270deg);
    -moz-transform: scale(0.25) rotate(270deg);
    -ms-transform: scale(0.25) rotate(270deg);
    -o-transform: scale(0.25) rotate(270deg);
    transform: scale(0.25) rotate(270deg);
    margin: auto 0; }

.search-item {
  margin: 0 0 4rem 0; }
  .search-item-top {
    padding: 0 0 1rem 0; }
  .search-item .h3,
  .search-item .h2 {
    margin: 0 0 5px 0; }

.search-word {
  font-weight: 400; }

.header-intro {
  display: block;
  position: relative;
  text-align: left;
  min-height: 20rem; }
  .header-intro:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 44;
    background: url("../img/pattern.png") no-repeat center/cover; }
  .header-intro:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 33; }
  .header-intro .bgr-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
  .header-intro .header-intro-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 35px 0;
    z-index: 55; }
    @media (min-width: 1024px) {
      .header-intro .header-intro-container {
        padding: 6rem 0; } }
    .header-intro .header-intro-container .header-intro-bread,
    .header-intro .header-intro-container .header-intro-title,
    .header-intro .header-intro-container .header-intro-subtitle {
      position: relative; }
    .header-intro .header-intro-container .header-intro-subtitle {
      font-weight: 700;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      margin-top: .5rem; }
      @media (min-width: 768px) {
        .header-intro .header-intro-container .header-intro-subtitle {
          font-size: 20px; } }
      @media (min-width: 1200px) {
        .header-intro .header-intro-container .header-intro-subtitle {
          font-size: 2.22222rem; } }
      @media (min-width: 1420px) {
        .header-intro .header-intro-container .header-intro-subtitle {
          font-size: 2rem; } }
      .header-intro .header-intro-container .header-intro-subtitle:before {
        content: "\e99b";
        margin-right: 1rem;
        position: relative;
        top: -2px; }
      .header-intro .header-intro-container .header-intro-subtitle span {
        font-weight: 400;
        margin: 0 0 0 5px; }
      .header-intro .header-intro-container .header-intro-subtitle.header-event-title:before {
        top: 4px; }
    .header-intro .header-intro-container .header-intro-subtitle-iiner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap; }
    .header-intro .header-intro-container.header-smaller {
      padding: 20px; }
      @media (min-width: 1024px) {
        .header-intro .header-intro-container.header-smaller {
          padding: 3rem 0; } }
  @media (min-width: 1024px) {
    .header-intro .header-detail-width {
      max-width: 780px;
      margin: auto; } }
  .header-intro.header-image:before {
    display: none; }
  .header-intro.header-image:after {
    opacity: 0.9; }

.text-container {
  padding-top: 8rem; }
  @media (min-width: 1024px) {
    .text-container {
      max-width: 780px;
      margin: auto; } }
  .text-container .perex {
    line-height: 5rem; }
    @media (min-width: 1024px) {
      .text-container .perex {
        line-height: 3rem; } }
  .text-container ul {
    margin: 3rem 0; }
  .text-container a {
    font-weight: 600; }

.dynamic-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;
  justify-content: center; }
  .dynamic-container .dynamic-box {
    padding: 0 2rem 4rem 2rem;
    width: 100%; }
    @media (min-width: 460px) {
      .dynamic-container .dynamic-box {
        width: 50%; } }
    @media (min-width: 1024px) {
      .dynamic-container .dynamic-box {
        width: 33.33%; } }
    @media (min-width: 460px) {
      .dynamic-container .dynamic-box.box-single {
        width: 100%; } }
    @media (min-width: 1024px) {
      .dynamic-container .dynamic-box.box-half {
        width: 50%; } }
  .dynamic-container.small-crosslink {
    display: block;
    margin: 0; }

.w-200 {
  width: 40rem; }
  @media (min-width: 320px) {
    .w-200 {
      width: 36.36364rem; } }
  @media (min-width: 460px) {
    .w-200 {
      width: 33.33333rem; } }
  @media (min-width: 768px) {
    .w-200 {
      width: 26.66667rem; } }
  @media (min-width: 1024px) {
    .w-200 {
      width: 25rem; } }
  @media (min-width: 1200px) {
    .w-200 {
      width: 22.22222rem; } }
  @media (min-width: 1420px) {
    .w-200 {
      width: 20rem; } }

.mw-200 {
  min-width: 40rem; }
  @media (min-width: 320px) {
    .mw-200 {
      min-width: 36.36364rem; } }
  @media (min-width: 460px) {
    .mw-200 {
      min-width: 33.33333rem; } }
  @media (min-width: 768px) {
    .mw-200 {
      min-width: 26.66667rem; } }
  @media (min-width: 1024px) {
    .mw-200 {
      min-width: 25rem; } }
  @media (min-width: 1200px) {
    .mw-200 {
      min-width: 22.22222rem; } }
  @media (min-width: 1420px) {
    .mw-200 {
      min-width: 20rem; } }

.h-200 {
  height: 40rem; }
  @media (min-width: 320px) {
    .h-200 {
      height: 36.36364rem; } }
  @media (min-width: 460px) {
    .h-200 {
      height: 33.33333rem; } }
  @media (min-width: 768px) {
    .h-200 {
      height: 26.66667rem; } }
  @media (min-width: 1024px) {
    .h-200 {
      height: 25rem; } }
  @media (min-width: 1200px) {
    .h-200 {
      height: 22.22222rem; } }
  @media (min-width: 1420px) {
    .h-200 {
      height: 20rem; } }

.widget-container {
  padding: 60px 0;
  position: relative; }
  @media (min-width: 1024px) {
    .widget-container {
      padding: 8rem 0; } }
  @media (min-width: 1024px) {
    .widget-container.with-ico {
      padding: 10rem 0; } }
  @media (min-width: 1024px) {
    .widget-container.with-ico.with-title {
      padding: 6rem 0 10rem 0; } }
  .widget-container.bgc-white {
    padding: 1px 0;
    margin: 60px 0; }
    @media (min-width: 1024px) {
      .widget-container.bgc-white {
        margin: 8rem 0; } }
    .widget-container.bgc-white.with-ico {
      padding-top: 50px;
      margin: 0 0 60px 0; }
      @media (min-width: 1024px) {
        .widget-container.bgc-white.with-ico {
          margin: 0 0 10rem 0;
          padding: 10rem 0 1px 0; } }
      @media (min-width: 1024px) {
        .widget-container.bgc-white.with-ico.with-title {
          padding: 6rem 0 1px 0;
          margin: 0 0 10rem 0; } }
  .widget-container .widget-container {
    margin: 0 !important;
    padding: 0 !important; }
  .widget-container.bgc-image {
    padding: 60px 0;
    margin: 0;
    background-size: cover !important;
    background-repeat: no-repeat !important; }
    @media (min-width: 1024px) {
      .widget-container.bgc-image {
        padding: 8rem 0; } }
    @media (min-width: 1024px) {
      .widget-container.bgc-image.with-ico {
        padding: 10rem 0; } }
    @media (min-width: 1024px) {
      .widget-container.bgc-image.with-ico.with-title {
        padding: 6rem 0 10rem 0; } }
    .widget-container.bgc-image:after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 22; }
    .widget-container.bgc-image .pux-container {
      position: relative;
      z-index: 33; }

.widget-container .numbers-container,
.widget-container .gallery-container,
.widget-container .banner-container,
.widget-container .icons-container,
.widget-container .banner-container,
.widget-container .inportant-container,
.widget-container .tenders-container,
.widget-container .card-container,
.widget-container .person-container {
  margin-bottom: -4rem; }

.widget-container .event-page {
  margin-bottom: -6rem; }

.widget-container.small-helper .icons-container {
  margin-top: -2rem;
  margin-bottom: 0; }

.widget-title {
  text-align: center;
  margin: -2.5rem 0 5rem 0; }

.widget-pattern {
  position: relative; }
  .widget-pattern:before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 22;
    background: url("../img/pattern.png"); }

body.contrast .header-intro:before,
body.contrast .widget-pattern:before {
  display: none; }

.widget-container-inner {
  position: relative;
  z-index: 44; }

.insert-image a {
  display: inline-block; }
  .insert-image a:before {
    display: none !important; }

.filter-container {
  padding: 3rem; }
  .filter-container-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem; }
  .filter-container .filter-box {
    padding: 0 1.5rem 2rem 1.5rem; }

.circle-btn {
  width: 80px;
  height: 80px;
  border-radius: 60px 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -40px;
  left: 50%;
  margin-left: -40px;
  z-index: 33; }
  @media (min-width: 1024px) {
    .circle-btn {
      top: -55px;
      margin-left: -55px;
      width: 110px;
      height: 110px; } }
  .circle-btn span:before {
    font-size: 43px; }
    @media (min-width: 1024px) {
      .circle-btn span:before {
        font-size: 53px; } }

.contact-item {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1024px) {
    .contact-item {
      min-height: 70rem; } }
  .contact-item-helper {
    padding: 0 0 0 28px; }
  .contact-item-left {
    width: 100%;
    padding: 6rem; }
    @media (min-width: 1024px) {
      .contact-item-left {
        width: 40%; } }
    @media (min-width: 1200px) {
      .contact-item-left {
        width: 30%; } }
  .contact-item-right {
    width: 100%;
    position: relative; }
    @media (min-width: 1024px) {
      .contact-item-right {
        width: 60%; } }
    @media (min-width: 1200px) {
      .contact-item-right {
        width: 70%; } }
    .contact-item-right iframe {
      width: 100%;
      height: 50rem; }
      @media (min-width: 1024px) {
        .contact-item-right iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; } }
  .contact-item .contact-company {
    padding: 3rem 0 0 0; }
  .contact-item .btn {
    margin: 4rem 0 0 0; }
  .contact-item .contact-ic {
    display: flex;
    align-items: baseline; }
    .contact-item .contact-ic:before {
      font-weight: 400;
      margin: 0 10px 0 0; }
    .contact-item .contact-ic.ic-home {
      font-weight: bold; }

.ic-home:before {
  content: "\e918"; }

.ic-address:before {
  content: "\e9a3"; }

.ic-phone {
  align-items: center; }
  .ic-phone:before {
    content: "\e99c"; }

.ic-email {
  align-items: center; }
  .ic-email:before {
    content: "\e99a"; }

.line-helper {
  width: 6%;
  position: relative;
  align-self: center;
  height: 75px; }
  .line-helper:before {
    content: "";
    height: 75px;
    border-left-width: 2px;
    border-left-style: dotted;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -1px; }
  .line-helper:last-child {
    display: none !important; }

.file-item {
  margin: 0 0 5px 0; }

.text-video-content {
  display: flex;
  margin: 4rem -2rem 0 -2rem;
  flex-wrap: wrap; }
  .text-video-content-text {
    padding: 0 2rem 4rem 2rem;
    width: 100%;
    order: 2; }
    @media (min-width: 768px) {
      .text-video-content-text {
        width: 66.66%;
        order: 1; } }
  .text-video-content-video {
    width: 100%;
    padding: 0 2rem 4rem 2rem;
    order: 1; }
    @media (min-width: 768px) {
      .text-video-content-video {
        width: 33.33%;
        order: 2; } }
    .text-video-content-video .embed-video {
      height: 30rem; }

.dynamic-container.small-crosslink {
  margin: 0 -2px; }

.dynamic-container.small-crosslink .dynamic-box {
  padding: 0 2px 4px 2px;
  flex-grow: 1; }

.crosslink-helper {
  display: flex;
  flex-wrap: wrap; }

.dynamic-container .dynamic-box.crosslink-box {
  display: flex;
  width: 100%; }
  @media (min-width: 600px) {
    .dynamic-container .dynamic-box.crosslink-box {
      width: 50%; } }
  @media (min-width: 1024px) {
    .dynamic-container .dynamic-box.crosslink-box {
      width: 33.33%; } }
  @media (min-width: 1024px) {
    .dynamic-container .dynamic-box.crosslink-box.box-half {
      width: 50%; } }
  @media (min-width: 460px) {
    .dynamic-container .dynamic-box.crosslink-box.box-single {
      width: 100%; } }

.crosslink-item {
  background: #006633;
  display: flex;
  align-items: center;
  padding: 4rem;
  width: 100%; }
  .crosslink-item .crosslink-item-inner {
    display: flex;
    width: 100%;
    align-items: center; }
  .crosslink-item .crosslink-item-image {
    width: 50px;
    min-width: 50px;
    margin-right: 15px;
    display: flex; }
  .crosslink-item .crosslink-ico:before {
    font-size: 45px; }
  .crosslink-item .crosslink-item-inner-content-perex {
    font-weight: 400; }
  .crosslink-item .h3 {
    margin: 0 0 5px 0; }
  .crosslink-item .link {
    font-weight: 400; }
  .crosslink-item:focus .link-arrow:after, .crosslink-item:hover .link-arrow:after {
    right: -5px; }

.crosslink-helper.items-1 .crosslink-item {
  border-radius: 20px; }

.crosslink-helper.items-2 .crosslink-box:nth-child(1) .crosslink-item {
  border-radius: 20px 0 0 20px; }

.crosslink-helper.items-2 .crosslink-box:nth-child(2) .crosslink-item {
  border-radius: 0 20px 20px 0; }

.crosslink-helper.items-3 .crosslink-box:nth-child(1) .crosslink-item {
  border-radius: 20px 0 0 20px; }

@media (min-width: 100px) and (max-width: 599px) {
  .crosslink-helper.items-3 .crosslink-box:nth-child(1) .crosslink-item {
    border-radius: 20px 20px 0 0; }
  .crosslink-helper.items-3 .crosslink-box:nth-child(3) .crosslink-item {
    border-radius: 0 0 20px 20px; } }

@media (min-width: 600px) and (max-width: 1023px) {
  .crosslink-helper.items-3 .crosslink-box:nth-child(1) .crosslink-item {
    border-radius: 20px 0 0 0; }
  .crosslink-helper.items-3 .crosslink-box:nth-child(2) .crosslink-item {
    border-radius: 0 20px 0 0; }
  .crosslink-helper.items-3 .crosslink-box:nth-child(3) .crosslink-item {
    border-radius: 0 0 20px 20px; } }

@media (min-width: 1024px) {
  .crosslink-helper.items-3 .crosslink-box:nth-child(1) .crosslink-item {
    border-radius: 20px 0 0 20px; }
  .crosslink-helper.items-3 .crosslink-box:nth-child(3) .crosslink-item {
    border-radius: 0 20px 20px 0; } }

.crosslink-helper.items-4 .crosslink-box:nth-child(1) .crosslink-item {
  border-radius: 20px 0 0 0; }

@media (min-width: 100px) and (max-width: 599px) {
  .crosslink-helper.items-4 .crosslink-box:nth-child(1) .crosslink-item {
    border-radius: 20px 20px 0 0; }
  .crosslink-helper.items-4 .crosslink-box:nth-child(4) .crosslink-item {
    border-radius: 0 0 20px 20px; } }

@media (min-width: 600px) and (max-width: 1023px) {
  .crosslink-helper.items-4 .crosslink-box:nth-child(2) .crosslink-item {
    border-radius: 0 20px 0 0; }
  .crosslink-helper.items-4 .crosslink-box:nth-child(3) .crosslink-item {
    border-radius: 0 0 0 20px; }
  .crosslink-helper.items-4 .crosslink-box:nth-child(4) .crosslink-item {
    border-radius: 0 0 20px 0; } }

@media (min-width: 1024px) {
  .crosslink-helper.items-4 .crosslink-box:nth-child(3) .crosslink-item {
    border-radius: 0 20px 0 0; }
  .crosslink-helper.items-4 .crosslink-box:nth-child(4) .crosslink-item {
    border-radius: 0 0 20px 20px; } }

.crosslink-helper.items-5 .crosslink-box:nth-child(1) .crosslink-item {
  border-radius: 20px 0 0 0; }

.crosslink-helper.items-5 .crosslink-box:nth-child(5) .crosslink-item {
  border-radius: 0 0 20px 0; }

@media (min-width: 100px) and (max-width: 599px) {
  .crosslink-helper.items-5 .crosslink-box:nth-child(1) .crosslink-item {
    border-radius: 20px 20px 0 0; }
  .crosslink-helper.items-5 .crosslink-box:nth-child(5) .crosslink-item {
    border-radius: 0 0 20px 20px; } }

@media (min-width: 600px) and (max-width: 1023px) {
  .crosslink-helper.items-5 .crosslink-box:nth-child(2) .crosslink-item {
    border-radius: 0 20px 0 0; }
  .crosslink-helper.items-5 .crosslink-box:nth-child(5) .crosslink-item {
    border-radius: 0 0 20px 20px; } }

@media (min-width: 1024px) {
  .crosslink-helper.items-5 .crosslink-box:nth-child(4) .crosslink-item {
    border-radius: 0 0 0 20px; }
  .crosslink-helper.items-5 .crosslink-box:nth-child(3) .crosslink-item {
    border-radius: 0 20px 0 0; } }

.crosslink-helper.items-6 .crosslink-box:nth-child(1) .crosslink-item {
  border-radius: 20px 0 0 0; }

.crosslink-helper.items-6 .crosslink-box:nth-child(6) .crosslink-item {
  border-radius: 0 0 20px 0; }

@media (min-width: 100px) and (max-width: 599px) {
  .crosslink-helper.items-6 .crosslink-box:nth-child(1) .crosslink-item {
    border-radius: 20px 20px 0 0; }
  .crosslink-helper.items-6 .crosslink-box:nth-child(6) .crosslink-item {
    border-radius: 0 0 20px 20px; } }

@media (min-width: 600px) and (max-width: 1023px) {
  .crosslink-helper.items-6 .crosslink-box:nth-child(2) .crosslink-item {
    border-radius: 0 20px 0 0; }
  .crosslink-helper.items-6 .crosslink-box:nth-child(5) .crosslink-item {
    border-radius: 0 0 0 20px; } }

@media (min-width: 1024px) {
  .crosslink-helper.items-6 .crosslink-box:nth-child(3) .crosslink-item {
    border-radius: 0 20px 0 0; }
  .crosslink-helper.items-6 .crosslink-box:nth-child(4) .crosslink-item {
    border-radius: 0 0 0 20px; } }

.gm-ui-hover-effect {
  opacity: 1;
  top: 0 !important;
  right: 0 !important; }
  .gm-ui-hover-effect span {
    width: 30px !important;
    height: 30px !important;
    margin: 0 !important; }

.marker-detail-image-cta {
  margin-top: 15px;
  display: none; }
  @media (min-width: 768px) {
    .marker-detail-image-cta {
      display: block; } }

.iframe-item {
  width: 100%; }
  .iframe-item iframe {
    width: 100%;
    border: none;
    display: none; }
    @media (min-width: 768px) {
      .iframe-item iframe {
        display: block; } }
  .iframe-item .iframe-link {
    text-align: center;
    padding: 2rem 0; }
    @media (min-width: 768px) {
      .iframe-item .iframe-link {
        display: none; } }

.product-gallery {
  margin-bottom: 40px;
  margin-left: -10px;
  margin-right: -10px; }
  .product-gallery .col-xs-12 {
    padding-bottom: 20px; }
  .product-gallery [class^="col-"] {
    padding-left: 10px;
    padding-right: 10px; }
  .product-gallery .detail-photo-item-text {
    text-align: center; }

.main-slider h2,
h1,
.h1 {
  font-size: 28px;
  font-weight: 700;
  margin: 1rem 0 3rem 0; }
  @media (min-width: 460px) {
    .main-slider h2,
    h1,
    .h1 {
      font-size: 30px; } }
  @media (min-width: 768px) {
    .main-slider h2,
    h1,
    .h1 {
      font-size: 38px; } }
  @media (min-width: 1200px) {
    .main-slider h2,
    h1,
    .h1 {
      font-size: 5.33333rem; } }
  @media (min-width: 1420px) {
    .main-slider h2,
    h1,
    .h1 {
      font-size: 4.8rem; } }

h2,
.h2 {
  font-size: 28px;
  font-weight: 700;
  margin: 4rem 0; }
  @media (min-width: 460px) {
    h2,
    .h2 {
      font-size: 30px; } }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 32px; } }

@media (min-width: 1200px) and (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 4rem; } }

@media (min-width: 1200px) and (min-width: 1420px) {
  h2,
  .h2 {
    font-size: 3.6rem; } }

h3,
.h3 {
  font-size: 20px;
  font-weight: 700;
  margin: 2rem 0; }
  @media (min-width: 460px) {
    h3,
    .h3 {
      font-size: 21px; } }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 23px; } }
  @media (min-width: 1200px) {
    h3,
    .h3 {
      font-size: 2.66667rem; } }
  @media (min-width: 1420px) {
    h3,
    .h3 {
      font-size: 2.4rem; } }

.widget-container .widget-title {
  margin: 0 0 5rem 0; }

@media (min-width: 300px) {
  h4,
  .h4 {
    font-size: 3.6rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    font-size: 3.27273rem; } }

@media (min-width: 460px) {
  h4,
  .h4 {
    font-size: 3rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    font-size: 2.5rem; } }

@media (min-width: 300px) {
  h4,
  .h4 {
    line-height: 3.6rem; } }

@media (min-width: 320px) {
  h4,
  .h4 {
    line-height: 3.27273rem; } }

@media (min-width: 460px) {
  h4,
  .h4 {
    line-height: 3rem; } }

@media (min-width: 768px) {
  h4,
  .h4 {
    line-height: 2.5rem; } }

@media (min-width: 300px) {
  big,
  .perex {
    font-size: 3.6rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    font-size: 3.27273rem; } }

@media (min-width: 460px) {
  big,
  .perex {
    font-size: 3rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    font-size: 2.4rem; } }

@media (min-width: 1024px) {
  big,
  .perex {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    font-size: 2.2rem; } }

@media (min-width: 300px) {
  big,
  .perex {
    line-height: 4.4rem; } }

@media (min-width: 320px) {
  big,
  .perex {
    line-height: 4rem; } }

@media (min-width: 460px) {
  big,
  .perex {
    line-height: 3.66667rem; } }

@media (min-width: 768px) {
  big,
  .perex {
    line-height: 2.93333rem; } }

@media (min-width: 1024px) {
  big,
  .perex {
    line-height: 2.75rem; } }

@media (min-width: 1200px) {
  big,
  .perex {
    line-height: 2.6rem; } }

@media (min-width: 300px) {
  small,
  .text-small {
    font-size: 3.2rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    font-size: 2.90909rem; } }

@media (min-width: 460px) {
  small,
  .text-small {
    font-size: 2.66667rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    font-size: 2.13333rem; } }

@media (min-width: 1024px) {
  small,
  .text-small {
    font-size: 2rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    font-size: 1.77778rem; } }

@media (min-width: 1420px) {
  small,
  .text-small {
    font-size: 1.6rem; } }

@media (min-width: 300px) {
  small,
  .text-small {
    line-height: 4rem; } }

@media (min-width: 320px) {
  small,
  .text-small {
    line-height: 3.63636rem; } }

@media (min-width: 460px) {
  small,
  .text-small {
    line-height: 3.33333rem; } }

@media (min-width: 768px) {
  small,
  .text-small {
    line-height: 2.66667rem; } }

@media (min-width: 1024px) {
  small,
  .text-small {
    line-height: 2.5rem; } }

@media (min-width: 1200px) {
  small,
  .text-small {
    line-height: 2.22222rem; } }

@media (min-width: 1420px) {
  small,
  .text-small {
    line-height: 2rem; } }

@media (min-width: 300px) {
  .biger-text {
    font-size: 3.6rem; } }

@media (min-width: 320px) {
  .biger-text {
    font-size: 3.27273rem; } }

@media (min-width: 460px) {
  .biger-text {
    font-size: 3rem; } }

@media (min-width: 768px) {
  .biger-text {
    font-size: 2.4rem; } }

@media (min-width: 1024px) {
  .biger-text {
    font-size: 2.25rem; } }

@media (min-width: 1200px) {
  .biger-text {
    font-size: 2.2rem; } }

@media (min-width: 300px) {
  .biger-text {
    line-height: 5.2rem; } }

@media (min-width: 320px) {
  .biger-text {
    line-height: 4.72727rem; } }

@media (min-width: 460px) {
  .biger-text {
    line-height: 4.33333rem; } }

@media (min-width: 768px) {
  .biger-text {
    line-height: 3.46667rem; } }

@media (min-width: 1024px) {
  .biger-text {
    line-height: 3.25rem; } }

@media (min-width: 1200px) {
  .biger-text {
    line-height: 3rem; } }

@media (min-width: 300px) {
  .biger-text .perex {
    font-size: 4rem; } }

@media (min-width: 320px) {
  .biger-text .perex {
    font-size: 3.63636rem; } }

@media (min-width: 460px) {
  .biger-text .perex {
    font-size: 3.33333rem; } }

@media (min-width: 768px) {
  .biger-text .perex {
    font-size: 2.7rem; } }

@media (min-width: 300px) {
  .biger-text .perex {
    line-height: 5.6rem; } }

@media (min-width: 320px) {
  .biger-text .perex {
    line-height: 5.09091rem; } }

@media (min-width: 460px) {
  .biger-text .perex {
    line-height: 4.66667rem; } }

@media (min-width: 768px) {
  .biger-text .perex {
    line-height: 3.73333rem; } }

@media (min-width: 1024px) {
  .biger-text .perex {
    line-height: 3.5rem; } }

@media (min-width: 300px) {
  #big .biger-text {
    font-size: 4rem; } }

@media (min-width: 320px) {
  #big .biger-text {
    font-size: 3.63636rem; } }

@media (min-width: 460px) {
  #big .biger-text {
    font-size: 3.33333rem; } }

@media (min-width: 768px) {
  #big .biger-text {
    font-size: 2.66667rem; } }

@media (min-width: 1024px) {
  #big .biger-text {
    font-size: 2.5rem; } }

@media (min-width: 1200px) {
  #big .biger-text {
    font-size: 2.4rem; } }

@media (min-width: 300px) {
  #big .biger-text {
    line-height: 5.6rem; } }

@media (min-width: 320px) {
  #big .biger-text {
    line-height: 5.09091rem; } }

@media (min-width: 460px) {
  #big .biger-text {
    line-height: 4.66667rem; } }

@media (min-width: 768px) {
  #big .biger-text {
    line-height: 3.73333rem; } }

@media (min-width: 1024px) {
  #big .biger-text {
    line-height: 3.5rem; } }

@media (min-width: 1200px) {
  #big .biger-text {
    line-height: 3.2rem; } }

@media (min-width: 300px) {
  #big .biger-text .perex {
    font-size: 4.4rem; } }

@media (min-width: 320px) {
  #big .biger-text .perex {
    font-size: 4rem; } }

@media (min-width: 460px) {
  #big .biger-text .perex {
    font-size: 3.66667rem; } }

@media (min-width: 768px) {
  #big .biger-text .perex {
    font-size: 2.93333rem; } }

@media (min-width: 1024px) {
  #big .biger-text .perex {
    font-size: 2.9rem; } }

@media (min-width: 300px) {
  #big .biger-text .perex {
    line-height: 6rem; } }

@media (min-width: 320px) {
  #big .biger-text .perex {
    line-height: 5.45455rem; } }

@media (min-width: 460px) {
  #big .biger-text .perex {
    line-height: 5rem; } }

@media (min-width: 768px) {
  #big .biger-text .perex {
    line-height: 4rem; } }

@media (min-width: 1024px) {
  #big .biger-text .perex {
    line-height: 3.75rem; } }

@media (min-width: 1200px) {
  #big .biger-text .perex {
    line-height: 3.7rem; } }

@media (min-width: 768px) {
  .ul-columns ul {
    columns: 2; } }

.ul-columns ul li {
  padding: 0;
  display: flex;
  align-items: baseline; }
  @media (min-width: 300px) {
    .ul-columns ul li {
      font-size: 3.6rem; } }
  @media (min-width: 320px) {
    .ul-columns ul li {
      font-size: 3.27273rem; } }
  @media (min-width: 460px) {
    .ul-columns ul li {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .ul-columns ul li {
      font-size: 2.4rem; } }
  @media (min-width: 1024px) {
    .ul-columns ul li {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .ul-columns ul li {
      font-size: 2.2rem; } }
  .ul-columns ul li:before {
    content: "\e9a6";
    background: none !important;
    width: auto;
    height: auto;
    position: static;
    position: relative;
    top: 3px;
    margin-right: 20px; }
    @media (min-width: 300px) {
      .ul-columns ul li:before {
        font-size: 4.4rem; } }
    @media (min-width: 320px) {
      .ul-columns ul li:before {
        font-size: 4rem; } }
    @media (min-width: 460px) {
      .ul-columns ul li:before {
        font-size: 3.66667rem; } }
    @media (min-width: 768px) {
      .ul-columns ul li:before {
        font-size: 2.93333rem; } }
    @media (min-width: 1024px) {
      .ul-columns ul li:before {
        font-size: 2.75rem; } }
    @media (min-width: 1200px) {
      .ul-columns ul li:before {
        font-size: 2.44444rem; } }
    @media (min-width: 1420px) {
      .ul-columns ul li:before {
        font-size: 2.4rem; } }

.bgc-main .text-widget h2 {
  margin-top: 0; }

a {
  cursor: pointer; }
  a:focus, a:hover {
    text-decoration: none; }

main a {
  font-weight: 700; }

body.contrast main a {
  text-decoration: underline; }
  body.contrast main a:focus, body.contrast main a:hover {
    text-decoration: none; }

.link {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-decoration: none !important; }
  .link:before {
    font-size: 4rem;
    margin: 0 10px 0 0; }
    @media (min-width: 320px) {
      .link:before {
        font-size: 3.63636rem; } }
    @media (min-width: 460px) {
      .link:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .link:before {
        font-size: 2.66667rem; } }
    @media (min-width: 1024px) {
      .link:before {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .link:before {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      .link:before {
        font-size: 2rem; } }
  .link:hover, .link:focus {
    text-decoration: none; }
  .link.no-text {
    font-size: 0; }
    .link.no-text:before {
      margin: 0; }

.link-arrow:after {
  font-size: 1.2rem;
  content: "\e902";
  padding: 0 4px;
  transform: rotate(270deg);
  transition: all 0.3s ease-out;
  position: relative;
  right: 0; }
  @media (min-width: 320px) {
    .link-arrow:after {
      font-size: 1.09091rem; } }
  @media (min-width: 460px) {
    .link-arrow:after {
      font-size: 1rem; } }
  @media (min-width: 768px) {
    .link-arrow:after {
      font-size: 0.8rem; } }
  @media (min-width: 1024px) {
    .link-arrow:after {
      font-size: 0.75rem; } }
  @media (min-width: 1200px) {
    .link-arrow:after {
      font-size: 0.66667rem; } }
  @media (min-width: 1420px) {
    .link-arrow:after {
      font-size: 0.6rem; } }

.link-arrow:focus:after, .link-arrow:hover:after {
  right: -5px; }

.link-cross:before {
  font-size: 3.6rem;
  content: "\e9a1"; }
  @media (min-width: 320px) {
    .link-cross:before {
      font-size: 3.27273rem; } }
  @media (min-width: 460px) {
    .link-cross:before {
      font-size: 3rem; } }
  @media (min-width: 768px) {
    .link-cross:before {
      font-size: 2.4rem; } }
  @media (min-width: 1024px) {
    .link-cross:before {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .link-cross:before {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    .link-cross:before {
      font-size: 1.8rem; } }

.link span[class^="icon-"] {
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  margin-right: 10px;
  text-decoration: none !important; }
  @media (min-width: 300px) {
    .link span[class^="icon-"] {
      font-size: 4rem; } }
  @media (min-width: 320px) {
    .link span[class^="icon-"] {
      font-size: 3.63636rem; } }
  @media (min-width: 460px) {
    .link span[class^="icon-"] {
      font-size: 3.33333rem; } }
  @media (min-width: 768px) {
    .link span[class^="icon-"] {
      font-size: 3rem; } }

.blank {
  display: inline-flex !important;
  -webkit-flex-wrap: nowrap;
  -moz-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: flex-end;
  -moz-box-align: flex-end;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end; }
  .blank:before {
    margin-right: 10px;
    display: inline-block !important;
    text-decoration: none !important; }
    @media (min-width: 300px) {
      .blank:before {
        font-size: 3.2rem; } }
    @media (min-width: 320px) {
      .blank:before {
        font-size: 2.90909rem; } }
    @media (min-width: 460px) {
      .blank:before {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .blank:before {
        font-size: 2.13333rem; } }
    @media (min-width: 1024px) {
      .blank:before {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .blank:before {
        font-size: 1.8rem; } }
  .blank span + span {
    display: none; }

main .blank:before {
  content: "\e92c"; }

.no-ico {
  display: block; }
  .no-ico span {
    display: none !important; }

.no-download {
  display: block; }
  .no-download span[class^="icon-"] {
    display: none; }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  padding: 1.1rem 3.5rem;
  box-shadow: none;
  font-weight: 700;
  letter-spacing: 0.09px;
  min-height: 45px;
  transition: all 0.3s ease-out;
  text-decoration: none !important;
  font-size: 18px; }
  .btn:before {
    font-size: 4rem;
    margin: 0 10px 0 0; }
    @media (min-width: 320px) {
      .btn:before {
        font-size: 3.63636rem; } }
    @media (min-width: 460px) {
      .btn:before {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .btn:before {
        font-size: 2.66667rem; } }
    @media (min-width: 1024px) {
      .btn:before {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .btn:before {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      .btn:before {
        font-size: 2rem; } }
  .btn:focus, .btn:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .btn:focus {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px; }
  @media (min-width: 1024px) {
    .btn {
      font-size: 2.25rem; } }
  @media (min-width: 1200px) {
    .btn {
      font-size: 2rem; } }
  @media (min-width: 1420px) {
    .btn {
      font-size: 1.8rem; } }

main table {
  width: 100%;
  border: none !important; }
  main table tr th,
  main table tr td {
    padding: 1rem 1.5rem; }

main .respo-table table {
  width: 100%; }
  main .respo-table table thead {
    display: none; }
    @media (min-width: 768px) {
      main .respo-table table thead {
        display: table-header-group; } }
    main .respo-table table thead tr th {
      font-weight: bold;
      padding: 1rem 1.5rem; }
  main .respo-table table tbody tr {
    display: block;
    border-bottom: none;
    margin: 0 0 2rem 0; }
    @media (min-width: 768px) {
      main .respo-table table tbody tr {
        display: table-row;
        margin: 0;
        border: none; } }
    main .respo-table table tbody tr td {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      @media (min-width: 768px) {
        main .respo-table table tbody tr td {
          display: table-cell;
          border: none;
          width: auto; } }
      main .respo-table table tbody tr td:before {
        content: attr(data-th);
        display: block;
        text-align: left;
        font-family: "proxima-nova", sans-serif !important;
        margin: 0 1.5rem 0 0; }
        @media (min-width: 768px) {
          main .respo-table table tbody tr td:before {
            display: none; } }

main .job-table {
  border: none; }
  main .job-table td {
    border: none;
    display: block !important;
    vertical-align: top; }
    @media (min-width: 460px) {
      main .job-table td {
        display: table-cell !important; } }
    main .job-table td:first-child {
      font-weight: 700; }

main ol {
  margin: 0;
  padding: 0;
  list-style: none;
  counter-reset: my-ol; }
  main ol li {
    position: relative;
    margin: 0 0 1.5rem 0;
    padding: 0 0 0 25px; }
    main ol li:before {
      content: counter(my-ol) ".";
      counter-increment: my-ol;
      position: absolute;
      left: 0;
      top: 4px;
      font-weight: bold;
      font-family: "proxima-nova", sans-serif !important; }
    main ol li ul {
      margin: 1.5rem 0 0 0; }
  main ol[start="next"] {
    counter-reset: continue; }
    main ol[start="next"] li:before {
      counter-increment: continue; }
  main ol[start="1"] {
    counter-reset: my-ol 1; }
  main ol[start="2"] {
    counter-reset: my-ol 2; }
  main ol[start="3"] {
    counter-reset: my-ol 3; }
  main ol[start="4"] {
    counter-reset: my-ol 4; }
  main ol[start="5"] {
    counter-reset: my-ol 5; }
  main ol[start="6"] {
    counter-reset: my-ol 6; }
  main ol[start="7"] {
    counter-reset: my-ol 7; }
  main ol[start="8"] {
    counter-reset: my-ol 8; }
  main ol[start="9"] {
    counter-reset: my-ol 9; }
  main ol[start="10"] {
    counter-reset: my-ol 10; }
  main ol[start="11"] {
    counter-reset: my-ol 11; }
  main ol[start="12"] {
    counter-reset: my-ol 12; }
  main ol[start="13"] {
    counter-reset: my-ol 13; }
  main ol[start="14"] {
    counter-reset: my-ol 14; }
  main ol[start="15"] {
    counter-reset: my-ol 15; }
  main ol[start="16"] {
    counter-reset: my-ol 16; }
  main ol[start="17"] {
    counter-reset: my-ol 17; }
  main ol[start="18"] {
    counter-reset: my-ol 18; }
  main ol[start="19"] {
    counter-reset: my-ol 19; }
  main ol[start="20"] {
    counter-reset: my-ol 20; }

main ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  main ul li {
    margin: 0 0 1.5rem 0;
    padding: 0 0 0 25px;
    position: relative; }
    main ul li:before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      top: 10px;
      left: 0; }
    main ul li ul {
      margin: 1.5rem 0 0 0; }
  main ul.custom-list li:before {
    display: none; }
  main ul.custom-list li:after {
    content: "";
    width: 16px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 8px; }

@media (min-width: 1024px) {
  .page-map {
    display: flex;
    flex-wrap: wrap; } }

.page-map-column > ul li {
  padding: 0.25rem 0 0.25rem 2rem;
  margin: 0; }
  .page-map-column > ul li:before {
    display: none; }

.page-map-column > ul > li {
  width: 100%;
  margin-bottom: 2rem; }
  @media (min-width: 460px) {
    .page-map-column > ul > li {
      width: 50%; } }
  @media (min-width: 768px) {
    .page-map-column > ul > li {
      width: 33.333%; } }
  @media (min-width: 1420px) {
    .page-map-column > ul > li {
      width: auto; } }

.page-map-column > ul > li > a,
.page-map-column > ul > li > span {
  font-weight: 700; }
  @media (min-width: 1200px) {
    .page-map-column > ul > li > a,
    .page-map-column > ul > li > span {
      font-size: 4rem; } }
  @media (min-width: 1200px) and (min-width: 320px) {
    .page-map-column > ul > li > a,
    .page-map-column > ul > li > span {
      font-size: 3.63636rem; } }
  @media (min-width: 1200px) and (min-width: 460px) {
    .page-map-column > ul > li > a,
    .page-map-column > ul > li > span {
      font-size: 3.33333rem; } }
  @media (min-width: 1200px) and (min-width: 768px) {
    .page-map-column > ul > li > a,
    .page-map-column > ul > li > span {
      font-size: 2.66667rem; } }
  @media (min-width: 1200px) and (min-width: 1024px) {
    .page-map-column > ul > li > a,
    .page-map-column > ul > li > span {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    .page-map-column > ul > li > a,
    .page-map-column > ul > li > span {
      font-size: 2.22222rem; } }
  @media (min-width: 1200px) and (min-width: 1420px) {
    .page-map-column > ul > li > a,
    .page-map-column > ul > li > span {
      font-size: 2rem; } }

.page-map-column > ul ul {
  margin: 0; }

.page-map-column:first-child {
  flex-shrink: 1; }
  @media (min-width: 1024px) {
    .page-map-column:first-child > ul {
      display: flex;
      flex-wrap: nowrap; } }
  .page-map-column:first-child > ul > li {
    padding: 0 5rem; }

.page-map-column:last-child > ul li {
  padding: 0 5rem 1.5rem 5rem;
  margin-bottom: 0;
  width: 100%; }

.page-map-column .menu-home a {
  position: relative;
  padding-left: 20px; }
  .page-map-column .menu-home a:before {
    content: "\e919";
    font-size: 13px;
    display: inline;
    vertical-align: middle;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 0;
    top: 50%; }

.banner-item {
  font-weight: 400; }
  .banner-item-inner {
    display: flex;
    flex-direction: row;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative; }
    .banner-item-inner:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.3s ease-out; }
    .banner-item-inner:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-out; }
    .banner-item-inner-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 3rem;
      width: 100%;
      position: absolute;
      height: 100%;
      z-index: 33; }
  .banner-item-helper {
    width: 100%; }
  .banner-item:focus .banner-item-inner:before, .banner-item:hover .banner-item-inner:before {
    opacity: 0.8; }
  .banner-item:focus .banner-item-inner:after, .banner-item:hover .banner-item-inner:after {
    opacity: 0.75; }
  .banner-item .h2 {
    font-size: 21px; }
    @media (min-width: 460px) {
      .banner-item .h2 {
        font-size: 25px; } }
    @media (min-width: 768px) {
      .banner-item .h2 {
        font-size: 28px; } }
    @media (min-width: 1200px) {
      .banner-item .h2 {
        font-size: 3.33333rem; } }
    @media (min-width: 1420px) {
      .banner-item .h2 {
        font-size: 3rem; } }

.banner-item.style-1,
.banner-item.style-2,
.banner-item.style-3,
.banner-item.style-4 {
  text-decoration: none;
  display: block;
  position: relative;
  overflow: hidden; }
  .banner-item.style-1 .banner-item-bgr,
  .banner-item.style-2 .banner-item-bgr,
  .banner-item.style-3 .banner-item-bgr,
  .banner-item.style-4 .banner-item-bgr {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-out; }
  .banner-item.style-1 .banner-item-inner,
  .banner-item.style-2 .banner-item-inner,
  .banner-item.style-3 .banner-item-inner,
  .banner-item.style-4 .banner-item-inner {
    min-height: 219px; }
    @media (min-width: 768px) {
      .banner-item.style-1 .banner-item-inner,
      .banner-item.style-2 .banner-item-inner,
      .banner-item.style-3 .banner-item-inner,
      .banner-item.style-4 .banner-item-inner {
        min-height: 30.4rem; } }
    @media (min-width: 1024px) {
      .banner-item.style-1 .banner-item-inner,
      .banner-item.style-2 .banner-item-inner,
      .banner-item.style-3 .banner-item-inner,
      .banner-item.style-4 .banner-item-inner {
        min-height: 25.4rem; } }

.banner-item.style-1 .banner-item-inner-content-title {
  padding: 1rem 3rem;
  margin: 0; }

.banner-item.style-1 .banner-item-inner-content-btn {
  padding: 1rem 3rem;
  letter-spacing: 0.28px;
  font-weight: 700;
  transition: all 0.3s ease-out; }
  .banner-item.style-1 .banner-item-inner-content-btn.btn-banner {
    text-transform: none;
    border-radius: 50px;
    margin: 1rem 0 0 0; }

.banner-item.style-2:hover .banner-item-inner-content-row-btn, .banner-item.style-2:focus .banner-item-inner-content-row-btn {
  -webkit-transform: translateX(0.5rem);
  -moz-transform: translateX(0.5rem);
  -ms-transform: translateX(0.5rem);
  -o-transform: translateX(0.5rem);
  transform: translateX(0.5rem); }

.banner-item.style-2 .banner-item-inner-content {
  align-items: flex-start;
  justify-content: flex-end; }
  .banner-item.style-2 .banner-item-inner-content-title {
    padding: 1rem 3rem;
    margin: 0;
    display: inline-block; }
  .banner-item.style-2 .banner-item-inner-content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 0 0 0;
    width: 100%;
    text-align: left; }
    .banner-item.style-2 .banner-item-inner-content-row-perex {
      padding: 2rem 2rem 0 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .banner-item.style-2 .banner-item-inner-content-row-btn {
      padding: 0 0 0 2rem;
      transition: all 0.3s ease-out; }
      .banner-item.style-2 .banner-item-inner-content-row-btn:after {
        content: "\e99f";
        display: block;
        font-size: 30px; }

.banner-item.style-2 .banner-item-inner:before {
  opacity: 1; }

.banner-item.style-3:hover .banner-item-inner-content-btn, .banner-item.style-3:focus .banner-item-inner-content-btn {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

.banner-item.style-3 .banner-item-inner-content {
  flex-direction: column;
  justify-content: space-between;
  padding: 0 3rem; }
  .banner-item.style-3 .banner-item-inner-content-title {
    padding: 1rem 3rem;
    margin: 0; }
  .banner-item.style-3 .banner-item-inner-content-btn {
    padding: 1.5rem 3rem 2.5rem;
    letter-spacing: 0.28px;
    font-weight: 700;
    -webkit-transform: translateY(1rem);
    -moz-transform: translateY(1rem);
    -ms-transform: translateY(1rem);
    -o-transform: translateY(1rem);
    transform: translateY(1rem);
    transition: all 0.3s ease-out; }

.banner-item.style-4 .banner-item-inner-content-title {
  padding: 1rem 3rem;
  margin: 0;
  -webkit-transform: translateY(-4rem);
  -moz-transform: translateY(-4rem);
  -ms-transform: translateY(-4rem);
  -o-transform: translateY(-4rem);
  transform: translateY(-4rem); }

.banner-item.style-4 .banner-item-inner-content-btn {
  padding: 1.5rem 3rem;
  letter-spacing: 0.28px;
  font-weight: 700;
  transition: all 0.3s ease-out;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

.banner-box.dynamic-box {
  width: 100%; }
  @media (min-width: 460px) {
    .banner-box.dynamic-box {
      width: 100%; } }
  @media (min-width: 768px) {
    .banner-box.dynamic-box {
      width: 50%; } }
  @media (min-width: 1024px) {
    .banner-box.dynamic-box {
      width: 33.33%; } }
  .banner-box.dynamic-box.box-single {
    max-width: 78rem; }

@media (min-width: 768px) {
  .banner-box.box-single .banner-item-inner {
    min-height: 30.4rem; } }

.banner-box.box-single .h2 {
  font-size: 23px; }
  @media (min-width: 460px) {
    .banner-box.box-single .h2 {
      font-size: 28px; } }
  @media (min-width: 768px) {
    .banner-box.box-single .h2 {
      font-size: 33px; } }
  @media (min-width: 1200px) {
    .banner-box.box-single .h2 {
      font-size: 4rem; } }
  @media (min-width: 1420px) {
    .banner-box.box-single .h2 {
      font-size: 3.6rem; } }

.banner-box.box-half {
  width: 100%; }
  @media (min-width: 768px) {
    .banner-box.box-half .banner-item-inner {
      min-height: 30.4rem; } }
  @media (min-width: 1024px) {
    .banner-box.box-half .banner-item-inner {
      min-height: 39.1rem; } }
  @media (min-width: 768px) {
    .banner-box.box-half {
      width: 50%; } }
  .banner-box.box-half .h2 {
    font-size: 23px; }
    @media (min-width: 460px) {
      .banner-box.box-half .h2 {
        font-size: 28px; } }
    @media (min-width: 768px) {
      .banner-box.box-half .h2 {
        font-size: 33px; } }
    @media (min-width: 1200px) {
      .banner-box.box-half .h2 {
        font-size: 4rem; } }
    @media (min-width: 1420px) {
      .banner-box.box-half .h2 {
        font-size: 3.6rem; } }

.card-box .card-item {
  font-weight: 400;
  width: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  min-height: 250px;
  padding: 2.5rem 3rem;
  overflow: hidden;
  transition: all 0.3s ease-out;
  text-decoration: none !important; }
  @media (min-width: 1024px) {
    .card-box .card-item {
      height: 33.25rem; } }
  @media (min-width: 1200px) {
    .card-box .card-item {
      height: 29.55556rem; } }
  @media (min-width: 1420px) {
    .card-box .card-item {
      height: 26.6rem; } }
  .card-box .card-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 22;
    transition: all 0.3s ease-out; }
  .card-box .card-item:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    z-index: 22;
    transition: all 0.3s ease-out; }
  .card-box .card-item h3 {
    margin: 0; }
  .card-box .card-item-bgc {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    transition: all 0.3s ease-out; }
  .card-box .card-item-content {
    position: relative;
    z-index: 33; }
    @media (min-width: 460px) {
      .card-box .card-item-content {
        display: flex;
        align-items: center; } }
    .card-box .card-item-content-ico:before {
      font-size: 40px; }
      @media (min-width: 460px) {
        .card-box .card-item-content-ico:before {
          font-size: 50px;
          margin: 0 2.2rem 0 0; } }
    .card-box .card-item-content-text {
      padding: 15px 0 0 0; }
      @media (min-width: 460px) {
        .card-box .card-item-content-text {
          padding: 0; } }
    .card-box .card-item-content-link {
      font-weight: 700; }
    .card-box .card-item-content h3 {
      margin: 0; }
  .card-box .card-item:focus, .card-box .card-item:hover {
    text-decoration: none; }
    .card-box .card-item:focus .link:after, .card-box .card-item:hover .link:after {
      right: -5px; }

.card-box.box-half {
  min-height: 160px;
  width: 100%; }
  @media (min-width: 460px) {
    .card-box.box-half {
      width: 100%;
      min-height: 230px; } }
  @media (min-width: 768px) {
    .card-box.box-half {
      width: 50%;
      min-height: 280px; } }
  @media (min-width: 1024px) {
    .card-box.box-half .card-item {
      height: 38.75rem; } }
  @media (min-width: 1200px) {
    .card-box.box-half .card-item {
      height: 34.44444rem; } }
  @media (min-width: 1420px) {
    .card-box.box-half .card-item {
      height: 31rem; } }

.card-box.box-single {
  max-width: 790px;
  margin: 0 auto;
  min-height: 160px; }
  @media (min-width: 460px) {
    .card-box.box-single {
      min-height: 230px; } }
  @media (min-width: 768px) {
    .card-box.box-single {
      min-height: 280px; } }
  @media (min-width: 1024px) {
    .card-box.box-single .card-item {
      height: 46.25rem; } }
  @media (min-width: 1200px) {
    .card-box.box-single .card-item {
      height: 41.11111rem; } }
  @media (min-width: 1420px) {
    .card-box.box-single .card-item {
      height: 37rem; } }

.card-box.dynamic-box {
  min-height: 160px;
  width: 100%; }
  @media (min-width: 460px) {
    .card-box.dynamic-box {
      width: 100%; } }
  @media (min-width: 768px) {
    .card-box.dynamic-box {
      width: 50%; } }
  @media (min-width: 1024px) {
    .card-box.dynamic-box {
      width: 33.33%; } }

.contrast body.contrast .card-box .card-item:before {
  opacity: 1;
  transform: scale(1.25); }

.card-box-mini.dynamic-box {
  padding: 2rem;
  width: 100%; }
  @media (min-width: 1024px) {
    .card-box-mini.dynamic-box {
      width: 27%; } }

@media (min-width: 768px) {
  .card-box-mini.dynamic-box.box-half {
    width: 47%; } }

.card-box-mini .card-item-mini {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 0; }
  .card-box-mini .card-item-mini:after {
    border-left-style: dotted;
    border-left-width: 2px;
    position: absolute;
    top: 0;
    left: -2rem;
    height: 100%; }
  .card-box-mini .card-item-mini h3 {
    margin: 0;
    font-size: 20px; }
    @media (min-width: 460px) {
      .card-box-mini .card-item-mini h3 {
        font-size: 20px; } }
    @media (min-width: 1200px) {
      .card-box-mini .card-item-mini h3 {
        font-size: 2.66667rem; } }
    @media (min-width: 1420px) {
      .card-box-mini .card-item-mini h3 {
        font-size: 2.4rem; } }
  .card-box-mini .card-item-mini-ico {
    transition: 250ms; }
    .card-box-mini .card-item-mini-ico:before {
      font-size: 50px;
      position: relative;
      margin: 0 2.2rem 0 0; }
  .card-box-mini .card-item-mini-content-link {
    font-weight: 700; }
  .card-box-mini .card-item-mini-content h3 {
    margin: 0; }
  .card-box-mini .card-item-mini:focus, .card-box-mini .card-item-mini:hover {
    text-decoration: none; }
    .card-box-mini .card-item-mini:focus .link:after, .card-box-mini .card-item-mini:hover .link:after {
      right: -5px; }

.card-box-mini.box-single {
  max-width: 500px;
  margin: 0 auto; }

.slider-service .card-item-mini:hover .card-item-mini-ico {
  transform: scale(1.15); }

.card-box-small .card-item-small {
  display: block;
  text-align: center;
  font-weight: 400; }
  .card-box-small .card-item-small-ico:before {
    font-size: 70px; }
  .card-box-small .card-item-small .h3 {
    margin: 1.5rem 0; }
  .card-box-small .card-item-small-perex {
    margin: 0 0 1.5rem 0; }
  .card-box-small .card-item-small-link {
    font-weight: 700; }
  .card-box-small .card-item-small:focus .link:after, .card-box-small .card-item-small:hover .link:after {
    right: -5px; }

.card-box-small h3 {
  margin: 1.5rem 0 2rem 0; }

.card-box-small.box-single {
  max-width: 790px;
  margin: 0 auto; }

.card-box-big .card-item-big {
  font-weight: 400;
  display: block;
  height: 100%; }
  .card-box-big .card-item-big h3 {
    margin: 10px 0 15px 0; }
  .card-box-big .card-item-big-image {
    display: flex;
    overflow: hidden;
    min-height: 230px; }
    @media (min-width: 768px) {
      .card-box-big .card-item-big-image {
        min-height: 250px; } }
    @media (min-width: 1024px) {
      .card-box-big .card-item-big-image {
        min-height: 35rem; } }
    @media (min-width: 1200px) {
      .card-box-big .card-item-big-image {
        min-height: 31.11111rem; } }
    @media (min-width: 1420px) {
      .card-box-big .card-item-big-image {
        min-height: 28rem; } }
    .card-box-big .card-item-big-image-bgc {
      width: 100%;
      background-size: cover !important;
      background-position: top center !important;
      transition: all 0.3s ease-out; }
  .card-box-big .card-item-big-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 3.2rem; }
    @media (min-width: 320px) {
      .card-box-big .card-item-big-header {
        font-size: 2.90909rem; } }
    @media (min-width: 460px) {
      .card-box-big .card-item-big-header {
        font-size: 2.66667rem; } }
    @media (min-width: 768px) {
      .card-box-big .card-item-big-header {
        font-size: 2.13333rem; } }
    @media (min-width: 1024px) {
      .card-box-big .card-item-big-header {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .card-box-big .card-item-big-header {
        font-size: 1.77778rem; } }
    @media (min-width: 1420px) {
      .card-box-big .card-item-big-header {
        font-size: 1.6rem; } }
    .card-box-big .card-item-big-header-type {
      padding: 2rem 3rem; }
  .card-box-big .card-item-big h3 {
    font-size: 22px; }
    @media (min-width: 460px) {
      .card-box-big .card-item-big h3 {
        font-size: 24px; } }
    @media (min-width: 1024px) {
      .card-box-big .card-item-big h3 {
        font-size: 26px; } }
    @media (min-width: 1200px) {
      .card-box-big .card-item-big h3 {
        font-size: 3.11111rem; } }
    @media (min-width: 1420px) {
      .card-box-big .card-item-big h3 {
        font-size: 2.8rem; } }
  .card-box-big .card-item-big-content {
    padding: 2rem; }
    .card-box-big .card-item-big-content-ico {
      display: none; }
      .card-box-big .card-item-big-content-ico:before {
        font-size: 5rem; }
    .card-box-big .card-item-big-content-top {
      font-weight: 700; }
  .card-box-big .card-item-big-perex {
    padding: 0 0 15px 0; }
  .card-box-big .card-item-big-link {
    font-weight: 700; }
  .card-box-big .card-item-big:focus .card-item-big-image-bgc, .card-box-big .card-item-big:hover .card-item-big-image-bgc {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  .card-box-big .card-item-big:focus .link:after, .card-box-big .card-item-big:hover .link:after {
    right: -5px; }

@media (min-width: 1200px) {
  .card-box-big.box-half .card-item-big {
    display: flex; } }

.card-box-big.box-half .card-item-big-image {
  min-height: 230px; }
  @media (min-width: 768px) {
    .card-box-big.box-half .card-item-big-image {
      min-height: 280px; } }
  @media (min-width: 1200px) {
    .card-box-big.box-half .card-item-big-image {
      width: 50%;
      min-width: 50%; } }
  @media (min-width: 1200px) and (min-width: 1024px) {
    .card-box-big.box-half .card-item-big-image {
      min-height: 37.5rem; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    .card-box-big.box-half .card-item-big-image {
      min-height: 33.33333rem; } }
  @media (min-width: 1200px) and (min-width: 1420px) {
    .card-box-big.box-half .card-item-big-image {
      min-height: 30rem; } }

.card-box-big.box-half .card-item-big-content {
  padding: 3rem; }
  @media (min-width: 1200px) {
    .card-box-big.box-half .card-item-big-content {
      padding: 3rem 2rem 3rem 5rem;
      width: 50%;
      align-self: center; } }
  .card-box-big.box-half .card-item-big-content-ico {
    display: block;
    margin: 0 0 1.5rem 0; }

.card-box-big.box-half .card-item-big h3 {
  font-size: 22px; }
  @media (min-width: 460px) {
    .card-box-big.box-half .card-item-big h3 {
      font-size: 24px; } }
  @media (min-width: 1024px) {
    .card-box-big.box-half .card-item-big h3 {
      font-size: 26px; } }
  @media (min-width: 1200px) {
    .card-box-big.box-half .card-item-big h3 {
      font-size: 2.88889rem; } }
  @media (min-width: 1420px) {
    .card-box-big.box-half .card-item-big h3 {
      font-size: 2.6rem; } }

@media (min-width: 768px) {
  .card-box-big.box-single .card-item-big {
    display: flex; } }

.card-box-big.box-single .card-item-big-image {
  min-height: 230px; }
  @media (min-width: 768px) {
    .card-box-big.box-single .card-item-big-image {
      width: 50%;
      min-width: 50%;
      min-height: 280px; } }
  @media (min-width: 1024px) {
    .card-box-big.box-single .card-item-big-image {
      min-height: 37.5rem; } }
  @media (min-width: 1200px) {
    .card-box-big.box-single .card-item-big-image {
      min-height: 33.33333rem; } }
  @media (min-width: 1420px) {
    .card-box-big.box-single .card-item-big-image {
      min-height: 30rem; } }

.card-box-big.box-single .card-item-big-content {
  padding: 3rem; }
  @media (min-width: 768px) {
    .card-box-big.box-single .card-item-big-content {
      padding: 3rem 2rem 3rem 5rem;
      width: 50%;
      align-self: center; } }
  .card-box-big.box-single .card-item-big-content-ico {
    display: block;
    margin: 0 0 1.5rem 0; }

.card-box-big.dynamic-box {
  width: 100%; }
  @media (min-width: 768px) {
    .card-box-big.dynamic-box {
      width: 50%; } }
  @media (min-width: 1024px) {
    .card-box-big.dynamic-box {
      width: 33.33%; } }
  @media (min-width: 768px) {
    .card-box-big.dynamic-box.box-single {
      width: 100%;
      max-width: 990px;
      margin: 0 auto; } }

.card-container .line-helper {
  display: none; }
  @media (min-width: 1024px) {
    .card-container .line-helper {
      display: block; } }

main .tenders-table a {
  font-weight: 700; }

main .tenders-table table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden; }
  main .tenders-table table thead {
    display: none; }
    @media (min-width: 768px) {
      main .tenders-table table thead {
        display: table-header-group; } }
    main .tenders-table table thead tr {
      border: none; }
      main .tenders-table table thead tr th {
        font-weight: 700;
        padding: 1rem 0 1.5rem 0; }
        @media (min-width: 768px) {
          main .tenders-table table thead tr th {
            padding: 1rem 2.5rem; } }
        main .tenders-table table thead tr th:nth-child(3), main .tenders-table table thead tr th:nth-child(4) {
          text-align: right; }
  main .tenders-table table tbody tr {
    display: block;
    border-bottom: none;
    margin: 0 0 2rem 0;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease-out; }
    @media (min-width: 768px) {
      main .tenders-table table tbody tr {
        display: table-row;
        margin: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid; } }
    main .tenders-table table tbody tr td {
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
      padding: 1.8rem 2.5rem;
      line-height: 1.3;
      text-align: center;
      border-bottom-width: 1px;
      border-bottom-style: solid; }
      @media (min-width: 460px) {
        main .tenders-table table tbody tr td {
          text-align: right;
          justify-content: space-between; } }
      @media (min-width: 768px) {
        main .tenders-table table tbody tr td {
          display: table-cell;
          width: auto;
          text-align: left;
          border-bottom: none; } }
      main .tenders-table table tbody tr td:before {
        content: attr(data-th);
        display: none;
        text-align: left;
        font-family: "proxima-nova", sans-serif !important;
        margin: 0 1.5rem 0 0;
        line-height: 1.3; }
        @media (min-width: 460px) {
          main .tenders-table table tbody tr td:before {
            display: block; } }
        @media (min-width: 768px) {
          main .tenders-table table tbody tr td:before {
            display: none; } }
      main .tenders-table table tbody tr td:last-child {
        text-align: center;
        justify-content: center;
        border-bottom: none; }
        main .tenders-table table tbody tr td:last-child:before {
          display: none; }
      @media (min-width: 768px) {
        main .tenders-table table tbody tr td:nth-child(3), main .tenders-table table tbody tr td:nth-child(4) {
          text-align: right; } }
      main .tenders-table table tbody tr td span {
        display: none; }

main .second-table .tenders-table table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden; }
  main .second-table .tenders-table table thead {
    display: none; }
    @media (min-width: 768px) {
      main .second-table .tenders-table table thead {
        display: table-header-group; } }
    main .second-table .tenders-table table thead tr th {
      font-weight: 400;
      text-transform: uppercase;
      padding: 1rem 0 1.5rem 0;
      font-size: 3.6rem;
      text-align: left !important; }
      @media (min-width: 320px) {
        main .second-table .tenders-table table thead tr th {
          font-size: 3.27273rem; } }
      @media (min-width: 460px) {
        main .second-table .tenders-table table thead tr th {
          font-size: 3rem; } }
      @media (min-width: 768px) {
        main .second-table .tenders-table table thead tr th {
          font-size: 2.4rem; } }
      @media (min-width: 1024px) {
        main .second-table .tenders-table table thead tr th {
          font-size: 2.25rem; } }
      @media (min-width: 1200px) {
        main .second-table .tenders-table table thead tr th {
          font-size: 2rem; } }
      @media (min-width: 1420px) {
        main .second-table .tenders-table table thead tr th {
          font-size: 1.8rem; } }
  main .second-table .tenders-table table tbody {
    filter: drop-shadow(0 2rem 7rem rgba(0, 0, 0, 0.07)); }
    main .second-table .tenders-table table tbody tr {
      display: block;
      border-bottom: none !important;
      border-top: none;
      margin: 0 0 2rem 0;
      position: relative;
      cursor: pointer; }
      @media (min-width: 768px) {
        main .second-table .tenders-table table tbody tr {
          display: table-row;
          margin: 0;
          border: none; } }
      main .second-table .tenders-table table tbody tr:after {
        content: "";
        width: 100%;
        right: 0;
        position: absolute;
        height: 2px; }
      main .second-table .tenders-table table tbody tr:first-child:after {
        display: none; }
      main .second-table .tenders-table table tbody tr td {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: space-between;
        -moz-box-pack: space-between;
        -ms-flex-pack: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        width: 100%;
        position: relative;
        padding: 2.3rem 2.5rem;
        line-height: 1.3;
        text-align: right;
        border: none; }
        @media (min-width: 768px) {
          main .second-table .tenders-table table tbody tr td {
            display: table-cell;
            width: auto;
            text-align: left; } }
        @media (min-width: 768px) {
          main .second-table .tenders-table table tbody tr td span {
            position: absolute;
            left: 0;
            width: 0.6rem;
            height: 3.8rem;
            top: 50%;
            margin-top: -1.9rem;
            display: block; } }
        main .second-table .tenders-table table tbody tr td:before {
          content: attr(data-th);
          display: block;
          text-align: left;
          font-family: "proxima-nova", sans-serif !important;
          margin: 0 1.5rem 0 0;
          line-height: 1.3; }
          @media (min-width: 768px) {
            main .second-table .tenders-table table tbody tr td:before {
              display: none; } }
        main .second-table .tenders-table table tbody tr td:after {
          content: "";
          width: 2px;
          top: 0;
          right: 0;
          position: absolute;
          height: 100%; }
        main .second-table .tenders-table table tbody tr td:last-child {
          text-align: center;
          -webkit-box-pack: center;
          -moz-box-pack: center;
          -ms-flex-pack: center;
          -webkit-justify-content: center;
          justify-content: center; }
          main .second-table .tenders-table table tbody tr td:last-child:before {
            display: none; }
          main .second-table .tenders-table table tbody tr td:last-child:after {
            display: none; }

main .second-table .tenders-table .btn-download {
  font-size: 0; }
  main .second-table .tenders-table .btn-download:before {
    content: "\e92b";
    font-size: 4.8rem; }
    @media (min-width: 320px) {
      main .second-table .tenders-table .btn-download:before {
        font-size: 4.36364rem; } }
    @media (min-width: 460px) {
      main .second-table .tenders-table .btn-download:before {
        font-size: 4rem; } }
    @media (min-width: 768px) {
      main .second-table .tenders-table .btn-download:before {
        font-size: 3.2rem; } }
    @media (min-width: 1024px) {
      main .second-table .tenders-table .btn-download:before {
        font-size: 3rem; } }
    @media (min-width: 1200px) {
      main .second-table .tenders-table .btn-download:before {
        font-size: 2.66667rem; } }
    @media (min-width: 1420px) {
      main .second-table .tenders-table .btn-download:before {
        font-size: 2.4rem; } }

.tenders-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem; }
  .tenders-container .download-box {
    width: 100%;
    padding: 0 2rem 4rem 2rem; }
    @media (min-width: 768px) {
      .tenders-container .download-box {
        width: 50%; } }
    @media (min-width: 1024px) {
      .tenders-container .download-box {
        width: 33.33%; } }

.download-item {
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-out;
  font-weight: 400; }
  .download-item-ico {
    font-size: 53px; }
  .download-item-content {
    padding: 0 0 0 15px; }
    .download-item-content-date {
      font-size: 16px; }
      @media (min-width: 1024px) {
        .download-item-content-date {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .download-item-content-date {
          font-size: 1.77778rem; } }
      @media (min-width: 1420px) {
        .download-item-content-date {
          font-size: 1.6rem; } }
    .download-item-content-name {
      font-size: 20px; }
      @media (min-width: 1024px) {
        .download-item-content-name {
          font-size: 2.5rem; } }
      @media (min-width: 1200px) {
        .download-item-content-name {
          font-size: 2.22222rem; } }
      @media (min-width: 1420px) {
        .download-item-content-name {
          font-size: 2rem; } }
    .download-item-content-link {
      font-weight: bold;
      text-transform: uppercase; }
      .download-item-content-link a {
        text-transform: none; }
  .download-item:focus .download-item-content-link, .download-item:hover .download-item-content-link {
    text-decoration: underline; }

.events-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  @media (min-width: 1200px) {
    .events-box {
      margin: 0 -2rem; } }
  @media (min-width: 1024px) {
    .events-box h3 {
      margin: 0 0 2rem 0; } }
  .events-box .event-respo-title {
    display: block;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    margin: 0 0 30px 0;
    padding: 0 0 20px 0; }
    @media (min-width: 1024px) {
      .events-box .event-respo-title {
        display: none; } }
  .events-box-left {
    padding: 0 15px;
    width: 100%; }
    @media (min-width: 1024px) {
      .events-box-left {
        width: 47%; } }
    @media (min-width: 1200px) {
      .events-box-left {
        padding: 0 2rem;
        width: 30%; } }
    @media (min-width: 1420px) {
      .events-box-left {
        width: 27%; } }
  .events-box-right {
    padding: 0 15px;
    width: 100%; }
    @media (min-width: 1024px) {
      .events-box-right {
        width: 47%; } }
    @media (min-width: 1200px) {
      .events-box-right {
        padding: 0 2rem;
        width: 63%; } }
    @media (min-width: 1420px) {
      .events-box-right {
        width: 67%; } }
  .events-box.title-box {
    margin: 0 -2px 7rem -2px;
    display: none; }
    @media (min-width: 1024px) {
      .events-box.title-box {
        display: flex; } }
    .events-box.title-box .events-box-right {
      padding: 0 0 0 15px; }
      .events-box.title-box .events-box-right h3 {
        margin-left: -9px; }
    .events-box.title-box .events-box-left {
      padding: 0 0px 0 9px; }
      .events-box.title-box .events-box-left h3 {
        margin-left: -9px; }
    @media (min-width: 1024px) {
      .events-box.title-box .events-box-left {
        width: 50%; } }
    @media (min-width: 1200px) {
      .events-box.title-box .events-box-left {
        width: 33%; } }
    @media (min-width: 1420px) {
      .events-box.title-box .events-box-left {
        width: 30%; } }
    @media (min-width: 1024px) {
      .events-box.title-box .events-box-right {
        width: 50%; } }
    @media (min-width: 1200px) {
      .events-box.title-box .events-box-right {
        width: 67%; } }
    @media (min-width: 1420px) {
      .events-box.title-box .events-box-right {
        width: 70%; } }
    .events-box.title-box.disable-arrow .events-box-left {
      padding: 0 5px 0 0; }
      .events-box.title-box.disable-arrow .events-box-left h3 {
        margin-left: 0; }
    .events-box.title-box.disable-arrow .events-box-right {
      padding: 0 0 0 5px; }
      .events-box.title-box.disable-arrow .events-box-right h3 {
        margin-left: 0; }
    .events-box.title-box.disable-arrow .event-line:after {
      display: none; }
  .events-box.change-size .events-box-left {
    width: 100%; }
    @media (min-width: 1024px) {
      .events-box.change-size .events-box-left {
        width: 47%; } }
    @media (min-width: 1200px) {
      .events-box.change-size .events-box-left {
        width: 63%; } }
    @media (min-width: 1420px) {
      .events-box.change-size .events-box-left {
        width: 67%; } }
  .events-box.change-size .events-box-right {
    width: 100%; }
    @media (min-width: 1024px) {
      .events-box.change-size .events-box-right {
        width: 47%; } }
    @media (min-width: 1200px) {
      .events-box.change-size .events-box-right {
        width: 30%; } }
    @media (min-width: 1420px) {
      .events-box.change-size .events-box-right {
        width: 27%; } }
  @media (min-width: 1024px) {
    .events-box.change-size.title-box .events-box-left {
      width: 50%; } }
  @media (min-width: 1200px) {
    .events-box.change-size.title-box .events-box-left {
      width: 70%; } }
  @media (min-width: 1420px) {
    .events-box.change-size.title-box .events-box-left {
      width: 70%; } }
  @media (min-width: 1024px) {
    .events-box.change-size.title-box .events-box-right {
      width: 50%; } }
  @media (min-width: 1200px) {
    .events-box.change-size.title-box .events-box-right {
      width: 30%; } }
  @media (min-width: 1420px) {
    .events-box.change-size.title-box .events-box-right {
      width: 30%; } }
  .events-box .event-line {
    width: 100%;
    height: 8px;
    position: relative; }
    .events-box .event-line:after {
      content: "";
      position: absolute;
      top: -6px;
      left: -9px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent; }

.button-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem; }
  @media (min-width: 1024px) {
    .button-container {
      justify-content: center; } }
  .button-container .button-item {
    padding: 1rem 2rem; }

.event-container.box-single .event-container-left {
  width: 100%; }

.events-big .event-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  @media (min-width: 1200px) {
    .events-big .event-container {
      margin: 0 -3rem; } }
  .events-big .event-container .event-container-left {
    padding: 0 15px;
    width: 100%; }
    @media (min-width: 1200px) {
      .events-big .event-container .event-container-left {
        padding: 0 3rem;
        width: 50%; } }
  .events-big .event-container .event-container-right {
    padding: 0 15px;
    width: 100%; }
    @media (min-width: 1200px) {
      .events-big .event-container .event-container-right {
        padding: 0 3rem;
        width: 50%; } }

.event-item {
  margin: 0 0 4rem 0;
  display: block;
  transition: all 0.3s ease-out;
  font-weight: 400;
  text-decoration: none !important; }
  @media (min-width: 460px) {
    .event-item {
      display: flex; } }
  @media (min-width: 1024px) {
    .event-item {
      margin: 0 0 6rem 0; } }
  .event-item-image {
    width: 100%;
    height: 160px;
    position: relative;
    overflow: hidden; }
    @media (min-width: 460px) {
      .event-item-image {
        width: 200px;
        min-width: 200px;
        height: 130px; } }
    @media (min-width: 1024px) {
      .event-item-image {
        width: 20rem;
        min-width: 20rem; } }
    @media (min-width: 1024px) {
      .event-item-image {
        height: 16.25rem; } }
    @media (min-width: 1200px) {
      .event-item-image {
        height: 14.44444rem; } }
    @media (min-width: 1420px) {
      .event-item-image {
        height: 13rem; } }
    .event-item-image-label {
      display: none; }
    .event-item-image-bgc {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      transition: all 0.3s ease-out;
      z-index: 11;
      background-position: center center !important; }
      .event-item-image-bgc.center-center {
        background-position: center center !important; }
      .event-item-image-bgc.top-center {
        background-position: top center !important; }
  .event-item-date {
    display: none; }
    @media (min-width: 460px) {
      .event-item-date {
        width: 170px;
        min-width: 170px;
        display: block; }
        .event-item-date-inner {
          width: 100%;
          border-radius: 10px;
          min-height: 101px;
          padding: 2rem;
          display: flex;
          align-items: center;
          transition: all 0.3s ease-out; }
        .event-item-date-to {
          display: block;
          text-align: center; }
        .event-item-date-from {
          font-size: 28px;
          line-height: 1;
          letter-spacing: 0.14px;
          font-weight: 700; }
          .event-item-date-from:before {
            content: "\e99b";
            font-size: 23px;
            margin: 0 10px 0 0; } }
    @media (min-width: 460px) and (min-width: 1024px) {
      .event-item-date-from {
        font-size: 3.5rem; } }
    @media (min-width: 460px) and (min-width: 1200px) {
      .event-item-date-from {
        font-size: 3.11111rem; } }
    @media (min-width: 460px) and (min-width: 1420px) {
      .event-item-date-from {
        font-size: 2.8rem; } }
    .event-item-date .date-year {
      display: none; }
  .event-item-time {
    font-weight: 400; }
    @media (min-width: 460px) {
      .event-item-time {
        display: block;
        text-align: center; } }
  .event-item-content {
    padding: 15px 0 0 0; }
    @media (min-width: 460px) {
      .event-item-content {
        padding: 0 0 0 3rem; } }
    .event-item-content-date {
      font-weight: 700;
      padding: 0 0 5px 0; }
    .event-item-content h3 {
      letter-spacing: 0.26px;
      font-size: 22px;
      line-height: 26px; }
      @media (min-width: 1024px) {
        .event-item-content h3 {
          font-size: 3.25rem; } }
      @media (min-width: 1200px) {
        .event-item-content h3 {
          font-size: 2.88889rem; } }
      @media (min-width: 1420px) {
        .event-item-content h3 {
          font-size: 2.6rem; } }
      @media (min-width: 1024px) {
        .event-item-content h3 {
          line-height: 3.75rem; } }
      @media (min-width: 1200px) {
        .event-item-content h3 {
          line-height: 3.33333rem; } }
      @media (min-width: 1420px) {
        .event-item-content h3 {
          line-height: 3rem; } }
    .event-item-content-perex {
      display: none; }
    .event-item-content-link {
      font-weight: 700;
      display: none; }
    .event-item-content-label {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 2px 5px;
      font-size: 16px;
      font-weight: 700; }
      @media (min-width: 1024px) {
        .event-item-content-label {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .event-item-content-label {
          font-size: 1.77778rem; } }
      @media (min-width: 1420px) {
        .event-item-content-label {
          font-size: 1.6rem; } }
  .event-item:focus, .event-item:hover {
    text-decoration: none; }
    .event-item:focus .event-item-image-bgc, .event-item:hover .event-item-image-bgc {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }
    .event-item:focus .link:after, .event-item:hover .link:after {
      right: -5px; }
  .event-item.upcoming-item .event-item-image {
    display: none; }
  .event-item.upcoming-item .event-item-content-date {
    display: block;
    overflow: hidden; }
    @media (min-width: 460px) {
      .event-item.upcoming-item .event-item-content-date {
        display: none; } }
  .event-item.upcoming-item .event-item-date {
    display: none; }
    @media (min-width: 460px) {
      .event-item.upcoming-item .event-item-date {
        display: block; } }
  .event-item.upcoming-item .event-item-content {
    padding: 0; }
    @media (min-width: 460px) {
      .event-item.upcoming-item .event-item-content {
        padding: 0 0 0 3rem; } }

@media (min-width: 1200px) {
  .events-big .event-big-parent .event-item,
  .events-big .event-big .event-item {
    display: block;
    text-decoration: none !important; }
    .events-big .event-big-parent .event-item-image,
    .events-big .event-big .event-item-image {
      width: 100%;
      height: 220px;
      background-repeat: no-repeat !important;
      background-size: cover !important; } }
    @media (min-width: 1200px) and (min-width: 1024px) {
      .events-big .event-big-parent .event-item-image,
      .events-big .event-big .event-item-image {
        height: 35rem; } }
    @media (min-width: 1200px) and (min-width: 1200px) {
      .events-big .event-big-parent .event-item-image,
      .events-big .event-big .event-item-image {
        height: 31.11111rem; } }
    @media (min-width: 1200px) and (min-width: 1420px) {
      .events-big .event-big-parent .event-item-image,
      .events-big .event-big .event-item-image {
        height: 28rem; } }

@media (min-width: 1200px) {
    .events-big .event-big-parent .event-item-label, .events-big .event-big-parent .event-item-from,
    .events-big .event-big .event-item-label,
    .events-big .event-big .event-item-from {
      position: absolute;
      z-index: 22;
      min-height: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 21rem;
      padding: 0 1.5rem;
      font-size: 16px;
      font-weight: 700; } }
    @media (min-width: 1200px) and (min-width: 1024px) {
      .events-big .event-big-parent .event-item-label, .events-big .event-big-parent .event-item-from,
      .events-big .event-big .event-item-label,
      .events-big .event-big .event-item-from {
        font-size: 2rem; } }
    @media (min-width: 1200px) and (min-width: 1200px) {
      .events-big .event-big-parent .event-item-label, .events-big .event-big-parent .event-item-from,
      .events-big .event-big .event-item-label,
      .events-big .event-big .event-item-from {
        font-size: 1.77778rem; } }
    @media (min-width: 1200px) and (min-width: 1420px) {
      .events-big .event-big-parent .event-item-label, .events-big .event-big-parent .event-item-from,
      .events-big .event-big .event-item-label,
      .events-big .event-big .event-item-from {
        font-size: 1.6rem; } }

@media (min-width: 1200px) {
    .events-big .event-big-parent .event-item-from,
    .events-big .event-big .event-item-from {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0); }
    .events-big .event-big-parent .event-item-label,
    .events-big .event-big .event-item-label {
      left: 0;
      bottom: 0; }
    .events-big .event-big-parent .event-item-time,
    .events-big .event-big .event-item-time {
      display: inline-block;
      text-align: left; }
    .events-big .event-big-parent .event-item .event-item-date-to,
    .events-big .event-big .event-item .event-item-date-to {
      display: inline-block;
      text-align: left; }
    .events-big .event-big-parent .event-item-content,
    .events-big .event-big .event-item-content {
      padding: 3rem 0 0 0; }
      .events-big .event-big-parent .event-item-content-date,
      .events-big .event-big .event-item-content-date {
        padding: 0; } }
      @media (min-width: 1200px) and (min-width: 768px) {
        .events-big .event-big-parent .event-item-content-date,
        .events-big .event-big .event-item-content-date {
          padding: 0 0 2rem 0; } }

@media (min-width: 1200px) {
      .events-big .event-big-parent .event-item-content h3,
      .events-big .event-big .event-item-content h3 {
        letter-spacing: 0.34px;
        margin: 0 0 1.5rem 0; } }
      @media (min-width: 1200px) and (min-width: 1024px) {
        .events-big .event-big-parent .event-item-content h3,
        .events-big .event-big .event-item-content h3 {
          font-size: 4.25rem; } }
      @media (min-width: 1200px) and (min-width: 1200px) {
        .events-big .event-big-parent .event-item-content h3,
        .events-big .event-big .event-item-content h3 {
          font-size: 3.77778rem; } }
      @media (min-width: 1200px) and (min-width: 1420px) {
        .events-big .event-big-parent .event-item-content h3,
        .events-big .event-big .event-item-content h3 {
          font-size: 3.4rem; } }
      @media (min-width: 1200px) and (min-width: 1024px) {
        .events-big .event-big-parent .event-item-content h3,
        .events-big .event-big .event-item-content h3 {
          line-height: 5rem; } }
      @media (min-width: 1200px) and (min-width: 1200px) {
        .events-big .event-big-parent .event-item-content h3,
        .events-big .event-big .event-item-content h3 {
          line-height: 4.44444rem; } }
      @media (min-width: 1200px) and (min-width: 1420px) {
        .events-big .event-big-parent .event-item-content h3,
        .events-big .event-big .event-item-content h3 {
          line-height: 4rem; } }

@media (min-width: 1200px) {
      .events-big .event-big-parent .event-item-content-perex,
      .events-big .event-big .event-item-content-perex {
        display: block;
        padding: 0 0 2rem 0; }
      .events-big .event-big-parent .event-item-content-link,
      .events-big .event-big .event-item-content-link {
        font-weight: 700;
        display: block; }
      .events-big .event-big-parent .event-item-content-label,
      .events-big .event-big .event-item-content-label {
        display: none; }
    .events-big .event-big-parent .event-item.upcoming-item .event-item-content-date,
    .events-big .event-big-parent .event-item.upcoming-item .event-item-image,
    .events-big .event-big .event-item.upcoming-item .event-item-content-date,
    .events-big .event-big .event-item.upcoming-item .event-item-image {
      display: block; }
    .events-big .event-big-parent .event-item.upcoming-item .event-item-date,
    .events-big .event-big .event-item.upcoming-item .event-item-date {
      display: none; } }

.event-big-single {
  max-width: 520px; }
  .event-big-single .event-item {
    display: block; }
    .event-big-single .event-item-image {
      width: 100%;
      height: 170px;
      background-repeat: no-repeat !important;
      background-size: cover !important; }
      @media (min-width: 320px) {
        .event-big-single .event-item-image {
          height: 220px; } }
      @media (min-width: 460px) {
        .event-big-single .event-item-image {
          height: 250px; } }
      @media (min-width: 1024px) {
        .event-big-single .event-item-image {
          height: 35rem; } }
      @media (min-width: 1200px) {
        .event-big-single .event-item-image {
          height: 31.11111rem; } }
      @media (min-width: 1420px) {
        .event-big-single .event-item-image {
          height: 28rem; } }
    .event-big-single .event-item-label, .event-big-single .event-item-from {
      position: absolute;
      z-index: 22;
      min-height: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 30rem;
      padding: 0 1.5rem;
      font-size: 16px;
      font-weight: 700; }
      @media (min-width: 1024px) {
        .event-big-single .event-item-label, .event-big-single .event-item-from {
          font-size: 2rem; } }
      @media (min-width: 1200px) {
        .event-big-single .event-item-label, .event-big-single .event-item-from {
          font-size: 1.77778rem; } }
      @media (min-width: 1420px) {
        .event-big-single .event-item-label, .event-big-single .event-item-from {
          font-size: 1.6rem; } }
      @media (min-width: 1200px) {
        .event-big-single .event-item-label, .event-big-single .event-item-from {
          min-width: 22rem; } }
    .event-big-single .event-item-from {
      top: 0;
      left: 50%;
      transform: translate(-50%, 0); }
    .event-big-single .event-item-label {
      left: 0;
      bottom: 0; }
    .event-big-single .event-item-time {
      display: inline-block;
      text-align: left; }
    .event-big-single .event-item .event-item-date-to {
      display: inline-block;
      text-align: left; }
    .event-big-single .event-item-content {
      padding: 3rem 0 0 0; }
      .event-big-single .event-item-content-date {
        padding: 0; }
        @media (min-width: 768px) {
          .event-big-single .event-item-content-date {
            padding: 0 0 2rem 0; } }
      .event-big-single .event-item-content h3 {
        letter-spacing: 0.34px;
        margin: 0 0 1.5rem 0; }
        @media (min-width: 1024px) {
          .event-big-single .event-item-content h3 {
            font-size: 4.25rem; } }
        @media (min-width: 1200px) {
          .event-big-single .event-item-content h3 {
            font-size: 3.77778rem; } }
        @media (min-width: 1420px) {
          .event-big-single .event-item-content h3 {
            font-size: 3.4rem; } }
        @media (min-width: 1024px) {
          .event-big-single .event-item-content h3 {
            line-height: 5rem; } }
        @media (min-width: 1200px) {
          .event-big-single .event-item-content h3 {
            line-height: 4.44444rem; } }
        @media (min-width: 1420px) {
          .event-big-single .event-item-content h3 {
            line-height: 4rem; } }
      .event-big-single .event-item-content-perex {
        display: block;
        padding: 0 0 2rem 0; }
      .event-big-single .event-item-content-link {
        display: block; }
      .event-big-single .event-item-content-label {
        display: none; }
    .event-big-single .event-item.upcoming-item .event-item-content-date,
    .event-big-single .event-item.upcoming-item .event-item-image {
      display: block; }
    .event-big-single .event-item.upcoming-item .event-item-date {
      display: none; }
  @media (min-width: 1200px) {
    .event-big-single.box-half .event-item {
      display: flex;
      align-items: center; } }
  @media (min-width: 1200px) {
    .event-big-single.box-half .event-item-image {
      width: 50%;
      min-width: 50%; } }
  @media (min-width: 1200px) {
    .event-big-single.box-half .event-item-content {
      width: 50%;
      min-width: 50%;
      padding: 0 0 0 5rem; } }
  @media (min-width: 768px) {
    .event-big-single.box-single .event-item {
      display: flex;
      align-items: center; } }
  @media (min-width: 768px) {
    .event-big-single.box-single .event-item-image {
      width: 50%;
      min-width: 50%; } }
  @media (min-width: 768px) {
    .event-big-single.box-single .event-item-content {
      width: 50%;
      min-width: 50%;
      padding: 0 0 0 5rem; } }

.event-page {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .event-page .event-box {
    width: 100%;
    padding: 0 15px; }
    @media (min-width: 768px) {
      .event-page .event-box {
        width: 50%; } }
    @media (min-width: 1200px) {
      .event-page .event-box {
        width: 33.33%; } }
    @media (min-width: 1200px) {
      .event-page .event-box.box-half {
        width: 50%; } }
    @media (min-width: 768px) {
      .event-page .event-box.box-single {
        width: 100%;
        max-width: 990px; } }
  .event-page .event-big-single {
    max-width: 570px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .event-page .event-big-single {
        max-width: 100%;
        margin: 0; } }

.events-box .event-item {
  margin: 0; }

.events-box .event-box {
  padding: 0 0 5rem 0; }

.events-box-right .event-respo-title {
  margin: 40px 0 30px 0; }

.events-line {
  position: relative;
  width: 6%;
  display: none; }
  @media (min-width: 1024px) {
    .events-line {
      display: block; } }
  .events-line:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    border-left-style: dotted;
    border-left-width: 2px;
    left: 50%;
    margin-left: 0; }
    @media (min-width: 1200px) {
      .events-line:before {
        margin-left: 5px; } }

@media (min-width: 1200px) {
  .change-size .events-line:before {
    margin-left: -5px; } }

.event-container .event-big-single .event-item-content {
  padding: 3rem 0 0 0 !important; }

.event-item.upcoming-item {
  align-items: center; }
  .event-item.upcoming-item h3 {
    margin: 0; }
  .event-item.upcoming-item .event-item-content-label {
    margin: 2rem 0 0 0; }
  .event-item.upcoming-item .event-item-date {
    position: relative; }
  .event-item.upcoming-item .event-item-time {
    position: absolute;
    padding-left: 5px; }

.events-box .event-item-content h3 {
  margin-top: 0; }
  @media (min-width: 768px) {
    .events-box .event-item-content h3 {
      margin-top: 2rem; } }

.events-box .event-item {
  padding-bottom: 2rem;
  border-bottom: 1px solid silver; }
  @media (min-width: 460px) {
    .events-box .event-item {
      padding-bottom: 0;
      border: none; } }

.event-box-new {
  margin-bottom: 10px; }

.event-item-new {
  display: block;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px; }
  .event-item-new .event-item-new-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px; }
  .event-item-new .event-item-new-date {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    letter-spacing: 0.14px; }
    .event-item-new .event-item-new-date:before {
      content: "\e9a8";
      margin-right: 5px;
      font-size: 4.4rem; }
      @media (min-width: 320px) {
        .event-item-new .event-item-new-date:before {
          font-size: 4rem; } }
      @media (min-width: 460px) {
        .event-item-new .event-item-new-date:before {
          font-size: 3.66667rem; } }
      @media (min-width: 768px) {
        .event-item-new .event-item-new-date:before {
          font-size: 2.93333rem; } }
      @media (min-width: 1024px) {
        .event-item-new .event-item-new-date:before {
          font-size: 2.75rem; } }
      @media (min-width: 1200px) {
        .event-item-new .event-item-new-date:before {
          font-size: 2.44444rem; } }
      @media (min-width: 1420px) {
        .event-item-new .event-item-new-date:before {
          font-size: 2.2rem; } }
  .event-item-new .event-item-new-name {
    font-size: 4rem;
    letter-spacing: 0.26px;
    margin-bottom: 5px; }
    @media (min-width: 320px) {
      .event-item-new .event-item-new-name {
        font-size: 3.63636rem; } }
    @media (min-width: 460px) {
      .event-item-new .event-item-new-name {
        font-size: 3.33333rem; } }
    @media (min-width: 768px) {
      .event-item-new .event-item-new-name {
        font-size: 2.66667rem; } }
    @media (min-width: 1024px) {
      .event-item-new .event-item-new-name {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .event-item-new .event-item-new-name {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      .event-item-new .event-item-new-name {
        font-size: 2rem; } }
  .event-item-new .event-item-time {
    text-align: left; }
  .event-item-new .event-item-content-label {
    border-radius: 3px;
    font-size: 2.8rem; }
    @media (min-width: 320px) {
      .event-item-new .event-item-content-label {
        font-size: 2.54545rem; } }
    @media (min-width: 460px) {
      .event-item-new .event-item-content-label {
        font-size: 2.33333rem; } }
    @media (min-width: 768px) {
      .event-item-new .event-item-content-label {
        font-size: 1.86667rem; } }
    @media (min-width: 1024px) {
      .event-item-new .event-item-content-label {
        font-size: 1.75rem; } }
    @media (min-width: 1200px) {
      .event-item-new .event-item-content-label {
        font-size: 1.55556rem; } }
    @media (min-width: 1420px) {
      .event-item-new .event-item-content-label {
        font-size: 1.4rem; } }
  .event-item-new:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }

@media (min-width: 1200px) {
  .gallery-masonry.event-detail-gallery .gallery-box {
    width: 25%; } }

.row.gallery-widget-row {
  margin-left: -2rem;
  margin-right: -2rem; }
  .row.gallery-widget-row [class^="col"] {
    padding: 0 2rem 4rem 2rem; }
  .row.gallery-widget-row .jpg:before {
    display: none; }

.detail-photo {
  position: relative;
  height: 30rem;
  width: 100%;
  overflow: hidden; }
  .detail-photo-bgr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    transition: all 0.3s ease-out; }
  .detail-photo:focus .detail-photo-bgr, .detail-photo:hover .detail-photo-bgr {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }

.detail-description {
  padding: 5px 0 0 0; }

.widget-container .gallery-title {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 0 2rem 0; }
  .widget-container .gallery-title .widget-title {
    text-transform: none;
    margin: 0 2rem 0 0; }
  .widget-container .gallery-title-numbers {
    padding: 2rem 0 2rem 0;
    font-weight: 700; }
    @media (min-width: 300px) {
      .widget-container .gallery-title-numbers {
        line-height: 4.4rem; } }
    @media (min-width: 320px) {
      .widget-container .gallery-title-numbers {
        line-height: 4rem; } }
    @media (min-width: 460px) {
      .widget-container .gallery-title-numbers {
        line-height: 3.66667rem; } }
    @media (min-width: 768px) {
      .widget-container .gallery-title-numbers {
        line-height: 3.5rem; } }
    @media (min-width: 300px) {
      .widget-container .gallery-title-numbers {
        font-size: 3rem; } }
    @media (min-width: 320px) {
      .widget-container .gallery-title-numbers {
        font-size: 2.72727rem; } }
    @media (min-width: 460px) {
      .widget-container .gallery-title-numbers {
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      .widget-container .gallery-title-numbers {
        font-size: 2.2rem; } }
  .widget-container .gallery-title:before {
    content: "";
    margin: 0 15px 0 0; }
    @media (min-width: 300px) {
      .widget-container .gallery-title:before {
        width: 0.8rem; } }
    @media (min-width: 300px) {
      .widget-container .gallery-title:before {
        height: 4.4rem; } }
    @media (min-width: 320px) {
      .widget-container .gallery-title:before {
        height: 4rem; } }
    @media (min-width: 460px) {
      .widget-container .gallery-title:before {
        height: 3.66667rem; } }
    @media (min-width: 768px) {
      .widget-container .gallery-title:before {
        height: 3.5rem; } }

.gallery-masonry {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 2rem -5px; }
  .gallery-masonry .gallery-box {
    padding: 0 5px 10px 5px;
    width: 100%; }
    @media (min-width: 460px) {
      .gallery-masonry .gallery-box {
        width: 50%; } }
    @media (min-width: 768px) {
      .gallery-masonry .gallery-box {
        width: 33.33%; } }
    .gallery-masonry .gallery-box.grid-sizer {
      padding: 0; }

.icons-container {
  max-width: 1100px;
  margin: 0 auto; }
  .icons-container .ico-card-box {
    width: 100%;
    padding: 0 2rem 4rem 2rem; }
    @media (min-width: 768px) {
      .icons-container .ico-card-box {
        width: 27%; } }
    @media (min-width: 768px) {
      .icons-container .ico-card-box.box-half {
        width: 36%; } }
    @media (min-width: 768px) {
      .icons-container .ico-card-box.box-single {
        width: 100%;
        max-width: 500px; } }
  .icons-container .ico-card-box-small {
    width: 100%;
    padding: 2rem; }
    @media (min-width: 768px) {
      .icons-container .ico-card-box-small {
        width: 27%; } }
    @media (min-width: 768px) {
      .icons-container .ico-card-box-small.box-half {
        width: 36%; } }
    @media (min-width: 768px) {
      .icons-container .ico-card-box-small.box-single {
        width: 100%;
        max-width: 500px; } }

.ico-card-item-icon {
  padding: 0 0 1.5rem 0; }
  .ico-card-item-icon:before {
    font-size: 53px; }

.ico-card-item .h3 {
  margin: 0 0 2rem 0; }

.ico-card-item-small {
  display: flex;
  align-items: center; }
  .ico-card-item-small-icon {
    margin: 0 1.4rem 0 0; }
    .ico-card-item-small-icon:before {
      font-size: 51px; }
  .ico-card-item-small .h3 {
    margin: 0; }

.icons-container .line-helper {
  display: none; }
  @media (min-width: 768px) {
    .icons-container .line-helper {
      display: block; } }

.important-box {
  display: flex; }
  .important-box.box-single {
    max-width: 800px; }

.important-item {
  border-radius: 10px;
  padding: 2.5rem 3rem;
  display: block;
  width: 100%;
  transition: all 0.3s ease-out;
  font-weight: 400;
  text-decoration: none !important; }
  .important-item-date {
    font-weight: 700;
    padding: 0 0 5px 0; }
  .important-item-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 5px 0; }
    @media (min-width: 460px) {
      .important-item-title {
        font-size: 22px; } }
    @media (min-width: 1024px) {
      .important-item-title {
        font-size: 3.25rem; } }
    @media (min-width: 1200px) {
      .important-item-title {
        font-size: 2.88889rem; } }
    @media (min-width: 1420px) {
      .important-item-title {
        font-size: 2.6rem; } }
  .important-item-label {
    display: inline-block;
    padding: 2px 5px;
    margin: 0 0 1rem 0;
    line-height: 1; }
  .important-item-link {
    font-weight: 700; }
  .important-item:focus, .important-item:hover {
    text-decoration: none; }
    .important-item:focus .link:after, .important-item:hover .link:after {
      right: -5px; }

.notification-files-item {
  padding-bottom: 1.5rem;
  display: flex; }

.important-box.d-none-category {
  display: none !important; }

.important-box.d-none-job {
  display: none !important; }

.notification-filter-empty {
  text-align: center;
  padding: 3rem 0; }

.notification-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0 0 3rem 0; }

.notification-filter-item {
  min-width: 200px; }

.logo-carousel {
  display: flex;
  overflow: hidden;
  padding: 0 45px; }
  .logo-carousel.slick-initialized {
    display: block; }

.logo-box {
  padding: 0 15px;
  display: flex !important;
  height: 150px;
  flex-grow: 1;
  outline: 0; }

.logo-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(100%);
  transition: all 0.3s ease-out;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50); }
  .logo-item:hover {
    filter: grayscale(0);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .logo-item-inner {
    width: 100%;
    text-align: center; }
  .logo-item img {
    display: inline-block;
    max-height: 150px !important; }

.logo-colored .logo-item {
  filter: grayscale(0);
  opacity: 1; }

.logo-colored a.logo-item:hover {
  opacity: .6; }

.logo-carousel {
  position: relative; }
  .logo-carousel .slick-arrow {
    border: none;
    background: none;
    position: absolute;
    width: 22px;
    height: 44px;
    top: 50%;
    margin-top: -22px !important;
    font-size: 0;
    outline: 0; }
    .logo-carousel .slick-arrow:before {
      font-size: 44px;
      display: block; }
    .logo-carousel .slick-arrow.slick-prev {
      left: 0; }
      .logo-carousel .slick-arrow.slick-prev:before {
        content: "\e99e"; }
    .logo-carousel .slick-arrow.slick-next {
      right: 0; }
      .logo-carousel .slick-arrow.slick-next:before {
        content: "\e99f"; }

.map-box {
  position: relative;
  width: 100%;
  text-align: center; }
  .map-box-name {
    padding: 1.5rem 3rem;
    -webkit-border-radius: 6rem;
    -moz-border-radius: 6rem;
    -ms-border-radius: 6rem;
    border-radius: 6rem;
    display: inline-block;
    position: relative;
    z-index: 888;
    -webkit-transform: translate(0, 50%);
    -moz-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    -o-transform: translate(0, 50%);
    transform: translate(0, 50%); }
  .map-box #map-canvas {
    width: 100%;
    height: 50rem; }

.map-points {
  display: none; }

.marker-detail {
  display: none; }

.marker-detail-image {
  display: none; }
  @media (min-width: 768px) {
    .marker-detail-image {
      display: block; } }

.marker-detail-inner {
  display: block; }
  @media (min-width: 768px) {
    .marker-detail-inner {
      display: flex; } }
  .marker-detail-inner img {
    max-width: 100%;
    margin-bottom: 15px;
    border-radius: 10px; }
    @media (min-width: 768px) {
      .marker-detail-inner img {
        max-width: 220px;
        margin-bottom: 0; } }

.is-image .marker-detail-text {
  padding: 0 0 0 15px; }

.map-box h4 {
  text-align: left;
  padding-bottom: 2px; }

.marker-detail-inner {
  padding: 0 12px 12px 0px; }
  @media (min-width: 768px) {
    .marker-detail-inner {
      padding: 10px 22px 22px 10px; } }
  .marker-detail-inner .btn {
    text-transform: uppercase; }

.gm-style .gm-style-iw-d {
  overflow: auto !important; }

.gm-style .gm-style-iw-c {
  border-radius: 10px; }

.gm-style .gm-style-iw-tc::after {
  display: none; }

.marker-detail-subtitle {
  text-align: left;
  padding-bottom: 8px; }
  @media (min-width: 300px) {
    .marker-detail-subtitle {
      font-size: 3.2rem; } }
  @media (min-width: 320px) {
    .marker-detail-subtitle {
      font-size: 2.90909rem; } }
  @media (min-width: 460px) {
    .marker-detail-subtitle {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .marker-detail-subtitle {
      font-size: 2.13333rem; } }
  @media (min-width: 1024px) {
    .marker-detail-subtitle {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .marker-detail-subtitle {
      font-size: 1.8rem; } }
  @media (min-width: 300px) {
    .marker-detail-subtitle {
      line-height: 3.2rem; } }
  @media (min-width: 320px) {
    .marker-detail-subtitle {
      line-height: 2.90909rem; } }
  @media (min-width: 460px) {
    .marker-detail-subtitle {
      line-height: 2.66667rem; } }
  @media (min-width: 768px) {
    .marker-detail-subtitle {
      line-height: 2.13333rem; } }
  @media (min-width: 1024px) {
    .marker-detail-subtitle {
      line-height: 2rem; } }
  @media (min-width: 1200px) {
    .marker-detail-subtitle {
      line-height: 1.8rem; } }

.marker-detail-service,
.marker-detail-capacity,
.marker-detail-contact,
.marker-detail-mail,
.marker-detail-address {
  display: flex;
  align-items: center;
  line-height: 26px;
  font-size: 16px; }
  .marker-detail-service:before,
  .marker-detail-capacity:before,
  .marker-detail-contact:before,
  .marker-detail-mail:before,
  .marker-detail-address:before {
    padding-right: 10px;
    line-height: 26px; }
  .marker-detail-service a,
  .marker-detail-capacity a,
  .marker-detail-contact a,
  .marker-detail-mail a,
  .marker-detail-address a {
    text-decoration: underline; }
    .marker-detail-service a:hover,
    .marker-detail-capacity a:hover,
    .marker-detail-contact a:hover,
    .marker-detail-mail a:hover,
    .marker-detail-address a:hover {
      text-decoration: none; }

.marker-detail-text {
  text-align: left; }

.marker-detail-btn {
  text-align: left;
  margin-top: 15px; }
  @media (min-width: 768px) {
    .marker-detail-btn {
      display: none; } }

.marker-detail-content {
  text-align: left;
  line-height: 20px;
  font-size: 14px; }

.marker-detail-capacity:before {
  content: "\e9b2"; }

.marker-detail-contact:before {
  content: "\e9ca"; }

.marker-detail-address:before {
  content: "\e9cd"; }

.marker-detail-mail:before {
  content: "\e9c9"; }

.gm-style .gm-style-iw-c {
  padding-top: 22px; }

.marker-detail-close {
  opacity: 1 !important;
  display: none; }
  .marker-detail-close img {
    display: none !important; }
  .marker-detail-close:before {
    content: "\03a7";
    font-family: "proxima-nova", sans-serif !important; }

.bgc-main .marker-detail-inner {
  color: #4c4c4c; }
  .bgc-main .marker-detail-inner h4 {
    color: #4c4c4c; }

.pux-modal-leave,
.pux-modal {
  z-index: 99999; }
  .pux-modal-leave .modal-dialog,
  .pux-modal .modal-dialog {
    margin: 14rem auto 0 auto;
    max-width: 800px; }
  .pux-modal-leave .modal-content,
  .pux-modal .modal-content {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    background-repeat: no-repeat !important;
    border: none;
    text-shadow: none; }
    .pux-modal-leave .modal-content .close,
    .pux-modal .modal-content .close {
      outline: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 30px;
      height: 30px;
      font-size: 30px;
      cursor: pointer;
      z-index: 22;
      opacity: 1;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: alpha(opacity=100); }
    .pux-modal-leave .modal-content.modal-light,
    .pux-modal .modal-content.modal-light {
      background-color: #fff;
      color: #000;
      border: none; }
      .pux-modal-leave .modal-content.modal-light .close,
      .pux-modal .modal-content.modal-light .close {
        background: #5a5a5a;
        color: red; }
        .pux-modal-leave .modal-content.modal-light .close:focus, .pux-modal-leave .modal-content.modal-light .close:hover,
        .pux-modal .modal-content.modal-light .close:focus,
        .pux-modal .modal-content.modal-light .close:hover {
          background: #404040; }
    .pux-modal-leave .modal-content.modal-dark,
    .pux-modal .modal-content.modal-dark {
      background-color: #585857;
      color: #fff;
      border: none; }
      .pux-modal-leave .modal-content.modal-dark .close,
      .pux-modal .modal-content.modal-dark .close {
        background: grey;
        color: red; }
        .pux-modal-leave .modal-content.modal-dark .close:focus, .pux-modal-leave .modal-content.modal-dark .close:hover,
        .pux-modal .modal-content.modal-dark .close:focus,
        .pux-modal .modal-content.modal-dark .close:hover {
          background: #676767; }
    .pux-modal-leave .modal-content .modal-body,
    .pux-modal .modal-content .modal-body {
      padding: 30px; }
      .pux-modal-leave .modal-content .modal-body-title,
      .pux-modal .modal-content .modal-body-title {
        font-size: 22px;
        font-size: 1.375rem;
        padding: 0 0 15px 0;
        font-weight: bold; }
      .pux-modal-leave .modal-content .modal-body-subtitle,
      .pux-modal .modal-content .modal-body-subtitle {
        font-size: 18px;
        font-size: 1.125rem;
        padding: 0 0 10px 0; }
      .pux-modal-leave .modal-content .modal-body.no-padding,
      .pux-modal .modal-content .modal-body.no-padding {
        padding: 0; }
    .pux-modal-leave .modal-content.background-cover,
    .pux-modal .modal-content.background-cover {
      background-size: cover !important; }
    .pux-modal-leave .modal-content.top-center,
    .pux-modal .modal-content.top-center {
      background-position: top center !important; }
    .pux-modal-leave .modal-content.top-left,
    .pux-modal .modal-content.top-left {
      background-position: top left !important; }
    .pux-modal-leave .modal-content.top-right,
    .pux-modal .modal-content.top-right {
      background-position: top right !important; }
    .pux-modal-leave .modal-content.center-center,
    .pux-modal .modal-content.center-center {
      background-position: center center !important; }
    .pux-modal-leave .modal-content.bottom-center,
    .pux-modal .modal-content.bottom-center {
      background-position: bottom center !important; }
    .pux-modal-leave .modal-content.bottom-left,
    .pux-modal .modal-content.bottom-left {
      background-position: bottom left !important; }
    .pux-modal-leave .modal-content.bottom-right,
    .pux-modal .modal-content.bottom-right {
      background-position: bottom right !important; }

.modal-backdrop {
  background: #000;
  z-index: 9999; }

.number-box {
  display: flex; }

.number-item {
  padding: 5rem;
  border-radius: 20px;
  width: 100%; }
  .number-item-ico {
    font-size: 60px;
    height: 70px;
    line-height: 1; }
    @media (min-width: 1024px) {
      .number-item-ico {
        font-size: 80px;
        height: 90px; } }
    @media (min-width: 1200px) {
      .number-item-ico {
        font-size: 100px;
        height: 110px; } }
  .number-item-number {
    width: 70px;
    border: 3px solid;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    @media (min-width: 300px) {
      .number-item-number {
        font-size: 9rem; } }
    @media (min-width: 320px) {
      .number-item-number {
        font-size: 8.18182rem; } }
    @media (min-width: 460px) {
      .number-item-number {
        font-size: 7.5rem; } }
    @media (min-width: 768px) {
      .number-item-number {
        font-size: 6rem; } }
    @media (min-width: 1024px) {
      .number-item-number {
        font-size: 5.625rem; } }
    @media (min-width: 1200px) {
      .number-item-number {
        font-size: 5rem; } }
    @media (min-width: 1420px) {
      .number-item-number {
        font-size: 4.5rem; } }
    @media (min-width: 300px) {
      .number-item-number {
        line-height: 13rem; } }
    @media (min-width: 320px) {
      .number-item-number {
        line-height: 11.81818rem; } }
    @media (min-width: 460px) {
      .number-item-number {
        line-height: 10.83333rem; } }
    @media (min-width: 768px) {
      .number-item-number {
        line-height: 8.66667rem; } }
    @media (min-width: 1024px) {
      .number-item-number {
        line-height: 8.125rem; } }
    @media (min-width: 1200px) {
      .number-item-number {
        line-height: 7.22222rem; } }
    @media (min-width: 1420px) {
      .number-item-number {
        line-height: 6.5rem; } }
    @media (min-width: 1024px) {
      .number-item-number {
        width: 90px;
        height: 90px; } }
  @media (min-width: 1024px) and (min-width: 300px) {
    .number-item-number {
      font-size: 11rem; } }
  @media (min-width: 1024px) and (min-width: 320px) {
    .number-item-number {
      font-size: 10rem; } }
  @media (min-width: 1024px) and (min-width: 460px) {
    .number-item-number {
      font-size: 9.16667rem; } }
  @media (min-width: 1024px) and (min-width: 768px) {
    .number-item-number {
      font-size: 7.33333rem; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .number-item-number {
      font-size: 6.875rem; } }
  @media (min-width: 1024px) and (min-width: 1200px) {
    .number-item-number {
      font-size: 6.11111rem; } }
  @media (min-width: 1024px) and (min-width: 1420px) {
    .number-item-number {
      font-size: 5.5rem; } }
  @media (min-width: 1024px) and (min-width: 300px) {
    .number-item-number {
      line-height: 17rem; } }
  @media (min-width: 1024px) and (min-width: 320px) {
    .number-item-number {
      line-height: 15.45455rem; } }
  @media (min-width: 1024px) and (min-width: 460px) {
    .number-item-number {
      line-height: 14.16667rem; } }
  @media (min-width: 1024px) and (min-width: 768px) {
    .number-item-number {
      line-height: 11.33333rem; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .number-item-number {
      line-height: 10.625rem; } }
  @media (min-width: 1024px) and (min-width: 1200px) {
    .number-item-number {
      line-height: 9.44444rem; } }
  @media (min-width: 1024px) and (min-width: 1420px) {
    .number-item-number {
      line-height: 8.5rem; } }
    @media (min-width: 1200px) {
      .number-item-number {
        width: 110px;
        height: 110px;
        border: 4px solid; } }
  @media (min-width: 1200px) and (min-width: 300px) {
    .number-item-number {
      font-size: 13rem; } }
  @media (min-width: 1200px) and (min-width: 320px) {
    .number-item-number {
      font-size: 11.81818rem; } }
  @media (min-width: 1200px) and (min-width: 460px) {
    .number-item-number {
      font-size: 10.83333rem; } }
  @media (min-width: 1200px) and (min-width: 768px) {
    .number-item-number {
      font-size: 8.66667rem; } }
  @media (min-width: 1200px) and (min-width: 1024px) {
    .number-item-number {
      font-size: 8.125rem; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    .number-item-number {
      font-size: 7.22222rem; } }
  @media (min-width: 1200px) and (min-width: 1420px) {
    .number-item-number {
      font-size: 6.5rem; } }
  @media (min-width: 1200px) and (min-width: 300px) {
    .number-item-number {
      line-height: 21rem; } }
  @media (min-width: 1200px) and (min-width: 320px) {
    .number-item-number {
      line-height: 19.09091rem; } }
  @media (min-width: 1200px) and (min-width: 460px) {
    .number-item-number {
      line-height: 17.5rem; } }
  @media (min-width: 1200px) and (min-width: 768px) {
    .number-item-number {
      line-height: 14rem; } }
  @media (min-width: 1200px) and (min-width: 1024px) {
    .number-item-number {
      line-height: 13.125rem; } }
  @media (min-width: 1200px) and (min-width: 1200px) {
    .number-item-number {
      line-height: 11.66667rem; } }
  @media (min-width: 1200px) and (min-width: 1420px) {
    .number-item-number {
      line-height: 10.5rem; } }
  .number-item h2,
  .number-item h3 {
    margin: 0 0 2rem 0; }
  .number-item-perex {
    margin: 3rem 0 0 0; }
  .number-item-btn {
    margin: 2rem 0 0 0; }
  @media (min-width: 460px) {
    .number-item .number-item-inner {
      display: flex; } }
  .number-item .number-item-inner .number-item-number {
    width: auto;
    height: auto;
    border: none;
    line-height: 1; }
  @media (min-width: 1024px) {
    .number-item .number-item-inner .number-item-ico {
      font-size: 78px;
      height: auto; } }
  @media (min-width: 1200px) {
    .number-item .number-item-inner .number-item-ico {
      font-size: 78px;
      height: auto; } }
  .number-item .number-item-inner .number-item-perex {
    margin: 3rem 0 0 0; }
    @media (min-width: 460px) {
      .number-item .number-item-inner .number-item-perex {
        margin: 0; } }
  .number-item .number-item-inner-left {
    padding-right: 4rem; }

.number-left .dynamic-container .dynamic-box {
  width: 100%; }
  @media (min-width: 460px) {
    .number-left .dynamic-container .dynamic-box {
      width: 100%; } }
  @media (min-width: 600px) {
    .number-left .dynamic-container .dynamic-box {
      width: 100%; } }
  @media (min-width: 768px) {
    .number-left .dynamic-container .dynamic-box {
      width: 50%; } }
  @media (min-width: 1024px) {
    .number-left .dynamic-container .dynamic-box {
      width: 50%; } }
  @media (min-width: 1200px) {
    .number-left .dynamic-container .dynamic-box {
      width: 33.33%; } }

.person-box {
  text-align: center; }

.person-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400; }
  .person-item .h3 {
    margin: 5px 0 15px 0; }
  .person-item-image {
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    display: inline-block;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; }
    .person-item-image.man, .person-item-image.woman {
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .person-item-image.man:after, .person-item-image.woman:after {
        content: "\e943";
        display: block;
        font-size: 15rem; }
        @media (min-width: 768px) {
          .person-item-image.man:after, .person-item-image.woman:after {
            font-size: 10rem; } }
    .person-item-image.woman:after {
      content: "\e942"; }
  .person-item-content {
    padding: 2rem 0 0 0;
    letter-spacing: 0.18px; }
    .person-item-content-function {
      font-weight: 700; }
    .person-item-content-name {
      letter-spacing: 0.24px; }

.person-item .link-phone:before,
.person-small-item .link-phone:before {
  content: "\e99c"; }
  @media (min-width: 1024px) {
    .person-item .link-phone:before,
    .person-small-item .link-phone:before {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) {
    .person-item .link-phone:before,
    .person-small-item .link-phone:before {
      font-size: 2.22222rem; } }
  @media (min-width: 1420px) {
    .person-item .link-phone:before,
    .person-small-item .link-phone:before {
      font-size: 2rem; } }

.person-item .link-email:before,
.person-small-item .link-email:before {
  content: "\e99a"; }
  @media (min-width: 1024px) {
    .person-item .link-email:before,
    .person-small-item .link-email:before {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) {
    .person-item .link-email:before,
    .person-small-item .link-email:before {
      font-size: 2.22222rem; } }
  @media (min-width: 1420px) {
    .person-item .link-email:before,
    .person-small-item .link-email:before {
      font-size: 2rem; } }

.person-item.open .person-item-description-text,
.person-small-item.open .person-item-description-text {
  display: block; }

.person-item.open .person-item-description-show:after,
.person-small-item.open .person-item-description-show:after {
  transform: rotate(180deg); }

@media (min-width: 460px) {
  .box-single .person-item {
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: row; } }

@media (min-width: 460px) {
  .box-single .person-item-content {
    padding: 0 0 0 4rem; } }

@media (min-width: 460px) {
  .person-box.box-single {
    max-width: 570px; } }

@media (min-width: 1024px) {
  .person-box.box-half .person-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: left; } }

@media (min-width: 1024px) {
  .person-box.box-half .person-item-content {
    padding: 0 0 0 4rem; } }

@media (min-width: 1024px) {
  .person-box.box-half {
    max-width: 570px; } }

.person-small-box {
  padding: 0 2rem 4rem 2rem;
  width: 100%;
  display: flex; }
  @media (min-width: 1024px) {
    .person-small-box {
      width: 50%; } }

.person-small-container .person-small-table {
  width: 100%; }
  .person-small-container .person-small-table table {
    width: 100%; }
    .person-small-container .person-small-table table tbody tr td {
      padding: 2rem;
      vertical-align: middle;
      justify-content: flex-start; }
      @media (min-width: 768px) {
        .person-small-container .person-small-table table tbody tr td {
          padding: 0.75rem 2rem; } }
      .person-small-container .person-small-table table tbody tr td.empty-cell {
        display: none; }
        @media (min-width: 768px) {
          .person-small-container .person-small-table table tbody tr td.empty-cell {
            display: table-cell; } }

main .person-table a {
  font-weight: 700; }

main .person-table table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden; }
  main .person-table table thead {
    display: none; }
    @media (min-width: 768px) {
      main .person-table table thead {
        display: table-header-group; } }
    main .person-table table thead tr {
      border: none; }
      main .person-table table thead tr th {
        font-weight: 700;
        padding: 1rem 0 1.5rem 0; }
        @media (min-width: 768px) {
          main .person-table table thead tr th {
            padding: 1rem 2.5rem; } }
        main .person-table table thead tr th:nth-child(3), main .person-table table thead tr th:nth-child(4) {
          text-align: right; }
  main .person-table table tbody tr {
    display: block;
    border-bottom: none;
    margin: 0 0 2rem 0;
    position: relative;
    transition: all 0.3s ease-out; }
    @media (min-width: 768px) {
      main .person-table table tbody tr {
        display: table-row;
        margin: 0;
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border: none; } }
    main .person-table table tbody tr td {
      display: flex;
      justify-content: center;
      width: 100%;
      position: relative;
      padding: 1.8rem 2.5rem;
      line-height: 1.3;
      text-align: center;
      border-bottom-width: 1px;
      border-bottom-style: solid; }
      @media (min-width: 460px) {
        main .person-table table tbody tr td {
          text-align: right;
          justify-content: space-between; } }
      @media (min-width: 768px) {
        main .person-table table tbody tr td {
          display: table-cell;
          width: auto;
          text-align: left;
          border-bottom: none; } }
      main .person-table table tbody tr td:before {
        content: attr(data-th);
        display: none;
        text-align: left;
        font-family: "proxima-nova", sans-serif !important;
        margin: 0 1.5rem 0 0;
        line-height: 1.3; }
        @media (min-width: 460px) {
          main .person-table table tbody tr td:before {
            display: block; } }
        @media (min-width: 768px) {
          main .person-table table tbody tr td:before {
            display: none; } }
      main .person-table table tbody tr td:last-child {
        text-align: center;
        justify-content: center;
        border-bottom: none; }
        main .person-table table tbody tr td:last-child:before {
          display: none; }
      @media (min-width: 768px) {
        main .person-table table tbody tr td:nth-child(3), main .person-table table tbody tr td:nth-child(4) {
          text-align: right; } }

.person-item-description .person-item-description-show {
  display: inline-flex;
  align-items: center; }
  .person-item-description .person-item-description-show:after {
    content: "\e902";
    margin: 0 0 0 5px;
    font-size: 7px;
    transition: 250ms; }

.person-item-description-text {
  display: none;
  font-size: 15px;
  padding: 1rem 0 0 0;
  width: 100%; }

.person-item-icon {
  font-size: 32px;
  line-height: 1;
  margin: 0 0 1rem 0; }
  .person-item-icon a:focus, .person-item-icon a:hover {
    opacity: 0.8; }

.tooltip {
  font-size: 15px; }

.person-small-item-icon {
  line-height: 1;
  font-size: 25px;
  height: 25px;
  vertical-align: middle; }
  .person-small-item-icon a {
    line-height: 1;
    height: 25px;
    display: inline-block; }

.image-left .dynamic-container .dynamic-box {
  width: 100%; }
  @media (min-width: 1024px) {
    .image-left .dynamic-container .dynamic-box {
      width: 50%; } }
  @media (min-width: 1200px) {
    .image-left .dynamic-container .dynamic-box {
      width: 33.33%; } }

.image-left .person-container .person-item {
  display: block; }
  @media (min-width: 460px) {
    .image-left .person-container .person-item {
      text-align: left; } }

@media (min-width: 460px) {
  .image-left .person-container .person-item-inner {
    display: flex;
    align-items: center;
    justify-content: center; } }

.image-left .person-container .person-item-content {
  padding: 2rem 0 0 0; }
  @media (min-width: 460px) {
    .image-left .person-container .person-item-content {
      padding: 0 0 0 2rem; } }

.image-left .person-container .person-item .h3 {
  margin: 0; }

.image-left .person-container .person-item-content-function {
  margin: -5px 0 10px 0; }

.image-left .person-container .person-item-content-function,
.image-left .person-container .person-item-description-show,
.image-left .person-container .link {
  font-size: 3.2rem; }
  @media (min-width: 320px) {
    .image-left .person-container .person-item-content-function,
    .image-left .person-container .person-item-description-show,
    .image-left .person-container .link {
      font-size: 2.90909rem; } }
  @media (min-width: 460px) {
    .image-left .person-container .person-item-content-function,
    .image-left .person-container .person-item-description-show,
    .image-left .person-container .link {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .image-left .person-container .person-item-content-function,
    .image-left .person-container .person-item-description-show,
    .image-left .person-container .link {
      font-size: 2.13333rem; } }
  @media (min-width: 1024px) {
    .image-left .person-container .person-item-content-function,
    .image-left .person-container .person-item-description-show,
    .image-left .person-container .link {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .image-left .person-container .person-item-content-function,
    .image-left .person-container .person-item-description-show,
    .image-left .person-container .link {
      font-size: 1.77778rem; } }
  @media (min-width: 1420px) {
    .image-left .person-container .person-item-content-function,
    .image-left .person-container .person-item-description-show,
    .image-left .person-container .link {
      font-size: 1.6rem; } }

.table-small .person-table-small {
  padding: 0 2rem 4rem 2rem;
  width: 100%; }
  @media (min-width: 1024px) {
    .table-small .person-table-small {
      width: 50%; } }

.table-small a {
  font-weight: 400; }

.table-small td {
  border: none !important; }
  @media (min-width: 460px) {
    .table-small td:last-child {
      text-align: right; } }

.table-small .person-small-item-bottom {
  display: block; }
  @media (min-width: 460px) {
    .table-small .person-small-item-bottom {
      display: table-row; } }
  .table-small .person-small-item-bottom td {
    display: block;
    text-align: center; }
    @media (min-width: 460px) {
      .table-small .person-small-item-bottom td {
        display: table-cell;
        text-align: left; } }
    @media (min-width: 460px) {
      .table-small .person-small-item-bottom td:last-child {
        text-align: right; } }
  @media (min-width: 460px) {
    .table-small .person-small-item-bottom td:nth-child(2) {
      text-align: center; } }

.table-small .person-table-small-inner {
  box-shadow: 0 10px 30px rgba(232, 232, 232, 0.5); }

.product-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem; }
  @media (min-width: 768px) {
    .product-container {
      min-height: 30rem; } }
  .product-container .product-box {
    width: 100%;
    padding: 0 2rem 4rem 2rem;
    display: flex; }
    @media (min-width: 768px) {
      .product-container .product-box {
        width: 50%; } }
    @media (min-width: 1024px) {
      .product-container .product-box {
        width: 33.33%; } }

.product-item {
  width: 100%;
  min-height: 240px;
  height: 100%;
  position: relative;
  padding: 100px 6rem 0;
  overflow: hidden;
  font-weight: 400;
  transition: all 0.3s ease-out; }
  @media (min-width: 768px) {
    .product-item {
      min-height: 30rem;
      padding: 12rem 6rem 0; } }
  .product-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22; }
  .product-item:hover, .product-item:focus {
    text-decoration: none; }
    .product-item:hover .product-item-inner-btn, .product-item:focus .product-item-inner-btn {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0); }
    .product-item:hover .product-item-bgr, .product-item:focus .product-item-bgr {
      -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -ms-transform: scale(1.1);
      -o-transform: scale(1.1);
      transform: scale(1.1); }
  .product-item-bgr {
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-out; }
  .product-item-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 33; }
    .product-item-inner-title {
      padding: 1rem 3rem;
      margin: 0; }
    .product-item-inner-btn {
      padding: 1.5rem 3rem 2.5rem;
      font-weight: 700;
      -webkit-transform: translateY(1rem);
      -moz-transform: translateY(1rem);
      -ms-transform: translateY(1rem);
      -o-transform: translateY(1rem);
      transform: translateY(1rem);
      transition: all 0.3s ease-out; }
  .product-item-labels {
    position: absolute;
    top: 1rem;
    left: 1rem; }
    .product-item-labels .category-label {
      padding: 2.5px 5px;
      display: inline-block;
      margin: 1px; }

.category-label {
  padding: 4px 10px;
  display: inline-block;
  margin: 1px; }

.product-detail-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;
  justify-content: center; }
  .product-detail-row-item {
    padding: 0 2rem 4rem 2rem;
    width: 100%;
    position: relative; }
    @media (min-width: 768px) {
      .product-detail-row-item {
        width: 50%; } }
    .product-detail-row-item-labels {
      position: absolute;
      z-index: 22;
      top: 1rem;
      left: 3rem; }

.product-cta {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  .product-cta .h4 {
    margin: 0 2rem 2rem 0; }
  .product-cta .btn {
    margin: 0 0 2rem 0; }

.collapsible-item {
  display: flex;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
  font-weight: 400; }
  .collapsible-item.with-icon .collapsible-item-heading, .collapsible-item.with-numbers .collapsible-item-heading {
    padding: 0 2.5rem 0 0; }
  .collapsible-item-heading {
    width: 100%;
    padding: 0 2.5rem;
    cursor: pointer;
    display: flex;
    transition: all 0.3s ease-out; }
    .collapsible-item-heading:after {
      content: '\e9a0';
      display: flex;
      align-items: center;
      font-size: 24px;
      transition: 0.3s ease all;
      margin-left: auto;
      transition: all 0.3s ease-out;
      transform: rotate(180deg); }
    .collapsible-item-heading h2.h3 {
      display: flex;
      min-height: 60px;
      align-items: center; }
    .collapsible-item-heading h2.h3,
    .collapsible-item-heading .collapsible-item-index {
      padding: 1.5rem 0;
      margin: 0 1.5rem 0 0;
      line-height: 1;
      font-weight: 700;
      text-transform: none; }
      @media (min-width: 300px) {
        .collapsible-item-heading h2.h3,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 4.4rem; } }
      @media (min-width: 320px) {
        .collapsible-item-heading h2.h3,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 4rem; } }
      @media (min-width: 460px) {
        .collapsible-item-heading h2.h3,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 3.66667rem; } }
      @media (min-width: 768px) {
        .collapsible-item-heading h2.h3,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.93333rem; } }
      @media (min-width: 1024px) {
        .collapsible-item-heading h2.h3,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.75rem; } }
      @media (min-width: 1200px) {
        .collapsible-item-heading h2.h3,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.44444rem; } }
      @media (min-width: 1420px) {
        .collapsible-item-heading h2.h3,
        .collapsible-item-heading .collapsible-item-index {
          font-size: 2.2rem; } }
    .collapsible-item-heading .collapsible-item-index {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 50px;
      min-width: 50px;
      min-height: 50px; }
      @media (min-width: 768px) {
        .collapsible-item-heading .collapsible-item-index {
          width: 60px;
          min-width: 60px;
          min-height: 60px; } }
  .collapsible-item-container {
    width: 100%;
    overflow: hidden; }
    .collapsible-item-container .collapsible-item-text {
      transition: 0.3s ease-in-out opacity;
      height: 0;
      opacity: 0;
      overflow: hidden; }
    .collapsible-item-container.open {
      height: auto; }
      .collapsible-item-container.open .collapsible-item-text {
        height: auto;
        opacity: 1;
        padding: 3rem; }
      .collapsible-item-container.open .collapsible-item-heading:after {
        transform: rotate(0deg); }
  .collapsible-item-controller {
    width: 7.5rem;
    position: relative;
    cursor: pointer; }
    .collapsible-item-controller .close, .collapsible-item-controller .open {
      font-size: 30px;
      font-weight: 800;
      text-align: center;
      opacity: 1;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 3.7rem;
      left: 50%; }
    .collapsible-item-controller .close {
      display: inline-block; }
    .collapsible-item-controller .open {
      display: none; }
    .collapsible-item-controller.open .close {
      display: none; }
    .collapsible-item-controller.open .open {
      display: inline-block; }
  .collapsible-item:last-child {
    border-bottom: 1px solid; }
  .collapsible-item.with-icon .collapsible-item-heading .collapsible-item-index {
    padding: 0.5rem 0; }
    .collapsible-item.with-icon .collapsible-item-heading .collapsible-item-index:before {
      font-size: 35px; }
      @media (min-width: 768px) {
        .collapsible-item.with-icon .collapsible-item-heading .collapsible-item-index:before {
          font-size: 40px; } }
  .collapsible-item-text h2,
  .collapsible-item-text h3,
  .collapsible-item-text h4 {
    margin: 0 0 3rem 0; }

.slider-container {
  position: relative; }
  @media (min-width: 1024px) {
    .slider-container {
      min-height: 690px; } }
  .slider-container .slider-founder {
    display: none; }

.slider-container .main-slider-content .slider-founder {
  display: block; }

.slider-container .slider-founder {
  padding: 2rem 0 0 0; }
  .slider-container .slider-founder-title {
    padding: 0 0 10px 0; }

.slider-container.no-overlay .main-slider-bgc:before {
  display: none; }

.main-slider {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-height: 455px; }
  @media (min-width: 1024px) {
    .main-slider {
      position: static;
      min-height: 690px; } }
  .main-slider h2 {
    margin: 0; }
  .main-slider h3 {
    margin-bottom: 0; }
  .main-slider-bgc {
    width: 100%;
    background-position: center !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 11;
    min-height: 455px; }
    @media (min-width: 1024px) {
      .main-slider-bgc {
        min-height: 690px; } }
    .main-slider-bgc:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%); }
      @media (min-width: 768px) {
        .main-slider-bgc:before {
          background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%); } }
  .main-slider-inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 455px;
    height: 455px; }
    @media (min-width: 1024px) {
      .main-slider-inner {
        min-height: 550px;
        height: 550px; } }
  .main-slider-content {
    position: relative;
    z-index: 77;
    margin-top: 6rem; }
    @media (min-width: 1024px) {
      .main-slider-content {
        max-width: 640px; } }
    @media (min-width: 1200px) {
      .main-slider-content {
        max-width: 760px; } }
    @media (min-width: 1420px) {
      .main-slider-content {
        max-width: 900px; } }
    .main-slider-content-btn {
      padding: 4rem 0 0 0; }
      @media (min-width: 768px) {
        .main-slider-content-btn {
          padding: 2rem 0 0 0; } }
    @media (min-width: 300px) and (max-width: 767px) {
      .main-slider-content img {
        max-width: 60%;
        margin-bottom: 8rem; } }
  .main-slider .pux-container {
    width: 100%; }

.slider-service-static,
.slider-service {
  padding: 3rem 0 0 0; }
  @media (min-width: 1024px) {
    .slider-service-static,
    .slider-service {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 140px;
      z-index: 66;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0; } }
  .slider-service-static .pux-container,
  .slider-service .pux-container {
    width: 100%; }
  .slider-service-static .dynamic-box,
  .slider-service .dynamic-box {
    padding: 0 2rem 3rem 2rem;
    width: 100%; }
    @media (min-width: 1024px) {
      .slider-service-static .dynamic-box,
      .slider-service .dynamic-box {
        width: 27%;
        padding: 0 2rem; } }

.slider-service-static {
  position: static; }

.slider-service-static .line-helper,
.slider-service .line-helper {
  display: none; }
  @media (min-width: 1024px) {
    .slider-service-static .line-helper,
    .slider-service .line-helper {
      display: block; } }

.slider-grey {
  position: relative; }
  .slider-grey .pux-container {
    position: relative; }
    @media (min-width: 1024px) {
      .slider-grey .pux-container {
        display: flex;
        justify-content: flex-end; } }
  .slider-grey-box {
    padding: 0 20px 4rem 20px;
    width: 100%; }
    @media (min-width: 768px) {
      .slider-grey-box {
        width: 50%; } }
    @media (min-width: 1024px) {
      .slider-grey-box {
        width: 100%;
        padding: 0; } }
    .slider-grey-box:nth-child(4) {
      display: block; }
      @media (min-width: 1024px) {
        .slider-grey-box:nth-child(4) {
          display: none; } }
  .slider-grey-inner {
    position: relative;
    z-index: 77;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    padding: 40px 0 0 0; }
    @media (min-width: 1024px) {
      .slider-grey-inner {
        display: block;
        padding: 5rem 4rem 5rem 4rem;
        width: 380px;
        margin: 0;
        min-height: 550px;
        height: 550px;
        width: 400px;
        position: absolute;
        z-index: 88;
        bottom: 100%;
        margin-bottom: 140px;
        right: 0; } }
    @media (min-width: 1200px) {
      .slider-grey-inner {
        width: 400px; } }
    @media (min-width: 1420px) {
      .slider-grey-inner {
        width: 400px; } }
    .slider-grey-inner .btn {
      align-self: baseline; }
  .slider-grey-icon {
    width: 100px;
    height: 100px;
    border-radius: 50px 50px 0 0;
    z-index: 66;
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: -50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .slider-grey-icon:before {
      content: "\e909";
      display: block;
      font-size: 40px; }
    @media (min-width: 1024px) {
      .slider-grey-icon {
        display: none; } }
  .slider-grey-title {
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    padding: 0 0 4rem 0;
    width: 100%; }
    @media (min-width: 768px) {
      .slider-grey-title {
        font-size: 32px; } }
    @media (min-width: 1024px) {
      .slider-grey-title:before {
        content: "\e999";
        display: block;
        font-size: 53px; } }
    @media (min-width: 1024px) {
      .slider-grey-title {
        font-size: 3.5rem; } }
    @media (min-width: 1200px) {
      .slider-grey-title {
        font-size: 3.11111rem; } }
    @media (min-width: 1420px) {
      .slider-grey-title {
        font-size: 2.8rem; } }
    .slider-grey-title.no-icon {
      padding: 0 0 2.5rem 0; }
      .slider-grey-title.no-icon:before {
        display: none; }

.slider-grey-item {
  display: block;
  text-decoration: none !important; }
  @media (min-width: 1024px) {
    .slider-grey-item {
      margin: 0 0 3rem 0; } }
  .slider-grey-item .h3 {
    margin: 0 0 5px 0; }
  .slider-grey-item-date {
    font-weight: 700;
    padding: 0 0 6px 0; }
  .slider-grey-item-link {
    font-weight: 700; }
  .slider-grey-item:focus .link:after, .slider-grey-item:hover .link:after {
    right: -5px; }
  .slider-grey-item .important-item-label {
    display: none; }

.main-slider-arrows {
  display: none; }

@media (min-width: 1024px) {
  .only-slider .main-slider-content {
    margin: 0 110px;
    max-width: 1050px !important; } }

.only-slider .pux-container {
  position: relative; }

.only-slider .main-slider-arrows {
  display: flex;
  position: absolute;
  bottom: 50px;
  right: 25px;
  z-index: 777; }
  @media (min-width: 768px) {
    .only-slider .main-slider-arrows {
      bottom: 0; } }
  @media (min-width: 1024px) {
    .only-slider .main-slider-arrows {
      position: static;
      display: block; } }

.slick-arrow {
  z-index: 55;
  top: 50%;
  font-size: 0;
  border: none;
  background: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  outline: 0 !important;
  cursor: pointer; }
  @media (min-width: 1024px) {
    .slick-arrow {
      position: absolute;
      margin-top: -50px;
      height: 100px;
      width: 50px; } }
  .slick-arrow:before {
    font-size: 50px; }
    @media (min-width: 1024px) {
      .slick-arrow:before {
        font-size: 100px; } }
  .slick-arrow.slick-prev {
    left: 38px;
    margin: 0 4rem 0 0; }
    @media (min-width: 1024px) {
      .slick-arrow.slick-prev {
        margin: -50px 0 0 0; } }
    .slick-arrow.slick-prev:before {
      content: "\e920"; }
  .slick-arrow.slick-next {
    right: 38px; }
    .slick-arrow.slick-next:before {
      content: "\e919"; }

body.contrast .main-slider-bgc:before {
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%); }

@media (min-width: 1024px) {
  .slider-container.no-services {
    min-height: 550px; } }

@media (min-width: 1024px) {
  .slider-container.no-services .main-slider {
    min-height: 550px; } }

@media (min-width: 1024px) {
  .slider-container.no-services .main-slider-bgc {
    min-height: 550px; } }

@media (min-width: 1024px) {
  .slider-container.no-services .slider-grey-inner {
    margin-bottom: 0; } }

.slider-container.more-services .slider-service {
  bottom: 140px; }

.slider-container.slider-two .main-slider h2,
.slider-container.slider-two .h1,
.slider-container.slider-two h1 {
  display: inline;
  padding: 10px 0;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  margin: 0 0 0 15px;
  line-height: 38px; }
  @media (min-width: 460px) {
    .slider-container.slider-two .main-slider h2,
    .slider-container.slider-two .h1,
    .slider-container.slider-two h1 {
      line-height: 40px; } }
  @media (min-width: 768px) {
    .slider-container.slider-two .main-slider h2,
    .slider-container.slider-two .h1,
    .slider-container.slider-two h1 {
      line-height: 48px; } }
  @media (min-width: 1200px) {
    .slider-container.slider-two .main-slider h2,
    .slider-container.slider-two .h1,
    .slider-container.slider-two h1 {
      line-height: 75px; } }
  @media (min-width: 1200px) {
    .slider-container.slider-two .main-slider h2,
    .slider-container.slider-two .h1,
    .slider-container.slider-two h1 {
      font-size: 6.44444rem; } }
  @media (min-width: 1420px) {
    .slider-container.slider-two .main-slider h2,
    .slider-container.slider-two .h1,
    .slider-container.slider-two h1 {
      font-size: 5.8rem; } }

.slider-container.slider-two .main-slider-content h3 {
  display: inline;
  padding: 10px 0;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  margin: 0 0 0 15px;
  line-height: 30px; }
  @media (min-width: 460px) {
    .slider-container.slider-two .main-slider-content h3 {
      line-height: 31px; } }
  @media (min-width: 768px) {
    .slider-container.slider-two .main-slider-content h3 {
      line-height: 33px; } }
  @media (min-width: 1200px) {
    .slider-container.slider-two .main-slider-content h3 {
      line-height: 34px; } }

.slider-container.slider-two .slider-grey-item .important-item-label {
  display: inline-block; }

.slider-container.slider-two .slider-grey-title:before {
  display: none; }

.slider-container.slider-two .slider-grey-inner {
  height: 510px;
  min-height: 510px;
  transform: translateY(-20px); }

.btn-order .main-slider-content {
  display: flex;
  flex-direction: column; }

.btn-order .main-slider-content-btn {
  order: 3; }

.btn-order .slider-founder {
  order: 2; }

@media (min-width: 1024px) {
  .slider-container.small-important .slider-grey-inner {
    width: 345px;
    margin-bottom: 100px;
    height: 590px;
    min-height: 590px; } }

@media (min-width: 1024px) {
  .slider-container.small-important .slider-service {
    height: 100px; } }

.slider-container.small-important .line-helper,
.slider-container.small-important .line-helper:before {
  height: 80px; }

.slider-container.big-font h1,
.slider-container.big-font h2 {
  font-size: 28px; }
  @media (min-width: 460px) {
    .slider-container.big-font h1,
    .slider-container.big-font h2 {
      font-size: 34px; } }
  @media (min-width: 768px) {
    .slider-container.big-font h1,
    .slider-container.big-font h2 {
      font-size: 42px; } }
  @media (min-width: 1200px) {
    .slider-container.big-font h1,
    .slider-container.big-font h2 {
      font-size: 6.66667rem; } }
  @media (min-width: 1420px) {
    .slider-container.big-font h1,
    .slider-container.big-font h2 {
      font-size: 6rem; } }

.slider-container.big-font .main-slider-content {
  color: #fff;
  font-size: 26px;
  line-height: 30px; }

.slider-container.big-font .slider-founder {
  padding: 1rem 0 0 0; }

.slider-container.small-space .main-slider h1, .slider-container.small-space .main-slider h2, .slider-container.small-space .main-slider h3 {
  margin: 0 0 1rem 0; }

.slider-container.small-space .slider-founder {
  padding: 0;
  margin: 1rem 0 0 0; }

.slider-new.slider-second .slider-grey-inner {
  background: none;
  padding: 3rem 0 3rem 0; }
  @media (min-width: 1024px) {
    .slider-new.slider-second .slider-grey-inner {
      right: 15px; } }

.slider-new.slider-second .slider-grey-item {
  padding: 2.5rem; }
  @media (min-width: 1024px) {
    .slider-new.slider-second .slider-grey-item {
      margin: 0 0 2.5rem 0; } }

.slider-new.slider-second .slider-grey-item-date {
  display: none; }

.slider-new.slider-second .main-slider-content {
  max-width: 540px; }

.slider-new.slider-second .slider-founder {
  padding: 2.5rem 3.5rem; }

.slider-new.slider-second .main-slider-text {
  padding: 2.5rem 3.5rem;
  font-family: 'Roboto-Condensed'; }
  .slider-new.slider-second .main-slider-text h1,
  .slider-new.slider-second .main-slider-text h2 {
    margin: 0;
    font-weight: 400;
    font-family: 'Roboto-Condensed'; }
  .slider-new.slider-second .main-slider-text h1 strong,
  .slider-new.slider-second .main-slider-text h2 strong {
    font-family: 'Roboto-Condensed';
    font-weight: 700; }

.slider-new.slider-second .slider-founder-title {
  font-family: 'Roboto-Condensed'; }

.slider-new.slider-second .main-slider-content {
  margin-top: 0; }

.slider-new.slider-second .btn {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1.4rem 1.5rem;
  font-family: 'Roboto-Condensed'; }

.slider-new.slider-second .slider-grey-item .h3 {
  font-family: 'Soleil';
  font-weight: 700; }

.slider-new.slider-second .slider-grey-item-date {
  font-family: 'Soleil'; }

.slider-new.slider-second .link {
  font-weight: 400;
  font-style: italic;
  text-decoration: underline !important;
  display: inline-block;
  font-family: 'Soleil'; }
  .slider-new.slider-second .link:after {
    text-decoration: none;
    display: inline-block; }
  .slider-new.slider-second .link:hover {
    text-decoration: none !important; }

.slider-new.slider-second .slider-grey-item:hover .link {
  text-decoration: none !important; }

.slider-new.slider-second .main-slider-content-btn + .slider-founder {
  margin-top: 2rem; }

.slider-new.slider-second .btn-order .main-slider-content-btn + .slider-founder {
  margin-top: 0; }

.slider-new.slider-third .slider-grey-inner {
  background: none;
  padding: 3rem 0 3rem 0; }
  @media (min-width: 1024px) {
    .slider-new.slider-third .slider-grey-inner {
      right: 15px; } }

.slider-new.slider-third .slider-grey-item:hover .link {
  text-decoration: none !important; }

.slider-new.slider-third .link {
  font-weight: 400;
  font-style: italic;
  text-decoration: underline !important;
  display: inline-block;
  font-family: 'Soleil'; }
  .slider-new.slider-third .link:after {
    text-decoration: none;
    display: inline-block; }
  .slider-new.slider-third .link:hover {
    text-decoration: none !important; }

.slider-new.slider-third h1, .slider-new.slider-third h2 {
  text-transform: none; }

.slider-new.slider-third .slider-grey-item .h3 {
  margin: 10px 0 5px 0;
  font-family: 'basic-sans';
  font-weight: 700; }

.slider-new.slider-third .slider-grey-item-date {
  font-family: 'basic-sans';
  font-weight: 700; }

.slider-new.slider-third .slider-grey-item-link {
  margin: 0 0 10px 0; }

@media (min-width: 1024px) {
  .slider-new.slider-third .slider-grey-item {
    margin: 0 0 2.5rem 0; } }

.slider-new.slider-third .slider-grey-item-date {
  display: none; }

.slider-new.slider-third .slider-grey-inner .slider-grey-box:nth-child(1) .slider-grey-item {
  padding: 30px 3.5rem;
  clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%); }

.slider-new.slider-third .slider-grey-inner .slider-grey-box:nth-child(2) .slider-grey-item {
  padding: 20px 3.5rem;
  clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%); }

.slider-new.slider-third .slider-grey-inner .slider-grey-box:nth-child(3) .slider-grey-item {
  padding: 20px 3.5rem;
  clip-path: polygon(0 3%, 100% 0, 100% 93%, 0 100%); }

.slider-new.slider-third .main-slider-content {
  max-width: 100%;
  padding: 50px 30px 50px 30px;
  position: relative;
  margin-top: 3rem; }
  @media (min-width: 768px) {
    .slider-new.slider-third .main-slider-content {
      max-width: 545px;
      padding: 70px 50px 70px 90px; } }
  @media (min-width: 1200px) {
    .slider-new.slider-third .main-slider-content {
      max-width: 650px; } }
  .slider-new.slider-third .main-slider-content:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0 7%, 100% 0, 96% 100%, 3% 88%);
    z-index: 2; }
  .slider-new.slider-third .main-slider-content:after {
    content: "";
    background: url("../img/slider-frame.svg");
    width: 100%;
    height: 110%;
    position: absolute;
    top: -5%;
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: auto 100%;
    display: none; }
    @media (min-width: 768px) {
      .slider-new.slider-third .main-slider-content:after {
        display: block; } }

.slider-new.slider-third .main-slider-content img {
  max-width: 100%;
  margin-bottom: 0; }

.slider-new.slider-third .main-slider-design-helper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: none; }
  @media (min-width: 768px) {
    .slider-new.slider-third .main-slider-design-helper {
      display: block; } }
  .slider-new.slider-third .main-slider-design-helper:before {
    content: "";
    width: 10px;
    height: 15rem;
    border-radius: 8px;
    transform: rotate(-2deg);
    position: absolute;
    left: 5rem;
    bottom: 2rem; }
  .slider-new.slider-third .main-slider-design-helper:after {
    content: "";
    width: 10px;
    height: 15rem;
    border-radius: 8px;
    transform: rotate(2deg);
    position: absolute;
    right: 5rem;
    top: -1.5rem; }

.slider-new.slider-third .btn {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 700;
  padding: 1.4rem 1.5rem;
  font-family: 'basic-sans'; }

.slider-new.slider-third h1,
.slider-new.slider-third h2 {
  font-family: 'basic-sans'; }

.slider-new.slider-third .main-slider-text,
.slider-new.slider-third .main-slider-content-btn,
.slider-new.slider-third .slider-founder {
  position: relative;
  z-index: 3;
  font-family: 'basic-sans'; }

@media (min-width: 768px) {
  .slider-new.slider-third .main-slider-content {
    display: grid;
    gap: 2.5rem;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-areas: "top top" "founder btn"; } }

.slider-new.slider-third .main-slider-text {
  grid-area: top; }

.slider-new.slider-third .slider-founder {
  grid-area: founder; }

.slider-new.slider-third .main-slider-content-btn {
  grid-area: btn; }

@media (min-width: 768px) {
  .slider-new.slider-third .slick-dots {
    justify-content: flex-end; } }

@media (min-width: 1024px) {
  .slider-new.slider-third .slick-dots {
    justify-content: center; } }

.slider-new .slider-dots {
  position: absolute;
  right: 10px;
  width: calc(100% - 20px);
  top: 410px;
  z-index: 222; }
  @media (min-width: 460px) {
    .slider-new .slider-dots {
      top: 400px;
      right: 20px;
      width: calc(100% - 40px); } }
  @media (min-width: 1024px) {
    .slider-new .slider-dots {
      top: 500px; } }

.slider-new .slick-dots {
  margin: 0;
  padding: 0;
  list-style: 0;
  display: flex;
  justify-content: center;
  gap: 10px; }
  @media (min-width: 1024px) {
    .slider-new .slick-dots {
      justify-content: center; } }
  .slider-new .slick-dots li {
    margin: 0;
    padding: 0; }
    .slider-new .slick-dots li:before {
      display: none; }
    .slider-new .slick-dots li button {
      font-size: 0;
      padding: 0;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      border: none; }
      .slider-new .slick-dots li button:focus {
        outline: none; }

.slider-new .slick-dots li:first-child:last-child {
  display: none; }

.citation-item {
  max-width: 800px;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .citation-item {
      display: flex;
      align-items: center; } }
  .citation-item-image {
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    display: block;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin: 3rem auto;
    width: 125px;
    min-width: 125px;
    height: 125px; }
    @media (min-width: 768px) {
      .citation-item-image {
        display: inline-block;
        margin: 0 auto; } }
    @media (min-width: 1024px) {
      .citation-item-image {
        font-size: 22.5rem; } }
    @media (min-width: 1200px) {
      .citation-item-image {
        font-size: 20rem; } }
    @media (min-width: 1420px) {
      .citation-item-image {
        font-size: 18rem; } }
    @media (min-width: 1024px) {
      .citation-item-image {
        min-width: 22.5rem; } }
    @media (min-width: 1200px) {
      .citation-item-image {
        min-width: 20rem; } }
    @media (min-width: 1420px) {
      .citation-item-image {
        min-width: 18rem; } }
    @media (min-width: 1024px) {
      .citation-item-image {
        height: 22.5rem; } }
    @media (min-width: 1200px) {
      .citation-item-image {
        height: 20rem; } }
    @media (min-width: 1420px) {
      .citation-item-image {
        height: 18rem; } }
  @media (min-width: 768px) {
    .citation-item-content {
      padding: 0 0 0 5rem; } }
  .citation-item-content-text {
    font-weight: 700;
    padding: 0 0 2.5rem 0;
    font-style: italic; }
  .citation-item-content-author {
    font-style: italic;
    text-align: right; }
    .citation-item-content-author-name {
      font-weight: 700; }

.timeline-box {
  padding: 13rem 0 22rem;
  margin: auto;
  max-width: 830px;
  position: relative; }
  @media (min-width: 1024px) {
    .timeline-box {
      padding: 12rem 0 8rem; } }
  .timeline-box:before {
    content: "";
    width: 4px;
    top: 4rem;
    bottom: 16rem;
    left: 18px;
    position: absolute; }
    @media (min-width: 1024px) {
      .timeline-box:before {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%); } }
  .timeline-box:after {
    content: "";
    width: 4px;
    height: 200px;
    bottom: 6rem;
    left: 18px;
    position: absolute;
    z-index: 1; }
    @media (min-width: 1024px) {
      .timeline-box:after {
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        height: 300px; } }
  .timeline-box-start, .timeline-box-end {
    width: 6rem;
    height: 6rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    position: absolute;
    left: 20px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center; }
    @media (min-width: 1024px) {
      .timeline-box-start, .timeline-box-end {
        left: 50%;
        width: 50px;
        height: 50px; } }
  .timeline-box-start {
    top: 1rem; }
    .timeline-box-start:after {
      content: "\e92a";
      font-size: 3rem;
      -webkit-transform: translateX(-1px);
      -moz-transform: translateX(-1px);
      -ms-transform: translateX(-1px);
      -o-transform: translateX(-1px);
      transform: translateX(-1px); }
      @media (min-width: 1024px) {
        .timeline-box-start:after {
          font-size: 30px;
          -webkit-transform: translateX(-1.5px);
          -moz-transform: translateX(-1.5px);
          -ms-transform: translateX(-1.5px);
          -o-transform: translateX(-1.5px);
          transform: translateX(-1.5px); } }
  .timeline-box-end {
    bottom: 1rem; }
    .timeline-box-end:after {
      content: "\e929";
      font-size: 3rem;
      -webkit-transform: translateX(1px);
      -moz-transform: translateX(1px);
      -ms-transform: translateX(1px);
      -o-transform: translateX(1px);
      transform: translateX(1px); }
      @media (min-width: 1024px) {
        .timeline-box-end:after {
          font-size: 30px;
          -webkit-transform: translateX(2.5px);
          -moz-transform: translateX(2.5px);
          -ms-transform: translateX(2.5px);
          -o-transform: translateX(2.5px);
          transform: translateX(2.5px); } }

.timeline-item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3rem 0; }
  .timeline-item-image {
    width: 100%;
    padding-left: 6.5rem; }
    @media (min-width: 1024px) {
      .timeline-item-image {
        width: 50%;
        order: 2; } }
    .timeline-item-image-wrapper {
      height: auto;
      width: 100%;
      position: relative;
      overflow: hidden;
      max-height: 245px; }
  .timeline-item-content {
    width: 100%;
    padding-left: 6.5rem;
    position: relative; }
    @media (min-width: 1024px) {
      .timeline-item-content {
        width: 50%;
        order: 1;
        padding-right: 6.5rem;
        padding-left: 0;
        text-align: right; } }
    .timeline-item-content-title.h3 {
      padding-top: 2rem;
      padding-bottom: 3rem;
      margin: 0; }
      @media (min-width: 1024px) {
        .timeline-item-content-title.h3 {
          padding-top: 1rem;
          padding-bottom: 2rem; } }
    .timeline-item-content:after {
      content: "";
      position: absolute;
      border: 5px solid #ffffff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      left: 18px;
      top: 2rem;
      z-index: 2;
      -webkit-transform: translate(-13px, -5px);
      -moz-transform: translate(-13px, -5px);
      -ms-transform: translate(-13px, -5px);
      -o-transform: translate(-13px, -5px);
      transform: translate(-13px, -5px); }
      @media (min-width: 1024px) {
        .timeline-item-content:after {
          right: 0;
          top: 1rem;
          left: auto;
          -webkit-transform: translate(50%, 0px);
          -moz-transform: translate(50%, 0px);
          -ms-transform: translate(50%, 0px);
          -o-transform: translate(50%, 0px);
          transform: translate(50%, 0px); } }
    .timeline-item-content-perex {
      -moz-transition: all ease-in-out 500ms;
      -o-transition: all ease-in-out 500ms;
      -webkit-transition: all ease-in-out 500ms;
      transition: all ease-in-out 500ms; }
      .timeline-item-content-perex.collapsed {
        max-height: 131px;
        overflow: hidden; }
      .timeline-item-content-perex.collapsed.open {
        max-height: 2000px; }
    .timeline-item-content-btn {
      padding-top: 1.5rem;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: flex-end;
      -moz-box-pack: flex-end;
      -ms-flex-pack: flex-end;
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      cursor: pointer;
      font-weight: 600; }
      .timeline-item-content-btn.hidden {
        display: none; }
      .timeline-item-content-btn:after {
        content: "\e901";
        font-size: 4.5px;
        margin-left: 5px;
        -moz-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        -webkit-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms; }
      .timeline-item-content-btn.open:after {
        -ms-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
  .timeline-item.reverse .timeline-item-image {
    order: 1; }
    @media (min-width: 1024px) {
      .timeline-item.reverse .timeline-item-image {
        padding-left: 0;
        padding-right: 6.5rem;
        text-align: left; } }
  .timeline-item.reverse .timeline-item-content {
    order: 2; }
    @media (min-width: 1024px) {
      .timeline-item.reverse .timeline-item-content {
        padding-left: 6.5rem;
        padding-right: 0;
        text-align: left; } }
    @media (min-width: 1024px) {
      .timeline-item.reverse .timeline-item-content:after {
        right: auto;
        top: 1rem;
        left: 0;
        -webkit-transform: translate(-50%, 0px);
        -moz-transform: translate(-50%, 0px);
        -ms-transform: translate(-50%, 0px);
        -o-transform: translate(-50%, 0px);
        transform: translate(-50%, 0px); } }
    .timeline-item.reverse .timeline-item-content-btn {
      justify-content: flex-start; }

.video-box .embed-video {
  height: 41rem; }

.video-box.box-single {
  max-width: 1000px; }
  .video-box.box-single .embed-video {
    height: 56rem; }

.embed-video {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .embed-video iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0; }
  .embed-video .embed-video-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important;
    z-index: 22;
    transition: all 0.3s ease-out; }
  .embed-video:hover .embed-video-image, .embed-video:focus .embed-video-image {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }

.embed-video-overflow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer; }
  .embed-video-overflow.autoplay-1, .embed-video-overflow.played {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -moz-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -o-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -ms-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    transition: opacity 1000ms 400ms, visibility 0ms 1000ms; }

.embed-video-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 33; }
  .embed-video-icon:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 2.2rem solid transparent;
    border-bottom: 2.2rem solid transparent;
    border-left-width: 3.8rem;
    border-left-style: solid;
    margin-left: 1rem;
    transition: 250ms; }

.cta-block {
  width: 100%;
  background-size: cover !important;
  background-position: top center !important;
  display: flex;
  flex-direction: row;
  position: relative; }
  .cta-block-inner {
    position: relative;
    z-index: 22;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    min-height: 25rem; }
  .cta-block .pux-container {
    width: 100%; }
  .cta-block-content {
    max-width: 900px;
    text-align: center;
    margin: 0 auto; }
    .cta-block-content-btn {
      padding: 3rem 0 0 0; }
  .cta-block-btn {
    width: 100%;
    text-align: center; }

.cta-block-big {
  font-size: 16px; }
  @media (min-width: 1024px) {
    .cta-block-big {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .cta-block-big {
      font-size: 1.77778rem; } }
  @media (min-width: 1420px) {
    .cta-block-big {
      font-size: 1.6rem; } }
  .cta-block-big h2,
  .cta-block-big h3 {
    margin: 0 0 3rem 0; }
  .cta-block-big-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -2rem; }
    .cta-block-big-inner-left, .cta-block-big-inner-right {
      width: 100%; }
      @media (min-width: 1024px) {
        .cta-block-big-inner-left, .cta-block-big-inner-right {
          width: 50%;
          position: relative;
          padding-bottom: 70px !important; } }
    .cta-block-big-inner-right {
      padding: 0 2rem; }
      @media (min-width: 1024px) {
        .cta-block-big-inner-right {
          padding: 0 2rem 0 7rem; } }
    .cta-block-big-inner-left {
      position: relative;
      padding: 0 2rem 40px 2rem; }
      @media (min-width: 1024px) {
        .cta-block-big-inner-left {
          padding: 0 7rem 0 2rem; } }
      @media (min-width: 1024px) {
        .cta-block-big-inner-left .cta-block-big-btn {
          text-align: right;
          right: 7rem; } }
      @media (min-width: 1024px) {
        .cta-block-big-inner-left:after {
          content: "";
          position: absolute;
          top: 50%;
          margin-top: -50px;
          height: 100px;
          right: 0;
          border-left-style: dotted;
          border-left-width: 2px; } }
  .cta-block-big-btn {
    padding: 3rem 0 0 0; }
    @media (min-width: 1024px) {
      .cta-block-big-btn {
        position: absolute;
        bottom: 0; } }
  .cta-block-big .btn {
    min-width: 214px; }

.social-search {
  display: flex;
  margin: 0 0 5rem 0;
  flex-wrap: wrap; }
  @media (min-width: 460px) {
    .social-search {
      flex-wrap: nowrap; } }
  .social-search-select {
    width: 100%;
    margin: 0 15px 0 0; }
  .social-search-btn {
    padding: 3rem 0 0 0;
    text-align: center;
    width: 100%; }
    @media (min-width: 460px) {
      .social-search-btn {
        padding: 0;
        width: auto; } }
  .social-search .selectric-items {
    overflow: auto; }
  .social-search .selectric-items .selectric-scroll {
    overflow: visible; }

.social-workers {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, 1fr); }
  @media (min-width: 460px) {
    .social-workers {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 1024px) {
    .social-workers {
      grid-template-columns: repeat(3, 1fr); } }

.social-worker-item {
  background: #fff;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.18px; }
  .social-worker-item .h3 {
    margin: 5px 0 15px 0; }
  .social-worker-item-post {
    font-weight: 700; }
  .social-worker-item-name {
    letter-spacing: 0.24px; }
  .social-worker-item .link-phone:before {
    content: "\e99c"; }
    @media (min-width: 1024px) {
      .social-worker-item .link-phone:before {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .social-worker-item .link-phone:before {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      .social-worker-item .link-phone:before {
        font-size: 2rem; } }
  .social-worker-item .link-email:before {
    content: "\e99a"; }
    @media (min-width: 1024px) {
      .social-worker-item .link-email:before {
        font-size: 2.5rem; } }
    @media (min-width: 1200px) {
      .social-worker-item .link-email:before {
        font-size: 2.22222rem; } }
    @media (min-width: 1420px) {
      .social-worker-item .link-email:before {
        font-size: 2rem; } }
  .social-worker-item-title {
    margin: 1.5rem 0;
    font-weight: 700; }
  .social-worker-item-list {
    font-size: 14px; }

.street-container li {
  margin: 0;
  padding: 0 0 0 20px; }
  .street-container li:before {
    height: 1px;
    width: 10px;
    top: 13px; }

.street-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem; }
  .street-container ul {
    padding: 0 2rem;
    width: 100%; }
    @media (min-width: 460px) {
      .street-container ul {
        width: 33.33%; } }

.street-filter-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px 2rem -7px;
  justify-content: center;
  text-transform: uppercase; }
  .street-filter-list span {
    padding: 0 7px 10px 7px; }
  .street-filter-list a.street-filter-disabled {
    pointer-events: none; }

.branches-container {
  width: 100%;
  position: relative; }

@media (min-width: 1024px) {
  .branches-container-inner {
    display: flex; } }

#map-branches {
  width: 100%;
  min-height: 70vh; }
  @media (min-width: 1024px) {
    #map-branches {
      /*position: absolute;
    top:0;
    right:0;
    width: calc(100% - 37rem - ((100vw - 1580px + (40px * 2)) / 2));
    height: 100%;
    order: 2;*/
      max-width: 1100px;
      min-height: unset;
      order: 2; } }

.branches-box {
  position: relative;
  width: 100%;
  z-index: 888;
  overflow: auto;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .branches-box {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 1024px) {
    .branches-box {
      width: 40rem;
      display: block;
      order: 1; } }

.branch-item {
  padding: 3rem 3rem 3rem 3rem;
  width: 100%;
  border: 1px solid;
  font-size: 3.2rem;
  line-height: 4.8rem;
  cursor: pointer; }
  @media (min-width: 320px) {
    .branch-item {
      font-size: 2.90909rem; } }
  @media (min-width: 460px) {
    .branch-item {
      font-size: 2.66667rem; } }
  @media (min-width: 768px) {
    .branch-item {
      font-size: 2.13333rem; } }
  @media (min-width: 1024px) {
    .branch-item {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .branch-item {
      font-size: 1.77778rem; } }
  @media (min-width: 1420px) {
    .branch-item {
      font-size: 1.6rem; } }
  @media (min-width: 320px) {
    .branch-item {
      line-height: 4.36364rem; } }
  @media (min-width: 460px) {
    .branch-item {
      line-height: 4rem; } }
  @media (min-width: 768px) {
    .branch-item {
      line-height: 3.2rem; } }
  @media (min-width: 1024px) {
    .branch-item {
      line-height: 3rem; } }
  @media (min-width: 1200px) {
    .branch-item {
      line-height: 2.66667rem; } }
  @media (min-width: 1420px) {
    .branch-item {
      line-height: 2.4rem; } }
  @media (min-width: 768px) {
    .branch-item {
      width: 33.33%; } }
  @media (min-width: 1024px) {
    .branch-item {
      width: 100%; } }
  .branch-item a {
    font-weight: 700;
    display: inline-block; }
    .branch-item a:before {
      margin-right: 1.8rem;
      display: inline-block; }
    .branch-item a:focus, .branch-item a:hover {
      text-decoration: underline; }
      .branch-item a:focus:before, .branch-item a:hover:before {
        text-decoration: none; }
  .branch-item h3 {
    margin: 0;
    font-size: 3.6rem;
    line-height: 4.8rem; }
    @media (min-width: 320px) {
      .branch-item h3 {
        font-size: 3.27273rem; } }
    @media (min-width: 460px) {
      .branch-item h3 {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .branch-item h3 {
        font-size: 2.4rem; } }
    @media (min-width: 1024px) {
      .branch-item h3 {
        font-size: 2.25rem; } }
    @media (min-width: 1200px) {
      .branch-item h3 {
        font-size: 2rem; } }
    @media (min-width: 1420px) {
      .branch-item h3 {
        font-size: 1.8rem; } }
    @media (min-width: 320px) {
      .branch-item h3 {
        line-height: 4.36364rem; } }
    @media (min-width: 460px) {
      .branch-item h3 {
        line-height: 4rem; } }
    @media (min-width: 768px) {
      .branch-item h3 {
        line-height: 3.2rem; } }
    @media (min-width: 1024px) {
      .branch-item h3 {
        line-height: 3rem; } }
    @media (min-width: 1200px) {
      .branch-item h3 {
        line-height: 2.66667rem; } }
    @media (min-width: 1420px) {
      .branch-item h3 {
        line-height: 2.4rem; } }
  .branch-item-name {
    display: flex;
    align-items: center;
    margin: 0 0 1.5rem 0; }
    .branch-item-name span {
      margin-right: 10px; }
    .branch-item-name img {
      width: 4rem; }
    .branch-item-name .branch-item-image-active {
      display: none; }
  .branch-item-address {
    display: flex;
    margin-bottom: 15px; }
    .branch-item-address:before {
      content: "\e9cb";
      margin-right: 1.5rem;
      position: relative;
      top: 3px;
      font-size: 24px; }
    .branch-item-address a {
      text-decoration: underline; }
      .branch-item-address a:hover {
        text-decoration: none; }
  .branch-item-phone {
    margin-bottom: 15px; }
    .branch-item-phone a {
      text-decoration: underline; }
      .branch-item-phone a:before {
        content: "\e9ca";
        font-size: 24px; }
      .branch-item-phone a:hover {
        text-decoration: none; }
  .branch-item-email {
    margin-bottom: 15px; }
    .branch-item-email a {
      text-decoration: underline; }
      .branch-item-email a:before {
        content: "\e9c9";
        font-size: 19px; }
      .branch-item-email a:hover {
        text-decoration: none; }
  .branch-item-info {
    margin-bottom: 15px;
    display: flex; }
    .branch-item-info:before {
      content: "\e9c8";
      margin-right: 1.5rem;
      position: relative;
      top: 3px;
      font-size: 24px; }
  .branch-item:nth-child(2), .branch-item:nth-child(3) {
    margin-top: -1px; }
  .branch-item.active {
    position: relative;
    /*&:after{
      content: "";
      width: 100%;
      height: 1px;
      bottom: -1px;
      position: absolute;
    }*/ }
    .branch-item.active .branch-item-image {
      display: none; }
    .branch-item.active .branch-item-image-active {
      display: block; }

.branches-btn {
  width: 100%;
  margin: 4rem 0 0 0;
  padding: 0 3rem; }

.branches-window {
  display: none; }

.branches-detail-position {
  bottom: 0 !important;
  font-size: 16px;
  line-height: 24px; }
  .branches-detail-position .branches-window-name {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px; }
  .branches-detail-position .gm-style-iw {
    width: 250px;
    transform: translate(41px, -96px);
    box-shadow: none; }
  .branches-detail-position .gm-style-iw-tc {
    display: none; }
  .branches-detail-position .gm-style-iw-d {
    background: transparent;
    overflow: hidden !important;
    padding: 0 15px 15px 0; }
  .branches-detail-position button {
    display: none !important; }
  .branches-detail-position a {
    text-decoration: underline; }
    .branches-detail-position a:hover {
      text-decoration: none; }

.image-content-item-inner {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.image-content-item h2,
.image-content-item h3,
.image-content-item h4 {
  margin: 0 0 3rem 0; }

.image-content-item-left {
  max-width: 500px;
  width: 100%;
  display: flex; }
  @media (min-width: 1024px) {
    .image-content-item-left {
      width: 50%;
      min-width: 50%; } }

.image-content-item .image-content-item-text {
  width: 100%;
  padding: 4rem 0; }
  @media (min-width: 460px) {
    .image-content-item .image-content-item-text {
      padding: 4rem 8rem; } }

.image-content-item-right {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center; }
  @media (min-width: 1024px) {
    .image-content-item-right {
      width: 50%;
      min-width: 50%; } }

.image-content-item-bgc {
  display: none; }
  @media (min-width: 1024px) {
    .image-content-item-bgc {
      display: block;
      width: 100%;
      background-size: cover !important;
      background-position: top center !important; } }

.image-content-item .image-content-item-btn {
  margin-top: 3rem; }

@media (min-width: 1024px) {
  .image-content-item.reverse-item .image-content-item-left {
    order: 2; } }

@media (min-width: 1024px) {
  .image-content-item.reverse-item .image-content-item-right {
    order: 1; } }

@media (min-width: 1024px) {
  .start-reverse .image-content-item .image-content-item-left {
    order: 2; } }

@media (min-width: 1024px) {
  .start-reverse .image-content-item .image-content-item-right {
    order: 1; } }

@media (min-width: 1024px) {
  .start-reverse .image-content-item.reverse-item .image-content-item-left {
    order: 1; } }

@media (min-width: 1024px) {
  .start-reverse .image-content-item.reverse-item .image-content-item-right {
    order: 2; } }

.wizard-container {
  max-width: 1500px;
  margin: 0 auto;
  border-radius: 1rem;
  min-height: 820px; }
  @media (min-width: 500px) {
    .wizard-container {
      min-height: auto; } }
  .wizard-container .ErrorLabel {
    padding: 2rem 2rem 0 2rem; }
  .wizard-container .full-btn {
    display: none; }
  .wizard-container .personal-wizard .full-btn {
    display: inline-flex; }
  .wizard-container .personal-wizard .personal-btn {
    display: none; }
  .wizard-container .hide-input input {
    display: none; }
  .wizard-container .date-container {
    display: flex;
    gap: 15px;
    flex-direction: column; }
    @media (min-width: 460px) {
      .wizard-container .date-container {
        flex-direction: row; } }
    .wizard-container .date-container .data-item {
      width: 100%; }
  .wizard-container select.form-control:not([size]):not([multiple]) {
    height: 100%; }

.wizard-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 6rem 0 0 0;
  padding: 6rem 0 8rem 0;
  gap: 2rem;
  border-top-width: 1px;
  border-top-style: solid; }
  .wizard-bottom.jc-end {
    justify-content: flex-end; }
  .wizard-bottom.jc-between {
    justify-content: space-between; }
  .wizard-bottom .btn.disabled {
    cursor: not-allowed; }

.wizard-step {
  display: none;
  width: 100%;
  position: relative;
  padding: 80px 20px; }
  @media (min-width: 1200px) {
    .wizard-step {
      max-width: 940px;
      margin: 0 auto;
      padding: 0 20px; } }
  .wizard-step .file-placeholder {
    display: none !important; }
    .wizard-step .file-placeholder.active {
      display: flex !important; }
  .wizard-step .file-delete {
    cursor: pointer;
    display: none !important;
    pointer-events: all; }
    .wizard-step .file-delete:before {
      content: "\e9a1"; }
    .wizard-step .file-delete.active {
      display: flex !important; }
  .wizard-step.active {
    display: block; }
  .wizard-step .wizard-progress-layout {
    display: flex;
    gap: 5px; }
    .wizard-step .wizard-progress-layout .wizard-progress-left {
      width: 5px; }
    .wizard-step .wizard-progress-layout .wizard-progress-right {
      width: 100%; }
  .wizard-step .witard-progress {
    height: 100%;
    background-color: #c1c1c1;
    width: 5px;
    position: sticky;
    top: 70px;
    transform: translateX(-10px);
    transition: 250ms; }
    @media (min-width: 768px) {
      .wizard-step .witard-progress {
        transform: translateX(-30px); } }
    @media (min-width: 1200px) {
      .wizard-step .witard-progress {
        transform: translateX(-50px); } }
    .wizard-step .witard-progress .witard-progress-inner {
      width: 5px;
      background-color: green; }
  .wizard-step .form-item {
    padding: 0;
    width: 100%;
    position: relative; }
    @media (min-width: 768px) {
      .wizard-step .form-item {
        width: calc(50% - 2rem); } }
    .wizard-step .form-item.w100 {
      width: 100%; }
    @media (min-width: 768px) {
      .wizard-step .form-item.w50 {
        width: 50%; } }
    @media (min-width: 460px) {
      .wizard-step .form-item.w50-md {
        width: 100%; } }
    @media (min-width: 768px) {
      .wizard-step .form-item.w50-md {
        width: 50%; } }
    @media (min-width: 768px) {
      .wizard-step .form-item.border-left:before {
        content: "";
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: -10px; } }
  .wizard-step .form-section {
    border-width: 1px;
    border-style: solid;
    padding: 2rem;
    border-radius: 4px; }
    .wizard-step .form-section + .form-section {
      margin-top: 3rem; }
    .wizard-step .form-section + .form-item {
      margin-top: 3rem; }
  .wizard-step .form-row {
    gap: 3rem 4rem; }
  .wizard-step .grouped .editing-form-control-nested-control + .editing-form-control-nested-control,
  .wizard-step .grouped .editing-form-control-nested-control + .gruped-padding {
    margin: 10px 0 0 0; }
  .wizard-step .grouped.open .editing-form-control-nested-control + .editing-form-control-nested-control,
  .wizard-step .grouped.open .editing-form-control-nested-control + .gruped-padding {
    display: block; }
  .wizard-step .label-title {
    margin: 0 0 10px 0;
    display: block;
    font-weight: 600; }
  .wizard-step .form-item-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap; }
    .wizard-step .form-item-row .EditingFormLabel {
      margin: 0; }
    .wizard-step .form-item-row.grouped .editing-form-control-nested-control + .editing-form-control-nested-control {
      width: 100%; }
    .wizard-step .form-item-row .checkbox-list-horizontal {
      display: flex;
      gap: 10px;
      flex-wrap: wrap; }
  .wizard-step .radio-list-horizontal {
    display: flex;
    gap: 10px;
    flex-wrap: wrap; }
  .wizard-step .radio-list-vertical {
    display: flex;
    gap: 10px;
    flex-direction: column; }
    .wizard-step .radio-list-vertical br {
      display: none; }
  .wizard-step .wizard-section-title {
    font-weight: 700;
    margin: 3rem 0 1rem 0; }
    @media (min-width: 300px) {
      .wizard-step .wizard-section-title {
        font-size: 3.6rem; } }
    @media (min-width: 320px) {
      .wizard-step .wizard-section-title {
        font-size: 3.27273rem; } }
    @media (min-width: 460px) {
      .wizard-step .wizard-section-title {
        font-size: 3rem; } }
    @media (min-width: 768px) {
      .wizard-step .wizard-section-title {
        font-size: 2.4rem; } }
    @media (min-width: 1024px) {
      .wizard-step .wizard-section-title {
        font-size: 2.25rem; } }
    @media (min-width: 1200px) {
      .wizard-step .wizard-section-title {
        font-size: 2rem; } }
    @media (min-width: 300px) {
      .wizard-step .wizard-section-title {
        line-height: 3.6rem; } }
    @media (min-width: 320px) {
      .wizard-step .wizard-section-title {
        line-height: 3.27273rem; } }
    @media (min-width: 460px) {
      .wizard-step .wizard-section-title {
        line-height: 3rem; } }
    @media (min-width: 768px) {
      .wizard-step .wizard-section-title {
        line-height: 2.4rem; } }
    @media (min-width: 1024px) {
      .wizard-step .wizard-section-title {
        line-height: 2.25rem; } }
    @media (min-width: 1200px) {
      .wizard-step .wizard-section-title {
        line-height: 2rem; } }
  .wizard-step .EditingFormLabel {
    font-weight: 600;
    font-size: 16px;
    line-height: normal;
    margin: 0 0 1.5rem 0;
    display: inline-block; }
    @media (min-width: 1024px) {
      .wizard-step .EditingFormLabel {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .wizard-step .EditingFormLabel {
        font-size: 1.77778rem; } }
    @media (min-width: 1420px) {
      .wizard-step .EditingFormLabel {
        font-size: 1.6rem; } }
    .wizard-step .EditingFormLabel span {
      font-weight: 400; }
  .wizard-step .wizard-step-subtitle {
    font-weight: 700;
    padding-bottom: 3rem; }
    @media (min-width: 300px) {
      .wizard-step .wizard-step-subtitle {
        font-size: 3.4rem; } }
    @media (min-width: 320px) {
      .wizard-step .wizard-step-subtitle {
        font-size: 3.09091rem; } }
    @media (min-width: 460px) {
      .wizard-step .wizard-step-subtitle {
        font-size: 2.83333rem; } }
    @media (min-width: 768px) {
      .wizard-step .wizard-step-subtitle {
        font-size: 2.26667rem; } }
    @media (min-width: 1024px) {
      .wizard-step .wizard-step-subtitle {
        font-size: 2.125rem; } }
    @media (min-width: 1200px) {
      .wizard-step .wizard-step-subtitle {
        font-size: 1.9rem; } }
    @media (min-width: 300px) {
      .wizard-step .wizard-step-subtitle {
        line-height: 3.2rem; } }
    @media (min-width: 320px) {
      .wizard-step .wizard-step-subtitle {
        line-height: 2.90909rem; } }
    @media (min-width: 460px) {
      .wizard-step .wizard-step-subtitle {
        line-height: 2.66667rem; } }
    @media (min-width: 768px) {
      .wizard-step .wizard-step-subtitle {
        line-height: 2.13333rem; } }
    @media (min-width: 1024px) {
      .wizard-step .wizard-step-subtitle {
        line-height: 2rem; } }
    @media (min-width: 1200px) {
      .wizard-step .wizard-step-subtitle {
        line-height: 1.9rem; } }
  .wizard-step .wizard-line {
    width: 100%;
    height: 1px;
    margin: 2rem 0; }
  .wizard-step .wizard-step-title {
    margin: 0 0 6rem 0; }
  .wizard-step .selectric,
  .wizard-step .form-control, .wizard-step select, .wizard-step select.form-control, .wizard-step input[type="text"] {
    border-radius: 3px;
    padding: 0 2rem; }
    @media (min-width: 300px) {
      .wizard-step .selectric,
      .wizard-step .form-control, .wizard-step select, .wizard-step select.form-control, .wizard-step input[type="text"] {
        height: 9.2rem; } }
    @media (min-width: 320px) {
      .wizard-step .selectric,
      .wizard-step .form-control, .wizard-step select, .wizard-step select.form-control, .wizard-step input[type="text"] {
        height: 8.36364rem; } }
    @media (min-width: 460px) {
      .wizard-step .selectric,
      .wizard-step .form-control, .wizard-step select, .wizard-step select.form-control, .wizard-step input[type="text"] {
        height: 7.66667rem; } }
    @media (min-width: 768px) {
      .wizard-step .selectric,
      .wizard-step .form-control, .wizard-step select, .wizard-step select.form-control, .wizard-step input[type="text"] {
        height: 6.13333rem; } }
    @media (min-width: 1024px) {
      .wizard-step .selectric,
      .wizard-step .form-control, .wizard-step select, .wizard-step select.form-control, .wizard-step input[type="text"] {
        height: 5.75rem; } }
    @media (min-width: 1200px) {
      .wizard-step .selectric,
      .wizard-step .form-control, .wizard-step select, .wizard-step select.form-control, .wizard-step input[type="text"] {
        height: 5.6rem; } }
  .wizard-step .selectric {
    border-width: 1px;
    border-style: solid;
    padding: 0; }
  .wizard-step .selectric-wrapper:focus, .wizard-step .selectric-wrapper:hover {
    box-shadow: unset; }
  .wizard-step .textarea, .wizard-step textarea.form-control {
    height: 112px;
    padding: 1.5rem 1.8rem; }
  .wizard-step .radio input[type="radio"] + label {
    font-size: 15px; }
  .wizard-step .radio input[type="radio"] {
    width: 18px;
    height: 18px; }
  .wizard-step .wizard-step-desc {
    margin-top: 10px;
    display: none; }
  .wizard-step .line {
    width: 100%;
    height: 1px;
    margin: 6rem 0; }
  .wizard-step .open-ano,
  .wizard-step .open-zazadano,
  .wizard-step .open-ne {
    margin-top: 1rem; }
  .wizard-step .wizard-bottom .EditingFormLabel,
  .wizard-step .personal-detail-text {
    width: 100%;
    text-align: center; }
  .wizard-step .form-item.open-ne .EditingFormLabel {
    display: none; }
  .wizard-step .gruped-padding {
    padding-left: 4rem; }

.wizard-top {
  padding: 8rem 0;
  border-right-width: 1px;
  border-right-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 22;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  min-height: 820px;
  height: 100%;
  left: -130px;
  transition: 250ms; }
  @media (min-width: 500px) {
    .wizard-top {
      position: static;
      min-height: unset;
      border-top-left-radius: unset;
      border-bottom-left-radius: unset; } }
  @media (min-width: 1200px) {
    .wizard-top {
      border: none;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem; } }

.wizard-block {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 3rem; }

.wizard-menu {
  display: flex;
  gap: 0 2rem;
  flex-direction: column;
  width: 127px; }
  @media (min-width: 1200px) {
    .wizard-menu {
      flex-direction: row;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      align-items: center;
      justify-content: center;
      width: auto; } }
  .wizard-menu-link {
    padding: 1rem 1.5rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-weight: 600;
    font-size: 16px;
    min-width: 127px; }
    @media (min-width: 1024px) {
      .wizard-menu-link {
        font-size: 2rem; } }
    @media (min-width: 1200px) {
      .wizard-menu-link {
        font-size: 1.77778rem; } }
    @media (min-width: 1420px) {
      .wizard-menu-link {
        font-size: 1.6rem; } }
    @media (min-width: 1200px) {
      .wizard-menu-link {
        display: inline-flex;
        padding: 0 0.5rem  1.5rem 0.5rem;
        min-width: 96px; } }
    @media (min-width: 1500px) {
      .wizard-menu-link {
        min-width: 127px;
        padding: 0 1.5rem  2.5rem 1.5rem; } }
    .wizard-menu-link:after {
      content: "";
      width: 3px;
      height: 100%;
      position: absolute;
      right: -1px;
      bottom: 0;
      z-index: 22;
      opacity: 0; }
      @media (min-width: 500px) {
        .wizard-menu-link:after {
          width: 1px; } }
      @media (min-width: 1200px) {
        .wizard-menu-link:after {
          content: "";
          width: 100%;
          height: 1px;
          bottom: -1px;
          left: 0;
          opacity: 0; } }
    .wizard-menu-link span {
      display: block;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      border-radius: 50%;
      transition: 250ms; }
    .wizard-menu-link:hover {
      text-decoration: none; }
    .wizard-menu-link:focus, .wizard-menu-link.active {
      text-decoration: none; }
      .wizard-menu-link:focus:after, .wizard-menu-link.active:after {
        opacity: 1; }
    .wizard-menu-link.disabled {
      cursor: default; }
    .wizard-menu-link.invalid {
      align-items: center;
      position: relative; }
      .wizard-menu-link.invalid:before {
        content: "\0021";
        font-family: "proxima-nova", sans-serif !important;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background-color: #d40511;
        width: 20px;
        height: 20px;
        display: inline-flex;
        align-items: flex-end;
        justify-content: center;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        top: 7px;
        left: 20px;
        position: absolute; }
        @media (min-width: 1200px) {
          .wizard-menu-link.invalid:before {
            top: -25px;
            left: unset; } }

.scrolled.up .wizard-step .witard-progress {
  top: 140px; }

.scrolled.down .wizard-step .witard-progress {
  top: 100px; }

.wizard-container-inner {
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 820px; }
  @media (min-width: 500px) {
    .wizard-container-inner {
      overflow: unset; } }
  .wizard-container-inner .wizard-close {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    z-index: 11; }
    .wizard-container-inner .wizard-close:before {
      content: "\e9ce"; }
    @media (min-width: 500px) {
      .wizard-container-inner .wizard-close {
        display: none; } }
  .wizard-container-inner:before {
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 11;
    position: absolute;
    border-radius: 1rem;
    transition: 250ms; }
    @media (min-width: 500px) {
      .wizard-container-inner:before {
        display: none; } }
  @media (min-width: 500px) {
    .wizard-container-inner {
      min-height: auto; } }
  @media (min-width: 768px) {
    .wizard-container-inner {
      gap: 0 3rem; } }
  @media (min-width: 1200px) {
    .wizard-container-inner {
      display: block; } }
  .wizard-container-inner.open-menu:before {
    width: 100%; }
  .wizard-container-inner.open-menu .wizard-close:before {
    content: "\e9a1"; }
  .wizard-container-inner.open-menu .wizard-top {
    left: 0; }

.wizard-highlight {
  padding: 1rem;
  border: 1px solid red;
  display: flex;
  align-items: center; }
  .wizard-highlight:before {
    content: "\0021";
    font-family: "proxima-nova", sans-serif !important;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #d40511;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    margin-right: 1rem;
    min-width: 20px; }

.wizard-download {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  max-width: 1024px; }

.wizard-download-item {
  display: flex;
  align-items: center;
  border-radius: 1rem;
  padding: 3.3rem 3.5rem;
  font-weight: 400;
  width: 100%; }
  @media (min-width: 768px) {
    .wizard-download-item {
      width: calc(50% - 1.5rem); } }
  @media (min-width: 300px) {
    .wizard-download-item .icon-pdf {
      font-size: 8rem; } }
  @media (min-width: 320px) {
    .wizard-download-item .icon-pdf {
      font-size: 7.27273rem; } }
  @media (min-width: 460px) {
    .wizard-download-item .icon-pdf {
      font-size: 6.66667rem; } }
  @media (min-width: 768px) {
    .wizard-download-item .icon-pdf {
      font-size: 5.33333rem; } }
  @media (min-width: 1024px) {
    .wizard-download-item .icon-pdf {
      font-size: 5rem; } }
  @media (min-width: 1200px) {
    .wizard-download-item .icon-pdf {
      font-size: 4.44444rem; } }
  @media (min-width: 1420px) {
    .wizard-download-item .icon-pdf {
      font-size: 4rem; } }
  .wizard-download-item .wizard-download-item-ico {
    margin-right: 1.5rem; }
  .wizard-download-item .wizard-download-item-size {
    margin-left: 5px;
    margin-right: 1.5rem;
    white-space: nowrap; }
    @media (min-width: 1200px) {
      .wizard-download-item .wizard-download-item-size {
        display: block; } }
  @media (min-width: 1024px) {
    .wizard-download-item:after {
      content: "\e92b";
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2.2rem;
      margin-left: auto; } }
  @media (min-width: 1024px) and (min-width: 300px) {
    .wizard-download-item:after {
      width: 7rem; } }
  @media (min-width: 1024px) and (min-width: 320px) {
    .wizard-download-item:after {
      width: 6.36364rem; } }
  @media (min-width: 1024px) and (min-width: 460px) {
    .wizard-download-item:after {
      width: 5.83333rem; } }
  @media (min-width: 1024px) and (min-width: 768px) {
    .wizard-download-item:after {
      width: 4.66667rem; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .wizard-download-item:after {
      width: 4.375rem; } }
  @media (min-width: 1024px) and (min-width: 1200px) {
    .wizard-download-item:after {
      width: 3.88889rem; } }
  @media (min-width: 1024px) and (min-width: 1420px) {
    .wizard-download-item:after {
      width: 3.5rem; } }
  @media (min-width: 1024px) and (min-width: 300px) {
    .wizard-download-item:after {
      height: 7rem; } }
  @media (min-width: 1024px) and (min-width: 320px) {
    .wizard-download-item:after {
      height: 6.36364rem; } }
  @media (min-width: 1024px) and (min-width: 460px) {
    .wizard-download-item:after {
      height: 5.83333rem; } }
  @media (min-width: 1024px) and (min-width: 768px) {
    .wizard-download-item:after {
      height: 4.66667rem; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .wizard-download-item:after {
      height: 4.375rem; } }
  @media (min-width: 1024px) and (min-width: 1200px) {
    .wizard-download-item:after {
      height: 3.88889rem; } }
  @media (min-width: 1024px) and (min-width: 1420px) {
    .wizard-download-item:after {
      height: 3.5rem; } }
  @media (min-width: 1024px) and (min-width: 300px) {
    .wizard-download-item:after {
      min-width: 7rem; } }
  @media (min-width: 1024px) and (min-width: 320px) {
    .wizard-download-item:after {
      min-width: 6.36364rem; } }
  @media (min-width: 1024px) and (min-width: 460px) {
    .wizard-download-item:after {
      min-width: 5.83333rem; } }
  @media (min-width: 1024px) and (min-width: 768px) {
    .wizard-download-item:after {
      min-width: 4.66667rem; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .wizard-download-item:after {
      min-width: 4.375rem; } }
  @media (min-width: 1024px) and (min-width: 1200px) {
    .wizard-download-item:after {
      min-width: 3.88889rem; } }
  @media (min-width: 1024px) and (min-width: 1420px) {
    .wizard-download-item:after {
      min-width: 3.5rem; } }
  @media (min-width: 1024px) and (min-width: 300px) {
    .wizard-download-item:after {
      font-size: 4.4rem; } }
  @media (min-width: 1024px) and (min-width: 320px) {
    .wizard-download-item:after {
      font-size: 4rem; } }
  @media (min-width: 1024px) and (min-width: 460px) {
    .wizard-download-item:after {
      font-size: 3.66667rem; } }
  @media (min-width: 1024px) and (min-width: 768px) {
    .wizard-download-item:after {
      font-size: 2.93333rem; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .wizard-download-item:after {
      font-size: 2.75rem; } }
  @media (min-width: 1024px) and (min-width: 1200px) {
    .wizard-download-item:after {
      font-size: 2.44444rem; } }
  @media (min-width: 1024px) and (min-width: 1420px) {
    .wizard-download-item:after {
      font-size: 2.2rem; } }

.wizard-file .file-not-valid {
  display: none; }

.wizard-file .extension-not-valid {
  display: none; }

.wizard-file.not-valid .file-not-valid {
  display: block; }

.wizard-file.not-extension .extension-not-valid {
  display: block; }

.wizard-file.input-full .file-label {
  pointer-events: none; }

.wizard-file.input-full .file-button {
  opacity: 0.4; }

.dtp-picker-years {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 6px; }
  .dtp-picker-years .year-picker-item {
    padding-top: 0; }

.dtp-actual-num {
  display: none; }

.dtp div.dtp-actual-year,
.dtp div.dtp-actual-month {
  font-size: 25px !important; }

.dtp table.dtp-picker-days tr > td {
  font-size: 13px !important; }
  @media (min-width: 460px) {
    .dtp table.dtp-picker-days tr > td {
      font-size: 15px !important; } }

.dtp {
  font-size: 20px; }

.dtp-btn-ok {
  padding: 5px 15px !important;
  border-radius: 20px !important; }

.dtp > .dtp-content {
  max-width: 310px !important; }
  @media (min-width: 460px) {
    .dtp > .dtp-content {
      max-width: 345px !important; } }

.form-warrnig {
  font-size: 14px;
  color: #856404;
  display: block;
  margin: 0.5rem 0 0 0; }
  .form-warrnig:before {
    content: "\0021";
    font-family: "proxima-nova", sans-serif !important;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    background-color: #856404;
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: flex-end;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    margin-right: 5px; }

@media screen and (orientation: landscape) and (max-width: 991px) {
  .dtp-content {
    top: 0 !important;
    max-height: 100dvh !important;
    overflow: auto; } }

.food-page {
  padding: 1px 0;
  background-color: #fff5ef; }

main .food-menus table {
  border: none;
  border-collapse: collapse; }
  main .food-menus table thead tr th {
    background: #ffd5bc;
    border: 4px solid #fff5ef; }
    main .food-menus table thead tr th:nth-child(1), main .food-menus table thead tr th:nth-child(2) {
      background: none; }
  main .food-menus table tbody tr td {
    border: 4px solid #fff5ef;
    background: #ffe5d6; }
  main .food-menus table tbody tr td:first-child {
    border-left: none;
    border-right: none; }
  main .food-menus table tbody tr:nth-child(even) {
    background: none; }
  main .food-menus table tbody tr .day-name {
    background: #ffd5bc !important;
    border-right: none; }
  main .food-menus table tbody:nth-child(even) tr td {
    background: #ffdfcb; }

.food-nwl {
  background: #ffd5bc;
  padding: 20px;
  border: 2px solid #fff5ef;
  margin-bottom: 40px; }
  .food-nwl h3 {
    margin: 0 0 15px 0; }
  .food-nwl .form-horizontal-inner {
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    width: 100%; }
  .food-nwl .form-horizontal {
    width: calc(100% - 350px); }
  .food-nwl .nwl-text {
    min-width: 350px; }
  .food-nwl .form-group {
    width: 100%; }
    .food-nwl .form-group.form-group-submit {
      width: auto; }
  .food-nwl input[type="text"] {
    height: 45px; }
  .food-nwl .NewsletterSubscription {
    display: flex;
    align-items: center;
    gap: 2rem; }

.food-menu {
  display: none; }

.food-header {
  display: flex;
  gap: 2px;
  position: sticky;
  top: 60px;
  transition: 250ms;
  background-color: #ffd5bc; }
  .food-header .food-header-empty {
    min-width: 250px; }
  .food-header .food-header-item {
    width: 100%;
    padding: 1.5rem;
    font-weight: 700;
    font-size: 20px;
    border-left: 2px solid #fff; }

.food-item {
  display: flex;
  gap: 0;
  margin-bottom: 10px;
  background-color: #fff; }
  .food-item .food-item-day {
    min-width: 100px;
    font-size: 20px;
    background-color: #ffd5bc;
    font-weight: 700;
    padding: 1.5rem;
    display: flex;
    align-items: center; }
  .food-item .food-item-list {
    width: 100%; }

.food-item-list .food-type {
  display: flex;
  gap: 0;
  background-color: #fff;
  border-bottom: 4px solid #ffd5bc; }
  .food-item-list .food-type:last-child {
    border-bottom: 0; }
  .food-item-list .food-type .food-type-item {
    padding: 1.5rem;
    width: 100%;
    border-left: 2px solid #ffd5bc; }
    .food-item-list .food-type .food-type-item:first-child {
      min-width: 150px;
      max-width: 150px;
      border-left: 0; }

.ico-1a:before {
  content: "\e9b2"; }

.ico-3:before {
  content: "\e9b1"; }

.ico-7:before {
  content: "\e9b6"; }

.passedDay {
  display: none; }

.scrolled.up .food-header {
  top: 110px; }

.food-filter {
  max-width: 200px;
  padding-bottom: 30px; }

.nwl-container {
  background-color: #e6e6e6;
  padding: 20px;
  margin: 40px 0; }
  .nwl-container h3 {
    margin: 0 0 15px 0; }
  .nwl-container .form-horizontal-inner {
    display: flex;
    gap: 2rem;
    align-items: flex-end;
    width: 100%; }
  .nwl-container .nwl-text {
    min-width: 350px; }
  .nwl-container .form-group {
    width: 100%; }
    .nwl-container .form-group.form-group-submit {
      width: auto; }
  .nwl-container input[type="text"] {
    height: 45px;
    background-color: #fff; }
  .nwl-container .NewsletterSubscription {
    align-items: center;
    gap: 2rem; }

.header-cross {
  border-bottom-width: 5px;
  border-bottom-style: solid;
  display: none; }
  @media (min-width: 300px) and (max-width: 1023px) {
    .header-cross .pux-container {
      padding-left: 0;
      padding-right: 0; } }
  .header-cross-inner {
    display: flex;
    margin: 0 0 -5px 0; }
  .header-cross-link {
    min-height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    position: relative;
    transition: all 0.3s ease-out;
    font-size: 16px;
    text-align: center; }
    .header-cross-link:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom-style: solid;
      border-bottom-width: 12px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -14px; }
    .header-cross-link.style-home {
      font-size: 0;
      width: 65px;
      min-width: 65px;
      flex-grow: 0; }
      .header-cross-link.style-home:before {
        content: "\e918";
        font-size: 25px; }
      .header-cross-link.style-home:after {
        display: none; }
  @media (min-width: 768px) {
    .header-cross.cross-3 {
      display: block; } }
  @media (min-width: 1024px) {
    .header-cross.cross-4 {
      display: block; } }
  @media (min-width: 1024px) {
    .header-cross.cross-5 {
      display: block; } }
  @media (min-width: 1200px) {
    .header-cross.cross-6 {
      display: block; } }
  .header-cross.cross-6 .pux-container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 1420px) {
    .header-cross.cross-7 {
      display: block; } }
  .header-cross.cross-7 .pux-container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0; }

.header-select {
  display: block; }
  .header-select .selectric-wrapper:hover {
    box-shadow: none; }
  .header-select .selectric-wrapper .selectric-items {
    box-shadow: none; }
  @media (min-width: 768px) {
    .header-select .selectric-wrapper .selectric .label {
      height: 65px;
      line-height: 65px;
      padding: 0 3rem; } }
  @media (min-width: 768px) {
    .header-select.cross-3 {
      display: none; } }
  @media (min-width: 1024px) {
    .header-select.cross-4 {
      display: none; } }
  @media (min-width: 1024px) {
    .header-select.cross-5 {
      display: none; } }
  @media (min-width: 1200px) {
    .header-select.cross-6 {
      display: none; } }
  @media (min-width: 1420px) {
    .header-select.cross-7 {
      display: none; } }

.cross-helper .header-spacer {
  height: 110px; }
  @media (min-width: 768px) {
    .cross-helper .header-spacer {
      height: 125px; } }
  @media (min-width: 1024px) {
    .cross-helper .header-spacer.spacer-big {
      height: 175px; } }

.scrolled.down .cross-helper header.header-big {
  top: -50px; }
  @media (min-width: 768px) {
    .scrolled.down .cross-helper header.header-big {
      top: -65px; } }
  @media (min-width: 1024px) {
    .scrolled.down .cross-helper header.header-big {
      top: -115px; } }
  .scrolled.down .cross-helper header.header-big .header-logo {
    height: 60px; }

.cross-cards {
  display: flex;
  flex-wrap: wrap; }

.cross-card-item {
  position: relative;
  width: 100%;
  flex-grow: 1; }
  @media (min-width: 768px) {
    .cross-card-item {
      width: 50%; } }
  .cross-card-item-image {
    width: 100%;
    background-size: cover !important;
    background-position: top center !important;
    display: flex;
    flex-direction: row; }
    .cross-card-item-image:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      transition: all 0.3s ease-out; }
    .cross-card-item-image:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-out; }
  .cross-card-item h2 {
    margin: 0 0 2.5rem 0; }
  .cross-card-item-inner {
    display: flex;
    align-items: center;
    height: 300px;
    transition: opacity ease-out .5s;
    opacity: 0;
    position: relative;
    z-index: 33; }
    @media (min-width: 460px) {
      .cross-card-item-inner {
        height: 348px; } }
    @media (min-width: 768px) {
      .cross-card-item-inner {
        height: 548px; } }
    .cross-card-item-inner.set {
      opacity: 1; }
    .cross-card-item-inner.text-shadow h2 {
      text-shadow: 1px 1px 10px black, 1px 1px 20px black; }
  .cross-card-item-bottom {
    display: flex;
    flex-direction: row; }
    .cross-card-item-bottom-inner {
      display: flex;
      align-items: center;
      height: 90px;
      transition: opacity ease-out .5s;
      opacity: 0; }
      @media (min-width: 460px) {
        .cross-card-item-bottom-inner {
          height: 120px; } }
      @media (min-width: 768px) {
        .cross-card-item-bottom-inner {
          height: 140px; } }
      .cross-card-item-bottom-inner.set {
        opacity: 1; }

.main-crosslink {
  width: 100%;
  min-height: 832px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  background-color: #f6dfcc;
  background-repeat: no-repeat !important;
  background-position: top left !important;
  position: relative; }
  @media (min-width: 1024px) {
    .main-crosslink {
      background-position: top right !important; } }
  @media (min-width: 1921px) {
    .main-crosslink {
      background-size: cover !important; } }
  .main-crosslink .main-crosslink-description {
    border-radius: 30px;
    padding: 2.5rem;
    background: #F8BB91;
    font-weight: 700;
    font-family: 'Amatic SC', cursive;
    font-size: 5rem;
    line-height: 5.2rem;
    max-width: 440px;
    color: #000;
    position: absolute;
    top: 3rem; }
  .main-crosslink-background {
    background-size: cover !important;
    background-position: top center !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none; }
    @media (min-width: 768px) {
      .main-crosslink-background {
        display: block; } }
  .main-crosslink-image {
    display: block;
    width: 100%; }
    @media (min-width: 768px) {
      .main-crosslink-image {
        display: none; } }
  .main-crosslink-content {
    width: 100%;
    position: relative;
    z-index: 33; }
    .main-crosslink-content-description {
      color: #fff;
      padding: 5rem 0 3rem 0;
      background: #524a64; }
      @media (min-width: 768px) {
        .main-crosslink-content-description {
          background: rgba(82, 74, 100, 0.85); } }
      .main-crosslink-content-description h1,
      .main-crosslink-content-description h2 {
        margin: 0 0 5rem 0;
        color: #fff;
        font-family: 'Amatic SC', cursive; }
      @media (min-width: 300px) {
        .main-crosslink-content-description h1 {
          font-size: 7rem; } }
      @media (min-width: 320px) {
        .main-crosslink-content-description h1 {
          font-size: 6.36364rem; } }
      @media (min-width: 460px) {
        .main-crosslink-content-description h1 {
          font-size: 6rem; } }
  .main-crosslink-hotline {
    position: absolute;
    top: 4rem;
    left: 0;
    width: 100%;
    z-index: 44; }
  .main-crosslink .pux-container {
    position: relative; }
  .main-crosslink .main-crosslink-logo {
    position: absolute;
    left: 3.7rem;
    top: 26rem;
    max-width: 215px; }
    .main-crosslink .main-crosslink-logo img {
      max-width: 21.5rem; }
  .main-crosslink .main-crosslink-logo-text {
    font-size: 14px;
    color: grey;
    padding: 0 0 1rem 0; }

.main-crosslink-hotline-inner {
  display: inline-block; }
  @media (min-width: 768px) {
    .main-crosslink-hotline-inner {
      margin: 0 50px 0 0; } }
  @media (min-width: 1630px) {
    .main-crosslink-hotline-inner {
      margin: 0; } }

.main-crosslink-hotline-title {
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 -1.5rem 0; }

.main-crosslink-hotline-phone a {
  font-weight: 700;
  font-family: 'Amatic SC', cursive;
  display: inline-flex;
  align-items: center;
  letter-spacing: -1.5px; }
  @media (min-width: 300px) {
    .main-crosslink-hotline-phone a {
      font-size: 10rem; } }
  @media (min-width: 320px) {
    .main-crosslink-hotline-phone a {
      font-size: 9.09091rem; } }
  @media (min-width: 460px) {
    .main-crosslink-hotline-phone a {
      font-size: 8.33333rem; } }
  @media (min-width: 768px) {
    .main-crosslink-hotline-phone a {
      font-size: 6.66667rem; } }
  @media (min-width: 1024px) {
    .main-crosslink-hotline-phone a {
      font-size: 6.25rem; } }
  @media (min-width: 1200px) {
    .main-crosslink-hotline-phone a {
      font-size: 6rem; } }
  .main-crosslink-hotline-phone a:before {
    content: "\e9be";
    margin: 0 10px 0 0; }

.main-crosslink.inner-crosslink .main-corsslink-box {
  border-bottom-width: 1px;
  border-bottom-style: solid; }
  @media (min-width: 768px) {
    .main-crosslink.inner-crosslink .main-corsslink-box {
      border-bottom: none; } }
  .main-crosslink.inner-crosslink .main-corsslink-box:last-child {
    border: none; }

.main-crosslink-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -20px; }
  @media (min-width: 460px) {
    .main-crosslink-container {
      margin: 0 -25px; } }
  @media (min-width: 768px) {
    .main-crosslink-container {
      margin: 0 -25px; } }
  @media (min-width: 768px) {
    .main-crosslink-container {
      margin: 0 -6px;
      padding: 38rem 0 6rem 0; } }
  .main-crosslink-container .main-corsslink-box {
    width: 100%;
    min-width: 100%;
    display: flex; }
    @media (min-width: 768px) {
      .main-crosslink-container .main-corsslink-box {
        width: 33.33%;
        min-width: 33.33%;
        padding: 0 6px 30px 6px; } }
    @media (min-width: 1420px) {
      .main-crosslink-container .main-corsslink-box {
        width: 20%;
        min-width: 20%; } }
  @media (min-width: 768px) {
    .main-crosslink-container.two-cross .main-corsslink-box {
      width: 50%;
      min-width: 50%;
      padding: 0 2rem; } }
  @media (min-width: 1420px) {
    .main-crosslink-container.two-cross .main-corsslink-box {
      width: 40%;
      min-width: 40%;
      padding: 0 5rem; } }

.main-crosslink-item {
  width: 100%;
  padding: 5rem 4rem;
  display: block;
  position: relative;
  transition: all 0.3s ease-out;
  text-align: center;
  text-decoration: none !important;
  /*&:before{
    @include media(md){
      content:"";
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom-width: 17px;
      border-bottom-style: solid;
      position: absolute;
      top:-17px;
      left:50%;
      margin-left:-18px;
      transition: all 0.3s ease-out;
    }
  }*/ }
  @media (min-width: 768px) {
    .main-crosslink-item {
      border-radius: 3rem;
      padding: 3rem; } }
  .main-crosslink-item-name {
    padding: 0 0 2rem 0;
    font-size: 16px;
    max-width: 89%;
    margin: 0 auto; }
  .main-crosslink-item-text {
    padding: 0 0 2.5rem 0;
    font-family: 'Amatic SC', cursive;
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: -0.9px;
    line-height: 1.2;
    font-weight: 700; }
    @media (min-width: 768px) {
      .main-crosslink-item-text {
        padding: 0 0 65px 0; } }
  @media (min-width: 768px) {
    .main-crosslink-item-btn {
      position: absolute;
      bottom: 3rem;
      left: 0;
      width: 100%; } }

.two-cross .main-crosslink-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  @media (min-width: 460px) {
    .two-cross .main-crosslink-item {
      justify-content: space-between; } }

.two-cross .main-crosslink-item-text {
  padding: 0 10px 20px 10px; }
  @media (min-width: 300px) {
    .two-cross .main-crosslink-item-text {
      font-size: 7rem; } }
  @media (min-width: 320px) {
    .two-cross .main-crosslink-item-text {
      font-size: 6.36364rem; } }
  @media (min-width: 460px) {
    .two-cross .main-crosslink-item-text {
      font-size: 5.83333rem; } }
  @media (min-width: 768px) {
    .two-cross .main-crosslink-item-text {
      font-size: 5.5rem; } }
  @media (min-width: 460px) {
    .two-cross .main-crosslink-item-text {
      padding: 0 10px 0 10px; } }

.two-cross .main-crosslink-item-btn {
  position: static;
  width: 100%;
  padding: 0 10px 0 10px; }
  @media (min-width: 460px) {
    .two-cross .main-crosslink-item-btn {
      width: auto; } }

.title-box {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  align-items: center;
  padding: 0 0 2rem 0;
  justify-content: center; }
  .title-box-btn {
    padding: 0 1.5rem 3rem 1.5rem; }
  .title-box-title {
    padding: 0 1.5rem 3rem 1.5rem; }
    .title-box-title h1,
    .title-box-title h2 {
      margin: 0; }

.btn-covid {
  background: #fff !important;
  color: #000 !important;
  padding: 1.1rem 2rem; }
  .btn-covid:before {
    content: "\e9bf";
    margin: 0 10px 0 0;
    color: #FF0000; }
  .btn-covid:focus, .btn-covid:hover {
    background: #e6e6e6 !important; }

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0; }
  .selectric-wrapper:focus, .selectric-wrapper:hover {
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2); }
    .selectric-wrapper:focus .selectric-items, .selectric-wrapper:hover .selectric-items {
      box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2); }

.selectric-responsive {
  width: 100%; }

.selectric {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 50px;
    height: 50px;
    user-select: none;
    padding: 0 2rem 0 2rem; }
  .selectric .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 55px;
    height: 50px;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
    .selectric .button:after {
      font-size: 3rem;
      content: "";
      margin: 0 2rem;
      display: block;
      transition: all ease 200ms;
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
      @media (min-width: 320px) {
        .selectric .button:after {
          font-size: 2.72727rem; } }
      @media (min-width: 460px) {
        .selectric .button:after {
          font-size: 2.5rem; } }
      @media (min-width: 768px) {
        .selectric .button:after {
          font-size: 2rem; } }
      @media (min-width: 1024px) {
        .selectric .button:after {
          font-size: 1.875rem; } }
      @media (min-width: 1200px) {
        .selectric .button:after {
          font-size: 1.66667rem; } }
      @media (min-width: 1420px) {
        .selectric .button:after {
          font-size: 1.5rem; } }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric .button:after {
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.search-select .selectric-input {
  width: 96% !important;
  height: 40px !important;
  position: static !important;
  background: #fff !important;
  margin: 5px auto !important;
  display: block;
  padding: 0 10px !important;
  border: 1px solid #757575 !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  min-width: inherit; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem; }
  .selectric-items li {
    display: block;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all ease 200ms;
    line-height: 5rem; }
    @media (min-width: 768px) {
      .selectric-items li {
        line-height: 3rem; } }
    .selectric-items li:before {
      display: none; }
  .selectric-items .disabled {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    cursor: default !important;
    background: none !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 1rem;
    cursor: default;
    user-select: none;
    background: none; }
  .selectric-items .selectric-group.disabled li {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100); }
  .selectric-items .selectric-group li {
    padding-left: 2.5rem; }

.list-box .selectric-scroll ul li {
  padding: 1rem 2rem 1rem 1rem; }
  .list-box .selectric-scroll ul li:before {
    content: "";
    width: 12px;
    height: 12px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    display: inline-block;
    margin: 0 1rem 0 0;
    vertical-align: middle;
    position: static; }
  .list-box .selectric-scroll ul li:first-child:before {
    display: none; }

.dtp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  font-size: 14px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .dtp > .dtp-content {
    max-width: 300px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    max-height: 520px;
    position: relative;
    left: 50%; }
    .dtp > .dtp-content > .dtp-date-view .dtp-header {
      text-align: center;
      padding: 0.3em; }
  .dtp div.dtp-date,
  .dtp div.dtp-time {
    text-align: center;
    padding: 10px; }
  .dtp div.dtp-date > div {
    padding: 0;
    margin: 0; }
  .dtp div.dtp-actual-month {
    font-size: 1.5em; }
  .dtp div.dtp-actual-num {
    font-size: 3em;
    line-height: 0.9; }
  .dtp div.dtp-actual-maxtime {
    font-size: 3em;
    line-height: 0.9; }
  .dtp div.dtp-actual-year {
    font-size: 1.5em; }
  .dtp div.dtp-picker {
    padding: 1em;
    text-align: center; }
  .dtp div.dtp-picker-month,
  .dtp div.dtp-actual-time {
    font-weight: 500;
    text-align: center; }
  .dtp div.dtp-picker-month {
    padding-bottom: 20px !important;
    text-transform: uppercase !important; }
  .dtp .dtp-close {
    position: absolute;
    top: 0.5em;
    right: 1em; }
    .dtp .dtp-close > a > i {
      font-size: 1em; }
  .dtp-btn-ok {
    border: none;
    cursor: pointer;
    padding: 2px 5px; }

.dtp table.dtp-picker-days {
  margin: 0;
  min-height: 251px;
  border: none; }
  .dtp table.dtp-picker-days tr {
    border: none; }
    .dtp table.dtp-picker-days tr > td {
      border: none;
      font-weight: 700;
      font-size: 0.8em;
      text-align: center;
      padding: 0.5em 0.3em; }
      .dtp table.dtp-picker-days tr > td > span.dtp-select-day {
        padding: 0.4em 0.5em 0.5em 0.6em; }
      .dtp table.dtp-picker-days tr > td > a {
        text-decoration: none;
        padding: 0.4em 0.5em 0.5em 0.6em;
        border-radius: 50% !important; }
    .dtp table.dtp-picker-days tr > th {
      text-align: center;
      font-weight: 700;
      padding: 0.4em 0.3em; }

.dtp .dtp-picker-time > a {
  text-decoration: none;
  padding: 0.4em 0.5em 0.5em 0.6em;
  border-radius: 50% !important; }

.dtp .p10 {
  width: 10%;
  display: inline-block; }
  .dtp .p10 > a {
    text-decoration: none; }

.dtp .p20 {
  width: 20%;
  display: inline-block; }

.dtp .p60 {
  width: 60%;
  display: inline-block; }

.dtp .p80 {
  width: 80%;
  display: inline-block; }

.dtp a.dtp-meridien-am,
.dtp a.dtp-meridien-pm {
  position: relative;
  top: 10px;
  font-weight: 500;
  padding: 0.7em 0.5em;
  border-radius: 50% !important;
  text-decoration: none;
  font-size: 1em; }

.dtp .dtp-picker-time > .dtp-select-hour,
.dtp .dtp-picker-time > .dtp-select-minute {
  cursor: pointer; }

.dtp .dtp-buttons {
  padding: 0 1em 1em 1em;
  text-align: right; }

.dtp.hidden,
.dtp .hidden {
  display: none; }

.dtp .invisible {
  visibility: hidden; }

.dtp .left {
  float: left; }

.dtp .right {
  float: right; }

.dtp .clearfix {
  clear: both; }

.dtp .center {
  text-align: center; }

.dtp-picker-year {
  margin-bottom: 1px; }

.year-picker-item {
  text-align: center;
  padding-top: 5px;
  font-size: large;
  cursor: pointer;
  margin: 0 auto; }
  .year-picker-item.active {
    font-weight: bold; }

.dtp-actual-year:hover {
  cursor: pointer; }

.dtp-actual-year.disabled:hover {
  cursor: inherit; }

.dtp-select-year-range {
  display: inline-block;
  text-align: center;
  width: 100%;
  padding: 10px 0; }
  .dtp-select-year-range.before span {
    -ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    display: inline-block; }
  .dtp-select-year-range.after span {
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    display: inline-block; }

@media print {
  header,
  footer {
    display: none; } }
