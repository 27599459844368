
main{
  .tenders-table{
    a{
      font-weight: 700;
    }
    table{
      width:100%;
      border-collapse: collapse;
      border-style: hidden;
      thead{
        display: none;
        @include media($respo-table-break){
          display: table-header-group;
        }
        tr{
          border:none;
          th{
            font-weight: 700;
            padding:1rem 0 1.5rem 0;
            @include media($respo-table-break){
              padding:1rem 2.5rem;
            }
            &:nth-child(3),
            &:nth-child(4){
              text-align: right;
            }
          }
        }
      }
      tbody{
        tr{
          display: block;
          border-bottom: none;
          margin:0 0 2rem 0;
          position: relative;
          cursor: pointer;
          transition: all 0.3s ease-out;
          @include media($respo-table-break){
            display: table-row;
            margin: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
          }
          td{
            display: flex;
            justify-content: center;
            width:100%;
            position: relative;
            padding:1.8rem 2.5rem;
            line-height: 1.3;
            text-align: center;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            @include media(sm){
              text-align: right;
              justify-content: space-between;
            }
            @include media($respo-table-break){
              display: table-cell;
              width:auto;
              text-align: left;
              border-bottom: none;
            }
            &:before{
                content:attr(data-th);
                display:none;
                text-align:left;
                font-family:$font-family !important;
                margin:0 1.5rem 0 0;
                line-height: 1.3;
                @include media(sm){
                  display:block;
                }
                @include media($respo-table-break){
                    display:none;
                }
            }
            &:last-child{
              text-align: center;
              justify-content: center;
              border-bottom: none;
              &:before{
                display: none;
              }
            }
            &:nth-child(3),
            &:nth-child(4){
              @include media($respo-table-break){
                text-align: right;
              }
            }
            span{
              display: none;
            }
          }
        }
      }
    }
  }
}

main .second-table{
  .tenders-table{
    table{
      width:100%;
      border-collapse: collapse;
      border-style: hidden;
      thead{
        display: none;
        @include media($respo-table-break){
          display: table-header-group;
        }
        tr{
          th{
            font-weight: 400;
            text-transform: uppercase;
            padding:1rem 0 1.5rem 0;
            @include pux-static-size("font-size", 18px);
            text-align: left !important;
          }
        }
      }
      tbody{
        filter: drop-shadow(0 2rem 7rem rgba(0,0,0,0.07));
        tr{
          display: block;
          border-bottom: none !important;
          border-top: none;
          margin:0 0 2rem 0;
          position: relative;
          cursor: pointer;
          @include media($respo-table-break){
            display: table-row;
            margin: 0;
            border: none;
          }
          &:after{
            content:"";
            width: 100%;
            right:0;
            position: absolute;
            height: 2px;
          }
          &:first-child{
            &:after{
              display: none;
            }
          }
          td{
            @include flex-block;
            @include justify-content(space-between);
            width:100%;
            position: relative;
            padding:2.3rem 2.5rem;
            line-height: 1.3;
            text-align: right;
            border: none;
            @include media($respo-table-break){
              display: table-cell;
              width:auto;
              text-align: left;
            }
            span{
              @include media($respo-table-break){
                position: absolute;
                left:0;
                width: 0.6rem;
                height:3.8rem;
                top:50%;
                margin-top:-1.9rem;
                display: block;
              }
            }
            &:before{
                content:attr(data-th);
                display:block;
                text-align:left;
                font-family:$font-family !important;
                margin:0 1.5rem 0 0;
                line-height: 1.3;
                @include media($respo-table-break){
                    display:none;
                }
            }
            &:after{
              content:"";
              width: 2px;
              top:0;
              right:0;
              position: absolute;
              height: 100%;
            }
            &:last-child{
              text-align: center;
              @include justify-content(center);
              &:before{
                display: none;
              }
              &:after{
                display: none;
              }
            }
          }
        }
      }
    }
    .btn-download{
      font-size: 0;
      &:before{
        content:"\e92b";
        @include pux-static-size("font-size", 24px);
      }
    }
  }
}

.tenders-container{
  display: flex;
  flex-wrap: wrap;
  margin:0 -2rem;
  .download-box{
    width: 100%;
    padding:0 2rem 4rem 2rem;
    @include media(md){
      width: 50%;
    }
    @include media(lg){
      width: 33.33%;
    }
  }
}

.download-item{
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-out;
  font-weight: 400;
  &-ico{
    font-size: 53px;
  }
  &-content{
    padding:0 0 0 15px;
    &-date{
      font-size: 16px;
      @include pux-scale-from("font-size", lg, 16px);
    }
    &-name{
      font-size: 20px;
      @include pux-scale-from("font-size", lg, 20px);
    }
    &-link{
      font-weight: bold;
      text-transform: uppercase;
      a{
        text-transform: none;
      }
    }
  }
  &:focus,
  &:hover{
    .download-item-content-link{
      text-decoration: underline;
    }
  }
}
