

.product-container{
  display: flex;
  flex-wrap: wrap;
  margin:0 -2rem;
  @include media(md){
    min-height: 30rem;
  }
  .product-box{
    width: 100%;
    padding:0 2rem 4rem 2rem;
    display: flex;
    @include media(md){
      width: 50%;
    }
    @include media(lg){
      width: 33.33%;
    }
  }
}

.product-item{
  width: 100%;
  min-height: 240px;
  height: 100%;
  position: relative;
  padding: 100px 6rem 0;
  overflow: hidden;
  font-weight: 400;
  transition: all 0.3s ease-out;
  @include media(md){
    min-height: 30rem;
    padding: 12rem 6rem 0;
  }
  &:before{
    content:"";
    position:absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 22;
  }
  &:hover,
  &:focus{
    text-decoration: none;
    .product-item-inner-btn{
      @include transform(translateY(0));
    }
    .product-item-bgr{
      @include transform(scale(1.1));
    }
  }
  &-bgr{
    background-position: center center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all 0.3s ease-out;
  }
  &-inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 33;
    &-title{
      padding: 1rem 3rem;
      margin: 0;
    }
    &-btn{
      padding: 1.5rem 3rem 2.5rem;
      font-weight: 700;
      @include transform(translateY(1rem));
      transition: all 0.3s ease-out;
    }
  }
  &-labels{
    position: absolute;
    top: 1rem;
    left: 1rem;
    .category-label{
      padding: 2.5px 5px;
      display: inline-block;
      margin: 1px;
    }
  }
}

.category-label{
  padding: 4px 10px;
  display: inline-block;
  margin: 1px;
}

.product-detail-row{
  display: flex;
  flex-wrap: wrap;
  margin:0 -2rem;
  justify-content: center;
  &-item{
    padding:0 2rem 4rem 2rem;
    width: 100%;
    position: relative;
    @include media(md){
      width: 50%;
    }
    &-labels{
      position: absolute;
      z-index: 22;
      top: 1rem;
      left: 3rem;
    }
  }
}

.product-cta{
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .h4{
    margin:0 2rem 2rem 0;
  }
  .btn{
    margin:0 0 2rem 0;
  }
}
