
main{
  ol{
      margin:0;
      padding:0;
      list-style:none;
      counter-reset: my-ol;
      li{
          position:relative;
          margin:0 0 1.5rem 0;
          padding:0 0 0 25px;
          &:before{
              content: counter(my-ol)".";
              counter-increment: my-ol;
              position:absolute;
              left:0;
              top:4px;
              font-weight:bold;
              font-family: $font-family !important;
          }
          ul{
            margin:1.5rem 0 0 0;
          }
      }
      &[start="next"]{
        counter-reset:continue;
        li{
          &:before{
            counter-increment: continue;
          }
        }
      }
      @for $i from 1 through 20 {
        &[start="#{$i}"] {
            counter-reset: my-ol $i ;
        }
    }
  }
  ul{
      margin:0;
      padding:0;
      list-style:none;
      li{
          margin:0 0 1.5rem 0;
          padding:0 0 0 25px;
          position:relative;
          &:before{
              content:"";
              width:6px;
              height:6px;
              position:absolute;
              top: 10px;
              left:0;
          }
          ul{
            margin:1.5rem 0 0 0;
          }
      }
    &.custom-list{
      li{
        &:before{
          display: none;
        }
        &:after{
          content:"";
          width: 16px;
          height: 10px;
          position: absolute;
          left:0;
          top:8px;
        }
      }
    }
  }
}
