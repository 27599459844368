@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?4dcuce');
  src:  url('fonts/icomoon.eot?4dcuce#iefix') format('embedded-opentype'),
    url('fonts/icomoon.woff2?4dcuce') format('woff2'),
    url('fonts/icomoon.ttf?4dcuce') format('truetype'),
    url('fonts/icomoon.woff?4dcuce') format('woff'),
    url('fonts/icomoon.svg?4dcuce#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e9ce";
  color: #004953;
}
.icon-Vector-1:before {
  content: "\e9cd";
}
.icon-Group-6:before {
  content: "\e9cc";
}
.icon-info:before {
  content: "\e9c8";
}
.icon-mail:before {
  content: "\e9c9";
}
.icon-phone:before {
  content: "\e9ca";
}
.icon-pin:before {
  content: "\e9cb";
}
.icon-arrow-go2top:before {
  content: "\e9c7";
  color: #fff;
}
.icon-icon-0:before {
  content: "\e9ab";
}
.icon-icon-1:before {
  content: "\e9ac";
}
.icon-icon-3:before {
  content: "\e9ad";
}
.icon-icon-4:before {
  content: "\e9ae";
}
.icon-icon-101:before {
  content: "\e91d";
}
.icon-icon-11:before {
  content: "\e91f";
}
.icon-icon-12:before {
  content: "\e92f";
}
.icon-icon-13:before {
  content: "\e934";
}
.icon-icon-14:before {
  content: "\e935";
}
.icon-icon-15:before {
  content: "\e936";
}
.icon-icon-2:before {
  content: "\e938";
}
.icon-icon-5:before {
  content: "\e939";
}
.icon-icon-6:before {
  content: "\e9a7";
}
.icon-icon-7:before {
  content: "\e9a8";
}
.icon-icon-8:before {
  content: "\e9a9";
}
.icon-icon-91:before {
  content: "\e9aa";
}
.icon-card-0:before {
  content: "\e904";
}
.icon-card-1:before {
  content: "\e905";
}
.icon-card-10:before {
  content: "\e906";
}
.icon-card-11:before {
  content: "\e907";
}
.icon-card-12:before {
  content: "\e908";
}
.icon-card-13:before {
  content: "\e909";
}
.icon-card-14:before {
  content: "\e90a";
}
.icon-card-2:before {
  content: "\e90b";
}
.icon-card-3:before {
  content: "\e90c";
}
.icon-card-4:before {
  content: "\e90d";
}
.icon-card-5:before {
  content: "\e90e";
}
.icon-card-6:before {
  content: "\e90f";
}
.icon-card-7:before {
  content: "\e91a";
}
.icon-card-8:before {
  content: "\e91b";
}
.icon-card-9:before {
  content: "\e91e";
}
.icon-separator-25:before {
  content: "\e9bf";
}
.icon-separator-17:before {
  content: "\e9af";
}
.icon-separator-8:before {
  content: "\e9b0";
}
.icon-separator-18:before {
  content: "\e9b1";
}
.icon-separator-20:before {
  content: "\e9b2";
}
.icon-separator-21:before {
  content: "\e9b3";
}
.icon-separator-22:before {
  content: "\e9b4";
}
.icon-separator-23:before {
  content: "\e9b5";
}
.icon-separator-24:before {
  content: "\e9b6";
}
.icon-separator-0:before {
  content: "\e921";
}
.icon-separator-1:before {
  content: "\e922";
}
.icon-separator-10:before {
  content: "\e923";
}
.icon-separator-11:before {
  content: "\e924";
}
.icon-separator-12:before {
  content: "\e925";
}
.icon-separator-13:before {
  content: "\e926";
}
.icon-separator-14:before {
  content: "\e927";
}
.icon-separator-15:before {
  content: "\e92b";
}
.icon-separator-16:before {
  content: "\e92d";
}
.icon-separator-19:before {
  content: "\e92e";
}
.icon-separator-2:before {
  content: "\e931";
}
.icon-separator-3:before {
  content: "\e932";
}
.icon-separator-4:before {
  content: "\e933";
}
.icon-separator-5:before {
  content: "\e937";
}
.icon-separator-6:before {
  content: "\e93a";
}
.icon-separator-7:before {
  content: "\e93b";
}
.icon-separator-9:before {
  content: "\e93c";
}
.icon-tooltip .path1:before {
  content: "\e9c4";
  color: rgb(0, 0, 0);
}
.icon-tooltip .path2:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tooltip .path3:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-skype .path1:before {
  content: "\e9c0";
  color: rgb(0, 175, 240);
}
.icon-skype .path2:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-whatsapp .path1:before {
  content: "\e9c2";
  color: rgb(37, 211, 102);
}
.icon-whatsapp .path2:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ico-phone:before {
  content: "\e9be";
}
.icon-sipka_slideshow:before {
  content: "\e919";
}
.icon-sipka_slideshow1:before {
  content: "\e920";
}
.icon-home:before {
  content: "\e918";
}
.icon-krizek_vyhledavani:before {
  content: "\e917";
}
.icon-icon-10:before {
  content: "\e91c";
}
.icon-icon-21:before {
  content: "\e930";
}
.icon-icon-9:before {
  content: "\e93d";
}
.icon-system-1:before {
  content: "\e93e";
}
.icon-system-10:before {
  content: "\e93f";
}
.icon-system-11:before {
  content: "\e940";
}
.icon-system-12:before {
  content: "\e944";
}
.icon-system-13:before {
  content: "\e999";
}
.icon-system-14:before {
  content: "\e99a";
}
.icon-system-15:before {
  content: "\e99b";
}
.icon-system-16:before {
  content: "\e99c";
}
.icon-system-17:before {
  content: "\e99d";
}
.icon-system-2:before {
  content: "\e99e";
}
.icon-system-3:before {
  content: "\e99f";
}
.icon-system-4:before {
  content: "\e9a0";
}
.icon-system-5:before {
  content: "\e9a1";
}
.icon-system-6:before {
  content: "\e9a2";
}
.icon-system-7:before {
  content: "\e9a3";
}
.icon-system-8:before {
  content: "\e9a4";
}
.icon-system-9:before {
  content: "\e9a5";
}
.icon-check:before {
  content: "\e9a6";
}
.icon-mp4 .path1:before {
  content: "\e945";
  color: rgb(179, 179, 179);
}
.icon-mp4 .path2:before {
  content: "\e946";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-mp4 .path3:before {
  content: "\e947";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-mp4 .path4:before {
  content: "\e948";
  margin-left: -0.896484375em;
  color: rgb(27, 20, 100);
}
.icon-mp4 .path5:before {
  content: "\e949";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mp4 .path6:before {
  content: "\e94a";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mp4 .path7:before {
  content: "\e94b";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-png .path1:before {
  content: "\e94c";
  color: rgb(179, 179, 179);
}
.icon-png .path2:before {
  content: "\e94d";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-png .path3:before {
  content: "\e94e";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-png .path4:before {
  content: "\e94f";
  margin-left: -0.896484375em;
  color: rgb(140, 198, 63);
}
.icon-png .path5:before {
  content: "\e950";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-png .path6:before {
  content: "\e951";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-png .path7:before {
  content: "\e952";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path1:before {
  content: "\e953";
  color: rgb(179, 179, 179);
}
.icon-jpg .path2:before {
  content: "\e954";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-jpg .path3:before {
  content: "\e955";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-jpg .path4:before {
  content: "\e956";
  margin-left: -0.896484375em;
  color: rgb(0, 169, 157);
}
.icon-jpg .path5:before {
  content: "\e957";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path6:before {
  content: "\e958";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-jpg .path7:before {
  content: "\e959";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path1:before {
  content: "\e95a";
  color: rgb(179, 179, 179);
}
.icon-pdf .path2:before {
  content: "\e95b";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-pdf .path3:before {
  content: "\e95c";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-pdf .path4:before {
  content: "\e95d";
  margin-left: -0.896484375em;
  color: rgb(227, 6, 19);
}
.icon-pdf .path5:before {
  content: "\e95e";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path6:before {
  content: "\e95f";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path7:before {
  content: "\e960";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mov .path1:before {
  content: "\e961";
  color: rgb(179, 179, 179);
}
.icon-mov .path2:before {
  content: "\e962";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-mov .path3:before {
  content: "\e963";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-mov .path4:before {
  content: "\e964";
  margin-left: -0.896484375em;
  color: rgb(102, 102, 102);
}
.icon-mov .path5:before {
  content: "\e965";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mov .path6:before {
  content: "\e966";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mov .path7:before {
  content: "\e967";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-avi .path1:before {
  content: "\e968";
  color: rgb(179, 179, 179);
}
.icon-avi .path2:before {
  content: "\e969";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-avi .path3:before {
  content: "\e96a";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-avi .path4:before {
  content: "\e96b";
  margin-left: -0.896484375em;
  color: rgb(102, 45, 145);
}
.icon-avi .path5:before {
  content: "\e96c";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-avi .path6:before {
  content: "\e96d";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-avi .path7:before {
  content: "\e96e";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mp3 .path1:before {
  content: "\e96f";
  color: rgb(179, 179, 179);
}
.icon-mp3 .path2:before {
  content: "\e970";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-mp3 .path3:before {
  content: "\e971";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-mp3 .path4:before {
  content: "\e972";
  margin-left: -0.896484375em;
  color: rgb(41, 171, 226);
}
.icon-mp3 .path5:before {
  content: "\e973";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mp3 .path6:before {
  content: "\e974";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-mp3 .path7:before {
  content: "\e975";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-xls .path1:before {
  content: "\e976";
  color: rgb(179, 179, 179);
}
.icon-xls .path2:before {
  content: "\e977";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-xls .path3:before {
  content: "\e978";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-xls .path4:before {
  content: "\e979";
  margin-left: -0.896484375em;
  color: rgb(0, 146, 69);
}
.icon-xls .path5:before {
  content: "\e97a";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-xls .path6:before {
  content: "\e97b";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-xls .path7:before {
  content: "\e97c";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-doc .path1:before {
  content: "\e97d";
  color: rgb(179, 179, 179);
}
.icon-doc .path2:before {
  content: "\e97e";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-doc .path3:before {
  content: "\e97f";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-doc .path4:before {
  content: "\e980";
  margin-left: -0.896484375em;
  color: rgb(0, 113, 188);
}
.icon-doc .path5:before {
  content: "\e981";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-doc .path6:before {
  content: "\e982";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-doc .path7:before {
  content: "\e983";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-docx .path1:before {
  content: "\e9b7";
  color: rgb(179, 179, 179);
}
.icon-docx .path2:before {
  content: "\e9b8";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-docx .path3:before {
  content: "\e9b9";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-docx .path4:before {
  content: "\e9ba";
  margin-left: -0.896484375em;
  color: rgb(0, 113, 188);
}
.icon-docx .path5:before {
  content: "\e9bb";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-docx .path6:before {
  content: "\e9bc";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-docx .path7:before {
  content: "\e9bd";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-ppt .path1:before {
  content: "\e984";
  color: rgb(179, 179, 179);
}
.icon-ppt .path2:before {
  content: "\e985";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-ppt .path3:before {
  content: "\e986";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-ppt .path4:before {
  content: "\e987";
  margin-left: -0.896484375em;
  color: rgb(227, 64, 19);
}
.icon-ppt .path5:before {
  content: "\e988";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-ppt .path6:before {
  content: "\e989";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-ppt .path7:before {
  content: "\e98a";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-rar .path1:before {
  content: "\e98b";
  color: rgb(179, 179, 179);
}
.icon-rar .path2:before {
  content: "\e98c";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-rar .path3:before {
  content: "\e98d";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-rar .path4:before {
  content: "\e98e";
  margin-left: -0.896484375em;
  color: rgb(102, 45, 145);
}
.icon-rar .path5:before {
  content: "\e98f";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-rar .path6:before {
  content: "\e990";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-rar .path7:before {
  content: "\e991";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-zip .path1:before {
  content: "\e992";
  color: rgb(179, 179, 179);
}
.icon-zip .path2:before {
  content: "\e993";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-zip .path3:before {
  content: "\e994";
  margin-left: -0.896484375em;
  color: rgb(179, 179, 179);
}
.icon-zip .path4:before {
  content: "\e995";
  margin-left: -0.896484375em;
  color: rgb(251, 176, 59);
}
.icon-zip .path5:before {
  content: "\e996";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-zip .path6:before {
  content: "\e997";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-zip .path7:before {
  content: "\e998";
  margin-left: -0.896484375em;
  color: rgb(255, 255, 255);
}
.icon-woman:before {
  content: "\e942";
}
.icon-man:before {
  content: "\e943";
}
.icon-Datov-zdroj-1:before {
  content: "\e941";
}
.icon-pristupnost_kontrast_switch:before {
  content: "\e903";
}
.icon-360:before {
  content: "\e900";
}
.icon-arrow-big:before {
  content: "\e901";
}
.icon-arrow-small:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-facebook:before {
  content: "\e911";
}
.icon-linkedin:before {
  content: "\e912";
}
.icon-pinterest:before {
  content: "\e913";
}
.icon-twitter:before {
  content: "\e914";
}
.icon-vimeo:before {
  content: "\e915";
}
.icon-youtube:before {
  content: "\e916";
}
.icon-external-link:before {
  content: "\e92c";
}
.icon-right-quote-sign:before {
  content: "\e928";
}
.icon-timeline-hodiny-zlute:before {
  content: "\e929";
}
.icon-timeline-hodiny-bile:before {
  content: "\e92a";
}
