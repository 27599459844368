
.cta-block{
  width: 100%;
  background-size: cover !important;
  background-position: top center !important;
  display: flex;
  flex-direction: row;
  position: relative;
  &-inner{
    position: relative;
    z-index: 22;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 0;
    min-height: 25rem;
  }
  .pux-container{
    width: 100%;
  }
  &-content{
    max-width: 900px;
    text-align: center;
    margin:0 auto;
    &-btn{
      padding: 3rem 0 0 0;
    }
  }
  &-btn{
    width: 100%;
    text-align: center;
  }
}

.cta-block-big{
  font-size: 16px;
  @include pux-scale-from("font-size", lg, 16px);
  h2,
  h3{
    margin: 0 0 3rem 0;
  }
  &-inner{
    display: flex;
    flex-wrap: wrap;
    margin:0 -2rem;
    &-left,
    &-right{
      width: 100%;
      @include media(lg){
        width: 50%;
        position: relative;
        padding-bottom:70px !important;
      }
    }
    &-right{
      padding:0 2rem;
      @include media(lg){
        padding:0 2rem 0 7rem;
      }
    }
    &-left{
      position: relative;
      padding:0 2rem 40px 2rem;
      @include media(lg){
        padding:0 7rem 0 2rem;
      }
      .cta-block-big-btn{
        @include media(lg){
          text-align: right;
          right:7rem;
        }
      }
      &:after{
        @include media(lg){
          content: "";
          position: absolute;
          top:50%;
          margin-top:-50px;
          height: 100px;
          right: 0;
          border-left-style: dotted;
          border-left-width: 2px;
        }
      }
    }
  }
  &-btn{
    padding: 3rem 0 0 0;
    @include media(lg){
      position: absolute;
      bottom:0;
    }
  }
  .btn{
    min-width: 214px;
  }
}
