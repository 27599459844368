

.cms-bootstrap [class^="icon-"]:before, .cms-bootstrap [class*=" icon-"]:before {
    font-family: 'Core-icons' !important;
}

*:before,
*:after{
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.helper-4k{
  max-width: 200rem;
  margin:auto;
}

.hide{
  display: none !important;
}

.show{
  display:block !important;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.bold{
  font-weight: bold;
}

.underline{
  text-decoration: underline;
}

.line-through{
  text-decoration: line-through;
}

.uppercase{
  text-transform: uppercase;
}

.select-box{
  display: flex;
  justify-content: center;
  margin: 2rem auto 3rem;
  .filter-item{
    padding:0 1rem 2rem 1rem;
    min-width: 50%;
    @include media(sm) {
      width: 240px;
      min-width: auto;
    }
  }
}

.articles-filter {
  flex-wrap: wrap;
  .filter-item {
    padding:0 1rem 2rem 1rem;
    width: 50%;
    &:first-child {
      @include media(xxs,sm) {
        order: 3;
        width: 100%;
      }
    }
    @include media(md) {
      width: 33.3333%;
    }
    @include media(lg) {
      width: 25rem;
    }
  }
}

.insert-image{
  a{
    display: inline-block;
    &:before{
      display: none !important;
    }
  }
}

html {
		font-size: $scale-base-size*$scale-ratio-xxs;
		@include media(xs) {
				font-size: $scale-base-size*$scale-ratio-xs;
		}
		@include media(sm) {
				font-size: $scale-base-size*$scale-ratio-sm;
		}
		@include media(md) {
				font-size: $scale-base-size*$scale-ratio-md;
		}
		@include media(lg) {
				font-size: $scale-base-size*$scale-ratio-lg;
		}
		@include media(xl) {
				font-size: $scale-base-size*$scale-ratio-xl;
		}
		@include media(xxl) {
				font-size: $scale-base-size*$scale-ratio-xxl;
		}
}

@media only screen and( -webkit-min-device-pixel-ratio: 1.25 ),
       only screen and(      -o-min-device-pixel-ratio: 5/4 ),
       only screen and( min-resolution: 120dpi ),
       only screen and( min-resolution: 1.25dppx ) {
		html {
				font-size: $scale-base-size*$scale-ratio-xxs;
				@include media(xs) {
						font-size: $scale-base-size*$scale-ratio-xs;
				}
				@include media(sm) {
						font-size: $scale-base-size*$scale-ratio-sm;
				}
				@include media(md) {
						font-size: $scale-base-size*$scale-ratio-md;
				}
				@include media(lg) {
						font-size: $scale-base-size*$scale-ratio-lg;
				}
				@include media(xl) {
						font-size: $scale-base-size*$scale-ratio-xl*0.8;
				}
				@include media(xxl) {
						font-size: $scale-base-size*$scale-ratio-xxl*0.8;
				}
		}
}

form,
html,
body{
    font-family:$font-family;
    height:100%;
}

body{
 font-size: 18px;
 @include pux-scale-from("font-size", lg, 18px);
 &#big{
   font-size: 20px;
   @include pux-scale-from("font-size", lg, 20px);
 }
}

body{
  &#big{
    .slider-grey-title{
      font-size: 27px;
      @include pux-scale-from("font-size", lg, 30px);
    }
    .btn{
      font-size: 20px;
      @include pux-scale-from("font-size", lg, 20px);
    }
    .cta-block-big{
      font-size: 18px;
      @include pux-scale-from("font-size", lg, 18px);
    }
    .timeline-item-content-perex.collapsed{
      max-height: 122px;
      &.open{
        max-height: 2000px;
      }
    }
    .header .header-menu ul li ul{
      margin-top:-1px;
    }
    .breadcrumbs .home:before,
    .breadcrumbs a,
    .breadcrumbs span,
    .header-intro-subtitle{
      font-size:22px;
      @include pux-scale-from("font-size", xl, 22px);
    }
    h3,
    .h3{
      font-size: 22px;
      @include media(sm){
        font-size: 23px;
      }
      @include media(md){
        font-size: 25px;
      }
      @include pux-scale-from("font-size", xl, 26px);
    }
    footer{
        font-size: 18px;
        @include pux-scale-from("font-size", lg, 18px);
    }
    .important-item-item{
      font-size: 22px;
      @include media(sm) {
        font-size: 24px;
      }
      @include pux-scale-from("font-size", lg, 28px);
    }
    .download-item-content{
      &-date{
        font-size: 18px;
        @include pux-scale-from("font-size", lg, 18px);
      }
      &-name{
        font-size: 22px;
        @include pux-scale-from("font-size", lg, 22px);
      }
    }
    .card-box-big{
      .card-item-big{
        h3{
          font-size: 24px;
          @include media(sm){
            font-size: 26px;
          }
          @include media(lg){
            font-size: 28px;
          }
          @include pux-scale-from("font-size", xl, 30px);
        }
      }
    }
    .card-box-mini{
      .card-item-mini{
        h3{
          font-size: 22px;
          @include media(sm){
            font-size: 22px;
          }
          @include pux-scale-from("font-size", xl, 26px);
        }
      }
    }
    .event-big-single .event-item-content h3{
      @include pux-scale-from("font-size", lg, 34px);
    }
    .event-big-single .event-item-label,
    .event-big-single .event-item-from{
      font-size: 18px;
      @include pux-scale-from("font-size", lg, 18px);
    }
    .collapsible-item-heading h2.h3,
    .collapsible-item-heading .collapsible-item-index{
      @include pux-scale-with-min('font-size', 24px, 24px);
    }
    .events-big .event-big-parent .event-item-content h3,
    .events-big .event-big .event-item-content h3{
      @include pux-scale-from("font-size", lg, 34px);
      @include pux-scale-from("line-height", lg, 40px);
    }
    .EditingFormLabel{
      font-size: 18px;
      @include pux-scale-from("font-size", lg, 18px);
    }
    .radio input[type="radio"] + label{
      font-size: 17px;
      @include pux-scale-from("font-size", lg, 17px);
    }
    .wizard-step-subtitle{
      @include pux-scale-from("font-size", lg, 20px);
    }
  }
}

footer,
main{
  img{
      max-width:100%;
      height:auto !important;
  }
}

@each $value in $px-values {
  .pt-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("padding-top", $value, 20px, 1);
    } @else{
      @include pux-scale-with-min("padding-top", $value, $value, 1);
    }
  }

  .pb-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("padding-bottom", $value, 20px, 1);
    } @else{
       @include pux-scale-with-min("padding-bottom", $value, $value, 1);
     }
  }

  .mt-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("margin-top", $value, 20px, 1);
    } @else{
      @include pux-scale-with-min("margin-top", $value, $value, 1);
    }
  }

  .mb-#{strip-unit($value)} {
    @if(strip-unit($value) >= 20){
			@include pux-scale-with-min("margin-bottom", $value, 20px, 1);
    } @else{
      @include pux-scale-with-min("margin-bottom", $value, $value, 1);
    }
  }
}

.image-top-center{
  position: top center !important;
}

.image-top-left{
  position: top left !important;
}

.image-top-right{
  position: top right !important;
}

.image-center-center{
  position: center center !important;
}

.image-bottom-center{
  position: bottom center !important;
}

.image-bottom-left{
  position: bottom left !important;
}

.image-bottom-right{
  position: bottom right !important;
}

main{
    min-height:40rem;
}

.small-container{
  a{
    font-weight: 700;
  }
}


.color-box-one,
.color-box-two,
.color-box-three{
  padding: 20px;
  margin: 15px auto;
  max-width: 780px;
}

.widget-container .widget-title{
  &.color-box-one,
  &.color-box-two,
  &.color-box-three{
    margin: 0 auto 5rem auto;
  }
}

iframe[src*="youtube"]{
  aspect-ratio: 16 / 9;
  width: 100%;
}

.text-widget,
.event-detail{
  font-size: 2rem;
}

#big{
  .text-widget,
  .event-detail{
    font-size: 2.2rem;
  }
}