
.icons-container{
  max-width: 1100px;
  margin:0 auto;
  .ico-card-box{
    width: 100%;
    padding:0 2rem 4rem 2rem;
    @include media(md){
      width: 27%;
    }
    &.box-half{
      @include media(md){
        width: 36%;
      }
    }
    &.box-single{
      @include media(md){
        width: 100%;
        max-width: 500px;
      }
    }
  }
  .ico-card-box-small{
    width: 100%;
    padding:2rem;
    @include media(md){
      width: 27%;
    }
    &.box-half{
      @include media(md){
        width: 36%;
      }
    }
    &.box-single{
      @include media(md){
        width: 100%;
        max-width: 500px;
      }
    }
  }
}

.ico-card-item{
  &-icon{
    padding:0 0 1.5rem 0;
    &:before{
      font-size: 53px;
    }
  }
  .h3{
    margin:0 0 2rem 0;
  }
}

.ico-card-item-small{
  display: flex;
  align-items: center;
  &-icon{
    margin:0 1.4rem 0 0;
    &:before{
      font-size: 51px;
    }
  }
  .h3{
    margin:0;
  }
}

.icons-container{
  .line-helper{
    display: none;
    @include media(md){
      display: block;
    }
  }
}
