
.header-cross{
  border-bottom-width: 5px;
  border-bottom-style: solid;
  display: none;
  .pux-container{
    @include media(xxs, $scross-break){
      padding-left:0;
      padding-right: 0;
    }
  }
  &-inner{
    display: flex;
    margin:0 0 -5px 0;
  }
  &-link{
    min-height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    position: relative;
    transition: all 0.3s ease-out;
    font-size: 16px;
    text-align: center;
    &:after{
      content: "";
      width: 0;
      height: 0;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-bottom-style: solid;
      border-bottom-width: 12px;
      position: absolute;
      bottom: 0;
      left:50%;
      margin-left:-14px;
    }
    &.style-home{
      font-size: 0;
      width: 65px;
      min-width: 65px;
      flex-grow: 0;
      &:before{
        content:"\e918";
        font-size:25px;
      }
      &:after{
        display: none;
      }
    }
  }
  &.cross-3{
    @include media(md){
      display: block;
    }
  }
  &.cross-4{
    @include media(lg){
      display: block;
    }
  }
  &.cross-5{
    @include media(lg){
      display: block;
    }
  }
  &.cross-6{
    @include media(xl){
      display: block;
    }
    .pux-container{
      max-width: 100%;
      padding-left:0;
      padding-right: 0;
    }
  }
  &.cross-7{
    @include media(xxl){
      display: block;
    }
    .pux-container{
      max-width: 100%;
      padding-left:0;
      padding-right: 0;
    }
  }
}

.header-select{
  display: block;
  .selectric-wrapper{
    &:hover{
      box-shadow: none;
    }
    .selectric-items{
      box-shadow: none;
    }
    .selectric .label{
      @include media(md){
        height: 65px;
        line-height: 65px;
        padding: 0 3rem;
      }
    }
  }
  &.cross-3{
    @include media(md){
      display: none;
    }
  }
  &.cross-4{
    @include media(lg){
      display: none;
    }
  }
  &.cross-5{
    @include media(lg){
      display: none;
    }
  }
  &.cross-6{
    @include media(xl){
      display: none;
    }
  }
  &.cross-7{
    @include media(xxl){
      display: none;
    }
  }
}

.cross-helper{
  .header-spacer{
    height: 110px;
    @include media(md){
      height: 125px;
    }
    &.spacer-big{
      @include media($menu-break){
        height: 175px;
      }
    }
  }

}

.scrolled{
  &.down{
    .cross-helper{
      header.header-big{
        top:-50px;
        @include media(md){
          top:-65px;
        }
        @include media($menu-break){
          top:-115px;
        }
        .header-logo{
          height: 60px;
        }
      }
      .header-spacer.spacer-big {
        @include media($menu-break){
          //height: 160px;
        }
      }
    }
  }
}

.cross-cards{
  display: flex;
  flex-wrap: wrap;
}

.cross-card-item{
  position: relative;
  width: 100%;
  flex-grow: 1;
  @include media(md){
    width: 50%;
  }
  &-image{
    width: 100%;
    background-size: cover !important;
    background-position: top center !important;
    display: flex;
    flex-direction: row;
    &:before{
      content:"";
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      transition: all 0.3s ease-out;
    }
    &:after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      transition: all 0.3s ease-out;
    }
  }
  h2{
    margin:0 0 2.5rem 0;
  }
  &-inner{
    display: flex;
    align-items: center;
    height: 300px;
    transition: opacity ease-out .5s;
    opacity: 0;
    position: relative;
    z-index: 33;
    @include media(sm){
      height: 348px;
    }
    @include media(md){
      height: 548px;
    }
    &.set {
      opacity: 1;
    }
    &.text-shadow{
      h2{
        text-shadow: 1px 1px 10px black, 1px 1px 20px black;
      }
    }
  }
  &-bottom{
    display: flex;
    flex-direction: row;
    &-inner{
      display: flex;
      align-items: center;
      height: 90px;
      transition: opacity ease-out .5s;
      opacity: 0;
      @include media(sm){
        height: 120px;
      }
      @include media(md){
        height: 140px;
      }
      &.set {
        opacity: 1;
      }
    }
  }
}

.main-crosslink{
  width: 100%;
  min-height: 832px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  //background-size: cover !important;
  background-color: #f6dfcc;
  background-repeat: no-repeat !important;
  background-position: top left !important;
  ///background-size: 120rem auto !important;
  position: relative;
  @include media(lg){
    background-position: top right !important;
  }
  @media (min-width: 1921px){
    background-size: cover !important;
  }
  .main-crosslink-description{
    border-radius: 30px;
    padding: 2.5rem;
    background: #F8BB91;
    font-weight: 700;
    font-family: 'Amatic SC', cursive;
    font-size: 5rem;
    line-height: 5.2rem;
    max-width: 440px;
    color: #000;
    position: absolute;
    top: 3rem;
  }
  &-background{
    background-size: cover !important;
    background-position: top center !important;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: none;
    @include media(md){
      display: block;
    }
  }
  &-image{
    display: block;
    width: 100%;
    @include media(md){
      display: none;
    }
  }
  &-content{
    width:100%;
    position: relative;
    z-index: 33;
    &-description{
      color:#fff;
      padding: 5rem 0 3rem 0;
      background: rgba(#524a64, 1);
      @include media(md){
        background: rgba(#524a64, 0.85);
      }
      h1,
      h2{
        margin:0 0 5rem 0;
        color:#fff;
        font-family: 'Amatic SC', cursive;
      }
      h1{
        @include pux-scale-with-min("font-size", 60px, 35px);
      }
    }
  }
  &-hotline{
    position: absolute;
    top:4rem;
    left:0;
    width: 100%;
    z-index: 44;
  }
  .pux-container{
    position: relative;
  }
  .main-crosslink-logo{
    position: absolute;
    left: 3.7rem;
    top: 26rem;
    max-width: 215px;
    @include media(lg){
      //left: 6.7rem;
    }
    @include media(xl){
      //left: 11.7rem;
    }
    img{
      max-width: 21.5rem;
    }
  }
  .main-crosslink-logo-text{
    font-size: 14px;
    color: grey;
    padding: 0 0 1rem 0;
  }
}

.main-crosslink-hotline{
  &-inner{
    display: inline-block;
    @include media(md){
      margin: 0 50px 0 0;
    }
    @media (min-width: 1630px) {
      margin: 0;
    }
  }
  &-title{
    text-transform: uppercase;
    text-align: center;
    margin: 0 0 -1.5rem 0;
  }
  &-phone{
    a{
      font-weight: 700;
      font-family: 'Amatic SC', cursive;
      @include pux-scale-with-min("font-size", 60px, 50px);
      display: inline-flex;
      align-items: center;
      letter-spacing: -1.5px;
      &:before{
        content:"\e9be";
        margin:0 10px 0 0;
      }
    }
  }
}

.main-crosslink.inner-crosslink{
  .main-corsslink-box{
    border-bottom-width: 1px;
    border-bottom-style: solid;
    @include media(md){
      border-bottom: none;
    }
    &:last-child{
      border:none;
    }
  }
}

.main-crosslink-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin:0 -20px;
  @include media(sm){
    margin:0 -25px;
  }
  @include media(md){
    margin:0 -25px;
  }
  @include media(md){
    margin:0 -6px;
    padding:38rem 0 6rem 0;
  }
  .main-corsslink-box{
    width: 100%;
    min-width: 100%;
    display: flex;
    @include media(md){
      width: 33.33%;
      min-width: 33.33%;
      padding:0 6px 30px 6px;
    }
    @include media(xxl){
      width: 20%;
      min-width: 20%;
    }
  }
  &.two-cross{
    .main-corsslink-box{
      @include media(md){
        width: 50%;
        min-width: 50%;
        padding: 0 2rem;
      }
      @include media(xxl){
        width: 40%;
        min-width: 40%;
        padding: 0 5rem;
      }
    }
  }
}

.main-crosslink-item{
  width: 100%;
  padding:5rem 4rem;
  display: block;
  position: relative;
  transition: all 0.3s ease-out;
  text-align: center;
  text-decoration: none !important;
  @include media(md){
    border-radius: 3rem;
    padding: 3rem;
  }
  /*&:before{
    @include media(md){
      content:"";
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom-width: 17px;
      border-bottom-style: solid;
      position: absolute;
      top:-17px;
      left:50%;
      margin-left:-18px;
      transition: all 0.3s ease-out;
    }
  }*/
  &-name{
    padding:0 0 2rem 0;
    font-size: 16px;
    max-width: 89%;
    margin: 0 auto;
  }
  &-text{
    padding:0 0 2.5rem 0;
    font-family: 'Amatic SC', cursive;
    font-size: 35px;
    text-transform: uppercase;
    letter-spacing: -0.9px;
    line-height: 1.2;
    font-weight: 700;
    @include media(md){
      padding:0 0 65px 0;
    }
  }
  &-btn{
    @include media(md){
      position: absolute;
      bottom: 3rem;
      left:0;
      width: 100%;
    }
  }
}

.two-cross{
  .main-crosslink-item{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include media(sm){
      justify-content: space-between;
    }
  }
  .main-crosslink-item-text{
    @include pux-scale-with-min("font-size", 55px, 35px);
    padding:0 10px 20px 10px;
    @include media(sm){
      padding:0 10px 0 10px;
    }
  }
  .main-crosslink-item-btn{
    position: static;
    width: 100%;
    padding:0 10px 0 10px;
    @include media(sm){
      width: auto;
    }
  }
}

.title-box{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.5rem;
  align-items: center;
  padding: 0 0 2rem 0;
  justify-content: center;
  &-btn{
    padding:0 1.5rem 3rem 1.5rem;
  }
  &-title{
    padding:0 1.5rem 3rem 1.5rem;
    h1,
    h2{
      margin: 0;
    }
  }
}

.btn-covid{
  background: #fff !important;
  color: #000 !important;
  padding: 1.1rem 2rem;
  &:before{
    content:"\e9bf";
    margin:0 10px 0 0;
    color:#FF0000;
  }
  &:focus,
  &:hover{
    background: darken(#fff, 10) !important;
  }
}
