
.timeline-box{
  padding: 13rem 0 22rem;
  margin: auto;
  max-width: 830px;
  position: relative;
  @include media(lg){
    padding: 12rem 0 8rem;
  }
  &:before{
    content: "";
    width: 4px;
    top: 4rem;
    bottom: 16rem;
    left: 18px;
    position: absolute;
    @include media(lg){
      left: 50%;
      @include transform(translateX(-50%));
    }
  }
  &:after{
    content: "";
    width: 4px;
    height: 200px;
    bottom: 6rem;
    left: 18px;
    position: absolute;
    z-index: 1;
    @include media(lg){
      left: 50%;
      @include transform(translateX(-50%));
      height: 300px;
    }
  }
  &-start,
  &-end{
    width: 6rem;
    height: 6rem;
    @include border-radius(50%);
    position: absolute;
    left: 20px;
    @include transform(translateX(-50%));
    @include flex-block;
    @include align-items(center);
    @include justify-content(center);
    @include media(lg){
      left: 50%;
      width: 50px;
      height: 50px;
    }
  }
  &-start{
    top: 1rem;
    &:after{
      content: "\e92a";
      font-size: 3rem;
      @include transform(translateX(-1px));
      @include media(lg){
        font-size: 30px;
        @include transform(translateX(-1.5px));
      }
    }
  }
  &-end{
    bottom: 1rem;
    &:after{
      content: "\e929";
      font-size: 3rem;
      @include transform(translateX(1px));
      @include media(lg){
        font-size: 30px;
        @include transform(translateX(2.5px));
      }
    }
  }
}

.timeline-item{
  @include flex-block;
  @include flex-wrap(wrap);
  padding: 3rem 0;
  &-image{
    width: 100%;
    padding-left: 6.5rem;
    @include media(lg){
      width: 50%;
      order: 2;
    }
    &-wrapper{
      height: auto;
      width: 100%;
      position: relative;
      overflow: hidden;
      max-height: 245px;
    }
  }
  &-content{
    width: 100%;
    padding-left: 6.5rem;
    position: relative;
    @include media(lg){
      width: 50%;
      order: 1;
      padding-right: 6.5rem;
      padding-left: 0;
      text-align: right;
    }
    &-title.h3{
      padding-top: 2rem;
      padding-bottom: 3rem;
      margin: 0;
      @include media(lg){
        padding-top: 1rem;
        padding-bottom: 2rem;
      }
    }
    &:after{
      content: "";
      position: absolute;
      border: 5px solid #ffffff;
      @include border-radius(50%);
      width: 30px;
      height: 30px;
      left: 18px;
      top: 2rem;
      z-index: 2;
      @include transform(translate(-13px, -5px));
      @include media(lg){
        right: 0;
        top: 1rem;
        left: auto;
        @include transform(translate(50%, 0px));
      }
    }
    &-perex{
      @include transition(500ms);
      &.collapsed{
        max-height: 131px;
        overflow: hidden;
      }
      &.collapsed.open{
        max-height: 2000px;
      }
    }
    &-btn{
      padding-top: 1.5rem;
      @include flex-block;
      @include align-items(center);
      @include justify-content(flex-end);
      cursor: pointer;
      font-weight: 600;
      &.hidden{
        display: none;
      }
      &:after{
        content: "\e901";
        font-size: 4.5px;
        margin-left: 5px;
        @include transition;
      }
      &.open{
        &:after{
          @include rotate(180deg);
        }
      }
    }
  }
  &.reverse{
    .timeline-item-image{
      order: 1;
      @include media(lg){
        padding-left: 0;
        padding-right: 6.5rem;
        text-align: left;
      }
    }
    .timeline-item-content{
      order: 2;
      @include media(lg){
        padding-left: 6.5rem;
        padding-right: 0;
        text-align: left;
      }
      &:after{
        @include media(lg){
          right: auto;
          top: 1rem;
          left: 0;
          @include transform(translate(-50%, 0px));
        }
      }
      &-btn{
        justify-content: flex-start;
      }
    }
  }
}
