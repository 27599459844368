
.row.gallery-widget-row{
  margin-left: -2rem;
  margin-right: -2rem;
  [class^="col"]{
    padding: 0 2rem 4rem 2rem;
  }
  .jpg{
    &:before{
      display: none;
    }
  }
}

.detail-photo{
  position: relative;
  height: 30rem;
  width: 100%;
  overflow: hidden;
  &-bgr{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    transition: all 0.3s ease-out;
  }
  &:focus,
  &:hover{
    .detail-photo-bgr{
      @include transform(scale(1.10));
    }
  }
}

.detail-description{
  padding:5px 0 0 0;
}

.widget-container{
  .gallery-title{
    display: flex;
    align-items: center;
    position: relative;
    padding:0 0 2rem 0;
    .widget-title{
      text-transform: none;
      margin:0 2rem 0 0;
    }
    &-numbers{
      padding: 2rem 0 2rem 0;
      @include pux-scale-with-min("line-height", 35px, 22px);
      @include pux-scale-with-min("font-size", 22px, 15px);
      font-weight: 700;
    }
    &:before{
      content:"";
      @include pux-scale-with-min("width", 8px, 4px);
      @include pux-scale-with-min("height", 35px, 22px);
      margin:0 15px 0 0;
    }
  }
}

.gallery-masonry{
  display: flex;
  flex-wrap: wrap;
  margin:0 -5px 2rem -5px;
  .gallery-box{
    padding:0 5px 10px 5px;
    width: 100%;
    @include media(sm){
      width: 50%;
    }
    @include media(md){
      width: 33.33%;
    }
    &.grid-sizer{
      padding: 0;
    }
  }
}
