
.slider-container{
  position: relative;
  @include media(lg){
    min-height: 690px;
  }
  .slider-founder{
    display: none;
  }
}

.slider-container{
  .main-slider-content{
    .slider-founder{
      display: block;
    }
  }
  .slider-founder{
    padding: 2rem 0 0 0;
    &-title{
      padding:0 0 10px 0;
    }
  }
  &.no-overlay{
    .main-slider-bgc:before{
      display: none;
    }
  }
}

.main-slider{
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  min-height: 455px;
  @include media(lg){
    position: static;
    min-height: 690px;
  }
  h2{
    margin: 0;
  }
  h3{
    margin-bottom: 0;
  }
  &-bgc{
    width: 100%;
    background-position: center !important;
    background-size: cover !important;
    position: absolute;
    top:0;
    left:0;
    z-index: 11;
    min-height: 455px;
    @include media(lg){
      min-height: 690px;
    }
    &:before{
      content:"";
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(90deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0) 100%);
      @include media(md) {
        background-image: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
      }
    }
  }
  &-inner{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 455px;
    height: 455px;
    @include media(lg) {
      min-height: 550px;
      height: 550px;
    }
  }
  &-content{
    position: relative;
    z-index: 77;
    margin-top: 6rem;
    @include media(lg){
      max-width: 640px;
    }
    @include media(xl){
      max-width: 760px;
    }
    @include media(xxl){
      max-width: 900px;
    }
    &-btn{
      padding: 4rem 0 0 0;
      @include media(md) {
        padding: 2rem 0 0 0;
      }
    }
    img {
      @include media(xxs, sm) {
        max-width: 60%;
        margin-bottom: 8rem;
      }
    }
  }
  .pux-container{
    width:100%;
  }
}

.slider-service-static,
.slider-service{
  padding: 3rem 0 0 0;
  @include media(lg){
    position: absolute;
    left:0;
    bottom: 0;
    height: 140px;
    z-index: 66;
    width: 100%;
    display: flex;
    align-items: center;
    padding:0;
  }
  .pux-container{
    width: 100%;
  }
  .dynamic-box{
    padding:0 2rem 3rem 2rem;
    width: 100%;
    @include media(lg){
      width: 27%;
      padding:0 2rem;
    }
  }
}

.slider-service-static{
  position: static;
}

.slider-service-static,
.slider-service{
  .line-helper{
    display: none;
    @include media(lg){
      display: block;
    }
  }
}

.slider-grey{
  position: relative;
  .pux-container{
    position: relative;
    @include media(lg){
      display: flex;
      justify-content: flex-end;
    }
  }
  &-box{
    padding: 0 20px 4rem 20px;
    width: 100%;
    @include media(md){
      width: 50%;
    }
    @include media(lg){
      width: 100%;
      padding:0;
    }
    &:nth-child(4){
      display: block;
      @include media(lg){
        display: none;
      }
    }
  }
  &-inner{
    position: relative;
    z-index: 77;
    display: flex;
    flex-wrap: wrap;
    margin:0 -20px;
    padding:40px 0 0 0;
    @include media(lg){
      display: block;
      padding:5rem 4rem 5rem 4rem;
      width: 380px;
      margin: 0;
      min-height: 550px;
      height: 550px;
      width: 400px;
      position: absolute;
      z-index: 88;
      bottom: 100%;
      margin-bottom: 140px;
      right: 0;
    }
    @include media(xl){
      width: 400px;
    }
    @include media(xxl){
      width: 400px;
      //right: auto;
    }
    .btn{
      align-self: baseline;
    }
  }
  &-icon{
    width: 100px;
    height: 100px;
    border-radius: 50px 50px 0 0;
    z-index: 66;
    position: absolute;
    left: 50%;
    margin-left:-50px;
    top:-50px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before{
      content:"\e909";
      display: block;
      font-size: 40px;
    }
    @include media(lg){
      display: none;
    }
  }
  &-title{
    font-weight: 700;
    font-size: 25px;
    text-align: center;
    padding:0 0 4rem 0;
    width: 100%;
    @include media(md){
      font-size: 32px;
    }
    @include media(lg){
      &:before{
        content:"\e999";
        display: block;
        font-size: 53px;
      }
    }
    @include pux-scale-from("font-size", lg, 28px);
    &.no-icon{
      padding:0 0 2.5rem 0;
      &:before{
        display: none;
      }
    }
  }
}

.slider-grey-item{
  display: block;
  text-decoration: none !important;
  @include media(lg){
    margin:0 0 3rem 0;
  }
  .h3{
    margin: 0 0 5px 0;
  }
  &-date{
    font-weight: 700;
    padding:0 0 6px 0;
  }
  &-link{
    font-weight: 700;
  }
  &:focus,
  &:hover{
    .link{
      &:after{
        right:-5px;
      }
    }
  }
  .important-item-label{
    display: none;
  }
}

.main-slider-arrows{
  display: none;
}

.only-slider{
  .main-slider-content{
    @include media(lg){
      margin: 0 110px;
      max-width: 1050px !important;
    }
  }
  .pux-container{
    position: relative;
  }
  .main-slider-arrows{
    display: flex;
    position: absolute;
    bottom: 50px;
    right: 25px;
    z-index: 777;
    @include media(md){
      bottom:0;
    }
    @include media(lg){
      position: static;
      display: block;
    }
  }
}

.slick-arrow{
  z-index: 55;
  top:50%;
  font-size: 0;
  border:none;
  background: none;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  outline: 0 !important;
  cursor: pointer;
  @include media(lg){
    position: absolute;
    margin-top:-50px;
    height: 100px;
    width: 50px;
  }
  &:before{
    font-size: 50px;
    @include media(lg){
      font-size: 100px;
    }
  }
  &.slick-prev{
    left:38px;
    margin:0 4rem 0 0;
    @include media(lg){
      margin:-50px 0 0 0;
    }
    &:before{
      content:"\e920";
    }
  }
  &.slick-next{
    right:38px;
    &:before{
      content:"\e919";
    }
  }
}

body{
  &.contrast{
    .main-slider-bgc:before{
      background-image: linear-gradient(90deg, rgba(0,0,0,0.80) 0%, rgba(0,0,0,0) 100%);
    }
  }
}


.slider-container.no-services{
  @include media(lg){
    min-height: 550px;
  }
  .main-slider{
    @include media(lg){
      min-height: 550px;
    }
    &-bgc{
      @include media(lg){
        min-height: 550px;
      }
    }
  }
  .slider-grey-inner{
    @include media(lg){
      margin-bottom: 0;
    }
  }
}

.slider-container.more-services{
  .slider-service{
    bottom:140px;
  }
}

.slider-container.slider-two{
  .main-slider h2,
  .h1,
  h1{
    display:inline;
    padding: 10px 0;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    margin: 0 0 0 15px;
    line-height: 38px;
    @include media(sm){
      line-height: 40px;
    }
    @include media(md){
      line-height: 48px;
    }
    @include media(xl){
      line-height: 75px;
    }
    @include pux-scale-from("font-size", xl, 58px);
  }
  .main-slider-content h3{
    display:inline;
    padding: 10px 0;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    margin: 0 0 0 15px;
    line-height: 30px;
    @include media(sm){
      line-height: 31px;
    }
    @include media(md){
      line-height: 33px;
    }
    @include media(xl){
      line-height: 34px;
    }
  }
  .slider-grey-item .important-item-label{
    display: inline-block;
  }
  .slider-grey-title:before{
    display: none;
  }
  .slider-grey-inner{
    height: 510px;
    min-height: 510px;
    transform: translateY(-20px);
  }
}

.btn-order{
  .main-slider-content{
    display: flex;
    flex-direction: column;
  }
  .main-slider-content-btn{
    order: 3;
  }
  .slider-founder{
    order: 2;
  }
}

.slider-container.small-important{
  .slider-grey-inner{
    @include media(lg){
      width: 345px;
      margin-bottom: 100px;
      height: 590px;
      min-height: 590px;
    }
  }
  .slider-service{
    @include media(lg){
      height: 100px;
    } 
  }
  .line-helper,
  .line-helper:before{
    height: 80px;
  }
}

.slider-container.big-font{
  h1,
  h2{
    font-size: 28px;
    @include media(sm){
      font-size: 34px;
    }
    @include media(md){
      font-size: 42px;
    }
    @include pux-scale-from("font-size", xl, 60px);
  }
  .main-slider-content{
    color: #fff;
    font-size: 26px;
    line-height: 30px;
  }
  .slider-founder{
    padding: 1rem 0 0 0;  
  }
}

.slider-container.small-space{
  .main-slider h1, .main-slider h2, .main-slider h3{
    margin: 0 0 1rem 0;
  }
  .slider-founder{
    padding: 0;  
    margin: 1rem 0 0 0;
  }
}