
@import "../_mixins/pux/breakpoints.scss";
@import "../_mixins/pux/css-rules.scss";
@import "../_mixins/pux/scale.scss";
@import "../_variables.scss";



$modal-overlay-background: #000;
$modal-max-width: 800px;
$modal-margin: 14rem auto 0 auto;

$modal-base-radius: 0;
$modal-close-background: darken(grey,15);
$modal-close-color: red;

$modal-light-background: #fff;
$modal-light-color: #000;
$modal-light-border: none;
$modal-light-close-background: darken(grey,15);
$modal-light-close-color: red;

$modal-dark-background: #585857;
$modal-dark-color: #fff;
$modal-dark-border: none;
$modal-dark-close-background: grey;
$modal-dark-close-color: red;


.pux-modal-leave,
.pux-modal{
  z-index: 99999;
  .modal-dialog{
    margin: $modal-margin;
    max-width: $modal-max-width;
  }
  .modal-content{
    @include border-radius($modal-base-radius);
    background-repeat: no-repeat !important;
    border:none;
    text-shadow:none;
    .close{
      outline: 0;
      position:absolute;
      right:0;
      top:0;
      width:30px;
      height:30px;
      font-size: 30px;
      cursor:pointer;
      z-index:22;
      @include opacity(1);
    }
    &.modal-light{
      background-color: $modal-light-background;
      color: $modal-light-color;
      border: $modal-light-border;
      .close{
        background: $modal-light-close-background;
        color: $modal-light-close-color;
        &:focus,
        &:hover{
          background: darken($modal-light-close-background,10);
        }
      }
    }
    &.modal-dark{
      background-color: $modal-dark-background;
      color: $modal-dark-color;
      border: $modal-dark-border;
      .close{
        background: $modal-dark-close-background;
        color: $modal-dark-close-color;
        &:focus,
        &:hover{
          background: darken($modal-dark-close-background,10);
        }
      }
    }
    .modal-body{
      padding: 30px;
      &-title{
        @include font-size(22px);
        padding:0 0 15px 0;
        font-weight:bold;
      }
      &-subtitle{
        @include font-size(18px);
        padding:0 0 10px 0;
      }
      &-content{

      }
      &.no-padding{
        padding:0;
      }
    }
    &.background-cover{
      background-size: cover !important;
    }
    &.top-center{
      background-position: top center !important;
    }
    &.top-left{
      background-position: top left !important;
    }
    &.top-right{
      background-position: top right !important;
    }
    &.center-center{
      background-position: center center !important;
    }
    &.bottom-center{
      background-position: bottom center !important;
    }
    &.bottom-left{
      background-position: bottom left !important;
    }
    &.bottom-right{
      background-position: bottom right !important;
    }
  }
}

.modal-backdrop{
  background: $modal-overlay-background;
  z-index: 9999;
}
