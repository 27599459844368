
.header-spacer{
  height: 60px;
  &.spacer-big{
    @include media($menu-break){
      height: 110px;
    }
  }
}

header{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  position: relative;
  z-index: 999;
  position: fixed;
  width: 100%;
  top:0;
  left:0;
  transition: 250ms;
  .header{
    display: flex;
    justify-content: space-between;
    height: 60px;
    @include media($menu-break){
      height: 70px;
    }
    &-logo{
      display: flex;
      align-items: center;
      transition: 250ms;
      align-self: flex-end;
      height: 100%;
      @include media($menu-break){
        display: none;
      }
      @include media(xl){
        display: flex;
        align-items: center;
      }
      a{
        display: flex;
        span{
          display:none;
        }
        img{
          max-width: none;
          transition: 250ms;
          height: 45px;
          @include media(xxs, $menu-break){
            height: 45px !important;
          }
        }
      }
      .header-logo-helper{
        display: flex;
        a{
          display: block;
        }
      }
      &.header-logos{
        a img{
          @include media(sm, md){
            height: 45px !important;
          }
          @include media(xs, sm){
            height: 45px !important;
          }
          @include media(xxs, xs){
            height: 45px !important;
          }
        }
      }
    }
    .header-delimiter{
      margin: 0 20px;
      width: 1px;
      background: #000;
      @include media(xxs, md){
        margin: 0 10px;
      }
    }
    &-menu{
      width: 100%;
      @include media(md){
        display:none;
        position: absolute;
        top:100%;
        z-index: 77;
        left:0;
        overflow: auto;
        @include calc("max-height", "100vh - 60px");
      }
      @include media($menu-break){
        display: flex;
        position: static;
        width:auto;
        overflow: visible;
      }
      ul{
        margin:0;
        padding:0;
        list-style: none;
        width: 100%;
        @include media($menu-break){
          display: flex;
          width: auto;
        }
        li{
          display: block;
          position: relative;
          margin:0 0 10px 0;
          @include media($menu-break){
            margin:0 2rem 0 0;
            display: flex;
          }
          span,
          a{
            display: flex;
            align-items: center;
            padding:5px 20px;
            font-weight: 700;
            font-size: 18px;
            min-height: 40px;
            @include media($menu-break){
              padding:0 0.5rem;
              height: auto;
            }
            &:focus,
            &:hover{
              text-decoration: none;
            }
          }
          ul{
            display: none;
            width:100%;
            @include opacity(1);
            visibility: visible;
            @include transition;
            @include media($menu-break){
              position: absolute;
              width:270px;
              visibility: hidden;
              left:0;
              @include opacity(0);
              display: block;
              top:100%;
              max-height: 490px;
              overflow-y: auto;
              box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
            }
            &::-webkit-scrollbar {
                width: 10px;
            }
            &.visible{
              max-height: 100vh;
              overflow-y: visible;
            }
            li{
              margin:0;
              border-bottom-width: 1px;
              border-bottom-style: solid;
              line-height: 1;
              position: relative;
              span,
              a{
                font-weight: 400;
                padding:5px 30px;
                @include media($menu-break){
                  padding:1.5rem 3.5rem;
                  display: flex;
                  cursor: pointer;
                  align-items: center;
                  min-height: 6rem;
                  width: 100%;
                  font-weight: 700;
                }
                &:focus,
                &:hover{

                }
              }
              a, span{
                order: 1;
              }
              span.menu-openner{
                order: 2;
                margin: 0;
                padding: 0 10px;
                &:before{
                  @include media($menu-break){
                    transform: rotate(90deg);
                  }
                }
              }
              &:last-child{
                border:none;
              }
              ul{
                border-top-width: 1px;
                border-top-style: solid;
                @include media($menu-break){
                  position: absolute;
                  left:100%;
                  top:0;
                  box-shadow: none;
                  width: 270px;
                  border-top: none;
                  display: none;
                }
                li{
                  span,
                  a{
                    padding: 5px 40px;
                    @include media($menu-break){
                      padding:1.5rem 3.5rem;
                    }
                  }
                }
              }
              &:hover{
                @include media($menu-break){
                  span.menu-openner{
                    &:before{
                      transform: rotate(90deg);
                    }
                  }
                  ul{
                    display: block !important;
                    box-shadow: 0 0 5px rgba(0,0,0,0.3);
                  }
                }
              }
              &.open{
                & > .menu-openner{
                  &:before{
                    @include rotate(0deg);
                    @include media($menu-break){
                      transform: rotate(90deg);
                    }
                  }
                }
                & > ul{
                  display:block;
                  @include media($menu-break){
                    display: none;
                  }
                }
              }
            }
          }
          &.hovered,
          &:hover,
          &.focused{
            & > span,
            & > a{
              @include media($menu-break){
                outline: 0;
              }
            }
            & > .menu-openner{
              &:before{
                @include media($menu-break){
                  @include rotate(0deg);
                }
              }
            }
            & > ul {
              @include media($menu-break){
                visibility: visible;
                @include opacity(1);
              }
            }
          }
          &.open{
            & > .menu-openner{
              &:before{
                @include rotate(0deg);
              }
            }
            & > ul{
              display:block;
            }
          }
          &:last-child{
            @include media($menu-break){
              margin:0;
            }
          }
        }
      }
      .menu-openner{
        position: absolute;
        top:0;
        right:25px;
        width: 40px;
        height: 40px;
        padding:0;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media($menu-break){
          //display: none;
          position: static;
          width: auto;
          height: auto;
          margin:0 5px 0 0;
        }
        &:before{
          content:"\e9a0";
          transform: rotate(180deg);
          font-size: 14px;
          transition: 200ms;
        }
      }
    }
    &-hamburger{
      display: flex;
      align-items: center;
      @include media($menu-break){
        display: none;
      }
      .open-menu{
        width:25px;
        height:25px;
        position: relative;
        display: flex;
        align-items: center;
        span{
          height:3px;
          width:25px;
          @include transition;
          position: relative;
          &:before,
          &:after{
            content:"";
            width:25px;
            left:0;
            height:3px;
            position: absolute;
            @include transition;
          }
          &:before{
            top:-8px;
          }
          &:after{
            bottom:-8px;
          }
        }
      }
      .open-search{
        margin:0 3rem 0 1rem;
      }
    }
    &.open{
      .header-hamburger{
        .open-menu{
          span{
            background: transparent;
            &:before{
              top:0;
              @include rotate(45deg);
            }
            &:after{
              top:0;
              @include rotate(-45deg);
            }
          }
        }
      }
      .header-menu{
        display: flex;
      }
    }
  }
}

.header-email,
.header-phone{
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  @include pux-scale-from("font-size", lg, 18px);
  &:before{
    margin:0 10px 0 0;
  }
}

.header-email{
  &:before{
    content:"\e99a";
  }
}

.header-phone{
  font-weight: 700;
  &:before{
    font-weight: 400;
    content:"\e99c";
  }
}

.header-menu{
  a{
    &:before{
      @include pux-static-size("font-size", 18px);
      margin:0 10px 0 0;
    }
  }
  .menu-home{
    a{
      &:before{
        @include media($menu-break){
          content:"\e918";
        }
      }
    }
  }
  .menu-360{
    a{
      &:before{
        @include media($menu-break){
          font-size: 35px;
          content:"\e93e";
        }
      }
      &:after{
        content:"\e93e";
        margin:0 0 0 10px;
        font-size: 35px;
        @include media($menu-break){
          display: none;
        }
      }
    }
  }
}

header{
  .header-right{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    width: 100%;
    @include media($menu-break){
      flex-wrap: wrap;
    }
    &-menu{
      display:none;
      flex-wrap: wrap;
      position: absolute;
      top:100%;
      z-index: 77;
      left:0;
      width: 100%;
      overflow: auto;
      @include calc("max-height", "100vh - 60px");
      @include media(md){
        display: flex;
        position: static;
        overflow: visible;
      }
      @include media($menu-break){
        display: block;
      }
      @include media(xl){
        width: auto;
        display: flex;
      }
    }
  }
  .header-top{
    width: 100%;
    display: flex;
    align-items: center;
    padding:2rem 2rem 0 2rem;
    @include media(md){
      padding: 0;
    }
    @include media($menu-break){
      display: block;
      padding:14px 0 0 0;
    }
    @include media(xl){
      padding:20px 0 0 0;
    }
    &-inner{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      @include media(xl){
        margin:0 -12px;
        width: auto;
      }
    }
    &-item{
      padding:0 12px;
      &:last-child{
        display: none;
        @include media($menu-break){
          display: block;
        }
      }
    }
    &-logo{
      display: flex;
      align-items: center;
      a{
        display: flex;
        span{
          display:none;
        }
        img{
          max-width: none;
          height: 36px !important;
        }
      }
    }
  }
  .header-email{
    font-weight: 700;
  }
  .header-phone{
    font-weight: 700;
  }
  .open-search{
    &:before{
      content:"\e940";
    }
  }
  &.header-big{
    .header {
      height: 60px;
      @include media($menu-break){
        height: 110px;
      }
    }
    .header-menu{
      align-self: flex-end;
      @include media($menu-break){
        height: 60px;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
}

.search-overlay{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  flex-direction: row;
  display: none;
  &-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &-content{
    max-width: 740px;
    width: 100%;
    padding: 0 20px;
  }
  ul{
    margin:0;
    padding:0;
    list-style: none;
    li{
      margin:0 0 4px 0;
    }
  }
  .search-close{
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: all 0.3s ease-out;
    margin-left: auto;
    margin-right: 1rem;
    margin-top: 1rem;
    margin-bottom: 7rem;
    @include media(md) {
      width: 110px;
      height: 110px;
      margin-top: 5rem;
      margin-right: 7rem;
    }
    .esc{
      display: none;
      @include media(md) {
        display: block;
        width: 100%;
        text-align: center;
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 5px;
        left:0;
      }
    }
    &-inner{
      &:before{
        content:"\e917";
        display: block;
        font-size: 40px;
        @include media(md) {
          font-size: 50px;
        }
      }
    }
  }
  &.open{
    display: block;
  }
}

.header-search-helper{
  padding: 4rem 3rem 0 3rem;
  &-title{
    letter-spacing: 0.24px;
    font-size: 24px;
    font-weight: 700;
    margin:0 0 2rem 0;
    @include pux-scale-from("font-size", lg, 24px);
  }
  a{
    text-decoration: underline;
    font-weight: 400;
    transition: all 0.3s ease-out;
    font-size: 20px;
    @include pux-scale-from("font-size", lg, 20px);
    &:focus,
    &:hover{
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.header-search-box{
  &-inner{
    display: flex;
    &-input{
      width: 100%;
    }
  }
}

input[type="text"].search-panel-input{
  height: 60px;
  font-size: 24px;
  letter-spacing: 0.24px;
  overflow: hidden;
  text-overflow: ellipsis;
  @include pux-scale-from("font-size", lg, 24px);
  @include media(xxs, sm) {
    height: 50px;
    font-size: 18px;
  }
  &:focus{
    border:none;
  }
}

.search-panel-btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 160px;
  font-weight: 700;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: all 0.3s ease-out;
  @include media(xxs, sm) {
    font-size: 0;
    height: 50px;
    width: 50px;
  }
  &:before{
    content:"\e940";
    margin: 0 10px 0 0;
    @include media(xxs, sm) {
      font-size: 21px;
      margin: 0 auto;
    }
  }
}

header.header-big{
  .header-logo{
    @include media($menu-break){
      height: 110px;
    }
  }
  .header-top{
    @include media($menu-break){
      display: flex;
      justify-content: space-between;
    }
    @include media(xl){
      display: block;
    }
    &-logo{
      display: none;
      @include media($menu-break){
        display: flex;
        align-items: center;
      }
      @include media(xl){
        display: none;
      }
    }
  }
  .header.open{
    .header-right-menu{
      display: flex;
    }
  }
  .header-top{
    order: 2;
    @include media(md){
      order: 1;
    }
    &-inner{
      flex-wrap: wrap;
      @include media(md){
        flex-wrap: nowrap;
      }
    }
  }
  .header-top-item{
    width: 100%;
    text-align: center;
    padding: 0 12px 2rem 12px;
    @include media(md){
      width: auto;
      text-align: left;
      padding:0 12px;
    }
  }
  .header-menu{
    order: 1;
    @include media(md){
      order: 2;
    }
  }
}

.option-box{
  display: none;
  margin-top: 1px;
  position: fixed;
  right:0;
  z-index: 888;
  @include media(md) {
    display: block;
  }
  &-item{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before{
      font-size: 23px;
    }
    span{
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top:0;
      right: 0;
      white-space: nowrap;
      width: 0;
      overflow: hidden;
      padding:0;
      transition: 200ms;
    }
  }
  &-size{
    margin:0 0 1px 0;
  }
  .size-plus{
    &:before{
      content:"\e9a4";
    }
    &:hover{
      span{
        padding:0 15px;
        width: auto;
        min-width: 110px;
      }
    }
  }
  .size-minus{
    &:before{
      content:"\e944";
    }
    &:hover{
      span{
        padding:0 15px;
        width: auto;
        min-width: 130px;
      }
    }
  }
  .contrast-item{
    &:before{
      content:"\e99d";
    }
    .contrast-text-hight{
      display: none;
    }
    &:hover{
      .contrast-text-normal{
        padding:0 15px;
        width: auto;
        min-width: 140px;
      }
    }
  }
}

body{
  &#normal{
    .option-box{
      .size-minus{
        display: none;
      }
    }
  }
  &#big{
    .option-box{
      .size-plus{
        display: none;
      }
    }
  }
  &.contrast{
    .contrast-item{
      .contrast-text-normal{
        display: none;
      }
      .contrast-text-hight{
        display: inline-flex;
      }
      &:hover{
        .contrast-text-hight{
          padding:0 15px;
          width: auto;
          min-width: 140px;
        }
      }
    }
  }
}

.scrolled{
  &.down{
    header.header-big{
      @include media($menu-break){
        top:-50px;
      }
      .header-logo{
        height: 60px;
      }
    }
    header .header-logo a img{
      height: 45px !important;
    }
  }
}

.header-facebook{
  color: #4267B2;
  border:1px solid #4267B2;
  background: #fff;
  border-radius: 40px;
  font-size: 0;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  @include media(lg){
    font-size: 14px;
  }
  &:before {
    content:"\e911";
    font-size: 18px;
    @include media(lg){
      margin: 0 10px 0 0;
    }
  }
  &:focus,
  &:hover{
    background: #4267B2;
    color: #fff;
  }
}

.facebook-placeholder{
  display: block;
  .header-facebook{
    margin: 0 10px 0 0;
  }
  @media (max-width: 375px) {
    display: none;
  }
  @include media(md){
    display: none;
  }
}

.header-info{
  margin-left: 5px;
  &:before{
    content:"\e9c8";
  }
}