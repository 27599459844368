
.social-search{
  display: flex;
  margin: 0 0 5rem 0;
  flex-wrap: wrap;
  @include media(sm){
    flex-wrap: nowrap;
  }
  &-select{
    width: 100%;
    margin: 0 15px 0 0;
  }
  &-btn{
    padding: 3rem 0 0 0;
    text-align: center;
    width: 100%;
    @include media(sm){
      padding: 0;
      width: auto;
    }
  }
  .selectric-items{
    overflow: auto;
  }
  .selectric-items .selectric-scroll{
    overflow: visible;
  }
}

.social-workers{
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(1, 1fr);
    @include media(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
    @include media(lg) {
        grid-template-columns: repeat(3, 1fr);
    }
}

.social-worker-item{
  background: #fff;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  letter-spacing: 0.18px;
  .h3{
    margin: 5px 0 15px 0;
  }
  &-post{
    font-weight: 700;
  }
  &-name{
    letter-spacing: 0.24px;
  }
  .link-phone{
    &:before{
      content: "\e99c";
      @include pux-scale-from("font-size", lg, 20px);
    }
  }
  .link-email{
    &:before{
      content: "\e99a";
      @include pux-scale-from("font-size", lg, 20px);
    }
  }
  &-title{
    margin: 1.5rem 0;
    font-weight: 700;
  }
  &-list{
    font-size: 14px;
  }
}

.street-container{
  li{
    margin: 0;
    padding: 0 0 0 20px;
    &:before{
      height: 1px;
      width: 10px;
      top:13px;
    }
  }
}

.street-container{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;
  ul{
    padding: 0 2rem;
    width: 100%;
    @include media(sm){
      width: 33.33%;
    }
  }
}

.street-filter-list{
  display: flex;
  flex-wrap: wrap;
  margin: 0 -7px 2rem -7px;
  justify-content: center;
  text-transform: uppercase;
  span{
    padding: 0 7px 10px 7px;
  }
  a.street-filter-disabled {
    pointer-events: none;
  }
}
