
@import "../../sass/_mixins/pux/breakpoints.scss";
@import "../../sass/_mixins/pux/css-rules.scss";
@import "../../sass/_mixins/pux/scale.scss";
@import "../../sass/_variables.scss";

.selectric-wrapper {
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  &:focus,
  &:hover{
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2);
    .selectric-items{
      box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2);
    }
  }
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: $select-height;
    height: $select-height;
    user-select: none;
    padding:0 2rem 0 2rem;
  }

  .button {
    display: block;
    position: relative;
    right: 0;
    top: 0;
    width: 55px;
    height: $select-height;
    height: 100%;
    text-align: center;
    transition: all ease 200ms;
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &:after {
      @include pux-static-size("font-size", 15px);
      content: $select-arrow-code;
      margin: 0 2rem;
      display: block;
      transition: all ease 200ms;
      @include rotate(180deg);
    }
  }
}

.selectric-open {
  z-index: 9999;
  .selectric {
    .button {
      &:after{
        @include rotate(0deg);
      }
    }
  }
  .selectric-items {
    display: block;
  }
}

.selectric-disabled {
  @include opacity(0.5);
  cursor: default;
  user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
  select {
    position: absolute;
    left: -100%;
  }

  &.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0;
    }
  }
}

.selectric-input {
 position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.search-select{
  .selectric-input{
    width: 96% !important;
    height: 40px !important;
    position: static !important;
    background: #fff !important;
    margin: 5px auto !important;
    display: block;
    padding: 0 10px !important;
    border:1px solid #757575 !important;
  }
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  overflow: hidden;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: -1;
  min-width: inherit;
  .selectric-scroll {
    height: 100%;
    overflow: auto;
  }

  .selectric-above & {
    top: auto;
    bottom: 100%;
  }

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 2rem;
    min-height: 2rem;
  }

  li {
    display: block;
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all ease 200ms;
    line-height: 5rem;
    @include media(md){
      line-height: 3rem;
    }
    &:before{
        display:none;
    }

    &.selected {
    }

    &.highlighted {
    }

    &:hover {
    }
    &:nth-child(odd){
        &:hover{
        }
    }
  }

  .disabled {
    @include opacity(0.5);
    cursor: default !important;
    background: none !important;
    user-select: none;
  }

  .selectric-group {
    .selectric-group-label {
      font-weight: bold;
      padding-left: 1rem;
      cursor: default;
      user-select: none;
      background: none;
    }

    &.disabled li {
      @include opacity(1);
    }

    li {
      padding-left: 2.5rem;
    }
  }
}

.list-box{
  .selectric-scroll{
    ul{
      li{
        padding: 1rem 2rem 1rem 1rem;
        &:before{
          content:"";
          width:12px;
          height:12px;
          @include border-radius(0);
          display: inline-block;
          margin:0 1rem 0 0;
          vertical-align: middle;
          position: static;
        }
        &.selected{
          &:before{
          }
        }
        &:first-child{
          &:before{
            display: none;
          }
        }
      }
    }
  }
}
