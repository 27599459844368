
@import "../../sass/_mixins/pux/breakpoints.scss";
@import "../../sass/_mixins/pux/css-rules.scss";
@import "../../sass/_mixins/pux/scale.scss";
@import "../../sass/_variables.scss";


.dtp {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2000; font-size: 14px;
  @include no-select;
  & > .dtp-content{
    max-width: 300px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    max-height: 520px;
    position: relative;
    left: 50%;
    & > .dtp-date-view{
       .dtp-header {
          text-align: center;
          padding: 0.3em;
      }
    }
  }
  div.dtp-date,
  div.dtp-time {
     text-align: center;
     padding: 10px;
  }
  div.dtp-date > div {
     padding: 0; margin: 0;
  }
  div.dtp-actual-month {
     font-size: 1.5em;
  }
  div.dtp-actual-num {
    font-size: 3em;
    line-height: 0.9;
  }
  div.dtp-actual-maxtime {
     font-size: 3em;
     line-height: 0.9;
  }
  div.dtp-actual-year {
    font-size: 1.5em;
  }
  div.dtp-picker {
    padding: 1em;
    text-align: center;
  }
  div.dtp-picker-month,
  div.dtp-actual-time {
     font-weight: 500;
    text-align: center;
  }
  div.dtp-picker-month {
     padding-bottom:20px!important;
     text-transform: uppercase!important;
  }
  .dtp-close {
    position: absolute;
    top: 0.5em;
    right: 1em;
    & > a {
      & > i {
         font-size: 1em;
      }
    }
  }
  &-btn-ok{
    border:none;
    cursor: pointer;
    padding:2px 5px;
  }
}

.dtp {
  table{
    &.dtp-picker-days {
      margin: 0;
      min-height: 251px;
      border: none;
      tr{
        border: none;
        & > td{
          border: none;
          font-weight: 700;
          font-size: 0.8em;
          text-align: center;
          padding: 0.5em 0.3em;
          & > span.dtp-select-day {
            padding: 0.4em 0.5em 0.5em 0.6em;
          }
          & > a{
            text-decoration: none;
            padding: 0.4em 0.5em 0.5em 0.6em;
            border-radius: 50%!important;
            &.selected{
            }
          }
        }
        & > th {
           text-align: center;
           font-weight: 700;
           padding: 0.4em 0.3em;
        }
      }
    }
  }
  .dtp-picker-time{
    & > a{
      text-decoration: none;
      padding: 0.4em 0.5em 0.5em 0.6em;
      border-radius: 50%!important;
    }
  }
}

.dtp{
  .p10{
    width: 10%;
    display: inline-block;
    & > a{
      text-decoration: none;
    }
  }
  .p20 {
    width: 20%;
    display: inline-block;
  }
  .p60 {
    width: 60%;
    display: inline-block;
  }
  .p80 {
     width: 80%;
     display: inline-block;
  }
  a.dtp-meridien-am,
  a.dtp-meridien-pm {
     position: relative;
     top: 10px;
     font-weight: 500;
     padding: 0.7em 0.5em;
     border-radius: 50%!important;
     text-decoration: none;
     font-size:1em;
  }
  .dtp-actual-meridien a.selected {
  }
  .dtp-picker-time{
    & > .dtp-select-hour,
    & > .dtp-select-minute{
      cursor: pointer;
    }
  }
  .dtp-buttons{
    padding: 0 1em 1em 1em;
    text-align: right;
  }
}

.dtp{
  &.hidden,
  .hidden{
    display: none;
  }
  .invisible{
    visibility: hidden;
  }
  .left {
    float: left;
  }
  .right {
    float: right;
  }
  .clearfix {
    clear: both;
  }
  .center {
    text-align: center;
  }
  &-picker-year{
      margin-bottom: 1px;
  }
}

.year-picker-item{
    text-align: center;
    padding-top: 5px;
    font-size: large;
    cursor: pointer;
    margin: 0 auto;
    &.active{
      font-weight: bold;
    }
}

.dtp-actual-year:hover{
    cursor: pointer;
}
.dtp-actual-year.disabled:hover{
    cursor: inherit;
}

.dtp-select-year-range{
    display: inline-block;
    text-align: center;
    width: 100%;
    padding:10px 0;
    &.before{
      span{
        @include rotate(-90deg);
        display: inline-block;
      }
    }
    &.after{
      span{
        @include rotate(90deg);
        display: inline-block;
      }
    }
}
