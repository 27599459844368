
.important-box{
  display: flex;
  &.box-single{
    max-width: 800px;
  }
}

.important-item{
  border-radius: 10px;
  padding:2.5rem 3rem;
  display: block;
  width: 100%;
  transition: all 0.3s ease-out;
  font-weight: 400;
  text-decoration: none !important;
  &-date{
    font-weight: 700;
    padding:0 0 5px 0;
  }
  &-title{
    font-size: 20px;
    font-weight: 700;
    padding:0 0 5px 0;
    @include media(sm) {
      font-size: 22px;
    }
    @include pux-scale-from("font-size", lg, 26px);
  }
  &-label{
    display: inline-block;
    padding: 2px 5px;
    margin: 0 0 1rem 0;
    line-height: 1;
  }
  &-link{
    font-weight: 700;
  }
  &:focus,
  &:hover{
    text-decoration: none;
    .link{
      &:after{
        right:-5px;
      }
    }
  }
}

.notification-files-item {
    padding-bottom: 1.5rem;
    display: flex;
}

.important-box{
  &.d-none-category{
    display: none !important;
  } 
  &.d-none-job{
    display: none !important;
  }
}

.notification-filter-empty{
  text-align: center;
  padding: 3rem 0;
}

.notification-filter{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0 0 3rem 0;
}

.notification-filter-item{
  min-width: 200px;
}