
.person-box{
  text-align: center;
}

.person-item{
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  .h3{
    margin: 5px 0 15px 0;
  }
  &-image{
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    display: inline-block;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    &.man,
    &.woman{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      &:after{
        content: "\e943";
        display: block;
        font-size: 15rem;
        @include media(md){
          font-size: 10rem;
        }
      }
    }
    &.woman{
      &:after{
        content: "\e942";
      }
    }
  }
  &-content{
    padding:2rem 0 0 0;
    letter-spacing: 0.18px;
    &-function{
      font-weight: 700;
    }
    &-name{
      letter-spacing: 0.24px;
    }
  }
}

.person-item,
.person-small-item {
  .link-phone{
    &:before{
      content: "\e99c";
      @include pux-scale-from("font-size", lg, 20px);
    }
  }
  .link-email{
    &:before{
      content: "\e99a";
      @include pux-scale-from("font-size", lg, 20px);
    }
  }
  &.open{
    .person-item-description-text{
      display: block;
    }
    .person-item-description-show{
      &:after{
        transform: rotate(180deg);
      }
    }
  }
}

.box-single{
  .person-item{
    @include media(sm){
      text-align: left;
      display: flex;
      align-items: center;
      flex-direction: row;
    }
    &-content{
      @include media(sm){
        padding:0 0 0 4rem;
      }
    }
  }
}

.person-box.box-single{
  @include media(sm){
    max-width: 570px;
  }
}

.person-box.box-half{
  .person-item{
    @include media(lg){
      display: flex;
      align-items: center;
      flex-direction: row;
      text-align: left;
    }
    &-content{
      @include media(lg){
        padding:0 0 0 4rem;
      }
    }
  }
}

.person-box.box-half{
  @include media(lg){
    max-width: 570px;
  }
}

.person-small-box{
  padding:0 2rem 4rem 2rem;
  width: 100%;
  display: flex;
  @include media(lg){
    width: 50%;
  }
}

.person-small-container {
  .person-small-table {
    width: 100%;
    table {
      width: 100%;
      tbody {
        tr {
          td {
            padding: 2rem;
            vertical-align: middle;
            justify-content: flex-start;
            @include media($respo-table-break) {
              padding: 0.75rem 2rem;
            }
            &.empty-cell {
              display: none;
              :before {
                //display: none;
              }
              @include media($respo-table-break) {
                display: table-cell;
              }
            }
          }
        }
      }
    }
  }
}

main{
  .person-table{
    a{
      font-weight: 700;
    }
    table{
      width:100%;
      border-collapse: collapse;
      border-style: hidden;
      thead{
        display: none;
        @include media($respo-table-break){
          display: table-header-group;
        }
        tr{
          border:none;
          th{
            font-weight: 700;
            padding:1rem 0 1.5rem 0;
            @include media($respo-table-break){
              padding:1rem 2.5rem;
            }
            &:nth-child(3),
            &:nth-child(4){
              text-align: right;
            }
          }
        }
      }
      tbody{
        tr{
          display: block;
          border-bottom: none;
          margin:0 0 2rem 0;
          position: relative;
          transition: all 0.3s ease-out;
          @include media($respo-table-break){
            display: table-row;
            margin: 0;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border: none;
          }
          td{
            display: flex;
            justify-content: center;
            width:100%;
            position: relative;
            padding:1.8rem 2.5rem;
            line-height: 1.3;
            text-align: center;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            @include media(sm){
              text-align: right;
              justify-content: space-between;
            }
            @include media($respo-table-break){
              display: table-cell;
              width:auto;
              text-align: left;
              border-bottom: none;
            }
            &:before{
                content:attr(data-th);
                display:none;
                text-align:left;
                font-family:$font-family !important;
                margin:0 1.5rem 0 0;
                line-height: 1.3;
                @include media(sm){
                  display:block;
                }
                @include media($respo-table-break){
                    display:none;
                }
            }
            &:last-child{
              text-align: center;
              justify-content: center;
              border-bottom: none;
              &:before{
                display: none;
              }
            }
            &:nth-child(3),
            &:nth-child(4){
              @include media($respo-table-break){
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
}

.person-item-description{
  .person-item-description-show{
    display: inline-flex;
    align-items: center;
    &:after{
      content:"\e902";
      margin: 0 0 0 5px;
      font-size: 7px;
      transition: 250ms;
    }
  }
}

.person-item-description-text{
  display: none;
  font-size: 15px;
  padding: 1rem 0 0 0;
  width: 100%;
}

.person-item-icon{
  font-size: 32px;
  line-height: 1;
  margin: 0 0 1rem 0;
  a{
    &:focus,
    &:hover{
      opacity: 0.8;
    }
  }
}

.tooltip{
  font-size: 15px;
}

.person-small-item-icon{
  line-height: 1;
  font-size: 25px;
  height: 25px;
  vertical-align: middle;
  a{
    line-height: 1;
    height: 25px;
    display: inline-block;
  }
}

.image-left{
  .dynamic-container .dynamic-box{
    width: 100%;
    @include media(lg){
      width: 50%;
    }
    @include media(xl){
      width: 33.33%;
    }
  }
  .person-container{
    .person-item{
      display: block;
      @include media(sm){
        text-align: left;
      }
    }
    .person-item-inner{
      @include media(sm){
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .person-item-content{
      padding: 2rem 0 0 0;
      @include media(sm){
        padding: 0 0 0 2rem;
      }
    }
    .person-item .h3{
      margin: 0;
    }
    .person-item-content-function{
      margin: -5px 0 10px 0;
    }
    .person-item-content-function,
    .person-item-description-show,
    .link{
      //font-weight: 400;
      @include pux-static-size("font-size", 16px);
    }
  }
}

.table-small{
  .person-table-small{
    padding: 0 2rem 4rem 2rem;
    width: 100%;
    @include media(lg){
      width: 50%;
    }
  }
  a{
    font-weight: 400;
  }
  td{
    border:none !important;
    &:last-child{
      @include media(sm){
        text-align: right;
      }
    }
  }
  .person-small-item-bottom{
    display: block;
    @include media(sm){
      display: table-row;
    }
    td{
      display: block;
      text-align: center;
      @include media(sm){
        display: table-cell;
        text-align: left;
      }
      &:last-child{
        @include media(sm){
          text-align: right;
        }
      }
    }
    td:nth-child(2){
      @include media(sm){
        text-align: center;
      }
    }
  }
  .person-table-small-inner{
    box-shadow: 0 10px 30px rgba(232,232,232, 0.5);
  }
}
