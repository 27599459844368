
.nwl-container{
  background-color: #e6e6e6;
  padding: 20px;
  margin: 40px 0;
  h3{
    // display: none;
    margin: 0 0 15px 0;
   }
   .form-horizontal-inner{
     display: flex;
     gap: 2rem;
     align-items: flex-end;
     width: 100%;
   }
   .form-horizontal{
     //width: calc(100% - 350px);
   }
   .nwl-text{
     min-width: 350px;
   }
   .form-group{
     width: 100%;
     &.form-group-submit{
       width: auto;
     }
   }
   input[type="text"]{
     height: 45px;
     background-color: #fff;
   }
   .NewsletterSubscription{
     //display: flex;
     align-items: center;
     gap: 2rem;
   }
}