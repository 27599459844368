
.main-slider h2,
h1,
.h1{
  font-size: 28px;
  font-weight: 700;
  margin: 1rem 0 3rem 0;
  @include media(sm){
    font-size: 30px;
  }
  @include media(md){
    font-size: 38px;
  }
  @include pux-scale-from("font-size", xl, 48px);
}

h2,
.h2{
  font-size: 28px;
  font-weight: 700;
  margin: 4rem 0;
  @include media(sm){
    font-size: 30px;
  }
  @include media(md){
    font-size: 32px;
  }
  @include media(xl){
    @include pux-scale-from("font-size", xl, 36px);
  }
}

h3,
.h3{
  font-size: 20px;
  font-weight: 700;
  margin: 2rem 0;
  @include media(sm){
    font-size: 21px;
  }
  @include media(md){
    font-size: 23px;
  }
  @include pux-scale-from("font-size", xl, 24px);
}

.widget-container{
  .widget-title{
    margin:0 0 5rem 0;
  }
}

h4,
.h4{
  @include pux-scale-with-min("font-size", 25px, 18px);
  @include pux-scale-with-min("line-height", 25px, 18px);
}

big,
.perex{
  @include pux-scale-with-min("font-size", $font-size-perex, $min-font-size-perex);
  @include pux-scale-with-min("line-height", $line-height-perex, $min-line-height-perex);
}

small,
.text-small{
  @include pux-scale-with-min("font-size", $font-size-small, $min-font-size-small);
  @include pux-scale-with-min("line-height", $line-height-small, $min-line-height-small);
}

.biger-text{
  @include pux-scale-with-min("font-size", 22px, 18px);
  @include pux-scale-with-min("line-height", 30px, 26px);
  .perex{
    @include pux-scale-with-min("font-size", 27px, 20px);
    @include pux-scale-with-min("line-height", 35px, 28px);
  }
}

#big{
  .biger-text{
    @include pux-scale-with-min("font-size", 24px, 20px);
    @include pux-scale-with-min("line-height", 32px, 28px);
    .perex{
      @include pux-scale-with-min("font-size", 29px, 22px);
      @include pux-scale-with-min("line-height", 37px, 30px);
    }
  }
}

.ul-columns{
  ul{
    @include media(md){
      columns: 2;
    }
    li{
      padding: 0;
      display: flex;
      align-items: baseline;
      @include pux-scale-with-min("font-size", 22px, 18px);
      &:before{
        content: "\e9a6";
        background: none !important;
        width: auto;
        height: auto;
        position: static;
        position: relative;
        top: 3px;
        margin-right: 20px;
        @include pux-scale-with-min("font-size", 24px, 22px);
      }
    }
  }
}

.bgc-main .text-widget{
  h2{
    margin-top: 0;
  }
}