

.card-box{
  .card-item{
    font-weight: 400;
    width: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    min-height: 250px;
    @include pux-scale-from("height", lg, 266px);
    padding:2.5rem 3rem;
    overflow: hidden;
    transition: all 0.3s ease-out;
    text-decoration: none !important;
    &:before{
      content:"";
      position:absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 22;
      transition: all 0.3s ease-out;
    }
    &:after{
      //content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8;
      z-index: 22;
      transition: all 0.3s ease-out;
    }
    h3{
      margin: 0;
    }
    &-bgc{
      background-repeat: no-repeat !important;
      background-size: cover !important;
      position: absolute;
      top: 0;
      left:0;
      width: 100%;
      height: 100%;
      z-index: 11;
      transition: all 0.3s ease-out;
    }
    &-content{
      position: relative;
      z-index: 33;
      @include media(sm){
        display: flex;
        align-items: center;
      }
      &-ico{
        &:before{
          font-size: 40px;
          @include media(sm){
            font-size: 50px;
            margin:0 2.2rem 0 0;
          }
        }
      }
      &-text{
        padding:15px 0 0 0;
        @include media(sm){
          padding:0;
        }
      }
      &-link{
        font-weight: 700;
      }
      h3{
        margin:0;
      }
    }
    &:focus,
    &:hover{
      text-decoration: none;
      &:before{
        //opacity: 1;
        //transform: scale(1.25);
      }
      &:after{
        //opacity: 0.75;
      }
      .link{
        &:after{
          right:-5px;
        }
      }
    }
  }
  &.box-half{
    min-height: 160px;
    width: 100%;
    @include media(sm){
      width: 100%;
      min-height: 230px;
    }
    @include media(md){
      width: 50%;
      min-height: 280px;
    }
    .card-item{
      @include pux-scale-from("height", lg, 310px);
    }
  }
  &.box-single{
    max-width: 790px;
    margin:0 auto;
    min-height: 160px;
    @include media(sm){
      min-height: 230px;
    }
    @include media(md){
      min-height: 280px;
    }
    .card-item{
      @include pux-scale-from("height", lg, 370px);
    }
  }
  &.dynamic-box{
    min-height: 160px;
    width: 100%;
    @include media(sm){
      width: 100%;
    }
    @include media(md){
      width: 50%;
    }
    @include media(lg){
      width: 33.33%;
    }
  }
}

.contrast{
  body.contrast .card-box .card-item:before{
    opacity: 1;
    transform: scale(1.25);
  }
}

.card-box-mini{
  &.dynamic-box{
    padding:2rem;
    width: 100%;
    @include media(lg){
      width: 27%;
    }
  }
  &.dynamic-box.box-half{
    @include media(md){
      width: 47%;
    }
  }
  .card-item-mini{
    display: flex;
    align-items: center;
    position: relative;
    padding:10px 0;
    &:after{
      //content: "";
      border-left-style: dotted;
      border-left-width: 2px;
      position: absolute;
      top:0;
      left:-2rem;
      height: 100%;
    }
    h3{
      margin: 0;
      font-size: 20px;
      @include media(sm){
        font-size: 20px;
      }
      @include pux-scale-from("font-size", xl, 24px);
    }
    &-ico{
      transition: 250ms;
      &:before{
        font-size: 50px;
        position: relative;
        margin:0 2.2rem 0 0;
      }
    }
    &-content{
      &-link{
        font-weight: 700;
      }
      h3{
        margin: 0;
      }
    }
    &:focus,
    &:hover{
      text-decoration: none;
      .link{
        &:after{
          right:-5px;
        }
      }
    }
  }
  &.box-single{
    max-width: 500px;
    margin:0 auto;
  }
}

.slider-service{
  .card-item-mini:hover{
    .card-item-mini-ico{
      transform: scale(1.15);
    }
  }
}

.card-box-small{
  .card-item-small{
    display: block;
    text-align: center;
    font-weight: 400;
    &-ico{
      &:before{
        font-size: 70px;
      }
    }
    .h3{
      margin: 1.5rem 0;
    }
    &-perex{
      margin:0 0 1.5rem 0
    }
    &-link{
      font-weight: 700;
    }
    &:focus,
    &:hover{
      .link{
        &:after{
          right:-5px;
        }
      }
    }
  }
  h3{
    margin: 1.5rem 0 2rem 0;
  }
  &.box-single{
    max-width: 790px;
    margin:0 auto;
  }
}

.card-box-big{
  .card-item-big{
    font-weight: 400;
    display: block;
    //padding: 3.5rem;
    height: 100%;
    h3{
      margin: 10px 0 15px 0;
    }
    &-image{
      display: flex;
      overflow: hidden;
      min-height: 230px;
      @include media(md){
        min-height: 250px;
      }
      @include pux-scale-from("min-height", lg, 280px);
      &-bgc{
        width: 100%;
        background-size: cover !important;
        background-position: top center !important;
        transition: all 0.3s ease-out;
      }
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 700;
      @include pux-static-size('font-size', 16px);
      &-type {
        padding: 2rem 3rem;

      }
      &-title {

      }
    }
    h3{
      font-size: 22px;
      @include media(sm){
        font-size: 24px;
      }
      @include media(lg){
        font-size: 26px;
      }
      @include pux-scale-from("font-size", xl, 28px);
    }
    &-content{
      padding:2rem;
      &-ico{
        display: none;
        &:before{
          font-size: 5rem;
        }
      }
      &-top{
        font-weight: 700;
      }
    }
    &-perex{
      padding:0 0 15px 0;
    }
    &-link{
      font-weight: 700;
    }
    &:focus,
    &:hover{
      .card-item-big-image-bgc{
        @include transform(scale(1.05));
      }
      .link{
        &:after{
          right:-5px;
        }
      }
    }
  }
  &.box-half{
    .card-item-big{
      @include media(xl){
        display: flex;
        //align-items: center;
      }
      &-image{
        min-height: 230px;
        @include media(md){
          min-height: 280px;
        }
        @include media(xl){
          width: 50%;
          min-width: 50%;
          @include pux-scale-from("min-height", lg, 300px);
        }
      }
      &-content{
        padding:3rem;
        @include media(xl){
          padding:3rem 2rem 3rem 5rem;
          width: 50%;
          align-self: center;
        }
        &-ico{
          display: block;
          margin:0 0 1.5rem 0;
        }
      }
      h3{
        font-size: 22px;
        @include media(sm){
          font-size: 24px;
        }
        @include media(lg){
          font-size: 26px;
        }
        @include pux-scale-from("font-size", xl, 26px);
      }
    }
  }
  &.box-single{
    .card-item-big{
      @include media(md){
        display: flex;
      }
      &-image{
        min-height: 230px;
        @include media(md){
          width: 50%;
          min-width: 50%;
          min-height: 280px;
        }
        @include pux-scale-from("min-height", lg, 300px);
      }
      &-content{
        padding:3rem;
        @include media(md){
          padding:3rem 2rem 3rem 5rem;
          width: 50%;
          align-self: center;
        }
        &-ico{
          display: block;
          margin:0 0 1.5rem 0;
        }
      }
    }
  }
}

.card-box-big.dynamic-box{
  width: 100%;
  @include media(md){
    width: 50%;
  }
  @include media(lg){
    width: 33.33%;
  }
  &.box-single{
    @include media(md){
      width: 100%;
      max-width: 990px;
      margin: 0 auto;
    }
  }
}

.card-container{
  .line-helper{
    display: none;
    @include media(lg){
      display: block;
    }
  }
}
