
.events-box{
  display: flex;
  flex-wrap: wrap;
  margin:0 -15px;
  @include media(xl){
    margin:0 -2rem;
  }
  h3{
    @include media(lg){
      margin:0 0 2rem 0;
    }
  }
  .event-respo-title{
    display: block;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    margin:0 0 30px 0;
    padding:0 0 20px 0;
    @include media(lg){
      display: none;
    }
  }
  &-left{
    padding:0 15px;
    width: 100%;
    @include media(lg){
      width: 47%;
    }
    @include media(xl){
      padding:0 2rem;
      width: 30%;
    }
    @include media(xxl){
      width: 27%;
    }
  }
  &-right{
    padding:0 15px;
    width: 100%;
    @include media(lg){
      width: 47%;
    }
    @include media(xl){
      padding:0 2rem;
      width: 63%;
    }
    @include media(xxl){
      width: 67%;
    }
  }
  &.title-box{
    margin:0 -2px 7rem -2px;
    display: none;
    @include media(lg){
      display: flex;
    }
    .events-box-right{
      padding:0 0 0 15px;
      h3{
        margin-left: -9px;
      }
    }
    .events-box-left{
      padding:0 0px 0 9px;
      h3{
        margin-left: -9px;
      }
    }
    .events-box-left{
      @include media(lg){
        width: 50%;
      }
      @include media(xl){
        width: 33%;
      }
      @include media(xxl){
        width: 30%;
      }
    }
    .events-box-right{
      @include media(lg){
        width: 50%;
      }
      @include media(xl){
        width: 67%;
      }
      @include media(xxl){
        width: 70%;
      }
    }
    &.disable-arrow{
      .events-box-left{
        padding:0 5px 0 0;
        h3{
          margin-left: 0;
        }
      }
      .events-box-right{
        padding:0 0 0 5px;
        h3{
          margin-left: 0;
        }
      }
      .event-line:after{
        display: none;
      }
    }
  }
  &.change-size{
    .events-box-left{
      width: 100%;
      @include media(lg){
        width: 47%;
      }
      @include media(xl){
        width: 63%;
      }
      @include media(xxl){
        width: 67%;
      }
    }
    .events-box-right{
      width: 100%;
      @include media(lg){
        width: 47%;
      }
      @include media(xl){
        width: 30%;
      }
      @include media(xxl){
        width: 27%;
      }
    }
    &.title-box{
      .events-box-left{
        @include media(lg){
          width: 50%;
        }
        @include media(xl){
          width: 70%;
        }
        @include media(xxl){
          width: 70%;
        }
      }
      .events-box-right{
        @include media(lg){
          width: 50%;
        }
        @include media(xl){
          width: 30%;
        }
        @include media(xxl){
          width: 30%;
        }
      }
    }
  }
  .event-line{
    width: 100%;
    height: 8px;
    position: relative;
    &:after{
      content:"";
      position: absolute;
      top:-6px;
      left: -9px;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
    }
  }
}

.button-container{
  display: flex;
  flex-wrap: wrap;
  margin:0 -2rem;
  @include media(lg){
    justify-content: center;
  }
  .button-item{
    padding: 1rem 2rem;
  }
}

.event-container{
  &.box-single{
    .event-container-left{
      width: 100%;
    }
  }
}

.events-big{
  .event-container{
    display: flex;
    flex-wrap: wrap;
    margin:0 -15px;
    @include media(xl){
      margin:0 -3rem;
    }
    .event-container-left{
      padding:0 15px;
      width: 100%;
      @include media(xl){
        padding:0 3rem;
        width: 50%;
      }
    }
    .event-container-right{
      padding:0 15px;
      width: 100%;
      @include media(xl){
        padding:0 3rem;
        width: 50%;
      }
    }
  }
}

.event-item{
  margin:0 0 4rem 0;
  display: block;
  transition: all 0.3s ease-out;
  font-weight: 400;
  text-decoration: none !important;
  @include media(sm){
    display: flex;
  }
  @include media(lg){
    margin:0 0 6rem 0;
  }
  &-image{
    width: 100%;
    height: 160px;
    position: relative;
    overflow: hidden;
    @include media(sm){
      width: 200px;
      min-width: 200px;
      height: 130px;
    }
    @include media(lg){
      width: 20rem;
      min-width: 20rem;
    }
    @include pux-scale-from("height", lg, 130px);
    &-label{
      display: none;
    }
    &-bgc{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      transition: all 0.3s ease-out;
      z-index: 11;
      background-position: center center !important;
      &.center-center {
        background-position: center center !important;
      }
      &.top-center {
        background-position: top center !important;
      }
    }
  }
  &-date{
    display: none;
    @include media(sm){
      width: 170px;
      min-width: 170px;
      display: block;
      &-inner{
        width: 100%;
        border-radius: 10px;
        min-height: 101px;
        padding:2rem;
        display: flex;
        align-items: center;
        transition: all 0.3s ease-out;
      }
      &-to{
        display: block;
        text-align: center;
      }
      &-from{
        &:before{
          content:"\e99b";
          font-size: 23px;
          margin:0 10px 0 0;
        }
        font-size: 28px;
        @include pux-scale-from("font-size", lg, 28px);
        line-height: 1;
        letter-spacing: 0.14px;
        font-weight: 700;
      }
    }
    .date-year{
      display: none;
    }
  }
  &-time{
    font-weight: 400;
    @include media(sm){
      display: block;
      text-align: center;
    }
  }
  &-content{
    padding:15px 0 0 0;
    @include media(sm){
      padding:0 0 0 3rem;
    }
    &-date{
      font-weight: 700;
      padding:0 0 5px 0;
    }
    h3{
      letter-spacing: 0.26px;
      font-size: 22px;
      line-height: 26px;
      @include pux-scale-from("font-size", lg, 26px);
      @include pux-scale-from("line-height", lg, 30px);
    }
    &-perex{
      display: none;
    }
    &-link{
      font-weight: 700;
      display: none;
    }
    &-label{
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding:2px 5px;
      font-size: 16px;
      font-weight: 700;
      @include pux-scale-from("font-size", lg, 16px);
    }
  }
  &:focus,
  &:hover{
    text-decoration: none;
    .event-item-image-bgc{
      @include transform(scale(1.1));
    }
    .link{
      &:after{
        right:-5px;
      }
    }
  }
  &.upcoming-item{
    .event-item-image{
      display: none;
    }
    .event-item-content-date{
      display: block;
      overflow: hidden;
      @include media(sm){
        display: none;
      }
    }
    .event-item-date{
      display: none;
      @include media(sm){
        display: block;
      }
    }
    .event-item-content{
      padding:0;
      @include media(sm){
        padding:0 0 0 3rem;
      }
    }
  }
}

.events-big .event-big-parent,
.events-big .event-big{
  @include media(xl){
    .event-item{
      display: block;
      text-decoration: none !important;
      &-image{
        width: 100%;
        height: 220px;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        @include pux-scale-from("height", lg, 280px);
      }
      &-label,
      &-from{
        position: absolute;
        z-index: 22;
        min-height: 50px;
        height: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 21rem;
        padding:0 1.5rem;
        font-size: 16px;
        font-weight: 700;
        @include pux-scale-from("font-size", lg, 16px);
      }
      &-from{
        top:0;
        left:50%;
        transform: translate(-50%, 0);
      }
      &-label{
        left: 0;
        bottom:0;
      }
      &-time{
        display: inline-block;
        text-align: left;
      }
      .event-item-date-to{
        display: inline-block;
        text-align: left;
      }
      &-content{
        padding:3rem 0 0 0;
        &-date{
          padding: 0;
          @include media(md) {
            padding: 0 0 2rem 0;
          }
        }
        h3{
          letter-spacing: 0.34px;
          @include pux-scale-from("font-size", lg, 34px);
          @include pux-scale-from("line-height", lg, 40px);
          margin:0 0 1.5rem 0;
        }
        &-perex{
          display: block;
          padding:0 0 2rem 0;
        }
        &-link{
          font-weight: 700;
          display: block;
        }
        &-label{
          display: none;
        }
      }
      &.upcoming-item{
        .event-item-content-date,
        .event-item-image{
          display: block;
        }
        .event-item-date{
          display: none;
        }
      }
    }
  }
}

.event-big-single{
  max-width: 520px;
  .event-item{
    display: block;
    &-image{
      width: 100%;
      height: 170px;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      @include media(xs){
        height: 220px
      }
      @include media(sm){
        height: 250px
      }
      @include pux-scale-from("height", lg, 280px);
    }
    &-label,
    &-from{
      position: absolute;
      z-index: 22;
      min-height: 50px;
      height: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 30rem;
      padding:0 1.5rem;
      font-size: 16px;
      font-weight: 700;
      @include pux-scale-from("font-size", lg, 16px);
      @include media(xl){
        min-width: 22rem;
      }
    }
    &-from{
      top:0;
      left:50%;
      transform: translate(-50%, 0);
    }
    &-label{
      left: 0;
      bottom:0;
    }
    &-time{
      display: inline-block;
      text-align: left;
    }
    .event-item-date-to{
      display: inline-block;
      text-align: left;
    }
    &-content{
      padding:3rem 0 0 0;
      &-date{
        padding: 0;
        @include media(md) {
          padding: 0 0 2rem 0;
        }
      }
      h3{
        letter-spacing: 0.34px;
        @include pux-scale-from("font-size", lg, 34px);
        @include pux-scale-from("line-height", lg, 40px);
        margin:0 0 1.5rem 0;
      }
      &-perex{
        display: block;
        padding:0 0 2rem 0;
      }
      &-link{
        display: block;
      }
      &-label{
        display: none;
      }
    }
    &.upcoming-item{
      .event-item-content-date,
      .event-item-image{
        display: block;
      }
      .event-item-date{
        display: none;
      }
    }
  }
  &.box-half{
    .event-item{
      @include media(xl){
        display: flex;
        align-items: center;
      }
      &-image{
        @include media(xl){
          width: 50%;
          min-width: 50%;
        }
      }
      &-content{
        @include media(xl){
          width: 50%;
          min-width: 50%;
          padding:0 0 0 5rem;
        }
      }
    }
  }
  &.box-single{
    .event-item{
      @include media(md){
        display: flex;
        align-items: center;
      }
      &-image{
        @include media(md){
          width: 50%;
          min-width: 50%;
        }
      }
      &-content{
        @include media(md){
          width: 50%;
          min-width: 50%;
          padding:0 0 0 5rem;
        }
      }
    }
  }
}

.event-page{
  display: flex;
  flex-wrap: wrap;
  margin:0 -15px;

  .event-box{
    width: 100%;
    padding:0 15px;
    @include media(md){
      width: 50%;
    }
    @include media(xl){
      width: 33.33%;
    }
    &.box-half{
      @include media(xl){
        width: 50%;
      }
    }
    &.box-single{
      @include media(md){
        width: 100%;
        max-width: 990px;
      }
    }
  }
  .event-big-single{
    max-width: 570px;
    margin:0 auto;
    @include media(md){
      max-width: 100%;
      margin:0;
    }
  }
}

.events-box{
  .event-item{
    margin:0;
  }
  .event-box{
    padding:0 0 5rem 0;
  }
}

.events-box-right{
  .event-respo-title{
    margin:40px 0 30px 0;
  }
}

.events-line{
  position: relative;
  width: 6%;
  display: none;
  @include media(lg){
    display: block;
  }
  &:before{
    content:"";
    position: absolute;
    top:0;
    height: 100%;
    border-left-style: dotted;
    border-left-width: 2px;
    left: 50%;
    margin-left:0;
    @include media(xl){
      margin-left:5px;
    }
  }
}

.change-size{
  .events-line{
    &:before{
      @include media(xl){
        margin-left:-5px;
      }
    }
  }
}

.event-container{
  .event-big-single .event-item-content{
    padding:3rem 0 0 0 !important;
  }
}

.event-item.upcoming-item{
  align-items: center;
  h3{
    margin: 0;
  }
  .event-item-content-label{
    margin: 2rem 0 0 0;
  }
  .event-item-date{
    position: relative;
  }
  .event-item-time{
    position: absolute;
    padding-left: 5px;
    //width: 100%;
  }
}

.events-box{
  .event-item-content h3{
    margin-top: 0;
    @include media(md){
      margin-top: 2rem;
    }
  }
  .event-item{
    padding-bottom: 2rem;
    border-bottom: 1px solid silver;
    @include media(sm){
      padding-bottom: 0;
      border: none;
    }
  }
}

.event-box-new{
  margin-bottom: 10px;
}

.event-item-new{
  display: block;
  border: 1px solid;
  padding: 10px;
  border-radius: 10px;
  .event-item-new-top{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;
  }
  .event-item-new-date{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    letter-spacing: 0.14px;
    &:before{
      content: "\e9a8";
      margin-right: 5px;
      @include pux-static-size("font-size", 22px);
    }
  }
  .event-item-new-name{
    @include pux-static-size("font-size", 20px);
    letter-spacing: 0.26px;
    margin-bottom: 5px;
  }
  .event-item-time{
    text-align: left;
  }
  .event-item-content-label{
    border-radius: 3px;
    @include pux-static-size("font-size", 14px);
  }
  &:hover{
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
  }
}

.gallery-masonry.event-detail-gallery{
  .gallery-box{
    @include media(xl){
      width: 25%;
    }
  }
}