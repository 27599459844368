

// base
$image-content-break: lg;

.image-content-item{
  &-inner{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  h2,
  h3,
  h4{
    margin: 0 0 3rem 0;
  }


  &-left{
    max-width: 500px;
    width: 100%;
    display: flex;
    @include media($image-content-break){
      width: 50%;
      min-width: 50%;
    }
  }
  .image-content-item-text{
    width: 100%;
    padding: 4rem 0;
    @include media(sm){
      padding: 4rem 8rem;
    }
  }
  &-right{
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    @include media($image-content-break){
      width: 50%;
      min-width: 50%;
    }
  }
  &-bgc{
    display: none;
    @include media($image-content-break){
      display: block;
      width: 100%;
      background-size: cover !important;
      background-position: top center !important;
    }
  }
  .image-content-item-btn{
    margin-top: 3rem;
  }
  &.reverse-item{
    .image-content-item-left{
      @include media($image-content-break){
        order: 2;
      }
    }
    .image-content-item-right{
      @include media($image-content-break){
        order: 1;
      }
    }
  }
}


.start-reverse{
  .image-content-item{
    .image-content-item-left{
      @include media($image-content-break){
        order: 2;
      }
    }
    .image-content-item-right{
      @include media($image-content-break){
        order: 1;
      }
    }
    &.reverse-item{
      .image-content-item-left{
        @include media($image-content-break){
          order: 1;
        }
      }
      .image-content-item-right{
        @include media($image-content-break){
          order: 2;
        }
      }
    }
  }
}