
footer{
  font-size: 17px;
  @include pux-scale-from("font-size", lg, 18px);
  *{
    &:before{
      @include pux-static-size("font-size", 20px);
    }
  }
  .footer-top {
    display: flex;
    flex-wrap: wrap;
    padding: 10rem 0 7rem 0;
    margin:0 -20px;
    @include media(md){
      padding: 13rem 0 10rem 0;
    }
    @include media(lg){
      margin:0 -2rem;
    }
    &-item{
      width: 100%;
      padding:0 20px 3rem 20px;
      @include media(md){
        width: 33.33%;
      }
      @include media(lg){
        padding:0 2rem 3rem 2rem;
      }
    }
    &.two-logos{
      .footer-top-item{
        @include media(md){
          width: 50%;
        }
        @include media(lg){
          width: 33.33%;
        }
        &:last-child{
          @include media(md){
            width: 100%;
          }
          @include media(lg){
            width: 33.33%;
          }
        }
      }
    }
    &.two-address{
      .footer-top-item{
        @include media(lg){
          width: 25%;
        }
      }
      &.two-logos:last-child{
        .footer-top-item{
          @include media(md){
            width: 50%;
          }
          @include media(lg){
            width: 25%;
          }
        } 
        .footer-logos.cols-2{
          .footer-logo-item{
            width: 100%;
          }
        }
      }
    }
  }
  .footer-address{
    display: flex;
    align-items: baseline;
    padding:0 0 3.5rem 0;
    &:before {
      display: inline-block;
      content: "\e918";
      margin:0 8px 0 0;
    }
  }
  .footer-contact{
    display: flex;
    flex-wrap: wrap;
    &-item{
      &:first-child{
        padding:0 4rem 0 0 ;
      }
      &:last-child{
        flex-grow: 1;
      }
    }
  }
  .footer-map{
    display: none;
    @include media(xl){
      display: block;
    }
    a{
      display: block;
    }
  }
  .footer-map-link{
    display: flex;
    align-items: baseline;
    font-weight: 700;
    margin:0 0 3.5rem 0;
    &:before {
      display: inline-block;
      content: "\e9a3";
      margin:0 8px 0 0;
      font-weight: 400;
    }
  }
  .insert-phone{
    display: flex;
    align-items: baseline;
    &:before{
      display: inline-block;
      content: "\e99c";
      margin:0 8px 0 0;
    }
  }
  .insert-mail{
    display: flex;
    align-items: baseline;
    &:before{
      display: inline-block;
      content: "\e99a";
      margin:0 8px 0 0;
    }
  }
  .footer-links{
    padding:0 0 3rem 27px;
    &-title{
      font-weight: 700;
      margin:0 0 3rem -15px;
    }
  }
  .footer-social{
    ul{
      margin:0;
      padding:0;
      list-style: none;
      li{

      }
    }
  }
  .footer-logo {
    display: block;
    width: 100%;
    //min-height: 8.5rem;
  }
  .footer-logo-link {
    display: block;
    text-transform: uppercase;
    max-width: 280px;
    margin-top: 2rem;
    @include pux-static-size('font-size', 19px);
    @include pux-static-size('line-height', 23px);
    &.link-formated{
      text-transform: none;
    }
  }
  .social-ico{
    display: flex;
    align-items: baseline;
    &:before{
      display: inline-block;
      margin:0 8px 0 0;
    }
    &.facebook{
      &:before{
        content: "\e9a2";
      }
    }
  }
  .footer-bottom {
    padding: 3.5rem 0 3.5rem 0;
    @include pux-scale-with-min('font-size', 18px, 15px);
    &.row{
      display: block;
      text-align: center;
      @include media(lg){
        display: flex;
        text-align: left;
      }
    }
    div[class^=col] {
      display: block;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      @include media(md) {
        display: flex;
      }
      @include media(lg) {
        justify-content: flex-start;
        margin: 0;
      }
    }
    div[class^=col]:last-child {
      justify-content: center;
      margin: 4rem 0 0 0;
      @include media(lg) {
        justify-content: flex-end;
        flex-wrap: nowrap;
        white-space: nowrap;
        margin: 0;
      }
    }
  }
  .icon-footer-copyright-first {
    margin:2rem auto 2rem;
    height: 44px;
    width: 122px;
    background-image: url('../img/domovy-logo.svg');
    display: block;
    @include media(md) {
      margin:0 4rem 0 2rem;
    }
    @include media(xl){
      margin:0 6rem 0 4rem;
    }
  }
  .icon-footer-copyright-second {
    height: 36px;
    width: 196px;
    background-image: url('../img/virtual-logo.svg');
    display: block;
    margin: 0 auto;
    @include media(md) {
      margin: 0;
    }
  }
  .footer-logo-item{
    width: 100%;
    padding: 0 1.5rem 3rem 1.5rem;
    font-size: 16px;
    line-height: 20px;
    @include pux-scale-from("font-size", lg, 17px);
    @include pux-scale-from("line-height", lg, 21px);
    small{
      font-size: 16px;
      line-height: 20px;
      @include pux-scale-from("font-size", lg, 17px);
      @include pux-scale-from("line-height", lg, 21px);
    }
    &:first-child:last-child{
      padding: 0 1.5rem;
    }
  }
  .footer-logos{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem;
    &.cols-2{
      .footer-logo-item{
        width: 100%;
        @include media(sm){
          width: 50%;
        }
      }
    }
  }

}

#chatbase-bubble-window{
  bottom: 75px !important;
  height: 84dvh !important;
  @media (min-width: 640px) {
    bottom: 75px !important;
    height: 82dvh !important;
  }
}

.footer-info{
  font-size: 14px;
  padding-left: 28px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 24px;
  display: inline-block;
  margin-top: 10px;
}

#chatbase-bubble-button{
  width: 75px !important;
  height: 75px !important;
  border-radius: 50% !important;
  right: 1.5rem !important;
}