
.grecaptcha-badge { 
  visibility: hidden; 
  }

.label-form,
label{
  display: block;
  margin:1rem 0 0.5rem 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"]{
  -moz-appearance:textfield;
}

.form-control,
select,
select.form-control,
textarea,
textarea.form-control,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"]{
  width:100%;
  height: $input-height;
  padding: 0 3rem;
  border-width: 1px;
  border-style: solid;
  border-radius: 0;
  font-size: 18px;
  @include transition;
  @include pux-scale-from("font-size", lg, 18px);
  &:focus{
    outline: 0;
    border-width: 1px;
    border-style: solid;
  }
}

textarea.form-control,
textarea{
  height: $textarea-height;
  padding: 22px 3rem;
  &:focus{
    outline: 0;
    border-width: 1px;
    border-style: solid;
  }
}


input[type="submit"]{
  cursor: pointer;
  &:focus,
  &:hover{

  }
}

.required{
  label{
    &:before{
        content:"*";
        display: inline-block;
        vertical-align: 0.6rem;
        margin: 0 2px 0 0;
        @include pux-static-size("font-size", 10px);
    }
  }
}

label{
  &.required{
    &:after{
      content:"*";
      display: inline-block;
      vertical-align: 0.6rem;
      margin: 0 2px 0 0;
      @include pux-static-size("font-size", 10px);
    }
  }
}

.file{
  .uploader-upload{
      display:none;
  }
  label{
      display:inline-block;
      padding:1rem 2rem;
      text-transform:uppercase;
      cursor:pointer;
      @include pux-static-size("font-size", 14px);
      border-radius: 0;
      max-width: 24rem;
      margin:0;
      width: 100%;
      text-align:center;
      cursor:pointer;
      letter-spacing:1px;
      outline:0 !important;
  }
  .file-name{
      padding:0.5rem;
  }
}

@if($use-blink-error == 1){
  .form-validation-error,
  .Error:not(.form-validation-valid){
      select,
      .selectric,
      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="number"],
      textarea{
          border: 1px solid $error-input-border !important;
          @include animation(normal, blink, 1s, ease, 2);
      }
  }

  @include keyframes(blink){
      0% {
          background: $error-blink-start;
      }
      50% {
          background: $error-blink-final;
      }
      100% {
          background: $error-blink-start;
      }
  }
  .Error .form-validation-valid,
  .form-validation-valid{
      select,
      .selectric,
      input[type="text"],
      input[type="password"],
      input[type="email"],
      input[type="number"],
      textarea{
      background: #fff !important;
      animation: unset !important;
      border: 1px solid #757575 !important;
    }
  }
    select.form-validation-valid,
    .selectric.form-validation-valid,
    input[type="text"].form-validation-valid,
    input[type="password"].form-validation-valid,
    input[type="email"].form-validation-valid,
    input[type="number"].form-validation-valid,
    textarea.form-validation-valid{
      background: #fff !important;
      animation: unset !important;
      border: 1px solid #757575 !important;
    }
  .Error:has(.form-validation-valid) + .EditingFormErrorLabel,
  .form-validation-valid + .EditingFormErrorLabel{
    display: none;
  }
}

div.form-validation-valid{
  .FormErrorLabel,
  .EditingFormErrorLabel{
    display: none;
  }
}

.ErrorLabel,
.form-control-error,
div.form-validation-error,
.EditingFormErrorLabel{
    font-size: 14px;
    color:$error-label-color;
    display:block;
    margin:0.5rem 0 0 0;
}

.hide-error{
  .EditingFormErrorLabel{
    display: none;
  }
}

.form-validation-error + .EditingFormErrorLabel{
  display: none;
}

.radio{
  input[type="radio"]{
    display:none;
    & +label{
      position:relative;
      display: flex;
      align-items: baseline;
      margin:0;
      &:before{
        content:"";
        border-radius: 50%;
        width:20px;
        height:20px;
        min-width: 20px;
        display:block;
        border-width: 5px;
        border-style: solid;
        margin:0 10px 0 0;
        position: relative;
        top: 3px;
      }
    }
  }
}

.checkbox{
  input[type="checkbox"]{
    display:none;
    & +label{
      position:relative;
      margin:0;
      display: inline-flex;
      align-items: baseline;
      &:before{
        content:"\e9a6";
        width:20px;
        min-width: 20px;
        height:20px;
        display:flex;
        align-items: center;
        justify-content: center;
        margin:0 10px 0 0;
        font-size: 14px;
      }
    }
  }
}

.row-form{
  display: flex;
  flex-wrap: wrap;
  margin:0 -1rem;
}

.row-form{
  [class^="col-"]{
    padding-left:1rem;
    padding-right:1rem;
  }
}

.floating-label-text,
.floating-label {
    $input-height: 70px;
    $input-padding: 0;
    position: relative;
    width: 100%;
    padding:0 0 10px 0;
    @include transition;
    & > .placeholder {
      opacity: 1;
      pointer-events: none;
      line-height: $input-height;
      transform: translate3d(0,-100%,0);
      padding: $input-padding;
      display: inline-block;
      position: relative;
      z-index: 2;
      margin: 0;
      font-size: 30px;
      &.active {
        opacity: 0;
      }
    }
    & > label {
      position: absolute;
      top: 22px;
      left: 3rem;
      display: inline-block;
      transition: all .3s ease;
      margin:0;
      opacity: 1;
      z-index:11;
      padding: $input-padding;

      &.is-visible {
        transform: translate3d(0,-14px,0);
        opacity: 1;
        font-size:14px;
      }
    }
    & > input {
      position: relative;
      z-index: 1;
      float: left;
      width: 100%;
      height: $input-height;
      clear: both;
      padding: $input-padding;
      background-color: transparent;
    }
    &.active{
        padding:0 0 10px 0;
        input{
          padding:15px 3rem 0 3rem;
        }
        textarea.form-control,
        textarea{
          padding:30px 3rem 15px 3rem;
        }
        label{
          transform: translate3d(0,-15px,0);
            opacity: 1;
            font-size:14px;
            left: 3rem;
        }
    }
}

.form-container{
  max-width: 500px;
  margin:0 auto;
  .ErrorLabel{
    text-align: center;
    display: block;
    margin:0 0 2rem 0;
  }
  input[type="submit"]{
    margin:2rem auto 0 auto;
    display: flex;
  }
}


.InfoLabel{
  padding: 7px 10px;
	margin: 0;
	border: 1px solid transparent;
	font-weight: 700;
	margin-bottom: 20px;
  display:block;
  text-align:center;
  color: #155724;
	background-color: #d4edda;
	border-color: #d4edda;
	h2,
	h3 {
		color: #155724;
	}
}


.job-bottom{
  display: flex;
  gap: 8rem;
  flex-wrap: wrap;
  @include media(md){
    flex-wrap: nowrap;
  }
}

.job-form{
  width: 100%;
  .FormButton{
    margin-top: 2rem;
    margin-bottom: 2rem;
    //margin-left: auto;
    display: inline-block;
  }
}

.job-person{
  .person-item-inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4rem;
  }
  .person-item-content{
    padding: 0 !important;
  }
  .person-item-description-text,
  .person-item-description{
    display: none !important;
  }

}

.file-new{
  input[type="file"],
  .ExplanationText{
    display: none;
  }
  .file-label{
    display: flex;
    gap: 2rem;
    flex-direction: column;
    @include media(sm){
      flex-direction: row;
      align-items: center;
    }
  }
  .file-placeholder{
    width:100%;
    height: $input-height;
    padding: 0 3rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 0;
    font-size: 18px;
    @include transition;
    @include pux-scale-from("font-size", lg, 18px);
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .file-button{
    cursor: pointer;
  }
}

.wizard-file{
  input[type="file"],
  .ExplanationText{
    display: none;
  }
  .file-label{
    display: inline-flex;
    gap: 2rem;
    flex-direction: column;
    margin: 0;
    @include media(sm){
      flex-direction: row;
      align-items: center;
    }
  }
  .file-placeholder{
    width:100%;
    height: $input-height;
    border:none;
    border-radius: 0;
    font-size: 13px;
    @include transition;
    @include pux-scale-from("font-size", lg, 13px);
    pointer-events: none;
    display: flex;
    align-items: center;
    &.active{
      cursor: pointer;
      //pointer-events: all;
    }
  }
  .file-button{
    cursor: pointer;
  }
}

.recaptcha-privacy{
  @include pux-scale-with-min("font-size", 14px, 12px);
}

#big{
  .recaptcha-privacy{
    @include pux-scale-with-min("font-size", 16px, 14px);
  }
}

.form-chat-banner{
  padding: 10px;
  margin: 1.5rem 0;
  cursor: pointer;
}