
.btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 30px;
  padding:1.1rem 3.5rem;
  box-shadow: none;
  font-weight: 700;
  letter-spacing: 0.09px;
  min-height: 45px;
  transition: all 0.3s ease-out;
  text-decoration: none !important;
  &:before{
    @include pux-static-size("font-size", 20px);
    margin:0 10px 0 0;
  }
  &:focus,
  &:hover{
      text-decoration: none;
      @include box-shadow(none);
  }
  &:focus{
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 1px;
  }
  &.loading{
    &:before{
    }
  }
  font-size: 18px;
  @include pux-scale-from("font-size", lg, 18px);
}
