.page-map {
  @include media(lg){
    display: flex;
    flex-wrap: wrap;
  }
}
.page-map-column {
  > ul {
    li {
      padding: 0.25rem 0 0.25rem 2rem;
      margin: 0;
      &:before {
        display: none;
      }
    }
    >li {
      width: 100%;
      margin-bottom: 2rem;
      @include media(sm) {
        width: 50%;
      }
      @include media(md) {
        width: 33.333%;
      }
      @include media(xxl) {
        width: auto;
      }
    }
    >li > a ,
    >li > span {
      font-weight: 700;
      @include media(xl) {
        @include pux-static-size('font-size', 20px);
      }
    }
    ul {
      margin: 0;
    }

  }
  &:first-child {
    flex-shrink: 1;
    &>ul {
      @include media(lg){
        display: flex;
        flex-wrap: nowrap;
      }

    >li {
      padding: 0 5rem;
    }
    }
  }
  &:last-child {
    > ul {
      li {
        padding: 0 5rem 1.5rem 5rem;
        margin-bottom: 0;
        width: 100%;
      }
  }
}
.menu-home a {
  position: relative;
  padding-left: 20px;
  &:before {
    content: "\e919";
    font-size: 13px;
    display: inline;
    vertical-align: middle;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 0;
    top: 50%;
  }
}
}
