
.number-box{
  display: flex;
}

.number-item{
  padding: 5rem;
  border-radius: 20px;
  width: 100%;
  &-ico{
    font-size: 60px;
    height: 70px;
    line-height: 1;
    @include media(lg){
      font-size: 80px;
      height: 90px;
    }
    @include media(xl){
      font-size: 100px;
      height: 110px;
    }
  }
  &-number{
    width: 70px;
    @include pux-scale-with-min("font-size", 45px, 45px);
    @include pux-scale-with-min("line-height", 65px, 65px);
    border: 3px solid;
    border-radius: 50%;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include media(lg){
      width: 90px;
      height: 90px;
      @include pux-scale-with-min("font-size", 55px, 55px);
      @include pux-scale-with-min("line-height", 85px, 85px);
    }
    @include media(xl){
      width: 110px;
      height: 110px;
      @include pux-scale-with-min("font-size", 65px, 65px);
      @include pux-scale-with-min("line-height", 105px, 105px);
      border: 4px solid;
    }
  }
  h2,
  h3{
    margin:0 0 2rem 0;
  }
  &-perex{
    margin: 3rem 0 0 0;
  }
  &-btn{
    margin: 2rem 0 0 0;
  }
  .number-item-inner{
    @include media(sm){
      display: flex;
    }
    .number-item-number{
      width: auto;
      height: auto;
      border: none;
      line-height: 1;
    }
    .number-item-ico{
      @include media(lg){
        font-size: 78px;
        height: auto;
      }
      @include media(xl){
        font-size: 78px;
        height: auto;
      }
    }
    .number-item-perex{
      margin: 3rem 0 0 0;
      @include media(sm){
        margin: 0;
      }
    }
  }
  .number-item-inner-left{
    padding-right: 4rem;
  }
}

.number-left{
  .dynamic-container .dynamic-box{
    width: 100%;
    @include media(sm){
      width: 100%;
    }
    @media (min-width: 600px){
      width: 100%;
    }
    @include media(md){
      width: 50%;
    }
    @include media(lg){
      width: 50%;
    }
    @include media(xl){
      width: 33.33%;
    }
  }
}
