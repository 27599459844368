
.video-box{
  .embed-video{
    height: 41rem;
  }
  &.box-single{
    max-width: 1000px;
    .embed-video{
      height: 56rem;
    }
  }
}

.embed-video {
  position: relative;
  width: 100%;
  overflow: hidden;
  iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .embed-video-image{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    background-position: center !important;
    z-index: 22;
    transition: all 0.3s ease-out;
  }
  &:hover,
  &:focus{
    .embed-video-image{
      @include transform(scale(1.1));
    }
  }
}

.embed-video-overflow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  &.autoplay-1,
  &.played {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -moz-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -o-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    -ms-transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
    transition: opacity 1000ms 400ms, visibility 0ms 1000ms;
  }
}

.embed-video-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translate(-50%, -50%));
  z-index: 33;
  &:before {
    content:"";
    width: 0;
    height: 0;
    border-top: 2.2rem solid transparent;
    border-bottom: 2.2rem solid transparent;
    border-left-width: 3.8rem;;
    border-left-style: solid;
    margin-left:1rem;
    transition: 250ms;
  }
}
