
.slider-new.slider-second{
    .slider-grey-inner{
        background: none;
        padding: 3rem 0 3rem 0;
        @include media(lg){
            right: 15px;
        }
    }
    .slider-grey-item{
        padding: 2.5rem;
        @include media(lg){
            margin:0 0 2.5rem 0;
        }
    }
    .slider-grey-item-date{
        display: none;
    }
    .main-slider-content{
        max-width: 540px;
    }
    .slider-founder{
        padding: 2.5rem 3.5rem;
    }
    .main-slider-text{
        padding: 2.5rem 3.5rem;
        font-family: 'Roboto-Condensed';
        h1,
        h2{
            margin: 0;
            font-weight: 400;
            font-family: 'Roboto-Condensed';
        }
        h1 strong,
        h2 strong{
            font-family: 'Roboto-Condensed';
            font-weight: 700;
        }
    }
    .slider-founder-title{
        font-family: 'Roboto-Condensed';
    }
    .main-slider-content{
        margin-top: 0;
    }
    .btn{
        border-radius: 0;
        text-transform: uppercase;
        font-weight: 700;
        padding: 1.4rem 1.5rem;
        font-family: 'Roboto-Condensed';
    }
    .slider-grey-item .h3{
        font-family: 'Soleil';
        font-weight: 700;
    }
    .slider-grey-item-date{
        font-family: 'Soleil';
    }
    .link {
        font-weight: 400;
        font-style: italic;
        text-decoration: underline !important;
        display: inline-block;
        font-family: 'Soleil';
        &:after{
            text-decoration: none;
            display: inline-block;
        }
        &:hover{
            text-decoration: none !important;
        }
    }
    .slider-grey-item:hover{
        .link{
            text-decoration: none !important;
        }
    }
    .main-slider-content-btn + .slider-founder{
        margin-top: 2rem;
    }
    .btn-order{
        .main-slider-content-btn + .slider-founder{
            margin-top: 0;
        }
    }
}

.slider-new.slider-third{
    .slider-grey-inner{
        background: none;
        padding: 3rem 0 3rem 0;
        @include media(lg){
            right: 15px;
        }
    }
    .slider-grey-item:hover{
        .link{
            text-decoration: none !important;
        }
    }
    .link {
        font-weight: 400;
        font-style: italic;
        text-decoration: underline !important;
        display: inline-block;
        font-family: 'Soleil';
        &:after{
            text-decoration: none;
            display: inline-block;
        }
        &:hover{
            text-decoration: none !important;
        }
    }
    h1,h2{
        text-transform: none;
    }
    .slider-grey-item .h3{
        margin: 10px 0 5px 0;
        font-family: 'basic-sans';
        font-weight: 700;
    }
    .slider-grey-item-date{
        font-family: 'basic-sans';
        font-weight: 700;
    }
    .slider-grey-item-link{
        margin: 0 0 10px 0;
    }
    .slider-grey-item{
        @include media(lg){
            margin:0 0 2.5rem 0;
        }
    }
    .slider-grey-item-date{
        display: none;
    }
    .slider-grey-inner{
        .slider-grey-box:nth-child(1){
            .slider-grey-item{
                padding: 30px 3.5rem;
                clip-path: polygon(0 10%, 100% 0, 100% 100%, 0 90%);
            }
        }
        .slider-grey-box:nth-child(2){
            .slider-grey-item{
                padding: 20px 3.5rem;
                clip-path: polygon(0 0, 100% 0, 100% 93%, 0 100%);
            }
        }
        .slider-grey-box:nth-child(3){
            .slider-grey-item{
                padding: 20px 3.5rem;
                clip-path: polygon(0 3%, 100% 0, 100% 93%, 0 100%);
            }
        }
    }
    .main-slider-content{
        max-width: 100%;
        padding: 50px 30px 50px 30px;
        position: relative;
        margin-top: 3rem;
        @include media(md){
            max-width: 545px;
            padding: 70px 50px 70px 90px;
        }
        @include media(xl){
            max-width: 650px;
        }
        &:before{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            clip-path: polygon(0 7%, 100% 0, 96% 100%, 3% 88%);
            z-index: 2;
        }
        &:after{
            content: "";
            background: url('../img/slider-frame.svg');
            width: 100%;
            height: 110%;
            position: absolute;
            top: -5%;
            left: 0;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 100%;
            display: none;
            @include media(md){
                display: block;
            }
        }
    }
    .main-slider-content img{
        max-width: 100%;
        margin-bottom: 0;
    }
    .main-slider-design-helper{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: none;
        @include media(md){
            display: block;
        }
        &:before{
            content: "";
            width: 10px;
            height: 15rem;
            border-radius: 8px;
            transform: rotate(-2deg);
            position: absolute;
            left: 5rem;
            bottom: 2rem;
        }
        &:after{
            content: "";
            width: 10px;
            height: 15rem;
            border-radius: 8px;
            transform: rotate(2deg);
            position: absolute;
            right: 5rem;
            top: -1.5rem;
        }
    }
    .btn{
        border-radius: 0;
        text-transform: uppercase;
        font-weight: 700;
        padding: 1.4rem 1.5rem;
        font-family: 'basic-sans';
    }
    h1,
    h2{
        font-family: 'basic-sans';
    }
    .main-slider-text,
    .main-slider-content-btn,
    .slider-founder{
        position: relative;
        z-index: 3;
        font-family: 'basic-sans';
    }
    .main-slider-content{
        @include media(md){
            display: grid;
            gap: 2.5rem;
            align-items: center;
            grid-template-columns: 1fr;
            grid-template-areas: 
            "top top"
            "founder btn";
        }
    }
    .main-slider-text{
        grid-area: top;
    }
    .slider-founder{
        grid-area: founder;
    }
    .main-slider-content-btn{
        grid-area: btn;
    }
    .slick-dots{
        @include media(md){
            justify-content: flex-end; 
        }
        @include media(lg){
            justify-content: center; 
        }
    }
}

.slider-new{
    .slider-dots{
        position: absolute;
        right: 10px;
        width: calc(100% - 20px);
        top: 410px;
        z-index: 222;
        @include media(sm){
            top: 400px;
            right: 20px;
            width: calc(100% - 40px);
        }
        @include media(lg){
            top: 500px;
        }
    }
    .slick-dots{
        margin: 0;
        padding: 0;
        list-style: 0;
        display: flex;
        justify-content: center;
        gap: 10px;
        @include media(lg){
            justify-content: center; 
        }
        li{
            margin: 0;
            padding: 0;
            &:before{
                display: none;
            }
            button{
                font-size: 0;
                padding: 0;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                border: none;
                &:focus{
                    outline: none;
                }
            }
        }
    }
    .slick-dots li:first-child:last-child{
        display: none;
      }
}