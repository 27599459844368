
.pux-container{
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-left;
    padding-right: $container-padding-right;
    max-width: $container-max-width;
    &:after{
        display: block;
        content: "";
        clear: both;
    }
    @include media(sm){
        padding-left: $container-padding-left-sm;
        padding-right: $container-padding-right-sm;
    }
    @include media(lg){
      padding-left: $container-padding-left-lg;
      padding-right: $container-padding-right-lg;
    }
    @include media(xl){
      padding-left: $container-padding-left-xl;
      padding-right: $container-padding-right-xl;
    }
    .pux-container{
      padding-left:0;
      padding-right: 0;
    }
}

.pux-container{
    .row{
        @include flex-block;
        @include flex-wrap(wrap);
        margin-left: $row-margin-left;
        margin-right: $row-margin-right;
        @include media(sm){
          margin-left: $row-margin-left-sm;
          margin-right: $row-margin-right-sm;
        }
        @include media(lg){
          margin-left: $row-margin-left-lg;
          margin-right: $row-margin-right-lg;
        }
        @include media(xl) {
          margin-left: $row-margin-left-xl;
          margin-right: $row-margin-right-xl;
        }
    }
}

.pux-container{
    [class^="col-"]{
        padding-left: $col-padding-left;
        padding-right: $col-padding-right;
        @include media(sm){
          padding-left: $col-padding-left-sm;
          padding-right: $col-padding-right-sm;
        }
        @include media(lg){
          padding-left: $col-padding-left-lg;
          padding-right: $col-padding-right-lg;
        }
        @include media(xl){
          padding-left: $col-padding-left-xl;
          padding-right: $col-padding-right-xl;
        }
    }
}

.pux-fluid-container{
    padding-left: $fluid-container-padding-left;
    padding-right: $fluid-container-padding-right;
    @include media(md){
      padding-left: $fluid-container-padding-left-md;
      padding-right: $fluid-container-padding-right-md;
    }
    @include media(xl){
      padding-left: $fluid-container-padding-left-xl;
      padding-right: $fluid-container-padding-right-xl;
    }
}

.small-container{
  max-width: 860px;
  margin:auto;
  padding-left: $container-padding-left;
  padding-right: $container-padding-right;
  @include media(sm){
      padding-left: $container-padding-left-sm;
      padding-right: $container-padding-right-sm;
  }
  @include media(lg){
    padding-left: $container-padding-left-lg;
    padding-right: $container-padding-right-lg;
}
@include media(xl){
  padding-left: $container-padding-left-xl;
  padding-right: $container-padding-right-xl;
}
}

.pux-container{
  .small-container{
    max-width: 780px;
    padding-left:0;
    padding-right:0;
  }
}

.EditMode,
.DesignMode {
  header {
    position: static;
  }
  .header-spacer{
    display: none;
  }
}


//* __Lazy Load__ */
@keyframes animateLoaded__ {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

[data-lazy-type] {
  &.loaded {
    animation: $imgLoadAnimation;
  }
}

.back-to-top {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 56px;
  width: 56px;
  bottom: 90px;
  right: 2.5rem;
  transform: translateY(15px);
  text-align: center;
  border-radius: 100%;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 200ms;
  z-index: 1049;
  @include media(sm){
    bottom: 90px;
    right: 2.5rem;
  }
  span{
    display: none;
  }
  &:hover, &:focus {
    text-decoration: none;
    &::after {
      transform: translateY(-7px);
    }
  }
  &::after {
    content: '\e9c7';
    transition: all ease-in-out 200ms;
  }
  &-show {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}
