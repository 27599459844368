
.map-box{
  position: relative;
  width:100%;
  text-align: center;
  &-name{
    padding: 1.5rem 3rem;
    @include border-radius(6rem);
    display:inline-block;
    position: relative;
    z-index: 888;
    @include transform(translate(0, 50%));
  }
  #map-canvas{
    width: 100%;
    height:50rem;
  }
}

.map-points{
  display: none;
}

.marker-detail{
  display: none;
}

.marker-detail-image{
  display: none;
  @include media(md){
    display: block;  
  }
}

.marker-detail-inner{
  display: block;
  @include media(md){
    display: flex;  
  }
  img{
    max-width: 100%;
    margin-bottom: 15px;
    border-radius: 10px;
    @include media(md){
      max-width: 220px;
      margin-bottom: 0;
    }
  }
}

.is-image{
  .marker-detail-text{
    padding:0 0 0 15px;
  }
}

.map-box{
  h4{
    text-align: left;
    padding-bottom: 2px;
  }
}

.marker-detail-inner{
  padding: 0 12px 12px 0px;
  @include media(md){
    padding: 10px 22px 22px 10px;
  }
  .btn {
    text-transform: uppercase;
  }
}

.gm-style .gm-style-iw-d{
  overflow: auto !important;
}

.gm-style .gm-style-iw-c{
  border-radius: 10px;
  //background-color: rgba(255,255,255,0.9);
}

.gm-style .gm-style-iw-tc::after{
  //background-color: rgba(255,255,255,0.9);
  display: none;
}

.marker-detail-subtitle{
    text-align: left;
    padding-bottom: 8px;
    @include pux-scale-with-min("font-size", 18px, 16px);
    @include pux-scale-with-min("line-height", 18px, 16px);
}

.marker-detail-service,
.marker-detail-capacity,
.marker-detail-contact,
.marker-detail-mail,
.marker-detail-address{
  display: flex;
  align-items: center;
  line-height: 26px;
  font-size: 16px;
  &:before{
    padding-right: 10px;
    line-height: 26px;
  }
  a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}

.marker-detail-text{
  text-align: left;
}

.marker-detail-btn{
  text-align: left;
  margin-top: 15px;
  @include media(md){
    display: none;
  }
}

.marker-detail-content{
  text-align: left;
  line-height: 20px;
  font-size: 14px;
}

.marker-detail-capacity{
  &:before{
    content: "\e9b2";
  }
}

.marker-detail-contact{
  &:before{
    content: "\e9ca";
  }
}

.marker-detail-address{
  &:before{
    content: "\e9cd";
  }
}

.marker-detail-mail{
  &:before{
    content: "\e9c9";
  }
}

.gm-style .gm-style-iw-c{
  padding-top: 22px;
}

.marker-detail-close{
  opacity: 1 !important;
  img{
    display: none !important;
  }
  &:before{
    content:"\03a7";
    font-family: $font-family !important;
  }
  display: none;
}

.gm-style .gm-style-iw-c{
  //border-radius: 0 !important;
}

.bgc-main{
  .marker-detail-inner {
    color: #4c4c4c;
    h4{
      color: #4c4c4c;
    }
  }
}
