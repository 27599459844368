
.citation-item{
  max-width: 800px;
  margin:0 auto;
  @include media(md){
    display: flex;
    align-items: center;
  }
  &-image{
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    display: block;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin: 3rem auto;
    width: 125px;
    min-width: 125px;
    height: 125px;
    @include media(md){
      display: inline-block;
      margin: 0 auto;
    }
    @include pux-scale-from("font-size", lg, 180px);
    @include pux-scale-from("min-width", lg, 180px);
    @include pux-scale-from("height", lg, 180px);
  }
  &-content{
    @include media(md){
      padding:0 0 0 5rem;
    }
    &-text{
      font-weight: 700;
      padding:0 0 2.5rem 0;
      font-style: italic;
    }
    &-author{
      font-style: italic;
      text-align: right;
      &-name{
        font-weight: 700;
      }
    }
  }
}
