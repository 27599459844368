
a{
    cursor: pointer;
    &:focus,
    &:hover{
      text-decoration: none;
    }
}

main{
  a{
    font-weight: 700;
  }
}

body{
  &.contrast{
    main{
      a{
        text-decoration: underline;
        &:focus,
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}

.link{
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-decoration: none !important;
  &:before{
    @include pux-static-size("font-size", 20px);
    margin:0 10px 0 0;
  }
  &:hover,
  &:focus{
    text-decoration: none;
  }
  &.no-text{
    font-size: 0;
    &:before{
      margin: 0;
    }
  }
  &.loading{
    &:before{
    }
  }
}

.link-arrow{
  &:after{
    @include pux-static-size("font-size", 6px);
    content: "\e902";
    padding: 0 4px;
    transform: rotate(270deg);
    transition: all 0.3s ease-out;
    position: relative;
    right: 0;
  }
  &:focus,
  &:hover{
    &:after{
      right: -5px;
    }
  }
}

.link-cross{
  &:before{
    @include pux-static-size("font-size", 18px);
    content:"\e9a1";
  }
}

.link{
  span[class^="icon-"]{
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: flex-end;
    @include pux-scale-with-min("font-size", 30px, 20px);
    margin-right: 10px;
    text-decoration: none!important;
  }
}

.blank{
  display: inline-flex !important;
  @include flex-wrap(nowrap);
  @include align-items(flex-end);
  &:before{
    @include pux-scale-with-min("font-size", 18px, 16px);
    margin-right: 10px;
    display: inline-block!important;
    text-decoration: none!important;
  }
  & span + span{
    display: none;
  }
}

main{
  .blank{
    &:before{
      content: "\e92c";
    }
  }
}

.no-ico{
  display: block;
  span{
    display: none !important;
  }
}

.no-download{
  display: block;
  span[class^="icon-"]{
    display: none;
  }
}
