
.logo-carousel{
  display: flex;
  overflow: hidden;
  padding: 0 45px;
  &.slick-initialized{
    display: block;
  }
}

.logo-box{
  padding:0 15px;
  display: flex !important;
  height: 150px;
  flex-grow: 1;
  outline: 0;
}

.logo-item{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: grayscale(100%);
  transition: all 0.3s ease-out;
  @include opacity(0.5);
  &:hover{
    filter: grayscale(0);
    @include opacity(1);
  }
  &-inner{
    width: 100%;
    text-align: center;
  }
  img{
    display: inline-block;
    max-height: 150px !important;
  }
}

.logo-colored{
  .logo-item{
    filter: grayscale(0);
    opacity: 1;
  }
  a.logo-item{
    &:hover{
      opacity: .6;
    }
  }
}

.logo-carousel{
  position: relative;
  .slick-arrow{
    border:none;
    background: none;
    position: absolute;
    width: 22px;
    height: 44px;
    top:50%;
    margin-top:-22px !important;
    font-size: 0;
    outline: 0;
    &:before{
      font-size: 44px;
      display: block;
    }
    &.slick-prev{
      left:0;
      &:before{
        content:"\e99e";
      }
    }
    &.slick-next{
      right:0;
      &:before{
        content:"\e99f";
      }
    }
  }
}
