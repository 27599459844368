
main{
  table{
    width:100%;
    border:none !important;
    tr{
      th,
      td{
        padding:1rem 1.5rem;
      }
    }
  }
  .respo-table{
    table{
      width:100%;
      thead{
        display: none;
        @include media($respo-table-break){
          display: table-header-group;
        }
        tr{
          th{
            font-weight: bold;
            padding:1rem 1.5rem;
          }
        }
      }
      tbody{
        tr{
          display: block;
          border-bottom: none;
          margin:0 0 2rem 0;
          @include media($respo-table-break){
            display: table-row;
            margin: 0;
            border: none;
          }
          td{
            display: flex;
            justify-content: space-between;
            width:100%;
            @include media($respo-table-break){
              display: table-cell;
              border: none;
              width:auto;
            }
            &:before{
              content:attr(data-th);
              display:block;
              text-align:left;
              font-family:$font-family !important;
              margin:0 1.5rem 0 0;
              @include media($respo-table-break){
                  display:none;
              }
            }
          }
        }
      }
    }
  }
}

main{
  .job-table{
    border: none;
    td{
      border: none;
      display: block !important;
      vertical-align: top;
      @include media(sm){
        display: table-cell !important;
      }
      &:first-child{
        font-weight: 700;
      }
    }
  }
}