

.branches-container{
  width: 100%;
  position: relative;
  //min-height: 50vh;
}

.branches-container-inner{
  @include media(lg){
    display: flex;
  }
}

#map-branches{
  width: 100%;
  min-height: 70vh;
  @include media(lg){
    /*position: absolute;
    top:0;
    right:0;
    width: calc(100% - 37rem - ((100vw - #{$container-max-width} + (#{$main-left-xl} * 2)) / 2));
    height: 100%;
    order: 2;*/
    max-width: 1100px;
    min-height: unset;
    order: 2;
  }
}

.branches-box{
  position: relative;
  width: 100%;
  z-index: 888;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  @include media(md){
    display: flex;
    flex-wrap: wrap;
  }
  @include media(lg){
    width: 40rem;
    display: block;
    //padding: 0 0 4rem 0;
    order: 1;
  }
  //@media (min-width: 1500px){
    //left: calc((100vw - #{$container-max-width} + (#{$main-left-xl} * 2)) / 2);
  //}
}

.branch-item{
  padding: 3rem 3rem 3rem 3rem;
  width: 100%;
  border:1px solid;
  @include pux-static-size("font-size", 16px);
    @include pux-static-size("line-height", 24px);
  cursor: pointer;
  @include media(md){
    width: 33.33%;
  }
  @include media(lg){
    width: 100%;
  }
  a{
    font-weight: 700;
    display: inline-block;
    &:before{
      margin-right: 1.8rem;
      display: inline-block;
    }
    &:focus,
    &:hover{
      text-decoration: underline;
      &:before{
        text-decoration: none;
      }
    }
  }
  h3{
    margin: 0;
    @include pux-static-size("font-size", 18px);
    @include pux-static-size("line-height", 24px);
  }
  &-name{
    display: flex;
    align-items: center;
    margin: 0 0 1.5rem 0;
    span{
      margin-right: 10px;
    }
    img{
      width: 4rem;
    }
    .branch-item-image-active{
      display: none;
    }
  }
  &-address{
    display: flex;
    margin-bottom: 15px;
    &:before{
      content:"\e9cb";
      margin-right: 1.5rem;
      position: relative;
      top: 3px;
      font-size: 24px;
    }
    a{
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
  &-phone{
    margin-bottom: 15px;
    a{
      text-decoration: underline;
      &:before{
        content:"\e9ca";
        font-size: 24px;
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
  &-email{
    margin-bottom: 15px;
    a{
      text-decoration: underline;
      &:before{
        content:"\e9c9";
        font-size: 19px;
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
  &-info{
    margin-bottom: 15px;
    display: flex;
    &:before{
      content:"\e9c8";
      margin-right: 1.5rem;
      position: relative;
      top: 3px;
      font-size: 24px;
    }
  }
  &:nth-child(2),
  &:nth-child(3){
    margin-top: -1px;
  }
  &.active{
    position: relative;
    /*&:after{
      content: "";
      width: 100%;
      height: 1px;
      bottom: -1px;
      position: absolute;
    }*/
    .branch-item-image{
      display: none;
    }
    .branch-item-image-active{
      display: block;
    }
  }
}

.branches-btn{
  width: 100%;
  margin: 4rem 0 0 0;
  padding: 0 3rem;
}

.branches-window{
  display: none;
}

.branches-detail-position{
  bottom: 0 !important;
  font-size: 16px;
  line-height: 24px;
  .branches-window-name{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
  .gm-style-iw{
    width: 250px;
    transform: translate(41px, -96px);
    box-shadow: none;
  }
  .gm-style-iw-tc{
    display: none;
  }
  .gm-style-iw-d{
    background: transparent;
    overflow: hidden !important;
    padding: 0 15px 15px 0;
  }
  button{
    display: none !important;
  }
  a{
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }
}