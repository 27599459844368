
.map-point{
  display: none;
}

.brno-hr{
  margin: 3rem 0;
}

.breadcrumbs{
  display: block;
  padding:0 0 1rem 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  .home{
    font-size: 0 !important;
    transition: all 0.3s ease-out;
    &:after{
      content: "\e918";
      font-size: 18px;
      @include pux-scale-from("font-size", lg, 18px);
      display: inline;
    }
    &:hover,
    &:focus{
      text-decoration: none;
      &:after{
        text-decoration: none;
      }
    }
  }
  a{
    transition: all 0.3s ease-out;
    font-weight: 400;
    &:focus,
    &:hover{
      text-decoration: underline;
    }
    &[href="#"]{
      cursor: default;
      text-decoration: none !important;
    }
  }
  a,span{
    display: inline;
    font-size: 16px;
    @include media(md){
      font-size: 18px;
    }
    @include pux-scale-from("font-size", xl, 18px);
  }
  .breadcrumbs-separator:after{
    display: inline-block;
    vertical-align: middle;
    content: "\e902";
    position: relative;
    @include transform(scale(0.25) rotate(270deg));
  }
  &.breadcrumbs-old{
    opacity: 1;
  }
  &.breadcrumbs-new{
    opacity: 1;
    display: flex;
    align-items: center;
    overflow: visible;
    .CMSBreadCrumbsCurrentItem{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }
  .breadcrumbs-collapse-btn{
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 2px 10px;
    border: 1px solid #c1d0e1;
    border-radius: 3px;
    background-color: transparent;
    cursor: pointer;
    line-height: 1;
    &:before{
      position: absolute;
      z-index: 33;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: #fff;
      content: "";
      opacity: 0;
      border:1px solid #fff;
    }
  }
  .breadcrumbs-collapse-list{
    position: absolute;
    z-index: 22;
    top: 100%;
    left: -46px;
    flex-direction: column;
    gap: 5px;
    display: none;
    width: 280px;
    padding: 15px 20px;
    border: 1px solid #c1d0e1;
    border-radius: .3px;
    margin-top: -1px;
    background-color: #fff;
    border-top-left-radius: 0;
    @include media(sm){
      left: 0;
      width: auto;
    }
  }
  .breadcrumbs-collapse{
    position: relative;
    z-index: 22;
    a{
      color: #000;
      &:focus,
      &:hover{
        color: #000;
      }
    }
    &.open{
      filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.1));
      .breadcrumbs-collapse-list{
        display:  flex;
      }
      .breadcrumbs-collapse-btn{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fff;
        color: #000;
      }
      .breadcrumbs-collapse-btn:before{
        opacity: 1;
      }
    }
  }
}

.search-item-top{
  display: block;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include media(xxs, sm) {
    font-size: 16px;
  }
  .home{
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
    transition: all 0.3s ease-out;
    &:after{
      content: "\e918";
      font-size: 20px;
      @include pux-scale-from("font-size", lg, 20px);
      display: block;
      position: relative;
      top: -3px;
      @include media(xxs, sm) {
        font-size: 16px;
      }
    }
    &:hover,
    &:focus{
      text-decoration: none;
      &:after{
        text-decoration: none;
      }
    }
  }
  .search-separator:after{
    position: relative;
    top: 0px;
    display: inline-block;
    content: "\e902";
    vertical-align: middle;
    @include transform(scale(0.25) rotate(270deg));
    margin: auto 0;
  }
}

.search-item{
  margin:0 0 4rem 0;
  &-top{
    padding:0 0 1rem 0;
  }
  .h3,
  .h2{
    margin: 0 0 5px 0;
  }
}

.search-word{
  font-weight: 400;
}

.header-intro{
  display: block;
  position: relative;
  text-align: left;
  min-height: 20rem;
  &:before{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 44;
    background: url("../img/pattern.png") no-repeat center/cover;
  }
  &:after{
    content:"";
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    position: absolute;
    z-index: 33;
  }
  .bgr-image{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
  }

  .header-intro-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    padding: 35px 0;
    z-index: 55;
    @include media(lg) {
      padding: 6rem 0;
    }
    .header-intro-bread,
    .header-intro-title,
    .header-intro-subtitle {
      position: relative;
      //z-index: 3;
    }

    .header-intro-subtitle {
      font-weight: 700;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      margin-top: .5rem;
      @include media(md){
        font-size: 20px;
      }
      @include pux-scale-from("font-size", xl, 20px);

      &:before {
        content: "\e99b";
        margin-right: 1rem;
        position: relative;
        top: -2px;
      }
      span{
        font-weight: 400;
        margin:0 0 0 5px;
      }
      &.header-event-title{
        &:before{
          top: 4px;
        }
      }
    }
    .header-intro-subtitle-iiner{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    &.header-smaller{
      padding: 20px;
      @include media(lg) {
        padding: 3rem 0;
      }
    }
  }
  .header-detail-width{
    @include media(lg){
      max-width: 780px;
      margin: auto;
    }
  }
  &.header-image{
    &:before{
      display: none;
    }
    &:after{
      opacity: 0.9;
    }
  }
}

.text-container{
  padding-top: 8rem;
  @include media(lg){
    max-width: 780px;
    margin: auto;
  }
  .perex{
    line-height: 5rem;
    @include media(lg){
      line-height: 3rem;
    }
  }
  ul{
    margin: 3rem 0;
  }
  a{
    font-weight: 600;
  }
}

.dynamic-container{
  display: flex;
  flex-wrap: wrap;
  margin:0 -2rem;
  justify-content: center;
  .dynamic-box{
    padding:0 2rem 4rem 2rem;
    width: 100%;
    @include media(sm){
      width: 50%;
    }
    @include media(lg){
      width: 33.33%;
    }
    &.box-single{
      @include media(sm){
        width: 100%;
      }
      @include media(lg){
        //width: 50%;
      }
    }
    &.box-half{
      @include media(lg){
        width: 50%;
      }
    }
  }
  &.small-crosslink{
    display: block;
    margin: 0;
  }
}

.w-200{
  @include pux-static-size("width", 200px);
}

.mw-200{
  @include pux-static-size("min-width", 200px);
}

.h-200{
  @include pux-static-size("height", 200px);
}

.widget-container{
  padding: 60px 0;
  position: relative;
  @include media(lg){
    padding: 8rem 0;
  }
  &.with-ico{
    @include media(lg){
      padding: 10rem 0;
    }
    &.with-title{
      @include media(lg){
        padding: 6rem 0 10rem 0;
      }
    }
  }
  &.bgc-white{
    padding:1px 0;
    margin:60px 0;
    @include media(lg){
      margin: 8rem 0;
    }
    &.with-ico{
      padding-top:50px;
      margin:0 0 60px 0;
      @include media(lg){
        margin:0 0 10rem 0;
        padding:10rem 0 1px 0;
      }
      &.with-title{
        @include media(lg){
          padding:6rem 0 1px 0;
          margin:0 0 10rem 0;
        }
      }
    }
  }
  .widget-container{
    margin:0 !important;
    padding:0 !important;
  }
  &.bgc-image{
    padding: 60px 0;
    margin: 0;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    @include media(lg){
      padding: 8rem 0;
    }
    &.with-ico{
      @include media(lg){
        padding: 10rem 0;
      }
      &.with-title{
        @include media(lg){
          padding: 6rem 0 10rem 0;
        }
      }
    }
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 22;
    }
    .pux-container{
      position: relative;
      z-index: 33;
    }
  }
}

.widget-container{
  .numbers-container,
  .gallery-container,
  .banner-container,
  .icons-container,
  .banner-container,
  .inportant-container,
  .tenders-container,
  .card-container,
  .person-container{
    margin-bottom:-4rem;
  }
  .event-page{
    margin-bottom:-6rem;
  }
  &.small-helper{
    .icons-container{
      margin-top:-2rem;
      margin-bottom:0;
    }
  }
}

.widget-title{
  text-align: center;
  margin:-2.5rem 0 5rem 0;
}

.widget-pattern{
  position: relative;
  &:before{
    content:"";
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 22;
    background:url('../img/pattern.png');
  }
}

body{
  &.contrast{
    .header-intro,
    .widget-pattern{
      &:before{
        display: none;
      }
    }
  }
}

.widget-container-inner{
  position: relative;
  z-index: 44;
}

.insert-image{
  a{
    display: inline-block;
    &:before{
      display: none !important;
    }
  }
}

.filter-container{
  padding: 3rem;
  &-inner{
    display: flex;
    flex-wrap: wrap;
    margin:0 -1.5rem;
  }
  .filter-box{
    padding:0 1.5rem 2rem 1.5rem;
  }
}

.circle-btn{
  width: 80px;
  height: 80px;
  border-radius: 60px 60px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:-40px;
  left:50%;
  margin-left:-40px;
  z-index: 33;
  @include media(lg){
    top:-55px;
    margin-left:-55px;
    width: 110px;
    height: 110px;
  }
  span{
    &:before{
      font-size: 43px;
      @include media(lg){
        font-size: 53px;
      }
    }
  }
}

.contact-item{
  display: flex;
  flex-wrap: wrap;
  @include media(lg){
    min-height: 70rem;
  }
  &-helper{
    padding:0 0 0 28px;
  }
  &-left{
    width: 100%;
    padding:6rem;
    @include media(lg){
      width: 40%;
    }
    @include media(xl){
      width: 30%;
    }
  }
  &-right{
    width: 100%;
    position: relative;
    @include media(lg){
      width: 60%;
    }
    @include media(xl){
      width: 70%;
    }
    iframe{
      width: 100%;
      height: 50rem;
      @include media(lg){
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
      }
    }
  }
  .contact-company{
    padding:3rem 0 0 0;
  }
  .btn{
    margin: 4rem 0 0 0;
  }
  .contact-ic{
    display: flex;
    align-items: baseline;
    &:before{
      font-weight: 400;
      margin:0 10px 0 0;
    }
    &.ic-home{
      font-weight: bold;
    }
  }
}

.ic-home{
  &:before{
    content:"\e918";
  }
}

.ic-address{
  &:before{
    content:"\e9a3";
  }
}

.ic-phone{
  align-items: center;
  &:before{
    content:"\e99c";
  }
}

.ic-email{
  align-items: center;
  &:before{
    content:"\e99a";
  }
}

.line-helper{
  width: 6%;
  position: relative;
  align-self: center;
  height: 75px;
  &:before{
    content:"";
    height: 75px;
    border-left-width: 2px;
    border-left-style: dotted;
    position: absolute;
    top:0;
    left:50%;
    margin-left:-1px;
  }
  &:last-child{
    display: none !important;
  }
}

.file-item{
  margin:0 0 5px 0;
}

.text-video-content{
  display: flex;
  margin: 4rem -2rem 0 -2rem;
  flex-wrap: wrap;
  &-text{
    padding: 0 2rem 4rem 2rem;
    width: 100%;
    order: 2;
    @include media(md){
      width: 66.66%;
      order: 1;
    }
  }
  &-video{
    width: 100%;
    padding: 0 2rem 4rem 2rem;
    order: 1;
    @include media(md){
      width: 33.33%;
      order: 2;
    }
    .embed-video{
      height: 30rem;
    }
  }
}

.dynamic-container.small-crosslink{
  margin: 0 -2px;
}

.dynamic-container.small-crosslink .dynamic-box{
  padding: 0 2px 4px 2px;
  flex-grow: 1;
}

.crosslink-helper{
  display: flex;
  flex-wrap: wrap;
}

.dynamic-container .dynamic-box.crosslink-box{
  display: flex;
  width: 100%;
  @media (min-width: 600px){
    width: 50%;
  }
  @include media(lg){
    width: 33.33%;
  }
  &.box-half{
    @include media(lg){
      width: 50%;
    }
  }
  &.box-single{
    @include media(sm){
      width: 100%;
    }
  }
}

.crosslink-item{
  background: #006633;
  display: flex;
  align-items: center;
  padding: 4rem;
  width: 100%;
  .crosslink-item-inner{
    display: flex;
    width: 100%;
    align-items: center;
  }
  .crosslink-item-image{
    width: 50px;
    min-width: 50px;
    margin-right: 15px;
    display: flex;
  }
  .crosslink-ico{
    &:before{
      font-size: 45px;
    }
  }
  .crosslink-item-inner-content-perex{
    font-weight: 400;
  }
  .h3{
    margin: 0 0 5px 0;
  }
  .link{
    font-weight: 400;
  }
  &:focus,
  &:hover{
    .link-arrow:after {
      right: -5px;
    }
  }
}

.crosslink-helper.items-1{
  .crosslink-item{
    border-radius: 20px;
  }
}

.crosslink-helper.items-2{
  .crosslink-box:nth-child(1) .crosslink-item{
    border-radius: 20px 0 0 20px;
  }
  .crosslink-box:nth-child(2) .crosslink-item{
    border-radius: 0 20px 20px 0;
  }
}

.crosslink-helper.items-3{
  .crosslink-box:nth-child(1) .crosslink-item{
    border-radius: 20px 0 0 20px;
  }
  @media (min-width: 100px) and (max-width: 599px){
    .crosslink-box:nth-child(1) .crosslink-item{
      border-radius: 20px 20px 0 0;
    }
    .crosslink-box:nth-child(3) .crosslink-item{
      border-radius: 0 0 20px 20px;
    }
  }
  @media (min-width: 600px) and (max-width: 1023px){
    .crosslink-box:nth-child(1) .crosslink-item{
      border-radius: 20px 0 0 0;
    }
    .crosslink-box:nth-child(2) .crosslink-item{
      border-radius: 0 20px 0 0;
    }
    .crosslink-box:nth-child(3) .crosslink-item{
      border-radius: 0 0 20px 20px;
    }
  }
  @include media(lg){
    .crosslink-box:nth-child(1) .crosslink-item{
      border-radius: 20px 0 0 20px;
    }
    .crosslink-box:nth-child(3) .crosslink-item{
      border-radius: 0 20px 20px 0;
    }
  }
}

.crosslink-helper.items-4{
  .crosslink-box:nth-child(1) .crosslink-item{
    border-radius: 20px 0 0 0;
  }
  @media (min-width: 100px) and (max-width: 599px){
    .crosslink-box:nth-child(1) .crosslink-item{
      border-radius: 20px 20px 0 0;
    }
    .crosslink-box:nth-child(4) .crosslink-item{
      border-radius: 0 0 20px 20px;
    }
  }
  @media (min-width: 600px) and (max-width: 1023px){
    .crosslink-box:nth-child(2) .crosslink-item{
      border-radius: 0 20px 0 0;
    }
    .crosslink-box:nth-child(3) .crosslink-item{
      border-radius: 0 0 0 20px;
    }
    .crosslink-box:nth-child(4) .crosslink-item{
      border-radius: 0 0 20px 0;
    }
  }
  @include media(lg){
    .crosslink-box:nth-child(3) .crosslink-item{
      border-radius: 0 20px 0 0;
    }
    .crosslink-box:nth-child(4) .crosslink-item{
      border-radius: 0 0 20px 20px;
    }
  }
}

.crosslink-helper.items-5{
  .crosslink-box:nth-child(1) .crosslink-item{
    border-radius: 20px 0 0 0;
  }
  .crosslink-box:nth-child(5) .crosslink-item{
    border-radius: 0 0 20px 0;
  }
  @media (min-width: 100px) and (max-width: 599px){
    .crosslink-box:nth-child(1) .crosslink-item{
      border-radius: 20px 20px 0 0;
    }
    .crosslink-box:nth-child(5) .crosslink-item{
      border-radius: 0 0 20px 20px;
    }
  }
  @media (min-width: 600px) and (max-width: 1023px){
    .crosslink-box:nth-child(2) .crosslink-item{
      border-radius: 0 20px 0 0;
    }
    .crosslink-box:nth-child(5) .crosslink-item{
      border-radius: 0 0 20px 20px;
    }
  }
  @include media(lg){
    .crosslink-box:nth-child(4) .crosslink-item{
      border-radius: 0 0 0 20px;
    }
    .crosslink-box:nth-child(3) .crosslink-item{
      border-radius: 0 20px 0 0;
    }
  }
}

.crosslink-helper.items-6{
  .crosslink-box:nth-child(1) .crosslink-item{
    border-radius: 20px 0 0 0;
  }
  .crosslink-box:nth-child(6) .crosslink-item{
    border-radius: 0 0 20px 0;
  }
  @media (min-width: 100px) and (max-width: 599px){
    .crosslink-box:nth-child(1) .crosslink-item{
      border-radius: 20px 20px 0 0;
    }
    .crosslink-box:nth-child(6) .crosslink-item{
      border-radius: 0 0 20px 20px;
    }
  }
  @media (min-width: 600px) and (max-width: 1023px){
    .crosslink-box:nth-child(2) .crosslink-item{
      border-radius: 0 20px 0 0;
    }
    .crosslink-box:nth-child(5) .crosslink-item{
      border-radius: 0 0 0 20px;
    }
  }
  @include media(lg){
    .crosslink-box:nth-child(3) .crosslink-item{
      border-radius: 0 20px 0 0;
    }
    .crosslink-box:nth-child(4) .crosslink-item{
      border-radius: 0 0 0 20px;
    }
  }
}

.gm-ui-hover-effect{
  opacity: 1;
  top: 0 !important;
  right: 0 !important;
  span{
    width: 30px !important;
    height: 30px !important;
    margin: 0 !important;
  }
}

.marker-detail-image-cta{
  margin-top: 15px;
  display: none;
  @include media(md){
    display: block;
  }
}

.iframe-item{
  width: 100%;
  iframe{
    width: 100%;
    border: none;
    display: none;
    @include media(md) {
      display: block;
    }
  }
  .iframe-link {
    text-align: center;
    padding: 2rem 0;

    @include media(md) {
        display: none;
    }
  }
}

.product-gallery{
  margin-bottom: 40px;
  margin-left: -10px;
  margin-right: -10px;
  .col-xs-12{
    padding-bottom: 20px;
  }
  [class^="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }
  .detail-photo-item-text{
    text-align: center;
  }
}