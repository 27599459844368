
.collapsible-item {
  display: flex;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  border-bottom: none;
  font-weight: 400;
  &.with-icon,
  &.with-numbers {
    .collapsible-item-heading {
      padding: 0 2.5rem 0 0;
    }
  }
  &-heading {
    width: 100%;
    padding:0 2.5rem;
    cursor: pointer;
    display: flex;
    transition: all 0.3s ease-out;
    &:after {
      content: '\e9a0';
      display: flex;
      align-items: center;
      font-size: 24px;
      transition: 0.3s ease all;
      margin-left: auto;
      transition: all 0.3s ease-out;
      transform: rotate(180deg);
    }
    h2.h3{
      display: flex;
      min-height: 60px;
      align-items: center;
    }
    h2.h3,
    .collapsible-item-index {
      padding: 1.5rem 0;
      margin: 0 1.5rem 0 0;
      line-height: 1;
      font-weight: 700;
      text-transform: none;
      @include pux-scale-with-min('font-size', 22px, 22px);
    }
    .collapsible-item-index {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 50px;
      min-width: 50px;
      min-height: 50px;
      @include media(md) {
        width: 60px;
        min-width: 60px;
        min-height: 60px;
      }
    }
  }
  &-container {
    width: 100%;
    overflow: hidden;
    .collapsible-item-text  {
      transition: 0.3s ease-in-out opacity;
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
    &.open {
        height: auto;
      .collapsible-item-text {
        height: auto;
        opacity: 1;
        padding: 3rem
      }
      .collapsible-item-heading:after {
        transform: rotate(0deg);
      }
    }
  }
  &-controller {
    width: 7.5rem;
    position: relative;
    cursor: pointer;
    .close, .open {
      font-size: 30px;
      font-weight: 800;
      text-align: center;
      opacity: 1;
      transform: translate(-50%,-50%);
      position: absolute;
      top: 3.7rem;
      left: 50%;
    }
    .close {
      display: inline-block;
    }
    .open {
      display: none;
    }
    &.open {
      .close {
        display: none;
      }
      .open {
        display: inline-block;
      }
    }
  }
  &:last-child{
    border-bottom: 1px solid;
  }
  &.with-icon{
    .collapsible-item-heading{
      .collapsible-item-index{
        padding:0.5rem 0;
        &:before{
          font-size: 35px;
          @include media(md){
            font-size: 40px;
          }
        }
      }
    }
  }
  &-text{
    h2,
    h3,
    h4{
      margin: 0 0 3rem 0;
    }
  }
}
